<template>
	<!-- Top section with settings and provider selection -->
	<div class="appoint-search-header">
		<div class="align-items-center calender_header d-flex" :class="{ 'justify-content-between': this.currentView != 'Month', 'justify-content-evenly': this.currentView == 'Month' }">
			<div class="position-relative d-flex align-items-center float-start">
				<div class="search appment-patientSearch mr-15">
					<img class="serch_icon" src="/images/search-icon.svg" alt="">
					<input type="text" @keyup="patientSearch" @click="patientSearch" class="form-control" placeholder="Search Patient" v-model="patient_search" ref="globalSearch">
				</div>
				<div class="search_drop_box seach-list-scroll" v-if="patientList.length > 0 || this.isShowCreateDemographics">
					<div class="seach-list-vendore">
						<slot v-for="(item, index) in patientList" :key="index">
							<div class="con_drop_line">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="man_haeding">{{ item.last_name }}, {{ item.first_name }}
											<span>({{ item.gender }})</span>
										</div>
										<ul>
											<li>DOB: {{ item.dob }}</li>
											<li>HIN: {{ item.health_insurance_no }}</li>
											<li>Tel: {{ item.cell_phone }}</li>
										</ul>
									</div>
									<div class="col-md-6 text-end">
										<ol class="">
											<li class="prevent" @click="openMasterRecordPage($event, item.patient_id)"><a href="javascript:void(0)" class="">M</a></li>
											<li class="prevent" @click="openEchartRecordPage($event, item.patient_id)"><a href="javascript:void(0)" class="">E</a></li>
											<li class="prevent" @click="openRxPage($event, item.patient_id)"><a href="javascript:void(0)" class="">Rx</a></li>
											<li class="prevent" @click="openPrintLabel($event, item.patient_id)"><a href="javascript:void(0)" class="">Label</a></li>
										</ol>
									</div>
								</div>
							</div>
						</slot>
					</div>
					<div class="create_grpc" :class="{ 'border-top': patientList.length > 0 }">
						<router-link target="_blank" to="/patient/create">CREATE DEMOGRAPHIC</router-link>
					</div>
				</div>
				<span class="popup_link querytool-link fw-600 white-space-nowrap" @click="openQueryTool">Patient Query Tool</span>
			</div>
			<div class="calendar appointment-main-clnder appoint-date-select border-0">
				<ejs-datepicker ref='mainDatePicker' id="mainDatePicker" @change="changeScheduleDate"
					class="text-center e-field form-control " :format="{ skeleton: 'full' }"
					@focus='openDatePicker("mainDatePicker")' :showClearButton="false" :value="selectedDate"
					:allowEdit="false" :closeOnSelection="true" />
			</div>
			<div class="d-flex align-items-center">
				<div class="menu_list">
					<ul class="p-0 m-0 d-flex align-items-center">
						<li class="m-0" :class="{ active: currentView==='Day' }" @click="changeView('Day', $event)" ref="dayButton">Day</li>
						<li :class="{ active: currentView==='Week' }" @click="changeView('Week', $event)" ref="weekButton">Week</li>
						<li :class="{ active: currentView==='Month' }" @click="changeView('Month', $event)" class="m-0" ref="monthButton">Month</li>
					</ul>
				</div>
				<img id="settingIcon" src="/images/dots-icon.svg" class="dot_icon" @click="openSetting" v-if="currentView!=='Month'">
				<div class="selact_dropdown provid-select-appointment" v-if="currentView!=='Month'">
					<selectCheckBox :dataSource='provider_list' :labelName="tagProviderLabel" @clicked="selected_provider" @last-selected-item="handleLastSelectedItem" @last-item="handleLastItem" :displayDiv="displayDiv" :key="select_box_key" :isManageProviderId="true" />
				</div>
			</div>
		</div>
	</div>
	<!-- Main section with the schedule view -->
	<div :key="scheduleKey">
		<!-- Essential JS 2 Scheduler component configuration -->
		<div :class="{ 'd-none':(!hasResourceavailable || !providerListForNoResourceScreen) && this.currentView != 'Month'}">
			<ejs-schedule 
				id="schedule" 
				ref='scheduleObj' 
				height='850px' 
				width='100%'
				:editorTemplate="schedulerEditorTemplate" 
				:eventSettings='eventSettings' 
				:views="viewsDataSource"
				:popupOpen='onPopupOpen' 
				:eventRendered="onEventRendered" 
				:timeScale="timeScale" 
				:popupClose="onPopupClose"
				:allowDragAndDrop="allowDragAndDrop" 
				:group="groupResource" 
				:showQuickInfo="false" 
				:startHour="startHour" 
				:endHour="endHour" 
				cssClass='header-schedule' 
				:showHeaderBar='false' 
				:selectedDate="selectedDate" 
				:cellClick="onCellClick" 
				:eventClick="onEventClick" 
				:eventDoubleClick="onEventDoubleClick" 
				:renderCell='onRenderCell' 
				:dataBinding="onDataBinding"
				@dataBound="onDataBound" 
				:dragStop="onDragStop" 
				:showTimeIndicator="false" 
				:editorFooterTemplate="editorFooterTemplate" 
				:editorHeaderTemplate="editorHeaderTemplate"
			>
			<!-- Custom editor template for creating or editing events -->
			<template v-slot:schedulerEditorTemplate="{ }">
				<div class="p-15 appointment_popup">
					<!-- Input fields fappointment_popupor event details -->
					<div class="row justify-content-between">
						<!-- Demographic input -->
						<div class="col-md-6 custom-md-6">
							<label class="form-label mb-3">Demographic </label>
							<!-- Input field for Demographic -->
							<div class="search">
								<img class="serch_icon" src="/images/search-icon.svg" alt="">
								<input type="text" id="patient_id" name="patient_id" class="e-field e-input form-control" placeholder="Search Patient" @click="schedulerPatientSearch" @keyup="schedulerPatientSearch" v-model="appointmentForm.patient_name" autocomplete="off" ref="globalModalSearch">
								<div class="search_drop_box global-search" v-if="schedulerPatientList.length > 0 || this.isShowAppointmentCreateDemographics">
									<div class="srch-vndor-list-scroll" v-if="schedulerPatientList.length > 0">
										<slot v-for="(item, index) in schedulerPatientList" :key="index">
											<div class="con_drop_line" @click="schedulerPatientSelection($event, item)">
												<div class="row align-items-center">
													<div class="col-md-6">
														<div class="man_haeding">{{ item.last_name }}, {{ item.first_name }}
															<span>({{ item.gender }})</span>
														</div>
														<ul>
															<li>
																DOB: {{ item.dob }}
															</li>
															<li>
																HIN: {{ item.health_insurance_no }}
															</li>
															<li>
																Tel: {{ item.cell_phone }}
															</li>
														</ul>
													</div>
													<div class="col-md-6 text-end">
														<ol class="">
															<li class="prevent" @click="openMasterRecordPage($event, item.patient_id)"><a href="javascript:void(0)">M</a></li>
															<li class="prevent" @click="openEchartRecordPage($event, item.patient_id)"><a href="javascript:void(0)">E</a></li>
															<li class="prevent" @click="openRxPage($event, item.patient_id)"><a href="javascript:void(0)">Rx</a></li>
															<li class="prevent" @click="openPrintLabel($event, item.patient_id)"><a href="javascript:void(0)" class="">Label</a></li>
														</ol>
													</div>
												</div>
											</div>
										</slot>
									</div>
									<div class="create_grpc" :class="{ 'border-top': schedulerPatientList.length > 0 }"><router-link target="_blank" to="/patient/create">CREATE DEMOGRAPHIC</router-link></div>		
								</div>
							</div>
							<div class="invalid-feedback mb-1" style="display:block;" v-for="(error, index) of v$.appointmentForm.patient_id.$errors" :key="index">{{ error.$message }}</div>
							<div class="pt-3 pb-4 mb-3 patient_card_detail">
								<div class="card_status" v-if="this.appointmentForm.is_hcv_verified">
									<div class="d-flex align-items-center">
										<span class="icon">&#9679;</span> <span class="txt"> Patient Health Card Passed Validation</span>
									</div>
								</div>
								<div class="card_status" v-else>
									<div class="d-flex align-items-center">
										<span class="icon red">&#9679;</span> <span class="txt"> Patient Health Card Does Not Exist / Invalid</span>
									</div>
								</div>
							</div>
						</div>
						<!-- Scheduling Provider input -->
						<div class="col-md-6 ps-lg-3">
							<div class="ps-lg-4">
								<label class="form-label mb-3 ms-1">Scheduling Provider </label>
								<!-- Input field for Scheduling Provider -->
								<div class="Selact_drodwn" ref="dropdownSProviderRef">
									<div class="dropdown-div position-relative" @click="toggleProviderList">
										<div class="form-control caption"><img src="/images/doctor-man-icon.svg"
												class="ms-0 me-2" alt=""> {{ (appointmentForm.provider_name != null) ?
													appointmentForm.provider_name : 'Select Provider' }}</div>
										<div class="list" v-show="showProviderList">
											<div v-for="(item, index) in providerDataSource" :key="index" class="item"
												@click="selectProvider(item)">
												{{ item.name }}
											</div>
										</div>
									</div>
								</div>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.provider_id.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="pt-3 pb-4 patient_card_detail ms-1">
									<div class="Patient_task_link mb-3" v-if="this.appointmentForm.is_task" @click="redirectActiveTask(this.appointmentForm.patient_id)">
										<label><img src="/images/long_arrow.svg" class="img-fluid" style="width: calc(var(--scale-ratio) * 30px);"></label>
										<span>Patient has Unresolved Tasks</span>
									</div>
									<div class="Patient_task_link mb-3" v-if="!this.appointmentForm.is_hcv_verified && this.appointmentForm.id" @click="openBillingPage($event, appointmentForm.patient_id, appointmentForm.patient_name, appointmentForm.provider_id, appointmentForm.provider_name, 1)">
										<label><img src="/images/long_arrow.svg" class="img-fluid" style="width: calc(var(--scale-ratio) * 30px);"></label>
										<span>Proceed to Create Private Invoice</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row justify-content-center text-center mb-3">
						<div class="col-md-3 pb-3">
							<div class="form-group">
								<label class="form-label">Date</label>
								<div class="controls _datepic appntmnt-detls-date">
									<ejs-datepicker ref='appointment_date' class="e-field e-input" :format="dateFormat" :showClearButton="false" name="appointment_date" id="appointment_date" @change="schedulerDateChange('appointment_date')" @keyup="keyupdate('appointment_date')" @blur="schedulerDateChange('appointment_date')" @focus="openDatePicker('appointment_date')"></ejs-datepicker>
								</div>
								<slot v-if="v$.appointmentForm.appointment_date.$errors.length>0">
									<div  class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.appointment_date.$errors" :key="index">
										{{ error.$message }}
									</div>
								</slot>
								<slot v-else>
									<div class="invalid-feedback" style="display:block;">
										{{ validDateError }}
									</div>
								</slot>
							</div>
						</div>
						<div class="col-md-3 pb-3">
							<div class="form-group">
								<label class="form-label cursor-text">Start Time</label>
								<div class="controls appoin-start-time">
									<CustomTimePicker v-model="appointmentForm.appointment_starttime" @change="schedulerStartTimeChange"></CustomTimePicker>
								</div>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.appointment_starttime.$errors" :key="index">
									{{ error.$message }}
								</div>
							</div>
						</div>
						<div class="col-md-3 pb-3">
							<div class="form-group">
								<label class="form-label"> Duration (Mins) </label>
								<div class="controls m-auto duration-mins-dropdown">
									<CustomDropDown :options="duration_options" :initialValue="this.appointmentForm.duration_text" @item-selected="handleItemSelected" fieldName="duration"></CustomDropDown>

								</div>
							</div>
						</div>
						<div class="col-md-3 pb-3">
							<div class="form-group">
								<label class="form-label">End Time</label>
								<div class="controls endtime-fild-input">
									<input type="text" class="e-field e-input form-control text-center" name="appointment_endtime" id="appointment_endtime" value="" readonly>
								</div>
								<slot v-if="v$.appointmentForm.appointment_endtime.$errors.length>0">
									<div  class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.appointment_endtime.$errors" :key="index">
										{{ error.$message }}
									</div>
								</slot>
								<slot v-else>
									<div class="invalid-feedback" style="display:block;">
										{{ validEndTimeError }}
									</div>
								</slot>
							</div>
						</div>
						<div class="col-md-3 py-3 mt-3 ">
							<label class="form-label">Status </label>
							<div class="Selact_drodwn unline_remove" ref="dropdownStatusRef">
								<div class="dropdown-div" @click="toggleStatusList">
									<div class="form-control caption"> {{ (appointmentForm.appointment_status != null) ?
										appointmentForm.appointment_status : 'Status' }} <img src="/images/down-arrow-new.svg"
											class="img-fluid" :class="{ rotate180: this.showStatusList }"></div>
									<div class="list" v-show="showStatusList">
										<div v-for="(item, index) in StatusDataSource" :key="index" class="item"
											@click="selectStatus(item)">
											{{ item.status_label }}
										</div>
									</div>
								</div>
							</div>
							<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.status_id.$errors" :key="index">
								{{ error.$message }}
							</div>
						</div>
						<div class="col-md-3 py-3 mt-3">
							<label class="form-label">Mode </label>
							<div class="Selact_drodwn" ref="dropdownModeRef">
								<div class="dropdown-div" @click="toggleTypeList">
									<div class="form-control caption"> {{ (appointmentForm.appointment_type != null) ?
										appointmentForm.appointment_type : 'Mode' }} <img src="/images/down-arrow-new.svg"
											class="img-fluid" :class="{ rotate180: this.showTypeList }"></div>
									<div class="list" v-show="showTypeList">
										<div v-for="(item, index) in TypeDataSource" :key="index" class="item"
											@click="selectType(item)">
											{{ item.name }}
										</div>
									</div>
								</div>
							</div>
							<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.type_id.$errors" :key="index">
								{{ error.$message }}
							</div>
						</div>
					</div>
					<div class="row mb-3">	
						<div class="col-md-12 pb-3">
							<div class="text-left">
								<label class="form-label">Reason </label>
								<textarea id="reason_for_visit" ref='reason_for_visit' class="e-field form-control e-input reason-textarea" name="reason_for_visit" rows="3" cols="50"
									style="width: 100%; height: calc(var(--scale-ratio) * 100px) !important; resize: vertical"
									v-model="appointmentForm.reason_for_visit" @input="handleInputReasonVisit"></textarea>
								
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.appointmentForm.reason_for_visit.$errors" :key="index">
									{{ error.$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<!-- Custom template for rendering events in the schedule -->
			<template v-slot:schedulerTemplate="{ data }">
				<div class="subject dayView view-main h-100" v-if="(currentView=='Day' || currentView=='Today')" :class="{'w-100':this.checkedProviderCount() == 4, 'provider-caldr-name-3':this.checkedProviderCount() == 3, 'provider-caldr-name-2':this.
				checkedProviderCount() == 2, 'provider-caldr-name-1':this.checkedProviderCount() == 1}">
					<ul class="calendar_data_table w-100 h-100 d-flex" :class="{'d-flex':this.checkedProviderCount() == 2,'d-flex':this.checkedProviderCount() == 1 }">
						<li class="" :class="{'provider-caldr-4':this.checkedProviderCount() == 4, 'provider-caldr-3':this.checkedProviderCount() == 3,'provider-caldr-2':this.checkedProviderCount() == 2,'provider-caldr-1':this.checkedProviderCount() == 1 }">
							<div class="d-flex justify-content-between align-items-center h-100" :class="{'w-100':this.checkedProviderCount() == 4, }">
								<div class="target-click">
									<span v-if="data.is_hcv_verified"><img src="/images/calendar-icon.svg"></span> 
									<span v-else class="opacity-0"><img src="/images/calendar-icon.svg"></span>
									<slot>
										<div class="icon_tooltip appointment-list-clande patient-name-popper">
											<Popper class="theme" placement="bottom" hover arrow>
												<template v-slot:content>
													<span v-html="data.patientHtmlContent" class="popper-content"></span>
												</template>
												<div class="target-click toltip_dtl_prdr"  :class="{'tooltip-provider-name-2':this.checkedProviderCount() == 2}">
													<slot v-if="data.patient_name.length > 20">
															<p class="target-click m-0 d-inline-block ap-patient_name" :class="{'provider-name-7':this.checkedProviderCount() == 7,'provider-name-1':this.checkedProviderCount() == 1,'provider-name-4':this.checkedProviderCount() == 4,'provider-name-2':this.checkedProviderCount() == 2}">
															{{ data.patient_name.substring(0, 20) }}...
															</p>
													</slot>
													<slot v-else>
														<p class="target-click m-0 d-inline-block ap-patient_name" :class="{'provider-name-7':this.checkedProviderCount() == 7,'provider-name-1':this.checkedProviderCount() == 1,'provider-name-4':this.checkedProviderCount() == 4,'provider-name-2':this.checkedProviderCount() == 2}">
															{{ data.patient_name }}
														</p>
													</slot>
													<sup class="text text-danger" v-if="data.is_task || (data.is_hcv_verified === 0 && data.health_insurance_no != null && data.health_card_type == 'ON')">
														!
													</sup>
												</div>
											</Popper>
										</div>
									</slot>

								</div>
								<ol class="list_menu_txt p-0 d-flex align-items-center h-100" :class="{'room-circle-li':!this.extractNumberFromString(data.appointment_status)}">
									<li >
										<span class="circle_count" v-if="this.extractNumberFromString(data.appointment_status)">{{ this.extractNumberFromString(data.appointment_status) }}</span>
									</li>
									<li class="fasr_latter" @click="openMasterRecordPage($event, data.patient_id)" v-if="this.checkedProviderCount() < 3 ">
										M
									</li>
									<li class="fasr_latter" @click="openEchartRecordPage($event, data.patient_id)" v-if="this.checkedProviderCount() < 3 ">
										E
									</li>
									<li class="fasr_latter" v-if="this.checkedProviderCount() < 3 " @click="openBillingPage($event, data.patient_id, data.patient_name, data.provider_id, data.provider_name)">B</li>
									<li class="fasr_latter" @click="openRxPage($event, data.patient_id)" v-if="this.checkedProviderCount() < 3 ">
										Rx
									</li>
									<li class="drop-trigger"  @click="showEncounterDropdown" :class="echartDropdownClass(data.provider_id)"> 
										<i class="fa-solid fa-chevron-down"></i>
										<div class="drop-options encounterDropdown d-none">
											<div class="drop-option" @click="openMasterRecordPage($event, data.patient_id)">Master Record
											</div>
											<div class="drop-option" @click="openEchartRecordPage($event, data.patient_id)">
													E-Chart
											</div>
											<div class="drop-option" @click="openBillingPage($event, data.patient_id, data.patient_name, data.provider_id, data.provider_name)">Billing</div>
											<div class="drop-option" @click="openRxPage($event, data.patient_id)">
												Rx
											</div>
											<div class="drop-option" @click="openMeasurementPage($event, data.patient_id)">
												Measurements
											</div>
											<div class="drop-option" @click="openPrintLabel($event, data.patient_id)">Print Label</div>

										</div>
									</li>
								</ol>
							</div>
						</li>
						<li class="ml-15 w-100 d-flex align-items-center h-100" v-if="this.checkedProviderCount() <= 4">
							<div class="fasr_latter target-click" :class="{'pntnt-description-4':this.checkedProviderCount() == 4, 'pntnt-description-3':this.checkedProviderCount() < 4 && this.checkedProviderCount() > 1}">{{ data.reason_for_visit }}</div>
						</li>
					</ul>
				</div>
				<div class="weekView view-main"  v-if="currentView=='Week' ">
					<ul class="calendar_data_table appointmnt-ad-list">
						<li>
							<div class="d-flex justify-content-between align-items-center">
								<div class="target-click d-flex align-items-center">
									<span v-if="data.is_hcv_verified"><img src="/images/calendar-icon.svg"></span> 
									<span v-else class="opacity-0"><img src="/images/calendar-icon.svg"></span>
									<slot>
										<div class="icon_tooltip patient-name-popper">
											<Popper class="theme" placement="bottom" hover arrow>
												<template v-slot:content>
													<span v-html="data.patientHtmlContent" class="popper-content"></span>
												</template>
												<div class="target-click toltip_dtl_prdr">
													<p class="target-click m-0 d-inline-block">
														<slot v-if="data.patient_name.length > 20">
															{{ data.patient_name.substring(0, 20) }}...
														</slot>
														<slot v-else>
															{{ data.patient_name }}
														</slot>
													</p>
													<sup class="text text-danger" v-if="data.is_task || (data.is_hcv_verified === 0 && data.health_insurance_no != null && data.health_card_type == 'ON')">
														!
													</sup>
												</div>
											</Popper>
										</div>
									</slot>
								</div>
								<ol class="list_menu_txt p-0">
									<li class="drop-trigger"  @click="showEncounterDropdown"  :class="echartDropdownWeekClass(data.appointment_date)"> 
										<img src="/images/down-arrow-new.svg" alt="" class="">
										<div class="drop-options encounterDropdown d-none">
											<div class="drop-option" @click="openMasterRecordPage($event, data.patient_id)">Master Record
											</div>
											<div class="drop-option" @click="openEchartRecordPage($event, data.patient_id)">
													E-Chart
											</div>
											<div class="drop-option" @click="openBillingPage($event, data.patient_id, data.patient_name, data.provider_id, data.provider_name)">Billing</div>
											<div class="drop-option" @click="openRxPage($event, data.patient_id)">
												Rx
											</div>
											<div class="drop-option" @click="openMeasurementPage($event, data.patient_id)">
												Measurements
											</div>
											<div class="drop-option" @click="openPrintLabel($event, data.patient_id)">Print Label</div>
										</div>
									</li>
								</ol>
							</div>
						</li>
					</ul>
				</div>
				<div class="monthView view-main w-100"  v-if="currentView=='Month'">
					<slot v-for="(item, key) in additionalData" :key="key">
						<div v-if="item.Date === data.Date" class="month_scroll calendar_data_table provider-moth-appointment">
							<div class="pe-0 provider_date_appntmt">
								<span class="dscrpton-name-w-date d-block" v-for="(desc, index) in item.Description" :key="index" v-html="desc"></span>
							</div>
						</div>
					</slot>
				</div>
			</template>

			<template v-slot:editorFooterTemplate="{ data }">
				<div id="right-button" class="d-flex appointment-dtls-button w-100">
					<button id="appointmentCancel" className="e-control e-btn e-cancel-btn mx-5"> Cancel </button>
					<button id="appointmentDelete" className="e-control e-btn e-delete-btn mx-5" v-if="data.id" @click="onDeleteEvent(data)">Delete</button>
					<button id="appointmentSave" className="e-control e-btn e-save-btn mx-5"> Save</button>
				</div>
			</template>

			<template v-slot:editorHeaderTemplate="{ }">
				<div class="event-header">
					<div class="e-dlg-header-content" id="schedule_dialog_wrapper_dialog-header">
						<button id="appointmentClose" class="e-dlg-closeicon-btn e-control e-btn e-lib e-flat e-icon-btn" type="button" title="Close" aria-label="Close"><span class="e-btn-icon e-icon-dlg-close e-icons"></span></button>
						<div class="e-dlg-header" id="schedule_dialog_wrapper_title">
							<div class="e-title-text">APPOINTMENT DETAILS</div>
						</div>
					</div>
				</div>
			</template>

			<!-- Configure available views for the scheduler -->
			<e-views>
				<e-view option="Day" :isSelected="currentView==='Day'" :allowVirtualScrolling="true"></e-view>
				<e-view option="Week" :isSelected="currentView==='Week'" :allowVirtualScrolling="true"></e-view>
				<e-view option="Month" :isSelected="currentView==='Month'"  :allowVirtualScrolling="true"></e-view>
			</e-views>

			<!-- Configure resources for grouping events (e.g., by provider) -->
			<e-resources>
				<e-resource field="provider_id" title="Provider" name="Providers" :dataSource="filteredProviderDataSource"
					textField="name" idField="id" :allowVirtualScrolling="true">
				</e-resource>
			</e-resources>
		</ejs-schedule>
		</div>
		<div v-if="(!hasResourceavailable || !providerListForNoResourceScreen) && this.currentView != 'Month'">
			<div>
				<p class="pls-select-note text-center">Please select a provider to view the schedule.</p>
			</div>
		</div>
	</div>
	<!-- Settings modal for adjusting preferences -->
	<div class="modal fade" id="appointmentView-setting" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeAppPrefrences"></button>					
					<div class="box">
						<h2 class="popup_title">APPOINTMENT PREFERENCES</h2>
						<div class="row box-border justify-content-center p-10">
							<!-- Input fields for setting preferences -->
							<div class="col-md-4">
								<div class="pref-strt-end-time text-center">
									<label class="form-label">Start Time</label>
									<CustomTimePicker v-model="preferenceForm.startHour" @input="checkEndTime"></CustomTimePicker>
								</div>
							</div>
							<div class="col-md-4">
								<div class="pref-strt-end-time text-center">
									<label class="form-label">End Time</label>
									<CustomTimePicker v-model="preferenceForm.endHour" @input="checkEndTime"></CustomTimePicker>
								</div>
							</div>
							<div class="col-md-4">
								<div class="schedule-interval-mins text-center">
									<label class="form-label">Schedule Interval (Mins)</label>
									<CustomDropDown :options="duration_options" :initialValue="this.preferenceForm.defaultDuration_text" @item-selected="handleItemSelected" fieldName="defaultDuration"></CustomDropDown>
								</div>
							</div>

							<!-- Submit button to store preferences -->
							<div class="col-md-11 my-5 px-0">
								<div class="con_detl ps-5">
									<label>Auto-populate preferences to show all appointments</label>
									<input class="form-check-input me-4" :checked="isAppPrefChecked" v-model="isAppPrefChecked" type="checkbox" id="checkboxNoLabel" @change="getAppPrefTime">
								</div>
								<span class="m_title">Appointment Day-Sheet</span>
								<div class="con_detl ps-5">
									<label>Sorted by Timeline</label>
									<a href="#" class="dwn_link" @click="downloadPDF('byTimeline')">Download</a>
								</div>
								<div class="con_detl ps-5 mb-0">
									<label>Sorted by Patient Name Alphabetically</label>
									<a href="#" class="dwn_link" @click="downloadPDF('byPatientName')">Download</a>
								</div>
							</div>
							<div class="text-center appo-schedule-btn">
								<button type="button" class="comman_brdr_btn mx17" data-bs-dismiss="modal">Cancel</button>
								<button type="button" class="comman_btn mx17" @click="storePreference">Update </button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="appointment-confirm-delete" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg">
			<div class="modal-content">
				<div class="modal-body">
					<div class="box">
						<div class="text-center py-5">
							<span class="m_title">Are you sure to DELETE this appointment?</span>
						</div>
						<div class="text-center mt-4 appoint-delete-btn">
							<button type="button" class="comman_brdr_btn me-4" data-bs-dismiss="modal" @click="cancelDelete">Cancel</button>
							<button type="button" class="comman_btn ms-4" data-bs-dismiss="modal" @click="confirmDelete">Delete</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="contact_us_modal" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl contact_create_modal">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div>
							<h3 class="main_heading mb-40 l-h-n">Contact Us</h3>
							<p class="pt-15 pb-15 fs-16 l-h-n text-center fw-300 mb-35">We’re here to answer any question about our services. Please complete the following form to get connected directly.</p>
							<div class="connected-form-section">
								<div class="row">
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">First Name</label>
											<input type="text" class="form-control cursor-text" v-model="contactForm.first_name">
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
												v-for="(error, index) of v$.contactForm?.first_name?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.first_name">
												{{ errors.first_name[0] }}
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">Last Name</label>
											<input type="text" class="form-control cursor-text" v-model="contactForm.last_name">
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
												v-for="(error, index) of v$.contactForm?.last_name?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.last_name">
												{{ errors.last_name[0] }}
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">Email</label>
											<input type="email" class="form-control cursor-text" v-model="contactForm.email">
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
												v-for="(error, index) of v$.contactForm?.email?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.email">
												{{ errors.first_name[0] }}
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">Phone</label>
											<input type="text" class="form-control cursor-text" v-model="contactForm.phone">
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
												v-for="(error, index) of v$.contactForm?.phone?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.phone">
												{{ errors.phone[0] }}
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-12 col-lg-12">
										<div class="contact-form mb-40 l-h-n">
											<label class="form-label cursor-text mb-5p">How can we help you?</label>
											<textarea class="form-control" rows="3" v-model="contactForm.message"></textarea>
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
												v-for="(error, index) of v$.contactForm?.message?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.message">
												{{ errors.message[0] }}
											</div>
										</div>
									</div>
								</div>
								<div class="btn_part text-center">
									<button class="comman_btn fs-18" @click="submitContactForm">Submit <img src="images/loader.gif" v-if="this.loader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

<PrintLabelModal ref="printLabelModal" :patientId="this.selectedPatientId" />


</template>

<script>
import { Day, Week, Month, ScheduleComponent, DragAndDrop, ViewsDirective, ViewDirective, ResourcesDirective, ResourceDirective } from "@syncfusion/ej2-vue-schedule";
import moment from "moment";
import axios from "@/scripts/axios.js";
import { DataManager, CustomDataAdaptor } from "@syncfusion/ej2-data";
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import $ from "jquery";
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { Internationalization, L10n } from '@syncfusion/ej2-base';
import useVuelidate from "@vuelidate/core";
import { required, helpers, maxLength, email, minLength, numeric } from "@vuelidate/validators";
import Popper from "vue3-popper";
import CustomTimePicker from "../base/formFields/CustomTimePicker.vue"
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import PrintLabelModal from '../base/PrintLabelModal.vue';
import debounce from 'lodash/debounce';

L10n.load({
	'en-US': {
		'schedule': {
			'newEvent': 'APPOINTMENT DETAILS',
			'editEvent': 'APPOINTMENT DETAILS',
		},
	}
});

export default {
	setup: () => {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			appointmentForm: {
				patient_id: {
					required: helpers.withMessage("Please select Patient", required)
				},
				provider_id: {
					required: helpers.withMessage("Please select Provider.", required)
				},
				appointment_date: {
					required: helpers.withMessage("Please select Date.", required)
				},
				appointment_starttime: {
					required: helpers.withMessage("Please select Start Time.", required)
				},
				appointment_endtime: {
					required: helpers.withMessage("Invalid end time.", required)
				},
				duration: {
					required: helpers.withMessage("Please select Duration.", required)
				},
				reason_for_visit: {
					required: helpers.withMessage("Please enter reason.", required),
					maxLength: helpers.withMessage("Maximum 50 characters allowed.", maxLength(50)),
				},
				status_id: {
					required: helpers.withMessage("Please select Status.", required)
				},
				type_id: {
					required: helpers.withMessage("Please select Mode.", required)
				},
			},
			contactForm: {
				first_name: { required: helpers.withMessage("Please enter First Name.", required) },
				last_name: { required: helpers.withMessage("Please enter Last Name.", required) },
				email: {
					required: helpers.withMessage("Please enter Email.", required),
					email: helpers.withMessage("Please enter a valid Email.", email),
				},
				phone: {
					required: helpers.withMessage("Please enter Phone.", required),
					maxLength:helpers.withMessage("Please enter phone number max 20 char.", maxLength(20)),
					minLength:helpers.withMessage("Please enter phone number min 10 char.", minLength(10)),
                    numeric: helpers.withMessage("Please enter digits only.", numeric),
				},
				message: { required: helpers.withMessage("Please enter Message.", required) },
			},
		};
	},
	components: {
		'ejs-schedule': ScheduleComponent,
		"e-views": ViewsDirective,
		"e-view": ViewDirective,
		"e-resources": ResourcesDirective,
		"e-resource": ResourceDirective,
		'ejs-datepicker': DatePickerComponent,
		selectCheckBox,
		Popper,
		CustomTimePicker,
		CustomDropDown,
		PrintLabelModal
	},
	data() {
		return {
			appointmentList: [],
			groupedList: [],
			showProviderList: false,
			showStatusList: false,
			showTypeList: false,
			appointmentForm: {
				id: null,
				patient_id: null,
				patient_name: null,
				provider_id: null,
				provider_name: null,
				appointment_date: null,
				appointment_starttime: null,
				appointment_endtime: null,
				duration: null,
				reason_for_visit: null,
				status_id: null,
				appointment_status: null,
				type_id: null,
				appointment_type: null,
			},
			selectedDate: new Date(),
			// Key to track changes and force re-rendering of the schedule component
			scheduleKey: 0,
			// Form data for user preferences
			preferenceForm: {
				startHour: "06:00",
				endHour: "23:00",
				defaultDuration: 10,
				defaultDuration_text: '10'
			},
			// Default schedule start time
			startHour: "06:00",
			// Default schedule end time
			endHour: "23:00",
			// Default event duration
			defaultDuration: 10,
			// Time scale configuration for the schedule
			timeScale: {
				enable: true,
				interval: 10,
				slotCount: 1,
			},
			// Resource configuration for grouping by provider
			groupResource: { resources: ["Providers"] },
			// Available views for the schedule
			viewsDataSource: ["Day", "Week", "Month"],
			// Event settings for the schedule
			eventSettings: {
				template: "schedulerTemplate",
				dataSource: this.dataManager(),
				allowDeleting: true
			},
			// List of providers for filtering
			provider_list: [],
			provider_list_resources: [],
			// Display flag for provider selection dropdown
			displayDiv: false,
			// Key to force re-rendering of the selectCheckBox component
			select_box_key: 0,
			// Data source for the provider dropdown in the schedule
			providerDataSource: [],
			TypeDataSource: [],
			StatusDataSource: [],
			// Flag for user inactivity
			userInactive: false,
			// Flag to track changes in the calendar
			changeInCalendar: false,
			dateFormat: 'yyyy-MM-dd',
			schedulerPatientList: [],
			patientList: [],
			patient_search: null,
			currentView: "Day",
			allowDragAndDrop: true,
			scheduledAppointments: {},
			appointmentToDelete: null,
			isValidDuration: true,
			isAppPrefChecked: false,
			isMultiAppointment: [],
			defaultProviderChecked: 7,
			isShowCreateDemographics: false,
			lastSelectedItem: null,
			lastItem: false,
			additionalData: [],
			selectedProviderSortIds: [],
			tagProviderLabel: "Select Provider(s)",
			isShowAppointmentCreateDemographics: false,
			hasResourceavailable: false,
			duration_options: [
				{ value: 5, title: "05" },
				{ value: 10, title: "10" },
				{ value: 15, title: "15" },
				{ value: 20, title: "20" },
				{ value: 25, title: "25" },
				{ value: 30, title: "30" },
				{ value: 35, title: "35" },
				{ value: 40, title: "40" },
				{ value: 45, title: "45" },
				{ value: 50, title: "50" },
				{ value: 55, title: "55" },
				{ value: 60, title: "60" },
			],
			validDateError: null,
			validEndTimeError: null,
			isAvailabilityValidated: true,
			isSaveEvent: false,
			selectedPatientId: null,
			providerAppointmentCount:{},
			contactForm: {
				first_name: null,
				last_name: null,
				email: null,
				phone: null,
				message: null,
				from: 'appointment',
			},
			loader: false,
			schedulerEditorTemplate: "schedulerEditorTemplate",
			editorFooterTemplate: "editorFooterTemplate",
			editorHeaderTemplate: "editorHeaderTemplate",
			providerListForNoResourceScreen: true
		}
	},
	provide: {
		// Provide schedule views and drag-and-drop functionality
		schedule: [Day, Week, Month, DragAndDrop]
	},
	methods: {
		setDynamicCellHeight() {
			const startMinutes = this.convertTimeToMinutes(this.startHour); // 9:00 AM -> 540 minutes
			const endMinutes = this.convertTimeToMinutes(this.endHour); // 3:00 PM -> 900 minutes

			const totalTimeSpan = endMinutes - startMinutes;
			const viewportHeight = window.innerHeight;
			
			// Calculate the height with a minimum of 36px
			const calculatedHeight = Math.max(viewportHeight / (totalTimeSpan / this.defaultDuration), 36);
			
			// Calculate the height of the cell plus icon
			let cellPlusIconHeight = calculatedHeight > 36 
				? calculatedHeight / (this.defaultDuration >= 60 ? 2 : 2.35) 
				: 11;

			// Update styles dynamically without creating a new <style> tag
			const style = document.createElement('style');
			style.type = 'text/css';

			style.innerHTML = `
				.e-schedule .e-vertical-view .e-time-cells-wrap table td,
				.e-schedule .e-vertical-view .e-work-cells {
				height: ${calculatedHeight}px !important;
				}
				.e-schedule .e-vertical-view .e-content-wrap table td.e-work-cells:hover:after {
				top: ${cellPlusIconHeight}px;
				}
			`;

			// Append the new style to the document head
			document.head.appendChild(style);
		},
		convertTimeToMinutes(time) {
			const [timePart, modifier] = time.split(' ');
			let [hours, minutes] = timePart.split(':').map(Number);

			// Convert based on AM/PM
			if (modifier === 'PM' && hours < 12) hours += 12; // Convert PM hours (e.g., 1 PM to 13)
			if (modifier === 'AM' && hours === 12) hours = 0; // Convert 12 AM to 00

			return hours * 60 + minutes; // Convert to minutes
		},
		openContactUsModel() {
			window.$("#contact_us_modal").modal("show");
		},
		submitContactForm() {
			this.v$.contactForm.$validate();
			if (!this.v$.contactForm.$error) {
				this.contactForm.from = 'appointment';
				this.loader = true;
				axios.post('/auth/contact-us', this.contactForm)
					.then(response => {
						this.loader = false;
						if (response.data.success) {
							this.$filters.moshaToast(response.data.message, "success");
							this.contactForm = { first_name: '', last_name: '', email: '', phone: '', message: '' };
							this.v$.contactForm.$reset();
							this.contactForm.from = 'appointment';
							window.$("#contact_us_modal").modal("hide");
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
					})
					.catch(error => {
						this.loader = false;
						if (error.response.data.errors) {
							console.log(error.response.data.errors);
						} else {
							console.log("An error occurred. Please try again later.");
						}
					});
			} else {
				for (const [key] of Object.entries(this.v$.contactForm)) {
					if (this.v$.contactForm[key].$errors && this.v$.contactForm[key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}
		},
		openPrintLabel(event, patientId) {
			event.stopPropagation();
			this.selectedPatientId = patientId;
			this.$nextTick(() => {
				this.$refs.printLabelModal.openModal();
			});
		},
		keyupdate(obj) {
			if ($(`#${obj}-error`)) {
				$(`#${obj}-error`).text("");
			}
			const inputValue = $(`#${obj}`).val();
			var sanitizedInput = inputValue.replace(/[^\d]/g, '');
			if (sanitizedInput.length > 8) {
				sanitizedInput = sanitizedInput.substring(0, 8);
			}
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});
			$(`#${obj}`).val(formattedDate);
		},
		getAppPrefTime() {
			if (this.isAppPrefChecked) {
				const visibleProviderIds = this.provider_list_resources
					.filter(provider => provider.isvisible)
					.map(provider => provider.id);

				if (visibleProviderIds.length == 0) {
					return false
				}

				var onDayDate = '';
				onDayDate = $(".e-datepicker").val();
				axios.post("appointment/appointment-preferense-time", { providers: visibleProviderIds, calendar_current_view: this.currentView, on_day_date: onDayDate })
					.then((response) => {
						if (response.status == 200) {
							if (response.data.data.min_start_time && response.data.data.max_end_time && response.data.data.min_duration) {
								this.preferenceForm.defaultDuration = response.data.data.min_duration
								this.preferenceForm.startHour = response.data.data.min_start_time
								this.preferenceForm.endHour = response.data.data.max_end_time
								this.preferenceForm.defaultDuration_text = (this.duration_options.find(item => item.value === this.preferenceForm.defaultDuration) || {}).title || "Select";

								this.startHour = this.preferenceForm.startHour;
								this.endHour = this.preferenceForm.endHour;
								this.defaultDuration = this.preferenceForm.defaultDuration;
								this.timeScale.interval = this.defaultDuration;

								this.scheduleKey += 1;
							}
						}
					})
					.catch((error) => {
						console.log('new-call-error', error);
					})
			} else {
				if (localStorage.getItem('preference')) {
					this.preferenceForm = JSON.parse(localStorage.getItem('preference'));
				}

				this.startHour = this.preferenceForm.startHour;
				this.endHour = this.preferenceForm.endHour;
				this.defaultDuration = this.preferenceForm.defaultDuration;
				this.timeScale.interval = this.defaultDuration;
				this.scheduleKey += 1;
			}
		},
		patientSearch() {
			this.isShowCreateDemographics = true;
			if (this.patient_search && this.patient_search.length > 2) {
				axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
					.then((response) => {
						this.patientList = response.data.data;
					})
			}

			if (!this.patient_search || this.patient_search.length == 0) {
				this.patientList = [];
			}
		},
		onDragStop(args) {
			Object.assign(
				this.appointmentForm,
				args.data
			);
			this.appointmentForm.appointment_date = moment(args.data.StartTime).format("YYYY-MM-DD");
			this.appointmentForm.appointment_starttime = moment(args.data.StartTime).format("HH:mm:ss");
			this.appointmentForm.appointment_endtime = moment(args.data.EndTime).format("HH:mm:ss");
		},
		isCurrentMonth(dateStr) {
			const today = new Date();
			const dateToCheck = new Date(dateStr);

			return today.getMonth() === dateToCheck.getMonth() && today.getFullYear() === dateToCheck.getFullYear();
		},
		getOrdinalSuffix(day) {
			const exceptions = [11, 12, 13];
			if (exceptions.includes(day % 100)) return 'th';
			
			const suffixes = ['th', 'st', 'nd', 'rd'];
			return suffixes[day % 10] || 'th';
		},
		formatCustomDate(date) {
			var instance = new Internationalization();
			var weekday = instance.formatDate(date, { format: 'EEEE' });
			var month = instance.formatDate(date, { format: 'MMMM' });
			var day = instance.formatDate(date, { format: 'd' });

			var dayWithSuffix = day + this.getOrdinalSuffix(parseInt(day));
			return `${weekday}, ${month} ${dayWithSuffix}`;
		},
		onRenderCell(args) {
			this.$store.state.loader = true;
			let scheduleObj = this.$refs.scheduleObj.ej2Instances;
			if(args.elementType == "majorSlot"){
				let ele = document.createElement('span');
				ele.innerHTML = moment(args.date).format('h:mm A');
				$(args.element).html(ele);
				$(args.element).attr("title",moment(args.date).format('h:mm A'));
			}
			if (args.elementType === 'dateHeader' && scheduleObj.ej2Instances.currentView === 'Week') {
				var argsDate = this.formatCustomDate(args.date);
				let ele = document.createElement('div');
				ele.innerHTML = argsDate;
				$(args.element).html(ele.firstChild);
				$(args.element).css({
					"text-align": "center",
					"font-weight": "600",
					"font-size": "16px",
					"color": "#0E2641",
				});
			} else if (args.elementType == 'dateHeader') {
				$(args.element).css("display", "none");
			}
			// else if (args.elementType == "monthDay") {
			// 	let ele = document.createElement('div');
			// 	ele.innerHTML = this.getWeekName(args.date);
			// 	$(args.element).html(ele.firstChild);
			// 	$(args.element).css({
			// 		"text-align": "center",
			// 		"font-weight": "600",
			// 		"font-size": "16px",
			// 		"color": "#0E2641",
			// 	});
			// }
			// else if (args.elementType === "monthCells") {
			// 	setTimeout(() => {
			// 		if (!this.isCurrentMonth(args.date)) {
			// 			if ($(args.element).hasClass('e-other-month')) {
			// 				$(args.element).html('');
			// 			}
			// 		}
			// 		if ($(args.element).hasClass('e-other-month')) {
			// 			$(args.element).html('');
			// 		}
			// 		$(args.element).find('.e-other-month').html('');
			// 		$(args.element).find('.e-date-header').removeClass('e-navigate');
			// 		$(args.element).find('e-appointment-wrapper .e-more-indicator').html('');
			// 		$(args.element).find('.e-appointment-wrapper .e-appointment:not(:last)').html('');
			// 		$('.e-date-header').off('click').on('click', function (event) {
			// 			event.stopPropagation();
			// 			event.preventDefault();
			// 		});
			// 		$('.e-work-cells').off('click').on('click', function (event) {
			// 			event.stopPropagation();
			// 			event.preventDefault();
			// 		});
			// 		setTimeout(() => {
			// 			if (!this.isCurrentMonth(args.date)) {
			// 				if ($(args.element).hasClass('e-other-month')) {
			// 					$(args.element).html('');
			// 				}
			// 			}
			// 			if ($(args.element).hasClass('e-other-month')) {
			// 				$(args.element).html('');
			// 			}
			// 			$(args.element).find('.e-other-month').html('');
			// 			$(args.element).find('.e-date-header').removeClass('e-navigate');
			// 			$(args.element).find('e-appointment-wrapper .e-more-indicator').html('');
			// 			$(args.element).find('.e-appointment-wrapper .e-appointment:not(:last)').html('');
			// 			$('.e-date-header').off('click').on('click', function (event) {
			// 				event.stopPropagation();
			// 				event.preventDefault();
			// 			});
			// 			$('.e-work-cells').off('click').on('click', function (event) {
			// 				event.stopPropagation();
			// 				event.preventDefault();
			// 			});
			// 		}, 2000);
			// 		setTimeout(() => {
			// 			$(args.element).find('.e-other-month').html('');
			// 		}, 500);
			// 	}, 2000);
			// }
			this.$store.state.loader = false;
		},
		getWeekName(date) {
			return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
		},
		async changeView(item) {
			if (this.currentView === item) return;
			
			this.$store.state.loader = true;
			// Update active state (Vue handles this more efficiently than jQuery)
			this.currentView = item;

			this.$store.dispatch('updateCustomClass', '');
			if (item === 'Month') {
				this.$refs.scheduleObj.ej2Instances.group.resources = [];
				this.$refs.scheduleObj.ej2Instances.dataBind();
				this.$refs.scheduleObj.ej2Instances.changeCurrentView(item);
				this.allowDragAndDrop = false;

				this.provider_list.forEach(item => {
					item.isvisible = false;
					item.checked = false
				});
				this.provider_list_resources = this.provider_list
				localStorage.setItem("selected-provider-ids-dayview", JSON.stringify([]));
				this.selectedProviderSortIds = []
			} else if (item === 'Week') {
				this.$store.dispatch('updateCustomClass', '');
				// this.providerListForNoResourceScreen = false

				this.currentPage = 1;
				const scheduleInstance = this.$refs.scheduleObj.ej2Instances;
				scheduleInstance.group.resources = ["Providers"];
				scheduleInstance.dataBind();
				scheduleInstance.changeCurrentView(item);
				this.allowDragAndDrop = true;

				let selectedprovider = 0
				this.provider_list.forEach(item => {
					if (selectedprovider < 1 && item.checked) {
						item.isvisible = true;
						item.checked = true
						selectedprovider++
						localStorage.setItem("selected-provider-ids-dayview", JSON.stringify([item.id]));
						this.selectedProviderSortIds = [item.id]
					} else {
						item.isvisible = false;
						item.checked = false
					}
				});

				// Sync provider resources and update current view
				this.provider_list_resources = this.provider_list

				// Store auto preferences
				this.storeAutoPreference()

				// setTimeout(() => {
				// 	this.providerListForNoResourceScreen = true
				// }, 50);
			} else {
				// this.providerListForNoResourceScreen = false

				this.currentPage = 1;
				const scheduleInstance = this.$refs.scheduleObj.ej2Instances;
				scheduleInstance.group.resources = ["Providers"];
				scheduleInstance.dataBind();
				scheduleInstance.changeCurrentView(item);
				this.allowDragAndDrop = true;
				let visibleProviderCnt = 0

				this.provider_list_resources.forEach(item => {
					if (Array.isArray(this.selectedProviderSortIds) && this.selectedProviderSortIds.length > 0 && this.selectedProviderSortIds.includes(item.id) && visibleProviderCnt < this.defaultProviderChecked && item.checked) {
						visibleProviderCnt++
						item.isvisible = true;
					} else {
						item.isvisible = false;
					}
				});

				this.$store.dispatch('updateCustomClass', '');
				// Store auto preferences
				this.storeAutoPreference()

				// setTimeout(() => {
				// 	this.providerListForNoResourceScreen = true
				// }, 200);
			}

			localStorage.setItem("current-view", this.currentView);
			setTimeout(() => {
				this.$store.state.loader = false;
			}, 100);

		},
		onEventDoubleClick(args) {
			args.cancel = true;
		},
		onEventClick(args) {
			if (!$(args.originalEvent.srcElement).hasClass('target-click')) {
				args.cancel = true;
			} else {
				let scheduleObj = this.$refs.scheduleObj.ej2Instances;
				scheduleObj.openEditor(args.event, 'EditOccurrence')
			}
		},
		onCellClick(args) {
			let scheduleObj = this.$refs.scheduleObj.ej2Instances;
			this.selectedDate = new Date(args.startTime);
			if (scheduleObj.ej2Instances.currentView === 'Month') {
				this.scheduleKey += 1;
				this.allowDragAndDrop = true;
				this.currentView = 'Day';
			} else {
				scheduleObj.openEditor(args, 'Add');
			}
		},
		toggleTypeList() {
			this.showTypeList = !this.showTypeList;
		},
		selectType(item) {
			this.appointmentForm.type_id = item.id;
			this.appointmentForm.appointment_type = item.name;
		},
		toggleStatusList() {
			this.showStatusList = !this.showStatusList;
		},
		selectStatus(item) {
			this.appointmentForm.status_id = item.id;
			this.appointmentForm.appointment_status = item.status_label;
		},
		toggleProviderList() {
			this.showProviderList = !this.showProviderList;
		},
		selectProvider(item) {
			this.appointmentForm.provider_id = item.id;
			this.appointmentForm.provider_name = item.name;
		},
		changeScheduleDate: debounce(function (event) {
			if (!event || !event.value) return;
			this.$store.state.loader = true;
			let mainDatePicker = this.$refs.mainDatePicker.ej2Instances;
			this.selectedDate = new Date(mainDatePicker.changedArgs.value);
			this.$refs.mainDatePicker.focusOut();
			localStorage.setItem('calendar_selected_date', event.value);
			this.$store.dispatch('updateCustomClass', '');
			this.storeAutoPreference()
			setTimeout(() => {
				this.$store.state.loader = false;
			}, 100);
		}, 200),

		// Method to store user preferences in the database
		storePreference() {
			if (!this.checkEndTime()) {
				return false
			}

			const date = moment(this.selectedDate);
			let startDate = null
			let endDate = null
			if (this.currentView == 'Week') {
				startDate = date.clone().day(0).format('YYYY-MM-DD')
				endDate = date.clone().day(6).format('YYYY-MM-DD')
			} else {
				startDate = moment(this.selectedDate).format("YYYY-MM-DD")
				endDate = moment(this.selectedDate).format("YYYY-MM-DD")
			}

			const visibleProviderIds = this.provider_list_resources
				.filter(provider => provider.isvisible)
				.map(provider => provider.id);

			const form = {
				id: localStorage.getItem('userId'),
				preference: this.preferenceForm,
				providers: visibleProviderIds,
				startDate: startDate,
				endDate: endDate,
				provider_ids: this.selectedProviderSortIds
			}

			this.$store.state.loader = true;
			axios.post("user/update-preference", form)
				.then((response) => {
					if (response.data.status == 200) {
						// Update local storage with new preferences
						localStorage.setItem("preference", JSON.stringify(this.preferenceForm));
						// Update schedule with new preferences
						this.startHour = this.preferenceForm.startHour;
						this.endHour = this.preferenceForm.endHour;
						this.defaultDuration = this.preferenceForm.defaultDuration;
						this.timeScale.interval = this.defaultDuration;
						// Increment the key to force re-rendering of the schedule
						this.scheduleKey += 1;
						setTimeout(() => {
							this.$store.state.loader = false;
						}, 100);
						this.updateHeaderClass()
						window.$("#appointmentView-setting").modal("hide");
						this.setDynamicCellHeight();
					}
				}).catch(error => {
					this.preferenceForm.defaultDuration = this.defaultDuration
					this.preferenceForm.defaultDuration_text = (this.duration_options.find(item => item.value === this.preferenceForm.defaultDuration) || {}).title || "Select";
					setTimeout(() => {
						this.$store.state.loader = false;
					}, 100);

					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				})
		},
		storeAutoPreference() {
			this.$store.state.loader = true;
			let startDate = null
			let endDate = null
			const date = moment(this.selectedDate);

			if (this.currentView == 'Week') {
				startDate = date.clone().day(0).format('YYYY-MM-DD')
				endDate = date.clone().day(6).format('YYYY-MM-DD')
			} else {
				startDate = moment(this.selectedDate).format("YYYY-MM-DD")
				endDate = moment(this.selectedDate).format("YYYY-MM-DD")
			}

			let selectedProviderSortIds = this.selectedProviderSortIds.filter(id => id !== null)
			axios.post("appointment/auto-preference-update", {
				'start_date': startDate,
				'end_date': endDate,
				provider_ids: selectedProviderSortIds
			})
				.then((response) => {
					if (response.data.status == 200) {
						if (response.data.data.defaultDuration && response.data.data.defaultDuration > 0) {
							this.defaultDuration = response.data.data.defaultDuration;
							this.preferenceForm.defaultDuration = this.defaultDuration
							this.preferenceForm.defaultDuration_text = (this.duration_options.find(item => item.value === this.preferenceForm.defaultDuration) || {}).title || "Select";
							this.timeScale.interval = this.defaultDuration;

							let scheduleInstance = this.$refs.scheduleObj.ej2Instances;
							scheduleInstance.timeScale.interval = this.timeScale.interval;
							this.$store.state.loader = true;
							this.$store.dispatch('updateCustomClass', '');
							scheduleInstance.dataBind();
							
							this.$store.state.loader = false;
							localStorage.setItem("preference", JSON.stringify(this.preferenceForm));
							this.setDynamicCellHeight();
						}

					}
				})
		},
		// Method to open the user preference settings modal
		openSetting() {
			this.$store.dispatch('updateCustomClass', '');
			window.$("#appointmentView-setting").modal("show");
		},
		onDeleteEvent(data){
			this.appointmentToDelete = data;
			window.$("#appointment-confirm-delete").modal("show");
		},
		// Method called when the popup closes
		async onPopupClose(args) {
			this.updateHeaderClass()
			if (args.type === "Editor") {
				if (args.event && (args.event.target.classList.contains('e-icon-dlg-close') || args.event.target.classList.contains('e-dlg-closeicon-btn') || args.event.target.classList.contains('e-event-cancel'))) {
					$(".e-event-cancel").trigger('click')

					// Restore scroll position after calendar is ready
					setTimeout(() => {
						this.$store.state.loader = true;
						window.scrollTo(0, 0);
						this.$store.state.loader = false;
					}, 450);

					return true
				}
				if(!this.isSaveEvent){

					// Restore scroll position after calendar is ready
					setTimeout(() => {
						this.$store.state.loader = true;
						// window.scrollTo(0, 0);
						this.$store.state.loader = false;
					}, 450);

					return true
				}
				this.v$.appointmentForm.$validate();
				if (this.v$.appointmentForm.$error) {
					args.cancel = true;
				}
				if (!this.isAvailabilityValidated) {
					if (!this.isAvailabilityValidated) {
						this.$filters.moshaToast("The slot is currently unavailable. Please choose another time slot.", "error");
					}
					args.cancel = true;

				}
			}
		},
		isValidDate(dateString) {
			const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
			const date = new Date(year, month - 1, day);
			return date.getFullYear() === year && (month === 0 || date.getMonth() + 1 === month) && (day === 0 || date.getDate() === day);
		},
		schedulerDateChange(obj) {
			const sanitizedInput = $(`#${obj}`).val();
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});

			const isValidDate = (formattedDate != '') ? this.isValidDate(formattedDate) : false;
			if (isValidDate) {
				this.validDateError = "" // Clear error message if the date is valid
				if ($('#appointment_date').val() == '') {
					this.appointmentForm.appointment_date = null;
				} else {
					this.appointmentForm.appointment_date = $('#appointment_date').val();
				}
			} else {
				if (formattedDate != '') {
					this.validDateError = 'Please Enter a Valid Date';
				}
				this.appointmentForm.appointment_date = null;
				$(`#${obj}`).val("");
			}
		},
		// Method called when the popup opens
		async onPopupOpen(args) {
			if (this.currentView == 'Month') {
				args.cancel = true; // Prevent the popup
			}

			this.$store.dispatch('updateCustomClass', '');
			if (args.type === "Editor") {
				this.isAvailabilityValidated = true
				this.isValidDuration = true
				this.schedulerPatientList = [];
				this.v$.appointmentForm.$reset();
				this.appointmentForm = {
					id: null,
					patient_id: null,
					patient_name: null,
					provider_id: null,
					provider_name: null,
					appointment_date: null,
					appointment_starttime: null,
					appointment_endtime: null,
					duration: null,
					reason_for_visit: null,
					status_id: null,
					appointment_status: null,
					type_id: null,
					appointment_type: null,
				};
				Object.assign(
					this.appointmentForm,
					args.data
				);
				this.appointmentForm.appointment_date = moment(args.data.StartTime).format("YYYY-MM-DD");
				$("#appointment_date").val(moment(args.data.StartTime).format("YYYY-MM-DD"));
				this.appointmentForm.appointment_starttime = moment(args.data.StartTime).format("HH:mm:ss");
				this.appointmentForm.appointment_endtime = moment(args.data.EndTime).format("HH:mm:ss");
				$("#appointment_endtime").val(moment(args.data.EndTime).format("LT"));

				this.appointmentForm.duration = this.getDiffrenceInMinutes(this.appointmentForm.appointment_starttime, this.appointmentForm.appointment_endtime);
				if (this.appointmentForm.duration == 0) {
					this.appointmentForm.duration = this.preferenceForm.defaultDuration
					this.appointmentForm.appointment_endtime = moment(args.data.EndTime).add(this.appointmentForm.duration, 'minutes').format("HH:mm:ss");
					$("#appointment_endtime").val(moment(args.data.EndTime).add(this.appointmentForm.duration, 'minutes').format("LT"));
				}
				this.appointmentForm.duration_text = (this.duration_options.find(item => item.value === this.appointmentForm.duration) || {}).title || "Select";

				if (this.appointmentForm.patient_name) {
					$("#patient_id").val(this.appointmentForm.patient_name);
				}

				if (this.appointmentForm.id) {
					$(".e-event-save").html('Update')
				} else {
					$(".e-event-save").html('Save')
					let providerData = this.getObjectById(this.providerDataSource, args.data.provider_id);
					this.appointmentForm.provider_id = providerData.id;
					this.appointmentForm.provider_name = providerData.name;

					let statusData = this.getObjectById(this.StatusDataSource, 3);
					this.appointmentForm.status_id = statusData.id;
					this.appointmentForm.appointment_status = statusData.status_label;

					let typeData = this.getObjectById(this.TypeDataSource, 1);
					this.appointmentForm.type_id = typeData.id;
					this.appointmentForm.appointment_type = typeData.name;
				}

				let scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
				const saveButton = args.element.querySelector("#appointmentSave");
				const cancelButton = args.element.querySelector("#appointmentCancel");
				const closeButton = args.element.querySelector("#appointmentClose");

				saveButton.onclick = () => {
					this.isSaveEvent = true;
					if (args.target?.classList.contains("e-appointment")) {
						scheduleObj.saveEvent(this.appointmentForm, "Save");
					} else {
						scheduleObj.addEvent(this.appointmentForm);
					}
					scheduleObj.closeEditor();
				};
				cancelButton.onclick = (event) => {
					event.preventDefault(); // Prevent default behavior
					this.isSaveEvent = false;
					// Save current scroll position
					scheduleObj.closeEditor();
					document.activeElement.blur(); // Clear focus to avoid scroll jump
				};

				closeButton.onclick = (event) => {
					event.preventDefault(); // Prevent default behavior
					this.isSaveEvent = false;
					scheduleObj.closeEditor();
					document.activeElement.blur(); // Clear focus to avoid scroll jump
				};
			}
			if (args.type === "QuickInfo") {
				if ((!args.data.Id)) {
					args.cancel = true;
				}
			}
			if (args.type === 'DeleteAlert') {
				// Customize the delete confirmation modal here
				args.cancel = true; // Cancel the default modal
				this.appointmentToDelete = args.data;
				window.$("#appointment-confirm-delete").modal("show");
			}

		},
		// Method to configure data manager for schedule events
		dataManager() {
			let parsedProviderIds = []
			let selectedProviderIdsDayView = localStorage.getItem("selected-provider-ids-dayview")
			if (selectedProviderIdsDayView) {
				parsedProviderIds = JSON.parse(selectedProviderIdsDayView);
				parsedProviderIds = Array.from(new Set(parsedProviderIds));
		
			}
			if (parsedProviderIds.length > 0) {
				this.$store.state.loader = true;
			}

			const $this = this;

			return new DataManager({
				adaptor: new CustomDataAdaptor({
					getData: function (option) {
						$this.$store.state.loader = true;
						$this.providerAppointmentCount = {}
						let params = JSON.parse(option.data);
						let selectedProviderSortIds = $this.selectedProviderSortIds.filter(id => id !== null)
						let reqParams = {
							start_date: moment(new Date(params.StartDate)).format("YYYY-MM-DD"),
							end_date: moment(new Date(params.EndDate)).format("YYYY-MM-DD"),
							range_type: $this.currentView,
							request_for_duration_update: 1,
							provider_ids: selectedProviderSortIds
						};

						// Fetch events from the server
						let isMultiAppointment = []
						$this.updateHeaderClass()
						$this.providerAppointmentCount = {};
						axios.post("appointment/list", reqParams)
							.then((response) => {
								if ($this.currentView !== "Month") {
									$this.providerAppointmentCount = {};
									$this.$store.dispatch('updateCustomClass', '');
									const data = response.data.data.appointments.map((item) => {
										if (item.is_more_appointment) {
											isMultiAppointment.push(item.provider_name + "-" + item.provider_id);
										}
										let startTime = item.appointment_date + ' ' + item.appointment_starttime;

										let patientHoverContent = "<span>" + item.patient_name + " <ul>"
										if (item.is_hcv_verified) {
											patientHoverContent += "<li>Health card is valid</li>"
										} else if (item.is_hcv_verified === 0 && item.health_insurance_no != null && item.health_card_type == 'ON') {
											patientHoverContent += "<li class='patient-health-red'>Health card is invalid</li>"
										}

										if (item.is_task) {
											patientHoverContent += "<li class='patient-health-red'>Patient has unresolved tasks</li>"
										}

										patientHoverContent += '</ul></span>'
										item.patientHtmlContent = patientHoverContent

										if (!$this.providerAppointmentCount) {
											$this.providerAppointmentCount = {};
										}
										$this.providerAppointmentCount[item.provider_id] = ($this.providerAppointmentCount[item.provider_id] || 0) + 1;
										return {
											...item,
											StartTime: moment(startTime, "YYYY-MM-DD HH:mm:ss").toDate(),
											EndTime: moment(item.appointment_date + ' ' + item.appointment_endtime, "YYYY-MM-DD HH:mm:ss").toDate(),
											Id: item.id,
											Subject: item.patient_name,
											Description: item.reason_for_visit,
											Duration: item.duration
										};
									});

									$this.appointmentList = data;

									option.onSuccess(data);
									$this.isMultiAppointment = [...new Set(isMultiAppointment)];
								}
								else {
									const scheduleEvents = response.data.data.schedule_events;
									let ejsEvents = [];
									let inc = 1;
									for (const [date, events] of Object.entries(scheduleEvents)) {
										events.forEach(event => {
											const [user_name, timeRange] = event.split(' | ');
											const [start_time, end_time] = timeRange.replace(' (Special Hour)', '').split(' : ');

											const [year, month, day] = date.split('-').map(Number);
											const [startHour, startMinute, startSecond] = start_time.split(':').map(Number);
											const [endHour, endMinute, endSecond] = end_time.split(':').map(Number);

											const formattedStartTime = moment(start_time, 'HH:mm:ss').format('hh:mm A');
											const formattedEndTime = moment(end_time, 'HH:mm:ss').format('hh:mm A');

											const key = `${date}`;

											if (!ejsEvents[key]) {
												ejsEvents[key] = {
													Id: inc++,
													Subject: user_name,
													Description: [],
													StartTime: new Date(year, month - 1, day, startHour, startMinute, startSecond),
													EndTime: new Date(year, month - 1, day, endHour, endMinute, endSecond),
													Date: date
												};
											}

											ejsEvents[key].Description.push(`<b>${user_name}</b>  | ${formattedStartTime.trim()} - ${formattedEndTime.trim()}`);
											ejsEvents[key].EndTime = new Date(year, month - 1, day, endHour, endMinute, endSecond);
										});
									}

									$this.additionalData = Object.values(ejsEvents);
									option.onSuccess($this.additionalData);
								}

								$this.$store.state.loader = false;
							});

					},
					batchUpdate: function (option) {
						let data = JSON.parse(option.data);
						let scheduleObj = $this.$refs.scheduleObj.ej2Instances;
						$this.v$.appointmentForm.$validate();
						if ($this.isAvailabilityValidated && data.changed.length > 0 && $this.isSaveEvent && !$this.v$.appointmentForm.$error) {
							axios.post("appointment/update", $this.appointmentForm)
								.then(() => {
									$(".paginationDiv").remove()
									$this.storeAutoPreference()
									$this.sendMsgToSocket();
									scheduleObj.refreshEvents();
								}).catch(error => {
									if (error.response.status === 422) {
										$this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
									} else if (error.response.status === 500) {
										$this.$filters.moshaToast(error.response.data.message, "error");
										scheduleObj.refreshEvents();
									} else {
										$this.$filters.moshaToast(error.response.data.message, "error");
									}
								})
						} else if ($this.isAvailabilityValidated && data.added.length > 0 && $this.isSaveEvent && !$this.v$.appointmentForm.$error) {
							axios.post("appointment/store", $this.appointmentForm)
								.then(() => {
									$(".paginationDiv").remove()
									$this.storeAutoPreference()
									$this.sendMsgToSocket();
									scheduleObj.refreshEvents();
								}).catch(error => {
									if (error.response.status === 422) {
										$this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
									} else if (error.response.status === 500) {
										$this.$filters.moshaToast(error.response.data.message, "error");
										scheduleObj.refreshEvents();
									} else {
										$this.$filters.moshaToast(error.response.data.message, "error");
									}
								})
						} else if (data.deleted.length > 0) {
							axios.post("appointment/delete", $this.appointmentForm)
								.then(() => {
									$this.sendMsgToSocket();
									scheduleObj.refreshEvents();
								}).catch(error => {
									if (error.response.status === 422) {
										$this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
									} else {
										$this.$filters.moshaToast(error.response.data.message, "error");
									}
								})
						}
					},
				}),
			});
		},
		// Method to get the list of providers
		getProviderList() {
			let visibleProviderCnt = 0;
			let isvisible = false
			axios.post("json_list/provider", { roleId: [2] })
				.then((response) => {

					let combinedArray = response.data.data.map((item) => {
						if (Array.isArray(this.selectedProviderSortIds) && this.selectedProviderSortIds.length > 0 && this.selectedProviderSortIds.includes(item.id)) {
							return { id: item.id, name: item.full_name, checked: true };
						} else {
							isvisible = false
							return { id: item.id, name: item.full_name, checked: false };
						}

					});

					this.provider_list = combinedArray

					let limit = this.defaultProviderChecked
					if (this.currentView == 'Week') {
						limit = 1
					}

					let isChecked = false
					this.provider_list_resources = this.provider_list
					this.provider_list_resources = this.sortSelectedProvider(this.provider_list_resources, this.selectedProviderSortIds)
					this.provider_list_resources.forEach((data) => {
						if (visibleProviderCnt < limit && this.selectedProviderSortIds.includes(data.id)) {
							visibleProviderCnt++
							isvisible = true
							isChecked = true // Remove for pagination
						} else {
							isvisible = false
							isChecked = false // Remove for pagination
						}
						data.isvisible = isvisible
						data.checked = isChecked

					})

					let providerDataSource = response.data.data.map((item) => {
						return { id: item.id, name: item.full_name, checked: true };
					}).filter(item => item !== "" && item !== null && item !== undefined);
					this.providerDataSource = providerDataSource;

					if (this.currentView == 'Month') {
						this.$refs.scheduleObj.ej2Instances.group.resources = [];
						this.$refs.scheduleObj.ej2Instances.dataBind();
						this.$refs.scheduleObj.ej2Instances.changeCurrentView('Month');
						this.allowDragAndDrop = false;
					}

					this.select_box_key = this.select_box_key + 1;
				});
		},
		getStatusList() {
			axios.post("appointment-status/list")
				.then((response) => {
					this.StatusDataSource = response.data.data;
				})
		},
		getTypeList() {
			axios.post("appointment-type/list")
				.then((response) => {
					this.TypeDataSource = response.data.data;
				})
		},
		selected_provider: debounce(function (result) {
			this.$store.state.loader = true;

			this.$store.dispatch('updateCustomClass', '');

			this.fetchjsonToArray()
			
			this.provider_list = result;

			if (this.lastItem) {
				this.storeAutoPreference()
			}

			let isvisible = false
			let visibleProviderCnt = 0
			let limit = this.currentView === 'Week' ? 1 : this.defaultProviderChecked;

			if (this.currentView == 'Week') {
				this.provider_list.forEach((data) => {
					if (data.id == this.lastSelectedItem && this.provider_list_resources.length > 0) {
						data.isvisible = true
						data.checked = true
						localStorage.setItem("selected-provider-ids-dayview", JSON.stringify([data.id]));
						this.selectedProviderSortIds = [data.id]
					} else {
						data.isvisible = false
						data.checked = false
					}
				})
				this.provider_list_resources = this.provider_list

			} else {
				this.provider_list_resources = this.sortSelectedProvider(result, this.selectedProviderSortIds)

				this.provider_list_resources.forEach((data) => {
					if (visibleProviderCnt < limit && this.selectedProviderSortIds.includes(data.id) && this.selectedProviderSortIds.length > 0) {
						visibleProviderCnt++
						isvisible = true
					} else {
						isvisible = false
					}
					data.isvisible = isvisible

				})
			}

			setTimeout(() => {
				this.$store.state.loader = false;
			}, 500);

		}, 200),
		getObjectById(arr, id) {
			return arr.find(obj => obj.id === id);
		},
		getDiffrenceInMinutes(startTime, endTime) {
			var [hours1, minutes1] = startTime.split(':').map(Number);
			var [hours2, minutes2] = endTime.split(':').map(Number);

			var totalMinutes1 = hours1 * 60 + minutes1;
			var totalMinutes2 = hours2 * 60 + minutes2;

			return Math.abs(totalMinutes2 - totalMinutes1);
		},
		// Method to render events with custom background color
		onEventRendered(args) {
			if (this.currentView != 'Month') {
				if ($(args.element).find('.circle_count').length > 0) {
					$(args.element).find('.circle_count').css('color', args.data.color_code);
				}
				if (args.data.same_slot_count == 2) {
					$(args.element).addClass('pageTwoAppointment');
					args.element.style.display = 'none';
				} else {
					$(args.element).addClass('pageOneAppointment');
				}
				$(args.element).attr('data-name', args.data.provider_name);
				args.element.style.backgroundColor = args.data.color_code;
			} else {
				args.element.style.backgroundColor = '#FFF';
				args.element.style.top = "30px";
			}
		},
		schedulerStartTimeChange(time) {
			var endDate = new Date(this.appointmentForm.appointment_date + ' ' + time);
			endDate.setTime(endDate.getTime() + this.appointmentForm.duration * 60000);
			this.appointmentForm.appointment_starttime = time + ":00";
			this.appointmentForm.appointment_endtime = moment(endDate).format("HH:mm:ss");
			var isValidRange = this.checkTimeRange(this.appointmentForm.appointment_starttime, this.appointmentForm.appointment_endtime);
			if (isValidRange) {
				this.validEndTimeError = "";
				$("#appointment_endtime").val(moment(endDate).format("LT"));
				this.checkAvailbilitySlot();
			} else {
				$("#appointment_endtime").val("");
				this.appointmentForm.appointment_endtime = null;
				this.validEndTimeError = "Invalid end time";
			}
		},
		checkTimeRange(starttime, endtime) {
			const toSeconds = (time) => time.split(':').reduce((acc, val, i) => acc + Number(val) * [3600, 60, 1][i], 0);
			return toSeconds(endtime) >= toSeconds(starttime);
		},
		// Method called on input change for event duration
		schedulerDurationChange() {
			let durObj = new Date(new Date(`2024-03-01 ${this.appointmentForm.appointment_starttime}`).getTime() + this.appointmentForm.duration * 60000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

			let endDate = new Date(new Date(`2024-03-01 ${this.appointmentForm.appointment_starttime}`).getTime() + this.appointmentForm.duration * 60000)

			this.appointmentForm.appointment_endtime = moment(endDate).format("HH:mm:ss");
			var isValidRange = this.checkTimeRange(this.appointmentForm.appointment_starttime, this.appointmentForm.appointment_endtime);
			if (isValidRange) {
				this.validEndTimeError = "";
				$("#appointment_endtime").val(durObj);
				this.checkAvailbilitySlot();
			} else {
				$("#appointment_endtime").val("");
				this.appointmentForm.appointment_endtime = null;
				this.validEndTimeError = "Invalid end time";
			}
		},
		// Method to change the status of an event
		sendMsgToSocket() {
			try {
				const newMsg = {
					type: "appointmentTrigger",
					clinicId: localStorage.getItem("clinicId"),
					userId: localStorage.getItem("userId"),
					timeStamp: Date.now(),
				}
				this.$socket.send(JSON.stringify(newMsg));
			} catch (error) {
				this.$filters.moshaToast('WebSocket send error:' + error, "error");
			}
		},
		// Method to reset the user inactivity timer
		resetTimer() {
			this.userInactive = false;
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.userInactive = true
				this.refreshScheduler();
			}, 5000);
		},
		// Method to refresh the scheduler on user inactivity and calendar changes
		refreshScheduler() {
			if (this.userInactive === true && this.changeInCalendar === true) {
				let scheduleObj = this.$refs.scheduleObj.ej2Instances;
				scheduleObj.refreshEvents();
				this.userInactive = false;
				this.changeInCalendar = false;
			}
		},
		// Method to handle received data from the WebSocket
		handleReceivedData(socketData) {
			var msgDetail = JSON.parse(socketData);
			if (msgDetail.type == 'appointmentTrigger' && msgDetail.clinicId == localStorage.getItem("clinicId") && msgDetail.userId != localStorage.getItem("userId")) {
				this.changeInCalendar = true;
				this.refreshScheduler();
				this.resetTimer();
			}
		},
		schedulerPatientSearch() {
			if (this.appointmentForm.patient_name && this.appointmentForm.patient_id) {
				this.isShowAppointmentCreateDemographics = false;
			} else {
				this.isShowAppointmentCreateDemographics = true;
			}
			if (!this.appointmentForm.patient_name || this.appointmentForm.patient_name.length == 0) {
				this.schedulerPatientList = [];
			}
			if (this.appointmentForm.patient_name && this.appointmentForm.patient_name.length > 2) {
				axios.post("json_autocomplete/patient-search", { search_text: this.appointmentForm.patient_name })
					.then((response) => {
						this.schedulerPatientList = response.data.data;
					})
			} else {
				this.schedulerPatientList = [];
			}
		},
		schedulerPatientSelection($event, item) {
			if (!$($event.target).hasClass('prevent')) {
				this.appointmentForm.patient_id = item.patient_id;
				this.appointmentForm.patient_name = item.full_name;
				this.schedulerPatientList = [];
				this.isShowAppointmentCreateDemographics = false;
			}
		},
		async downloadPDF(sortBy) {
			const res = await fetch(process.env.VUE_APP_API_BASE_URL + '/appointment/print-todays-appointment', {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ "sortBy": sortBy }),
			});
			const blob = await res.blob();
			const urlObject = URL.createObjectURL(blob);
			window.open(urlObject, '_blank')
		},
		showEncounterDropdown($event) {
			$event.stopPropagation();

			// Find the parent element with class 'drop-trigger'
			const parentElement = $event.target.closest('.drop-trigger');
			if (parentElement) {
				// Find the child element with class 'encounterDropdown'
				const encViewElement = parentElement.querySelector('.encounterDropdown');
				if (encViewElement) {
					// Check if the dropdown is currently hidden
					const isHidden = encViewElement.classList.contains('d-none');

					// Hide all dropdowns initially
					document.querySelectorAll('.encounterDropdown').forEach(dropdown => {
						dropdown.classList.add('d-none');
					});

					if (isHidden) {
						// Temporarily display the dropdown to calculate its height
						encViewElement.classList.remove('d-none');
						encViewElement.style.top = "auto"; // Reset top to avoid conflicts
						const dropdownHeight = encViewElement.offsetHeight;

						// Calculate the position
						const buttonRect = parentElement.getBoundingClientRect();
						const viewportHeight = window.innerHeight;

						// Determine if there's enough space below the button
						if (buttonRect.bottom + dropdownHeight > viewportHeight && buttonRect.top > dropdownHeight) {
							encViewElement.style.top = `-${(dropdownHeight + 10)}px`;
						} else {
							encViewElement.style.top = `${(buttonRect.height + 10)}px`;
						}

						// Show the current dropdown
						encViewElement.classList.remove('d-none');
					} else {
						// Hide the current dropdown
						encViewElement.classList.add('d-none');
					}
				}
			}
		},
		handleClickOutside(event) {
			event.stopPropagation();

			const globalSearchElement = this.$refs.globalSearch;
			const globalSearchModalElement = this.$refs.globalModalSearch;
			if (globalSearchElement && !globalSearchElement.contains(event.target)) {
				this.patient_search = '';
				this.patientList = [];
				this.isShowCreateDemographics = false;
			} else if (globalSearchModalElement && !globalSearchModalElement.contains(event.target)) {
				this.schedulerPatientList = [];
				this.isShowAppointmentCreateDemographics = true;
			}
			const dropdownEle = this.$refs.dropdownStatusRef;
			if (dropdownEle && !dropdownEle.contains(event.target)) {
				this.showStatusList = false
			}

			const dropdownModeEle = this.$refs.dropdownModeRef;
			if (dropdownModeEle && !dropdownModeEle.contains(event.target)) {
				this.showTypeList = false
			}

			const dropdownSProviderEle = this.$refs.dropdownSProviderRef;
			if (dropdownSProviderEle && !dropdownSProviderEle.contains(event.target)) {
				this.showProviderList = false
			}

			const globalModalSearchEle = this.$refs.globalModalSearch;
			if (globalModalSearchEle && !globalModalSearchEle.contains(event.target) && !event.target.closest(`.search_drop_box`)) {
				this.schedulerPatientList = [];
				if (this.$refs.globalModalSearch && !this.$refs.globalModalSearch.contains(event.target)) {
					this.isShowAppointmentCreateDemographics = false;
				}
			}
		},
		async checkAvailbilitySlot() {
			axios.post("appointment/check-availability", {
				provider_id: this.appointmentForm.provider_id,
				appointment_date: this.appointmentForm.appointment_date,
				appointment_starttime: this.appointmentForm.appointment_starttime,
				appointment_endtime: this.appointmentForm.appointment_endtime,
				id: this.appointmentForm.id
			})
				.then((response) => {
					let detail = response.data.data;
					if (!detail.is_available) {
						this.$filters.moshaToast("The slot is currently unavailable. Please choose another time slot.", "error");
						this.isAvailabilityValidated = false
						return false
					}
					this.isAvailabilityValidated = true
					return true
				}).catch(error => {
					this.isAvailabilityValidated = false
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
					return false
				})
		},
		confirmDelete() {
			if (this.appointmentToDelete) {
				const $this = this;
				let scheduleObj = $this.$refs.scheduleObj.ej2Instances;
				axios.post("appointment/delete", $this.appointmentForm)
					.then(() => {
						$this.sendMsgToSocket();
						scheduleObj.closeEditor();
						// scheduleObj.refreshEvents();
						scheduleObj.dataBind();
						$this.$store.dispatch('updateCustomClass', '');
						$this.scheduleKey += 1;
					}).catch(error => {
						if (error.response.status === 422) {
							$this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						} else {
							$this.$filters.moshaToast(error.response.data.message, "error");
						}
					})
				this.appointmentToDelete = null;
				window.$("#appointment-confirm-delete").modal("hide");
			}
		},
		cancelDelete() {
			this.appointmentToDelete = null;
			window.$("#appointment-confirm-delete").modal("hide");
		},
		extractNumberFromString(str) {
			const match = str.match(/\d+/);
			if (match) {
				return parseInt(match[0]);
			} else {
				return "";
			}
		},
		closeAppPrefrences() {
			this.updateHeaderClass()
			window.$("#appointmentView-setting").modal("hide");
		},
		activateEventListener() {
			let providercnt = this.checkedProviderCount()
			let totalProvider = this.providerDataSource.length
			document.querySelectorAll('.seePrevAppointment').forEach(function (element) {
				if ((providercnt < 4 && providercnt > 0) || (providercnt == 0 && totalProvider < 4)) {
					element.parentNode.classList.add('paginationline');
				} else {
					element.parentNode.classList.remove('paginationline');
				}
				element.addEventListener('click', function () {
					$(this).next().text("1 / 2");
					var clickedDataName = $(this).attr('data-name');
					$('.pageTwoAppointment[data-name="' + clickedDataName + '"]').css("display", "none");
					$('.pageOneAppointment[data-name="' + clickedDataName + '"]').css({ "display": "block", "left": "0%" });
				});
			});
			document.querySelectorAll('.seeNextAppointment').forEach(function (element) {
				element.addEventListener('click', function () {
					$(this).prev().text("2 / 2");
					var clickedDataName = $(this).attr('data-name');
					$('.pageOneAppointment[data-name="' + clickedDataName + '"]').css("display", "none");
					$('.pageTwoAppointment[data-name="' + clickedDataName + '"]').css({ "display": "block", "left": "0%" });
				});
			});
		},
		checkedProviderCount() {
			return this.provider_list_resources.filter(provider => provider.isvisible).length;
		},
		checkEndTime() {
			if (this.preferenceForm.startHour && this.preferenceForm.endHour && this.preferenceForm.endHour <= this.preferenceForm.startHour) {
				this.$filters.moshaToast("End time should be greater than start time", "danger");
				return false
			}

			return true
		},
		redirectActiveTask(patientId) {
			const activeTaskUrl = this.$router.resolve({ name: 'PatientEChartActiveTaskList', params: { 'patient_id': patientId}, query: {'view':'all' } }).href;
			// Open the URL in a new tab
			window.open(activeTaskUrl, '_blank');
		},
		handleLastSelectedItem(item) {
			this.lastSelectedItem = item
		},
		handleLastItem(value) {
			this.lastItem = value
		},
		openQueryTool() {
			const routeData = this.$router.resolve({
				path: '/patient/query-tool',
			});
			window.open(routeData.href, '_blank');
		},
		openMasterRecordPage(event, patientId) {
			event.stopPropagation();
			this.hideEncounterDropdown();
			const url = this.$router.resolve({ name: 'PatientChart', params: { patient_id: patientId, } }).href;
			window.open(url, '_blank');
		},
		openEchartRecordPage(event, patientId) {
			event.stopPropagation();
			this.hideEncounterDropdown();
			const url = this.$router.resolve({ name: 'PatientEChart', params: { patient_id: patientId, } }).href;
			window.open(url, '_blank');

		},
		openBillingPage(event, patient_id, patient_name, provider_id, provider_name, is_private = 0) {
			event.stopPropagation();
			this.hideEncounterDropdown();
			const url = this.$router.resolve({
				name: 'InvoiceMasterCreate',
				query: { patient_id, patient_name, provider_id, provider_name, is_private }
			}).href;
			window.open(url, '_blank');
		},
		openRxPage(event, patientId) {
			event.stopPropagation();
			this.hideEncounterDropdown();
			const url = this.$router.resolve({ name: 'PatientEChartPrescriptionList', params: { patient_id: patientId, } }).href;
			window.open(url, '_blank');

		},
		openMeasurementPage(event, patientId) {
			event.stopPropagation();
			this.hideEncounterDropdown();
			const url = this.$router.resolve({ name: 'patientMeasurementList', params: { patient_id: patientId, } }).href;
			window.open(url, '_blank');

		},
		sortSelectedProvider(sourceArray, sortIds) {
			const filteredArray = sourceArray.filter(item => sortIds.includes(item.id));

			filteredArray.sort((a, b) => {
				return sortIds.indexOf(a.id) - sortIds.indexOf(b.id);
			});

			return filteredArray;
		},
		fetchjsonToArray() {
			let parsedProviderIds = []
			let selectedProviderIdsDayView = localStorage.getItem("selected-provider-ids-dayview")
			if (selectedProviderIdsDayView) {
				parsedProviderIds = JSON.parse(selectedProviderIdsDayView);
				parsedProviderIds = Array.from(new Set(parsedProviderIds));

			}
			this.selectedProviderSortIds = parsedProviderIds

		},
		onDataBinding(){
			this.$store.state.loader = true;
		},
		onDataBound() {
			// 1. Assign `data-id` to resource elements
			const resourceElements = document.querySelectorAll('.e-text-ellipsis');
			resourceElements.forEach((el, index) => {
				const resourceId = this.filteredProviderDataSource[index]?.id;
				if (resourceId) {
					if (this.providerAppointmentCount[resourceId]) {
						let resorceLbl = el.textContent
						if (resorceLbl.match(/\s\(\d+\)$/)) {
							resorceLbl = resorceLbl.replace(/\(\d+\)/, '')
						}
						el.textContent=resorceLbl+" ("+this.providerAppointmentCount[resourceId]+")"
					} else {
						let resorceLbl = el.textContent
						// Check if the string includes a pattern with ({int}) and remove it
						if (/\(\d+\)/.test(resorceLbl)) {
						resorceLbl = resorceLbl.replace(/\(\d+\)/, '').trim();
						}
						el.textContent=resorceLbl
					}
					el.setAttribute('data-id', resourceId);
				}
			});

			const $this = this;
			if (this.currentView == 'Day') {
				const resourceCells = document.querySelectorAll('.e-resource-cells .e-text-ellipsis');
				resourceCells.forEach(cell => {
					cell.addEventListener('click', this.onResourceClick);
				});
			}


			const tooltips = document.querySelectorAll('.icon_tooltip');

			tooltips.forEach(tooltip => {
				// Add event listener for the hover effect
				tooltip.addEventListener('mouseenter', function () {
					const popper = this.querySelector('.popper');

					if (popper) {
						setTimeout(() => {
							const style = window.getComputedStyle(popper);
							const display = style.display;
							// Check if display is not 'none'
							if (display !== 'none') {
								const rect = popper.getBoundingClientRect();
								const windowHeight = window.innerHeight || document.documentElement.clientHeight;
								const windowWidth = window.innerWidth || document.documentElement.clientWidth;

								// const popperHeight = popper.offsetHeight

								const isVisible = (
									rect.top >= 0 &&
									rect.left >= 0 &&
									rect.bottom <= windowHeight &&
									rect.right <= windowWidth
								);
								// If the popper is not visible, adjust its position
								if (!isVisible) {
									popper.style.setProperty('top', '-68px', 'important');
									$this.updatePseudoElementRule('.patient-name-popper .popper:after', 'display', 'none');
								} else {
									$this.updatePseudoElementRule('.patient-name-popper .popper:after', 'display', 'block');
								}
							}
						}, 200); // 1-second delay
					}
				});
			});
		
			this.$store.state.loader = false;
		},
		updatePseudoElementRule(selector, property, value) {
			// Check if a style element exists, if not create one
			let styleSheet = document.getElementById('dynamic-styles');
			if (!styleSheet) {
				styleSheet = document.createElement('style');
				styleSheet.id = 'dynamic-styles';
				document.head.appendChild(styleSheet);
			}

			// Convert the rules to an array
			const rules = Array.from(styleSheet.sheet.cssRules);

			// Find the index of the rule if it exists
			const ruleIndex = rules.findIndex(rule => rule.selectorText === selector);

			if (ruleIndex !== -1) {
				// If the rule exists, update it
				styleSheet.sheet.deleteRule(ruleIndex);
			}

			// Append the new or updated rule
			styleSheet.sheet.insertRule(`${selector} { ${property}: ${value} !important; }`, styleSheet.sheet.cssRules.length);
		},
		onResourceClick(event) {
			this.$store.state.loader = true;

			const element = event.currentTarget;
			const dataId = element.getAttribute('data-id');

			this.provider_list.forEach(item => {

				if (item.id == dataId) {
					item.isvisible = true
					item.checked = true
					localStorage.setItem("selected-provider-ids-dayview", JSON.stringify([item.id]));
				} else {
					item.isvisible = false
					item.checked = false
				}
			})

			this.provider_list_resources = this.provider_list

			setTimeout(() => {
				this.$store.state.loader = false;
			}, 100);

		},
		echartDropdownClass(providerId) {
			let currentInx = 0
			let tempInx = 0
			this.provider_list_resources.forEach((item) => {
				if (item.isvisible) {
					tempInx++
					if (providerId == item.id) {
						currentInx = tempInx
					}
				}
			})

			return {
				'drop-arrow-5': this.checkedProviderCount() > 4,
				'echartDropdown-left': currentInx > 4 && ((currentInx % 7 == 0) || this.generateRangeProviderSelection().includes(this.checkedProviderCount()))
			}
		},
		echartDropdownWeekClass(appointmentDate) {
			// Return the class directly if the date corresponds to Saturday (day index 6)
			return new Date(appointmentDate).getDay() === 6 ? 'echartDropdown-left' : '';
		},
		generateRangeProviderSelection() {
			return [5, 6].map(num => num + (this.currentPage - 1) * 7);
		},
		openDatePicker(refName) {
			var schObj = document.querySelector(`#${refName}`).ej2_instances[0];
			schObj.show();
		},
		handleItemSelected({ fieldName, item }) {
			if (fieldName == 'defaultDuration') {
				this.preferenceForm.defaultDuration_text = item.title
				this.preferenceForm[fieldName] = item.value;
			} else if (fieldName == 'duration') {
				this.appointmentForm.duration_text = item.title
				this.appointmentForm[fieldName] = item.value;
				this.schedulerDurationChange()
			} else {
				this.preferenceForm[fieldName] = item.value;
			}
		},
		hideEncounterDropdown() {
			$(".encounterDropdown").addClass('d-none')
		},
		updateHeaderClass(){
			const selectedProviders = this.provider_list.filter(item => item.checked);
			const count = selectedProviders.length;
			if (count > 0) {
				this.$store.dispatch('updateCustomClass', 'position-fixed');
			} else {
				this.$store.dispatch('updateCustomClass', '');
			}
		},
		handleInputReasonVisit(event){
			const cursorPosition = event.target.selectionStart;
			this.appointmentForm.reason_for_visit = event.target.value.toUpperCase();

			// Set the cursor back to its original position after updating the value
			this.$nextTick(() => {
				event.target.setSelectionRange(cursorPosition, cursorPosition);
			});

		}

	},
	created() {
		this.fetchjsonToArray()

		localStorage.setItem('calendar_selected_date', new Date());

		if (localStorage.getItem('preference')) {
			this.preferenceForm = JSON.parse(localStorage.getItem('preference'));
			if (!this.preferenceForm.defaultDuration_text) {
				this.preferenceForm.defaultDuration_text = (this.duration_options.find(item => item.value === this.preferenceForm.defaultDuration) || {}).title || "Select";
				if (this.preferenceForm.defaultDuration_text == 'Select') {
					this.preferenceForm.defaultDuration = 10
					this.preferenceForm.defaultDuration_text = '10'
				}
			}
			this.startHour = this.preferenceForm.startHour;
			this.endHour = this.preferenceForm.endHour;
			this.defaultDuration = this.preferenceForm.defaultDuration;
			this.timeScale.interval = this.defaultDuration;
		}
		$(".encounterDropdown").hide();

		switch (localStorage.getItem("current-view")) {
			case "Day":
				this.currentView = 'Day';
				break;
			case "Month":
				this.currentView = 'Month';
				this.$refs.scheduleObj.ej2Instances.group.resources = [];
				this.$refs.scheduleObj.ej2Instances.dataBind();
				this.$refs.scheduleObj.ej2Instances.changeCurrentView(this.currentView);
				this.allowDragAndDrop = false;
				this.changeView(this.currentView)
				break;
			case "Week":
				this.currentView = 'Week';
				break;
			default:
				this.currentView = 'Day';
				break;
		}
	},
	mounted() {
		this.setDynamicCellHeight();
		window.addEventListener('resize', this.setDynamicCellHeight);
		this.getProviderList();
		this.getStatusList();
		this.getTypeList();
		const $this = this;
		document.body.addEventListener("click", function (evt) {
			if (!$(evt.target).closest('.selectCheckBox').length) {
				$this.displayDiv = false;
			}
			let time = null;
			if ($(evt.target).hasClass('e-time-slots')) {
				time = $(evt.target).children().text()
			} else if ($(evt.target).parent().hasClass('e-time-slots')) {
				time = $(evt.target).text();
			}

			if (time) {
				let date = moment($this.selectedDate).format("YYYY-MM-DD");

				var newTime = new Date(new Date("1970/01/01 " + time) + ($this.defaultDuration * 60000 * 2)).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });


				let endDate = new Date(`${date} ${newTime}`);
				let startDate = new Date(`${date} ${time}`);

				let scheduleObj = $this.$refs.scheduleObj.ej2Instances;
				let cellData = {
					startTime: startDate,
					endTime: endDate,
				};
				scheduleObj.openEditor(cellData, 'Add');
			}

		});

		this.resetTimer();
		window.addEventListener('mousemove', this.resetTimer);
		window.addEventListener('keydown', this.resetTimer);
		window.addEventListener('scroll', this.resetTimer);

		document.addEventListener('click', this.handleClickOutside);

		// if (this.currentView === 'Month') {
		// 	this.$nextTick(() => {
		// 		$(document).on('click', '.e-date-header', function (event) {
		// 			event.stopPropagation();
		// 			event.preventDefault();
		// 		});
		// 		$('.e-other-month').each(function () {
		// 			$(this).html('');
		// 		});
		// 	});
		// } else {
		// 	$(document).off('click', '.e-date-header');
		// }

		$(document).on("click", '._datepic', function () {
			const inputField = $(this).find('input');
			const inputId = inputField.attr('id');
			$this.openDatePicker(inputId);
		});

	},
	beforeUnmount() {
		window.removeEventListener('mousemove', this.resetTimer);
		window.removeEventListener('keydown', this.resetTimer);
		window.removeEventListener('scroll', this.resetTimer);
		clearTimeout(this.timeout);
	},
	computed: {
		// Computed property to get socket data from the store
		socketData() {
			return this.$store.getters.getSocketData;
		},
		filteredProviderDataSource() {
			return this.provider_list_resources.filter(provider => provider.isvisible);
		},
		providerSelectionText() {
			const selectedProviders = this.provider_list.filter(item => item.checked);
			const count = selectedProviders.length;
			if (count > 0 && this.providerListForNoResourceScreen ) {
				this.$store.dispatch('updateCustomClass', 'position-fixed');
			} else {
				this.$store.dispatch('updateCustomClass', '');
			}

			return {
			label: count === 0 
				? "Select Provider(s)" 
				: `${count} Selected Provider${count > 1 ? "s" : ""}`,
			hasResourceAvailable: count > 0,
			};
		}

	},
	watch: {
		// currentView(newValue) {
		// 	if (newValue === 'Month') {
		// 		this.$nextTick(() => {
		// 			$(document).on('click', '.e-date-header', function (event) {
		// 				event.stopPropagation();
		// 				event.preventDefault();
		// 			});
		// 			$('.e-other-month').each(function () {
		// 				$(this).html('');
		// 			});
		// 		});
		// 	} else {
		// 		$(document).off('click', '.e-date-header');
		// 	}
		// },
		// Watcher for socket data changes
		socketData: {
			handler(newData) {
				if (newData) {
					this.handleReceivedData(newData);
				}
			},
			immediate: true,
		},
		"isMultiAppointment"(newVal) {
			const resourceElements = document.querySelectorAll('.e-text-ellipsis');
			resourceElements.forEach((el, index) => {
				const resourceId = this.filteredProviderDataSource[index]?.id;
				if (resourceId) {
					el.setAttribute('data-id', resourceId);
				}
			});

			let $this = this;
			$('.e-text-ellipsis').each(function () {
				var pName = $(this).text();
				// Initialize arrays to store the string and numeric parts
				let providerNameArr = [];
				let providserIds = [];

				// Iterate over each element in the newVal array
				newVal.forEach(item => {
					// Split the item by the hyphen
					let parts = item.split('-');

					// Push the first part to the strings array
					providerNameArr.push(parts[0]);

					// Convert the second part to a number and push to the numbers array
					providserIds.push(parseInt(parts[1], 10));
				});

				if (/\(\d+\)/.test(pName)) {
					pName = pName.replace(/\(\d+\)/, '').trim();
				}

				if (providserIds.includes($(this).data('id')) && providerNameArr.includes(pName) && ['Today', 'Day', 'Week'].includes($this.currentView)) {
					if (!$(this).next().hasClass('paginationDiv')) {
						$(this).after('<div class="icon paginationDiv"><span class="seePrevAppointment" data-name="' + pName + '"><img src="/images/calender-left-sub-arrow.svg" class=""></span><span class="text">1 / 2</span><span class="seeNextAppointment" data-name="' + pName + '" ><img src="/images/calender-right-sub-arrow.svg" class=""></span></div>');
					}
				}
			});
			setTimeout(() => {
				if ($(document).find('e-other-month')) {
					$('.e-other-month').html('');
				}
			}, 500);
			this.activateEventListener()
		},
		providerSelectionText: {
			immediate: true, // Run immediately on load
			handler(newValue) {
				this.tagProviderLabel = newValue.label;
				this.hasResourceavailable = newValue.hasResourceAvailable;
			}
		}

	}
};
</script>

<style>
	
	/*APPOINTMENT POPUP DESIGN  */
.e-dialog .e-dlg-header {width: unset;}
.e-schedule-dialog .e-dlg-header-content {padding: 0;}

.appointment_popup .search input{color: #0E2641;}

.e-dialog .e-dlg-header .e-title-text {text-align: center;color: #0E2641;font-family: Inter;font-size: calc(var(--scale-ratio) * 24px);font-weight: 600;margin-bottom: calc(var(--scale-ratio) * 42px);cursor: text;}
.appointment_popup, .e-input-group, .e-input-group.e-control-wrapper, input.e-input, textarea.e-input {color: #0e2641;font-family: Inter;}
.appointment_popup{max-width: calc(var(--scale-ratio) * 720px);margin: auto;}
.appointment_popup #patient_id,.appointment_popup .Selact_drodwn{max-width:calc(var(--scale-ratio) * 300px);}

.appointment_popup,
input.e-input,
textarea.e-input,
.e-input-group,
.e-input-group.e-control-wrapper {color: #0E2641;font-family: 'Inter';}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {color: #0E2641;background: rgba(236, 227, 209, 0.50);font-size: 18px;font-weight: 600;}
.e-calendar .e-header .e-icon-container .e-prev {margin-right: 15px;}

.e-datepicker.e-popup-wrapper{border-radius: 5px;border: 0.5px solid #0E2641;}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before {content: unset !important;}

.calendar .e-input-group.e-input-focus {background-color: #F6F1E8;border: none;}

.e-calendar, .e-bigger.e-small .e-calendar {max-width: 400px !important;}

.e-btn.e-flat.e-primary:hover, .e-css.e-btn.e-flat.e-primary:hover {background: #0E2641 !important;border-color: #0E2641;color: #F4D09E !important;}

.e-calendar .e-content.e-year td:hover span.e-day, .e-calendar .e-content td.e-cell:hover span.e-day, .e-calendar .e-content td:focus span.e-day, .e-bigger.e-small .e-calendar .e-content td:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td:focus span.e-day {background-color: #fff;}

.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day:hover {background-color: #0E2641;color: #F4D09E !important;border: none !important;}
.e-calendar .e-header .e-title{color: #0E2641 !important;font-size: 18px !important;font-weight: 600;border-radius: 5px;padding: 7px 10px;line-height: normal !important;margin-left: 15px !important;}
.e-calendar .e-header .e-title:hover{background: rgba(236, 227, 209, 0.50);color: #0E2641;}
.e-calendar .e-header span.e-icons {color: #0E2641;font-size: 18px;}
.e-calendar .e-header .e-icon-container {margin-right: 15px;}
.e-calendar .e-content span.e-day, .e-calendar th{font-size: 16px;}

.appointment_popup .form-control,
.appointment_popup .e-input-group,
.appointment_popup .e-input-group.e-control-wrapper {border-radius: 5px !important;border: 0.5px solid #0E2641 !important;box-sizing: border-box !important;height: calc(var(--scale-ratio) * 40px);font-size: calc(var(--scale-ratio) * 16px);font-weight: 400;padding: calc(var(--scale-ratio) * 8px) calc(var(--scale-ratio) * 15px);line-height: normal;margin-bottom: calc(var(--scale-ratio) * 2px);}

.endtime-fild-input .form-control {border: 0 !important;color: #0E2641;font-size: calc(var(--scale-ratio) * 16px);font-weight: 400;line-height: normal;cursor: default;box-shadow: none;}
.endtime-fild-input input.form-control:focus, .endtime-fild-input input.form-control:active{box-shadow: none;}

.appointment_popup .form-control:focus {box-shadow: none;}

#appointment-confirm-delete .modal-dialog{max-width: calc(var(--scale-ratio) * 610px);}
#appointment-confirm-delete .modal-body{padding: calc(var(--scale-ratio) * 60px) calc(var(--scale-ratio) * 95px);}
#appointment-confirm-delete .m_title{font-size: calc(var(--scale-ratio) * 20px);font-weight: 600;line-height: normal;}
.patient_card_detail .card_status {padding-left: calc(var(--scale-ratio) * 11px)}
.patient_card_detail .card_status .txt{font-size: calc(var(--scale-ratio) * 14px);font-weight: 400;}
.patient_card_detail .card_status .icon{font-size: calc(var(--scale-ratio) * 18px);vertical-align: middle;margin-right: calc(var(--scale-ratio) * 18px);}
.patient_card_detail .card_status .icon.red{color:#C00;}
.Patient_task_link{font-size: calc(var(--scale-ratio) * 14px);font-weight: 600;color: #0E2641;}
.Patient_task_link span{text-decoration: underline;padding-left: calc(var(--scale-ratio) * 10px);cursor: pointer;}
.appointment_popup .form-label {font-weight: 600;margin-bottom: calc(var(--scale-ratio) * 10px);cursor: text;font-size: calc(var(--scale-ratio) * 15px)}


.appointment_popup .e-input-group input.e-input {padding: 0 !important;text-align: center;}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.appointment_popup .e-date-wrapper:not(.e-filled) .e-date-icon.e-icons,
.e-time-wrapper .e-time-icon.e-icons,
*.e-control-wrapper.e-time-wrapper .e-time-icon.e-icons {margin: 0;display: none;}

.ml-15{margin-left: calc(var(--scale-ratio) * 15px);}

.e-dialog .e-dlg-content {font-size: calc(var(--scale-ratio) * 16px);overflow: unset;}
.e-control, .e-css{font-family:'Inter' !important;}

.e-popup.e-popup-open.e-dialog {width: calc(var(--scale-ratio) * 1190px) !important;border-radius: 5px;overflow: auto;padding: calc(var(--scale-ratio) * 50px) calc(var(--scale-ratio) * 20px);}
.e-footer-content {display: flex !important;justify-content: space-evenly;padding: 0px calc(var(--scale-ratio) * 120px) calc(var(--scale-ratio) * 30px) calc(var(--scale-ratio) * 120px) !important;}
.e-footer-content .e-event-cancel{order: 0;}
.e-footer-content .e-schedule-dialog.e-control.e-event-save{order: 2;background-color: #0E2641;color: #F4D09E;}
.e-footer-content .e-schedule-dialog.e-control.e-event-save:hover {background-color: #253B4A;border: 1px solid #253B4A;}
.e-footer-content .e-event-delete{order: 1}

.e-footer-content .e-schedule-dialog.e-control {border: 1px solid #0E2641;color: #0E2641;padding: calc(var(--scale-ratio) * 19px) calc(var(--scale-ratio) * 33px);min-width: calc(var(--scale-ratio) * 170px);border-radius: 5px;font-weight: 600;text-align: center;font-size: calc(var(--scale-ratio) * 16px);line-height: normal;text-transform: capitalize;}

.e-footer-content .e-schedule-dialog.e-control:hover {background-color: #F6F1E8;;}
.e-treeview .e-list-text {color: rgb(14 38 65);font-size: 14px;min-height: auto;line-height: normal;}

	.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {position: absolute;right: calc(var(--scale-ratio) * 20px);top: calc(var(--scale-ratio) * 20px);left: auto;height: calc(var(--scale-ratio) * 58px);width: calc(var(--scale-ratio) * 58px);border-radius: 5px;}
.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn:hover{background-color: #ece3d180;}
.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn .e-btn-icon {font-size: calc(var(--scale-ratio) * 18px);}
	
	/* calander css start */
	.paginationDiv{display: flex;align-items: center;color: #0E2641;font-size: calc(var(--scale-ratio) * 14px);font-weight: 600;line-height: normal;justify-content: center;}
	.month_scroll{max-height: 130px;overflow: auto;}

	.calender_page_btn{color: #0E2641;font-size: 14px;font-style: normal;font-weight: 600;line-height: normal;text-align: center;}
	.calender_page_btn .left_icon,.calender_page_btn .right_icon{margin: 0px 15px;border-radius: 50%;cursor: pointer;position: absolute;z-index: 11;}

	.calender_page_btn img.left_icon{left: 0;}
	.calender_page_btn img.right_icon{left: auto;right: 0;}
	.header-schedule {border: 1px solid rgb(255 255 255 / 12%);}
	.header-schedule td.e-resource-cells.e-disable-dates {position: relative;}

	.e-left-indent-wrap {display: none;}
	.e-schedule .e-vertical-view .e-content-wrap table td.e-work-cells:hover {background:#F6F6F7;position: relative;}
	.e-schedule .e-vertical-view .e-work-cells:hover {color: rgba(209, 195, 166, 1);}
	.e-schedule .e-vertical-view .e-left-indent {width: calc(var(--scale-ratio) * 112px);}
	
	.e-schedule .e-vertical-view .e-content-wrap table td.e-work-cells:hover:after{font-size: calc(var(--scale-ratio)* 15px);color: #A0A3A7;line-height: normal;content: "\2b";font-family: FontAwesome;font-style: normal;font-weight: 900;position: absolute;
	top: 24.8px;vertical-align: middle;}
	.e-schedule .e-vertical-view .e-work-cells, .e-schedule .e-vertical-view .e-date-header-wrap table tbody td {background: #fff;border-right: 1px solid #687789;border-bottom: 1px dashed rgba(160, 163, 167, 0.50);border-left: 0;vertical-align: top;}
	.e-schedule .e-vertical-view .e-content-wrap,.e-schedule .e-vertical-view .e-date-header-wrap{margin-right: 0px;}

	.e-schedule .e-vertical-view .e-date-header-wrap table tbody tr:nth-child(3) td {border-color: white !important;}
	.e-schedule .e-vertical-view .e-time-cells-wrap tr:first-child .e-time-cells {border-top: 0;}
	.e-schedule .e-vertical-view .e-time-cells-wrap .e-time-cells {border-bottom: 1px dashed rgba(160, 163, 167, 0.50);font-size: calc(var(--scale-ratio) * 16px);border-right: 1px solid;color: #687789;}
	.e-schedule .e-vertical-view .e-time-cells-wrap .e-time-cells:hover {background: #F6F6F7;}
	.e-schedule.e-device .e-vertical-view .e-time-cells-wrap{width: 60px;}

	.header-schedule.e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-resource-cells,.e-schedule .e-vertical-view .e-date-header-wrap.e-all-day-auto,.e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-header-cells {border: 0;}

	.e-schedule .e-text-ellipsis {color: #0E2641;font-weight: 600;font-size: calc(var(--scale-ratio) * 18px);cursor: pointer;display: inline-block;}

	.search {position: relative;}
	.search input {padding: calc(var(--scale-ratio) * 8.5px) calc(var(--scale-ratio) * 15px) calc(var(--scale-ratio) * 8.5px) calc(var(--scale-ratio) * 45px) !important;border-radius: 5px;border: 0.5px solid #0E2641;color: #687789;line-height: normal;font-size: calc(var(--scale-ratio) * 18px);cursor: text;font-weight: 300;background: unset;}
	.search input:hover {background: #fff;}
	.search input:focus {box-shadow: none;border: 0.50px solid #0E2641;}

	
	.appointmnt-ad-list .icon_tooltip .target-click p {white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 120px;display: block;}
	.appointmnt-ad-list .icon_tooltip .target-click p:hover {text-decoration: underline;}
	.appointmnt-ad-list .target-click p {white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 120px;display: block;}
.pntnt-description-3{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: calc(var(--scale-ratio) * 216px);display: inline-block;text-transform: capitalize !important;vertical-align: middle;}
.pntnt-description-4{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 200px;display: inline-block;text-transform: capitalize !important;vertical-align: middle;}

.ap-patient_name{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
/*	width: 130px;*/
	vertical-align: middle;}
	.provider-name-1{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 152px;margin-right: calc(var(--scale-ratio) * 15px) !important;}
	.provider-name-4{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 90px;}
	.provider-name-7{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 112px;}
	.provider-name-2{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 192px;}
	.tooltip-provider-name-2{margin-right: 8px;width: 200px;}
	.ap-patient_name:hover{text-decoration: underline;}
	.echartDropdown-left .drop-options {margin: 0 0 0 -106px;}

	.global-search .srch-vndor-list-scroll::-webkit-scrollbar{display: block;}

	
.input_date_picker .e-input-group {margin: 0 !important;border: 1px solid #A0A3A7 !important;font-size: calc(var(--scale-ratio) * 18px) !important;min-height: calc(var(--scale-ratio) * 60px);padding-left: calc(var(--scale-ratio) * 30px) !important;font-family: 'Inter' !important;font-weight: 300 !important;}
.input_date_picker .e-input-group input.e-input::placeholder{color: #A0A3A7 !important;}
.input_date_picker .e-input-group input.e-input{margin: 0 !important;}
	
	
	.calendar .e-input-group-icon.e-date-icon {margin: 0 10px 1px 0px !important;position: relative;}

	.e-schedule-dialog.e-device .e-dlg-header-content{background: transparent;box-shadow: none;margin-bottom: 0;}
	.e-treeview .e-list-item.e-active > .e-fullrow{background: transparent;border: 0;}
	.e-treeview .e-ul{padding: 0;}
	.e-schedule .e-resource-tree-popup .e-resource-tree.e-treeview .e-text-content{padding: 0;}
	.e-treeview .e-list-item.e-active > .e-text-content .e-list-text{color: #0e2641;}
	.e-treeview .e-list-item.e-active, .e-treeview .e-list-item.e-hover, .e-treeview .e-list-item.e-node-focus {background: rgba(236, 227, 209, 0.50);}
	.e-schedule .e-schedule-resource-toolbar .e-icon-menu::before{color: #0e2641;}

	.calendar .e-input-group-icon.e-date-icon::before {background-image: url(/images/calendar-search.svg);content: '' !important;height: 25px;width: 25px;position: absolute;left: 0;top: 0;bottom: 0;background-size: cover;margin: auto;}

	.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {color: #0E2641 !important;}

	.e-calendar .e-content.e-year td:hover span.e-day, .e-calendar .e-content td.e-cell:hover span.e-day, .e-calendar .e-content td:focus span.e-day, .e-bigger.e-small .e-calendar .e-content td:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td:focus span.e-day {background-color: #fff;}

	.e-calendar .e-content td.e-focused-date.e-today span.e-day,
	.e-calendar .e-content td.e-focused-date.e-today span.e-day:hover {background-color: #0E2641;color: #F4D09E !important;border: none !important;}
	.e-calendar .e-header .e-title{color: #0E2641;font-size: 18px;font-weight: 600;border-radius: 5px;padding: 7px 10px;line-height: normal;margin-left: 15px;}
	.e-calendar .e-header .e-title:hover{background: rgba(236, 227, 209, 0.50);color: #0E2641;}
	.e-calendar .e-header span.e-icons {color: #0E2641;font-size: 18px;}
	.e-calendar .e-header .e-icon-container {margin-right: 15px;}
	.e-calendar .e-content span.e-day, .e-calendar th{font-size: 16px;}

	.weekView.view-main{width: 100%;padding-right: calc(var(--scale-ratio) * 15px);}

	.e-calendar .e-content td.e-selected span.e-day,.e-calendar .e-content.e-decade td.e-selected:hover span.e-day,.e-calendar .e-content td.e-selected.e-focused-date:hover span.e-day,.e-calendar .e-content td.e-selected.e-focused-date span.e-day,.e-calendar .e-content.e-year td.e-selected span.e-day,.e-calendar .e-content.e-year td.e-selected:hover span.e-day {background-color: #0E2641;color: #F4D09E !important;}

	.e-calendar .e-content td.e-today span.e-day {border: 1px solid #0E2641;color: #0E2641;}
	

	.e-btn.e-flat.e-primary,
	.e-css.e-btn.e-flat.e-primary {color: #0E2641;background: rgba(236, 227, 209, 0.50);font-size: calc(var(--scale-ratio) * 18px);font-weight: 600;}
	.e-calendar .e-header .e-icon-container .e-prev {margin-right: 15px;}

	.e-datepicker.e-popup-wrapper{border-radius: 5px;border: 0.5px solid #0E2641;}

	.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
	.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before {content: unset !important;}

	.calendar .e-input-group.e-input-focus {background-color: #F6F1E8;border: none;}

	.e-calendar, .e-bigger.e-small .e-calendar {max-width: 400px !important;}

	.e-btn.e-flat.e-primary:hover,
	.e-css.e-btn.e-flat.e-primary:hover {
		background: #0E2641;
		border-color: #0E2641;
		color: #F4D09E;
	}
	.calendar_data_table {font-size: calc(var(--scale-ratio) * 16px);font-weight: 500;padding: 0;font-family: 'Inter';color: #0E2641;margin: 0;}
	.calendar_data_table .target-click span{vertical-align: middle;margin-right: 4px;}
	.toltip_dtl_prdr:hover{text-decoration: underline}

	.toltip_dtl_prdr sup.text{font-size: 1em;}

	.calendar_data_table>li {display: inline-block;
		/*min-width: 48%;*/
		padding: 0px 0 0px 3px;color: #0E2641;}

	.calendar_data_table .provider-caldr-3{min-width: 50%;}
	.provider-caldr-3 .ap-patient_name{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 132px;margin-right: calc(var(--scale-ratio) * 15px) !important;}

	.provider-caldr-4{display: flex;width: 100%;}

	.calendar_data_table .provider-caldr-1{min-width: 375px;}
	.provider-caldr-name-3{width: 100%;display: flex;}
	.provider-caldr-name-3 .calendar_data_table{display: flex;align-items: center;}
	.provider-caldr-name-2{width: 100%;}
	.provider-caldr-name-1{width: 100%;}

	.e-schedule .e-schedule-resource-toolbar{background: #fff;align-items:center;min-height: calc(var(--scale-ratio) * 42px);padding: calc(var(--scale-ratio) * 10px);}
	.e-schedule .e-schedule-resource-toolbar .e-resource-level-title .e-resource-name{color: #0E2641;text-align: center;font-weight: 600;font-size: calc(var(--scale-ratio) * 18px);cursor: pointer;display: inline-block;padding: calc(var(--scale-ratio) * 5px);}


	.weekView .calendar_data_table>li{width: 100%;}
	.list_menu_txt .drop-trigger .drop-options a{color: #0e2641;text-decoration: none;}
	.calender_plushsign{position: absolute;right: 0;top: -4px;color: #0E2641;font-size: 14px;font-weight: 600;line-height: normal;}
	.weekView .calender_plushsign{position: absolute;right: 0;top: -4;color: #0E2641;font-size: 14px;font-weight: 600;line-height: normal;}
	.e-schedule .e-month-view .e-work-cells:hover .e-appointment {background: #f5f1e8 !important;box-shadow: none;}
	.monthView .calendar_data_table{font-size: calc(var(--scale-ratio) * 10px);white-space: break-spaces;}
	.monthView .calendar_data_table .sub_heading{font-size: calc(var(--scale-ratio) * 10px);font-weight: 500;}
	.e-schedule .e-month-view .e-appointment{border: 0;background: transparent !important;top: 0 !important;height: auto;}
	.e-schedule .e-month-view .e-appointment:focus{box-shadow: none;}
	.e-schedule .e-month-view .e-work-cells{background: #fff;}
	.e-schedule .e-month-view .e-work-cells, .e-schedule .e-month-agenda-view .e-work-cells,.e-schedule .e-month-view .e-date-header-wrap table td, .e-schedule .e-month-agenda-view .e-date-header-wrap table td{border-width: 0px 0px 2px 2px;border-color: #ECE3D1;}
	.e-schedule .e-month-view .e-work-cells, .e-schedule .e-month-agenda-view .e-work-cells{border-width: 0px 1px 1px 0px;padding: 3px;position: relative;cursor: default;}
	.e-schedule .e-month-view .e-date-header:hover{text-decoration: none;}
	.e-schedule .e-month-view .e-work-cells:hover{background: rgba(236, 227, 209, 0.50);}
	.e-schedule .e-month-view .e-work-cells:hover .e-date-header{color: #0E2641;}
	.e-schedule .e-month-view .e-current-date .e-date-header {border-radius: 5px;background: #0E2641;color: #F4D09E !important;margin: 0;text-decoration: none;float: right;height: auto;}
	.e-schedule .e-month-view .e-date-header{color: #A0A3A7;font-size: calc(var(--scale-ratio) * 12px);font-weight: 600;line-height: normal;float: right;cursor: default;padding: 3px 4px !important;}
	.e-schedule .e-month-view .e-appointment .e-appointment-details,.e-schedule .e-month-view .e-appointment{overflow: unset;}
	.e-schedule .e-month-view .e-date-header-wrap table td:first-child, .e-schedule .e-month-view .e-content-wrap table td:first-child {border-left-width: 1px;}
	.e-schedule .e-month-view .e-appointment-wrapper{top: 30px;}
	.e-schedule .e-month-view .e-date-header-wrap table td{font: calc(var(--scale-ratio) * 16px) !important;}

	.e-treeview .e-list-item .e-text-content .e-list-text{font-size: calc(var(--scale-ratio) * 14px);}
	
	.e-schedule .e-vertical-view .e-header-cells{height: auto;}



	
	/*	---- new css -----*/

	.e-schedule-dialog.e-device .e-save-icon, .e-schedule-dialog.e-device .e-back-icon{display: none;}
	.e-schedule .e-vertical-view .e-work-cells, .e-schedule .e-vertical-view .e-date-header-wrap table tbody td {font-size: calc(var(--scale-ratio) * 12px) !important;}
	/* .e-schedule .e-vertical-view .e-time-cells-wrap table td, .e-schedule .e-vertical-view .e-work-cells{height: 36px;} */

	
	.e-input-group input.e-input:focus, .e-input-group.e-control-wrapper input.e-input:focus, .e-input-group textarea.e-input:focus, .e-input-group.e-control-wrapper textarea.e-input:focus, .e-input-group.e-input-focus input.e-input, .e-input-group.e-control-wrapper.e-input-focus input.e-input{padding: 0;}

	.appoint-date-select .e-input-group{border: 0.5px solid #0E2641;}

	/*	---- new css end-----*/


	@media (max-width: 1750px) {
		.pntnt-description-4{max-width: 180px;}
	}
	@media (max-width: 1650px) {
		/* master recored */
		.patient_chart .left_side_bar .side-bar-menu{padding-left: 10px;}

		/* e-chart */

		.consult_add_page .btn_part button{padding: 17px 10px;}
		.pntnt-description-4{max-width: 120px;}

	}

	
	@media (max-width: 1500px) {
		.tooltip-provider-name-2{width: 150px;}

	}
	
	@media (max-width: 1400px) {
		#create-setting .modal-body {padding:60px 30px;overflow: scroll;}
		#create-setting .regular_hours,#create-setting .special_hour,#create-setting .composation {padding: 0px;}
		#create-setting .con_detl{padding:0;}

		.calendar .e-input-group-icon.e-date-icon::before{height: 20px;width: 20px;}

		/* master recored */
		.patient_chart .containt_detail p > span {padding-left: 10px;}
		
		/* e-chart */
		.PatientChart_header .close_sign{margin-left: 20px;}
		.e-chart_page .start_ai_scribe .no_appointment{margin: 0;}
		.allergies .a_header .search_drop_box{width: 600px;}
		.edit_delet_link{text-align: center;}
		.edit_delet_link .popup_link {margin: 3px 8px;display: inline-block;}


		.consult_add_page .btn_part button{min-width: unset;padding: 17px 30px;}

		.pntnt-description-4{max-width: 60px;}
		
		.appointmnt-ad-list .icon_tooltip .target-click p{max-width:90px;}		

	}

	/*check responsiveness  */

	
	@media (max-width: 1920px) {
		.calendar_data_table .provider-caldr-3{min-width: 53%;}

	}


	@media (max-width: 1600px) {
		.e-schedule .e-vertical-view .e-left-indent {width: 70px;}
		.e-schedule .e-resource-tree-popup{width: 156px;}

		.e-schedule .e-vertical-view .e-time-cells-wrap{width: 70px;}
		.provider-caldr-4{min-width: 170px;}

	}

	@media (max-width: 1280px) {
		.e-schedule .e-vertical-view .e-left-indent {width: 70px;}

		.e-schedule .e-resource-tree-popup{width: 156px;}
		
	}

	@media (max-width: 1450px) {
		.provider-name-7{max-width: 65px;}
	}


	@media (max-width: 1500px) {
		.provider-name-6 .ap-patient_name{width: 90px;}

	}



	/*check responsiveness end  */


	/* calander css end */

	/* selact dropdown */
	.Selact_drodwn .dropdown-div .caption {border-radius: 5px;border: 0.5px solid #0E2641;cursor: pointer;}

	.Selact_drodwn .dropdown-div .caption img {width: calc(var(--scale-ratio) * 20px);margin-left: calc(var(--scale-ratio) * 20px);}
	.Selact_drodwn .dropdown-div.open .caption img {transform: rotate(180deg);}
	.Selact_drodwn .dropdown-div .list {position: absolute;margin-top: calc(var(--scale-ratio) * 7px);background: #FFF;box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);width: 100%;border-radius: 0 0 3px 3px;z-index:11;max-height: calc(var(--scale-ratio) * 300px);overflow: auto;}
	.Selact_drodwn .dropdown-div .list .item {padding: calc(var(--scale-ratio) * 9px) calc(var(--scale-ratio) * 32px);cursor: pointer;border-bottom: 0.5px solid #ECE3D1;line-height: normal;font-size: calc(var(--scale-ratio) * 16px)}
	.Selact_drodwn.unline_remove .dropdown-div .list .item{border-bottom: 0}

	/* .Selact_drodwn .dropdown .list .item.selected {font-weight: bold;} */
	.Selact_drodwn .dropdown-div .list .item:hover {background-color: rgba(236, 227, 209, 0.5);}

	/* .Selact_drodwn .dropdown.open .caption {border-radius: 3px 3px 0 0;border-bottom: solid 1px #999;} */
	.Selact_drodwn .dropdown-div.open .list {display: block;}

	/* selact dropdown end*/
	input.e-input::selection,
	textarea.e-input::selection,
	.e-input-group input.e-input::selection,
	.e-input-group.e-control-wrapper input.e-input::selection,
	.e-float-input input::selection,
	.e-float-input.e-control-wrapper input::selection,
	.e-input-group textarea.e-input::selection,
	.e-input-group.e-control-wrapper textarea.e-input::selection,
	.e-float-input textarea::selection,
	.e-float-input.e-control-wrapper textarea::selection {
		background: #F5F1E8;
		color: #0E2641;
	}

	
	.e-schedule .e-vertical-view .e-day-wrapper .e-appointment:focus {border: 0;box-shadow: none;}
	.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {overflow: unset;width: 99.8% !important;display: flex;align-items: center;line-height: normal;}
	.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-appointment-details{display: flex;align-content: center;align-items: center;height: 100%;padding: 0 0;}

	.target-click{cursor: pointer;text-transform: uppercase;}
	
	.rotateIcon{transform: rotate(45deg);}
	.e-date-header.e-navigate {float: right;}
	
	.e-spinner-pane {display: none !important;}

	/* Month view each day cell box */
	td.e-work-cells.e-work-days {cursor: pointer;}

	.patient-name-popper .popper{border-radius: 5px;background: rgba(0, 0, 0, 0.70) !important;backdrop-filter: blur(5px);text-align: left;cursor: default; display: inline-block;padding: calc(var(--scale-ratio) * 10px) !important;top: 28px !important;inset: calc(var(--scale-ratio) * 30px) auto auto auto !important;}
	.patient-name-popper .popper:after{background: #4c4c4c !important;backdrop-filter: blur(5px);top: calc(var(--scale-ratio) * -9px);left: calc(var(--scale-ratio) * 24px);margin: 0;}
	.patient-name-popper .popper span.popper-content{color: #FFF;font-size: calc(var(--scale-ratio) * 14px);font-weight: 600;line-height: normal;margin-right: 0 !important;cursor: text;text-transform: uppercase;height: auto;display: block;}
	.patient-name-popper .popper span.popper-content span{height: auto;display: block;}
	.patient-health-red{color: #FF5A78;}
	.patient-name-popper .popper span.popper-content ul{list-style-type: disc;padding-left: calc(var(--scale-ratio) * 20px);text-transform: none;}
	
	.e-datepicker .e-model-header{background: #f5f1e8;color: #0e2641;}
	/* Center-align the resource names */
	.e-schedule .e-resource-cells {text-align: center !important;}
</style>

<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies mesurement">
			<div class="a_header">
				<div>
					<div class="search">
						<img class="serch_icon" src="/images/search-icon.svg" alt="">
						<input type="text" class="form-control" placeholder="Search Metric Group" ref="globalSearch"
							v-model="search_metric_group" @keydown.enter="searchMatricGroup" @input="handleInput" >
					</div>
					<span class="position-relative">
						<button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
						<button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length }}</button>
						<CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getVitalGroupList"></CustomFilter>
					</span>
				</div>
				<div>
					<button class="comman_btn" @click="createGroup"> Create Group </button>
				</div>
			</div>

			<div class="table-responsive consult-list template">
				<table class="table">
					<thead>
						<tr>
							<th class="hover_unset w-50px"></th>
							<th scope="col" :class="{ 'active': this.orderByColumnName == 'name' }" @click="changeSort('name')">Group <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.orderBy == 'asc' }"></th>
							<th scope="col" :class="{ 'active': this.orderByColumnName == 'included_metrics' }" @click="changeSort('included_metrics')">Included Metrics <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.orderBy == 'asc' }"></th>
							<th scope="col" class="hover_unset cursor-auto"></th>
						</tr>
					</thead>
					<tbody v-if="vitalGroupData.length > 0">
						<tr v-for="(item, listIndex) in this.vitalGroupData" :key="listIndex">
							<td></td>
							<td>{{ item.name }}</td>
							<td style="text-wrap:unset">{{ item.included_metric_names }}</td>
							<td class="edit_delet_link">
								<span class="popup_link" @click="editGroup(item.id)">Edit</span>
								<span class="popup_link" @click="deleteGroup(item.id)">Delete</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="modal fade" id="create_metric_group" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" @click="closeCreateModel"
						aria-label="Close"></button>
					<div class="box h-100">
						<div>
							<h2 class="popup_title">Create Metric Group</h2>

							<div class="custom-form">
								<div class="input_box">
									<div class="metric-filterGroup-dropdown">
										<input type="text" required="" id="typeunique_idX-2" ref="filterGroupEleRef"
											class="form-control form-control-lg" v-model="form.name" @input="filterGroupName" @focus="filterGroupName">
										<label class="form-label" for="typeunique_idX-2">
											Group Name
											<small class="asterisksign">*</small>
										</label>
										<ul v-if="filteredGroupOptions.length" class="suggestion-list">
											<li
											v-for="(option, index) in filteredGroupOptions"
											:key="index"
											@click="selectOption(option)"
											>
											{{ option }}
											</li>
										</ul>
									</div>
									<ValidationErrorMessageList :errors="v$.form.name.$errors" />
								</div>
								<hr class="mt-4" style="border: 1px solid #0E2641; opacity: 1;">
								<div class="multi-metric-group" style="height: calc(var(--scale-ratio) * 510px);">
									<div class="add_fild position-relative">
										<slot v-for="(item, index) in matrixInpObj" :key="index">
											<button class="comman_brdr_btn fs-18 py-20 mr-30 mb-30"
												v-if="item.is_custom === true">
												{{ item.name }}
											</button>
											<div class="position-relative mb-30" v-if="item.is_custom === false">
												<span class="popup_link" @click="removeInpMatric(index)" v-if="index != 0">Cancel</span>
												<div class="metric-filteredVitalList">
													<CustomDropDown :options="filteredVitalList(index)" :initialValue="item.display_name" @item-selected="handleItemSelected" :fieldName="getFieldName(index)" :key="vitalIndex">
													</CustomDropDown>
												</div>
												<ValidationErrorMessageList :errors="v$.form.included_metrics.$errors" divClass="mb-2" />
											</div>
										</slot>
									</div>
									<div class="plus_sign_row mt-0" @click="addNewVitalInput">+</div>
								</div>
							</div>
						</div>
						<div class="text-center">
							<button class="comman_brdr_btn big_btn mx36" @click="closeCreateModel"> Cancel </button>
							<button class="comman_btn big_btn mx36" @click="createVitalGroup">
								<img src="images/loader.gif" v-if="loader" style="width: calc(var(--scale-ratio) * 18px);" />
								Create
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal fade" id="edit_metric_group" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl edit_measurement_group">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" @click="closeEditModel"
						aria-label="Close"></button>

					<div class="box" style="height:auto;overflow: unset;">
						<div>
							<h2 class="popup_title">Edit Measurement Group</h2>
							<div class="main_tagline">{{ singleVitalGroupData.name }}</div>
							<hr class="mt-4" style="border: 1px solid #0E2641;opacity: 1;">

							<div class="custom-form" style="margin: 0 calc(var(--scale-ratio) * 75px)">
								<div class="multi-metric-group" style="height: calc(var(--scale-ratio) * 510px);padding: calc(var(--scale-ratio) * 5px);">
									<div class="add_fild position-relative">
										<slot class="" v-for="(item, index) in matrixInpObj" :key="index">
											<div class="position-relative d-inline-block">
												<button class="comman_brdr_btn measur-firstletter mr-30 mb-30"
													v-if="item.is_custom === true && item.name" >
													{{ this.$filters.capitalizeFirstLetter(item.name) }}
												</button>
												<img src="images/close-icon.svg" class="filter-remove cursor-pointer" @click="removeMetricGrp(index)" v-if="item.is_custom === true && item.name">
											</div>
											<div class="d-flex align-items-center mb-20" v-if="item.is_custom === false">
												<div class="filteredvital-dropdown w-100">
													<CustomDropDown :options="filteredVitalList(index)" :initialValue="item.display_name" @item-selected="handleItemSelected" :fieldName="getFieldName(index)" >
													</CustomDropDown>
												</div>
												<slot>
													<div class="measurement-add-cancel-link l-h-n">
														<span class="popup_link" @click="removeInpMatric(index)" v-if="item.is_custom === false">Cancel</span>
													</div>
												</slot>
											</div>
										</slot>
										<ValidationErrorMessageList :errors="v$.form.included_metrics.$errors" divClass="mb-2" />
									</div>
									<div class="plus_sign_row mt-0" v-if="isShowAddBtn" @click="addNewVitalInput">+</div>
								</div>
							</div>
						</div>

						<div class="text-center">
							<button class="comman_brdr_btn big_btn mx36" @click="closeEditModel"> Cancel </button>
							<button class="comman_btn big_btn mx36" @click="updateMetricGroup">
								<img src="images/loader.gif" v-if="loader" style="width: calc(var(--scale-ratio) * 18px);" />
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from '../sidebar.vue';
import CustomFilter from '../../../base/CustomFilter.vue';
import axios from "@/scripts/axios.js";
import CustomDropDown from '../../../base/formFields/CustomDropDown.vue';
import $ from "jquery";
import {
	required,
	helpers,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../../../base/ValidationErrorMessageList.vue';

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			vitalGroupData: [],
			matrixInpObj: [],
			vitalList: [],
			loader: false,
			form: {
				id: null,
				name: '',
				included_metrics: [],
			},
			advanceFilter : {},
			name_text:'Select Group Name',
			search_metric_group: '',
			singleVitalGroupData: [],

			isShowCustomFilter: false,
			filterList: [],
			filterOptions: [
				{ value: "group_name", title: "Group" },
				{ value: "vital_matrix", title: "Included Metrics" },
			],
			groupNameOpt:["Body Measurements", "Common Triage", "Urinalysis"],
			orderBy: '',
			orderByColumnName: '',
			onFetchSingalVitalCount: '',
			filteredGroupOptions:[],
			searchTimeout: null,
			isShowAddBtn:true,
			vitalIndex:0
		}
	},
	components: {
		sidebar,
		CustomFilter,
		CustomDropDown,
		ValidationErrorMessageList
	},
	mounted() {
		document.addEventListener('click', this.handleClickOutside);
		this.getVitalGroupList()
	},
	methods: {
		handleInput() {
            // Clear the previous timer if it exists
            clearTimeout(this.searchTimeout);

            // Start a new timer that triggers after 2 seconds of idle time
            this.searchTimeout = setTimeout(() => {
                this.searchMatricGroup();
            }, 1000); // 1-second idle time
        },
		getVitalGroupList() {
			this.$store.state.loader = true;
			axios.post("vital-group/list", {
				search_text: this.search_metric_group,
				order_by: this.orderBy,
				order_by_column:this.orderByColumnName,
				advanceFilter:this.advanceFilter,
			})
			.then((response) => {
				this.$store.state.loader = false;
				this.vitalGroupData = response.data.data;
			})
			.catch(error => {
				this.$store.state.loader = false;
				if (error.response.status == 422) {
					this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
				} else {
					this.$filters.moshaToast(error.response.data.message, "error");
				}
			});
		},
		addNewVitalInput() {
			const lastItemIndex = this.matrixInpObj.length - 1;
			if (this.matrixInpObj.length == 0 || (this.form['included_metrics'] && this.form['included_metrics'][lastItemIndex] !== undefined && this.form['included_metrics'][lastItemIndex] !== null)) {
				this.matrixInpObj.push({
					id: null,
					name: null,
					is_custom: false,
				});
				this.isShowAddBtn = false
			} else {
				this.$filters.moshaToast("Please Select new metric", "error");
			}
		},
		vitalSearch() {
			this.vitalList = [];
			axios.post("json_autocomplete/vital-search")
				.then((response) => {
					const vitalTempData = []
						const details = response.data.data;
						$.each(details, function (key, detail) {
							let title = detail['name']
							if (detail['unit']) {
								title += ' ('+detail['unit']+')'
							}

							vitalTempData.push({
								value: detail['id'],
								title: title,
								display_name:"Select Metric"
							})
						})
						this.vitalList = vitalTempData;
				})
				.catch((error) => {
					this.loader = false;
					if (error.response.status == 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		setCostumeMetric(index) {
			if (this.form['included_metrics'] && this.form['included_metrics'][index] !== undefined && this.form['included_metrics'][index] !== null) {
				this.matrixInpObj[index].is_custom = true;
			} else {
				this.$filters.moshaToast("Please Select new metric", "error");
			}
		},
		async createGroup() {
			await this.vitalSearch()
			this.resetFormData()
			window.$("#create_metric_group").modal("show");

		},
		createVitalGroup() {
			this.v$.$validate();
			if (!this.v$.$error) {
				this.loader = true;
				axios.post("vital-group/store", this.form)
				.then((response) => {
					if(response.data.status == 200) {
						this.loader = false;
						this.getVitalGroupList();
						this.$filters.moshaToast(response.data.message, "success");
						window.$("#create_metric_group").modal("hide");
					}
				})
				.catch(error => {
					this.loader = false;
					if (error.response.status == 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
			} else {
				console.log(Object.entries(this.v$.form))
				for (const [key] of Object.entries(this.v$.form)) {
					if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}


		},
		editGroup(id) {
			this.vitalSearch()
			this.retrieveGroup(id);
		},
		resetFormData() {
			this.v$.$reset();
			this.vitalIndex += 1
			this.form.name = '';
			this.form.included_metrics = [];
			this.form.id = null;
			this.filteredGroupOptions = []
			this.matrixInpObj = [{
								id: null,
								name: null,
								is_custom: false,
								display_name:"Select Metric"
							}];
			
		},
		searchMatricGroup() {
			// Clear the previous timer if it exists
			clearTimeout(this.searchTimeout);

			this.getVitalGroupList();
		},
		retrieveGroup(id)
		{
			this.loader = true;
			this.form.id = id;
			axios.post("vital-group/retrieve", { id: id })
			.then((response) => {
				if(response.data.status === 200)
				{
					this.singleVitalGroupData = response.data.data;
					this.form = this.singleVitalGroupData;
					let includedMetricsArray = this.singleVitalGroupData.included_metrics.split(',').map(Number);
					this.form.included_metrics = includedMetricsArray
					this.onFetchSingalVitalCount = includedMetricsArray.length;
					this.matrixInpObj = this.form.included_metric
					window.$("#edit_metric_group").modal("show");
					this.loader = false;
					this.isShowAddBtn = true
				}
			})
			.catch(error => {
				this.loader = false;
				if (error.response.status == 422) {
					this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
				} else {
					this.$filters.moshaToast(error.response.data.message, "error");
				}
			});
		},
		selectEditVital(id, name) {
			const exists = this.matrixInpObj.some(item => item.id === id && item.name === name);
			const inFetchExists = this.singleVitalGroupData.included_metric.some(item => item.id === id && item.name === name);

			if (!exists && !inFetchExists) {
				const lastItemIndex = this.matrixInpObj.length - 1;
				console.log('lastItemIndex:- ', lastItemIndex);

				this.matrixInpObj[lastItemIndex].id = id;
				this.matrixInpObj[lastItemIndex].name = name;
				this.vitalList = [];
			}
			else 
			{
				this.$filters.moshaToast("These vital metric is already exist", "error");
			}
		},
		removeInpMatric(index){
			if (this.form.id) {
				this.isShowAddBtn = true
			}

			this.matrixInpObj.splice(index, 1);
			this.form['included_metrics'].splice(index, 1);
		},
		closeEditModel()
		{
			window.$("#edit_metric_group").modal("hide");
		},
		closeCreateModel()
		{
			window.$("#create_metric_group").modal("hide");
		},
		updateMetricGroup(){
			this.v$.$validate();
			if (!this.v$.$error) {
				this.loader = true;
					axios.post("vital-group/update", this.form)
					.then((response) => {
						if(response.data.status == 200){
							this.loader = false;
							this.getVitalGroupList();
							this.closeEditModel();
							this.$filters.moshaToast(response.data.message, "success");
							window.$("#edit_metric_group").modal("hide");
						}
					})
					.catch(error => {
						this.loader = false;
						if (error.response.status == 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					});
			} else {
				console.log(Object.entries(this.v$.form))
				for (const [key] of Object.entries(this.v$.form)) {
					if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}


		},
		deleteGroup(id)
		{
			this.$swal({
				title: 'Are you sure to DELETE this records?',
				text: '',
				icon: '',
				showCancelButton: true,
				confirmButtonColor: '#DD6B55',
				confirmButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				closeOnConfirm: false,
				closeOnCancel: false,
				reverseButtons: true, // This will swap the Confirm and Cancel buttons
					customClass: {
						container: 'my-swal-container',
						popup: 'my-swal-popup delete-popup',
						header: 'my-swal-header',
						title: 'my-swal-title',
						closeButton: 'my-swal-close-button',
						icon: 'my-swal-icon',
						image: 'my-swal-image',
						content: 'my-swal-content',
						input: 'my-swal-input',
						actions: 'my-swal-actions',
						confirmButton: 'my-swal-confirm-button',
						cancelButton: 'my-swal-cancel-button',
						footer: 'my-swal-footer'
					}
			}).then((result) => { 
				if (result.isConfirmed) {
					this.$store.state.loader = true;
					axios.post("vital-group/delete", { id: id })
					.then((response) => {
						if(response.data.status == 200){
							this.getVitalGroupList();
							this.$store.state.loader = false;
							this.$filters.moshaToast(response.data.message, "success");
						}
					})
					.catch(error => {
						this.loader = false;
						if (error.response.status == 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					});
				}
			});
		},
		addFilter(){
			this.isShowCustomFilter = !this.isShowCustomFilter;
		},
		handleCustomField(values){
			this.filterList = values
			let advanceFilter={}
			values.forEach(item => {
                let value = item.value
                if (item.value == 'Not Set') {
                    value = ''
                }
                advanceFilter[item.field] = value
            });
			this.advanceFilter = advanceFilter
		},
		changeSort(fieldName){
			if (fieldName != this.orderByColumnName) {
				this.orderBy = 'desc'
			} else if (this.orderBy == 'desc') {
				this.orderBy = 'asc'
			} else{
				this.orderBy = 'desc'
			}

			this.orderByColumnName = fieldName

			this.getVitalGroupList();
		},
		handleClickOutside(event) {
			this.matrixInpObj.forEach((item, index) => {
				const metricInput = this.$refs['vitalMetricInput_' + index];
				if (metricInput && metricInput[0] instanceof HTMLElement && !metricInput[0].contains(event.target)) {
					this.vitalList = [];
				}
			});

			if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
					this.getVitalGroupList()
					this.isShowCustomFilter = false;
			}

			const filterGroupEleRefEle = this.$refs.filterGroupEleRef;
			if (filterGroupEleRefEle && !filterGroupEleRefEle.contains(event.target) && !event.target.closest(`.metric-groupnameopt-dropdown`)) {
				this.filteredGroupOptions = []; // Reset filtered options
			}

		},
		getFieldName(index) {
			return `included_metrics.${index}`;
		},
		handleItemSelected({ fieldName, item }) {
			if (fieldName == 'name') {
				this.form[fieldName] = item.value;
				this.name_text =  item.value;

			} else {
				// Regular expression to match 'included_metrics.{digit}' and extract the digit
				const regex = /^included_metrics\.(\d+)$/;
				const match = fieldName.match(regex);
	
				if (match) {
					const index = parseInt(match[1]); // Extract the digit and convert it to an integer
					
					// Ensure included_metrics is an array
					if (!Array.isArray(this.form['included_metrics'])) {
						this.form['included_metrics'] = [];
					}
	
					// Assign the value to the specific index of included_metrics array
					this.form['included_metrics'][index] = item.value;
					this.matrixInpObj[index].name = item.title
					this.matrixInpObj[index].display_name = item.title

					if (this.form.id) {
						this.matrixInpObj[index].is_custom = true;
						this.isShowAddBtn = true
					} else {
						this.matrixInpObj[index].is_custom = false;
					}
				}
			}
		},
		filteredVitalList(index) {
			// Ensure included_metrics is an array
			if (!Array.isArray(this.form['included_metrics'])) {
				this.form['included_metrics'] = [];
			}

			// Filter out already selected values, except for the current dropdown's value
			return this.vitalList.filter(vital => 
				!this.form['included_metrics'].includes(vital.value) || 
				this.form['included_metrics'][index] === vital.value
			);
		},
		checkCondition(index) {
			return index + 1 === this.matrixInpObj.filter(item => item.is_custom === true).length;
		},
		filterGroupName(){
			const query = this.form.name.toLowerCase();
			this.filteredGroupOptions = this.groupNameOpt.filter(option =>
				option.toLowerCase().includes(query)
			);
		},
		selectOption(option){
			this.form.name = option; // Set the input to the selected option
			this.filteredGroupOptions = []; // Clear the suggestions
		},
		removeMetricGrp(index){
			if (this.matrixInpObj.length == 1) {
				this.$filters.moshaToast("You are not permitted to eliminate all metric measurements.", "error");
				return false
			}
			this.matrixInpObj.splice(index, 1);
			this.form['included_metrics'].splice(index, 1);
		}
	},
	computed: {
		trueStatusItems() {
			// Filter the array to include only items where status is true
			return this.matrixInpObj.filter(item => item.is_custom === true);
		}
	},
	validations() {
		return {
			form: {
				name: {
					required: helpers.withMessage("Please enter group name.", required)
				},
				included_metrics:{
					required: helpers.withMessage("Please select metric.", required)
				}
			}
		}
	}
}
</script>
import App from './App.vue';
import store from "./store";
import router from "./router";
import { createApp } from 'vue'
import moment from "moment";
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from "@/scripts/axios.js";
import VueSmoothScroll from "vue3-smooth-scroll";
import WebSocketService from "@/scripts/websocket.js";
import scrollbarDirective from './scripts/scrollbarDirective';

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		console.log(error.message);
		if (error.response.status === 422) {
			store.commit("setErrors", error.response.data.data);
			return Promise.reject(error);
		} else if (error.response.status === 401) {
			localStorage.clear();
			setTimeout(function () {
				location.reload();
			}, 100);
		} else {
			return Promise.reject(error);
		}
	}
);
const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueSweetalert2);
app.use(VueSmoothScroll, {
	updateHistory: false,
	offset: -250,
	easingFunction: (t) => t * t,
});
app.directive('auto-scrollbar', scrollbarDirective);


app.config.errorHandler = function (err, vm, info) {
console.log(err);	
console.log(info);
}

const socket = new WebSocketService();
app.config.globalProperties.$socket = socket;


app.mount("#app");

app.config.globalProperties.$filters = {
	inputDateTime(date, format) {
		if (date) {
			let dt_format = format;
			const datePattern = /^\d{4}-\d{2}-\d{2}$/;
			const dateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z?$/;
			
			if(format == 'hh:mm A'){
				let dateString = date.toString();
				const parts = dateString.split(' ');
				const timePart = parts[parts.length - 1];
				const formattedTime = moment(timePart, 'hh:mm:ss').format('hh:mm A');
				return formattedTime;
			}else{
				if (datePattern.test(date)) {
					dt_format = format ? format : "YYYY-MM-DD";
				} else if (dateTimePattern.test(date)) {
					dt_format = format ? format : "YYYY-MM-DD hh:mm A";
				}				
			}
			return moment(date).format(dt_format);
		} else {
			return "";
		}
	},
	moshaToast(msg, type, time = 3000) {
		if (type == 'error') {
			type = 'danger';
		}
		createToast(msg, {
			type: type, // or 'info', 'warning', 'error'
			position: 'top-right', // toast position
			timeout: time, // toast duration in milliseconds
			showIcon: true, // show an icon in the toast
		});
	},
	patientCalculatedAge(dob, is_only_int=false) {
		if (dob) {
			const birthDate = new Date(dob);
			const currentDate = new Date();

			let years = currentDate.getFullYear() - birthDate.getFullYear();
			let months = currentDate.getMonth() - birthDate.getMonth();
			let days = currentDate.getDate() - birthDate.getDate();

			// Adjust age if birthday hasn't occurred yet this month
			if (currentDate.getDate() < birthDate.getDate()) {
				months--;

				// Adjust months to be positive
				if (months < 0) {
					years--;
					months += 12;
				}
			}

			const cal_years = years > 1 ? `${years} years` : years === 1 ? '1 year' : '';
			const cal_months = months > 1 ? `${months} months` : months === 1 ? '1 year' : '';
			const cal_days = days > 1 ? `${days} months` : days === 1 ? '1 year' : '';

			if (cal_years == "" && cal_months == "" && cal_days == ""){
				let isToday =
					birthDate.getDate() === currentDate.getDate() &&
					birthDate.getMonth() === currentDate.getMonth() &&
					birthDate.getFullYear() === currentDate.getFullYear();
				if (isToday) {
					if (is_only_int) {
						return "";
					}
					return "0 day";
				}
			}

			if (is_only_int) {
				return cal_years.split(' ')[0];
			}
			return `${cal_years}`;
		}
		return 0;
	},
	strToUpperCase(strVal) {
		if(strVal){
			return strVal.toUpperCase();
		}
	},
	// getCurrentDateTime() {
	// 	const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];	 

	// 	const currentDate = new Date();
	// 	const monthIndex = currentDate.getMonth();
	// 	const month = months[monthIndex];
	// 	const day = currentDate.getDate();
	// 	const year = currentDate.getFullYear();

	// 	let hour = currentDate.getHours();
	// 	const minute = currentDate.getMinutes();
	// 	const period = hour >= 12 ? 'pm' : 'am';
	// 	hour = hour % 12 || 12;

	// 	const formattedDateTime = `${month} ${day}, ${year}, ${hour}:${minute.toString().padStart(2, '0')} ${period}`;
	// 	return formattedDateTime;
	// },
	getCurrentDateTime() {
		const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
	
		const timeZone = localStorage.getItem('appTimeZone');
		
		const targetDate = new Date(new Date().toLocaleString("en-US", { timeZone }));
	
		const monthIndex = targetDate.getMonth();
		const month = months[monthIndex];
		const day = targetDate.getDate();
		const year = targetDate.getFullYear();
	
		let hour = targetDate.getHours();
		const minute = targetDate.getMinutes();
		const period = hour >= 12 ? 'pm' : 'am';
		hour = hour % 12 || 12;
	
		const formattedDateTime = `${month} ${day}, ${year}, ${hour}:${minute.toString().padStart(2, '0')} ${period}`;
		return formattedDateTime;
	},
	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	},
	capitalizeFirstWordFirstLetter(string) {
		if (string === null || string === undefined) {
			return '';
		}
		const words = string.split(' ');
		if (words.length > 0) {
			words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
		}
	
		return words.join(' ');
	},
	formatFaxNumber(faxNumber, phoneNumbers) {
			let faxRegex = /^(1[ .-]?)?(\d{3}|\(\d{3}\))[ .-]?\d{3}[ .-]?\d{4}$/; // Pattern for fax numbers
			// Trim any trailing spaces
			faxNumber = faxNumber.trim();
    
			// Split input by comma
			let faxNumbersArray = faxNumber.split(',').map(fax => fax.trim());
		
			// Check the second-to-last fax number (before the latest comma)
			const lastFaxNumber = faxNumbersArray[faxNumbersArray.length - 2]; // Second to last fax number
		
			// If the last character is a comma and the second-to-last number is invalid
			if (faxNumber.endsWith(',') && (lastFaxNumber && lastFaxNumber.length === 0 || !faxRegex.test(lastFaxNumber))) {
				// Remove the invalid number and comma
				faxNumbersArray.splice(faxNumbersArray.length - 2, 1);
				faxNumber = faxNumbersArray.join(', ');
				return { faxNumber, phoneNumbers };
			}
		
			// Format each fax number individually
			const formattedFaxNumbers = faxNumbersArray.map((number) => {
				// Remove non-numeric characters
				let numericFax = number.replace(/\D/g, "");
		
				// Format as XXX-XXX-XXXX
				if (numericFax.length >= 3 && numericFax.length <= 6) {
					return `${numericFax.slice(0, 3)}-${numericFax.slice(3)}`;
				} else if (numericFax.length > 6) {
					return `${numericFax.slice(0, 3)}-${numericFax.slice(3, 6)}-${numericFax.slice(6, 10)}`;
				} else {
					return number; // If length is less than 3, keep it as is
				}
			});
		
			let remainingFaxNumbers = [];
		
			formattedFaxNumbers.forEach((formattedFax) => {
				if (faxRegex.test(formattedFax)) {
					// If valid, push into phoneNumbers array
					phoneNumbers.push(formattedFax);
				} else {
					remainingFaxNumbers.push(formattedFax);
				}
			});
		
			// Remove duplicates and sort phoneNumbers
			phoneNumbers = Array.from(new Set(phoneNumbers)).sort((a, b) => {
				const numA = a.replace(/\D/g, '');
				const numB = b.replace(/\D/g, '');
				return numB.localeCompare(numA);
			});
		
			// Join remaining fax numbers back with commas
			faxNumber = remainingFaxNumbers.join(', ');
		
			return { faxNumber, phoneNumbers };
	},
	formatPhoneNumber(phoneNumber){
		if (!phoneNumber) return "";
		const input = phoneNumber.replace(/\D/g, ""); // Remove non-digit characters
		if (input.length <= 3) {
			return input; // First 3 digits
		} else if (input.length <= 6) {
			return `${input.slice(0, 3)}-${input.slice(3)}`; // Format as 647-777
		} else {
			return `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6, 10)}`; // Format as 647-777-7777
		}	  
	}
}

// function setDynamicCss() {
// 	const now = new Date();
// 	const year = now.getFullYear();
// 	const month = String(now.getMonth() + 1).padStart(2, '0');
// 	const day = String(now.getDate()).padStart(2, '0');
// 	const hours = String(now.getHours()).padStart(2, '0');
// 	const minutes = String(now.getMinutes()).padStart(2, '0');
// 	const seconds = String(now.getSeconds()).padStart(2, '0');

// 	const currentDateTime = `${year}${month}${day}${hours}${minutes}${seconds}`;
// 	const cssLink = document.getElementById('dynamic-css');
// 	cssLink.href = `css/custom.css?v=${currentDateTime}`;
// }

// setDynamicCss();
  
// Set the initial ratio when the app loads
updateScaleRatio();

window.addEventListener('resize', updateScaleRatio);

function updateScaleRatio() {
	let ratio = window.innerWidth / 1920;
	ratio = ratio.toFixed(4); // Limit ratio to 4 decimal places
	if (ratio > 1) {
		ratio = 1;
	}

	document.documentElement.style.setProperty('--scale-ratio', ratio);
}

store.watch(
	(state) => state.totalMessageCount,
	(newCount) => {
		const currentRoute = router.currentRoute.value;
		const title = currentRoute.meta.title;

		// Ensure newCount is defined, non-zero, and a valid number
		if (title && typeof newCount !== "undefined" && newCount > 0) {
			document.title = `${title} (${newCount})`;
		} else {
			document.title = title ? `${title}` : "EMR";
		}
	}
);
<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies files_overview">
            <div class="a_header pe-0 faxes-deleted-filter-row">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" :placeholder="searchFieldText" v-model="form.keyword"
                            @keyup.enter="getFilesList" @input="debouncedSearch" ref="documentSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter
                        </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters | {{ this.filterList.length
                            }}</button>
                        <CustomFilter :options="this.filterDeletedOptions" v-if="isShowCustomFilter && this.$route.name === 'InboxDeletedList'"
                            @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getFilesList">
                        </CustomFilter>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter && this.$route.name !== 'InboxDeletedList'"
                            @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getFilesList">
                        </CustomFilter>
                        <button class="comman_brdr_btn"
                            v-if="this.$route.name === 'InboxDeletedList' && this.selectedItems.length > 0"
                            @click="restoreMultiRecord()"> Restore </button>
                        <button class="comman_brdr_btn"
                            v-if="this.$route.name === 'Inbox' && this.selectedItems.length > 0"
                            @click="deleteMultiRecord()"> Delete </button>
                    </span>
                </div>
                <p class="deleted-faxes-msg mb-0" v-if="this.$route.name === 'InboxDeletedList'">Deleted Faxes will be
                    permanently deleted after 30 days</p>
            </div>
            <div class="table-responsive consult-list inbox-table-list faxes-received-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-50px"><input class="form-check-input" type="checkbox"
                                    v-model="selectAllChk" @change="checkSelectAll"></th>
                            <th v-if="this.$route.name === 'Inbox'" scope="col" :class="{ 'active': this.sortBy == 'received_datetime' }"
                                @click="changeSort('received_datetime')">
                                <span>Received Date Time</span> 
                                <img src="/images/down-arrow-new.svg" class="img-fluid">
                            </th>
                            <th v-if="this.$route.name === 'InboxDeletedList'" scope="col" :class="{ 'active': this.sortBy == 'updated_datetime' }"
                                @click="changeSort('updated_datetime')">
                                <span>Deleted Date Time</span> 
                                <img src="/images/down-arrow-new.svg" class="img-fluid">
                            </th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'source_author' }"
                                @click="changeSort('source_author')">From <img src="/images/down-arrow-new.svg"
                                    class="img-fluid"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'description' }"
                                @click="changeSort('description')">Fax Subject <img src="/images/down-arrow-new.svg"
                                    class="img-fluid"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'source_type' }"
                                @click="changeSort('source_type')">Type <img src="/images/down-arrow-new.svg"
                                    class="img-fluid"></th>
                            <th scope="col" class="hover_unset cursor-default"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.filesList" :key="index"
                            :class="{ 'active': selectedItems.includes(item.id), 'recent-update-animation': recentInsertIds.includes(String(item.id)) }" :ref="`row-${item.id}`">
                            <td><input class="form-check-input" type="checkbox" :checked="isChecked(item.id)"
                                    @change="checkSelect(item.id)"></td>
                            <td>
                                <router-link  :to="{ name: 'InboxEdit', params: { id: item.id } }"
                                    class="rcivd_date_line position-relative">
                                    <span v-if="item.main_name != null">
                                        {{ item.main_name }}
                                    </span>
                                    <span v-else-if="this.$route.name === 'InboxDeletedList'">
                                        {{ item.deleted_datetime }}
                                    </span>
                                    <span v-else>
                                        {{ item.received_datetime }}
                                    </span>
                                    <!-- <span class="rct-update-round d-inline-block position-absolute align-middle rounded-circle" v-if="item.recent_update"></span> -->
                                </router-link>
                                <!-- <span class="badge bg-success ml-10" v-if="item.recent_update">Update</span> -->
                            </td>
                            <td>{{ item.source_author }}</td>
                            <td>{{ item.description }}</td>
                            <td> {{item.source_type}}</td>
                            <td class="edit_delet_link">
                                <span class="popup_link"><router-link 
                                        :to="{ name: 'InboxEdit', params: { id: item.id } }">View</router-link></span>
                                <span class="popup_link" @click="deleteRecord(item.id)"
                                    v-if="this.$route.name === 'Inbox'">Delete</span>
                                <span class="popup_link" @click="restoreRecord(item.id)"
                                    v-if="this.$route.name === 'InboxDeletedList'">Restore</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>
<script>
import sidebar from './sidebar.vue';
import axios from "@/scripts/axios.js";
import CustomFilter from '../base/CustomFilter.vue';

export default {
        data() {
            return {
				selectAllChk: false,
                filesList:[],
                selectedItems:[],
                allItems:[],
                selectChk:[],
                sortBy:'received_datetime',
                sortOrder:'Desc',
                filterList:[],
                isShowCustomFilter:false,
                filterOptions:[
                    { value: "received_timerange", title: "Received Date Time" },
                    { value: "patient_document.source_author", title: "From" },
                    { value: "patient_document.description", title: "Fax Subject" },
                    { value: "patient_document.source_type", title: "Type" },
                ],
                filterDeletedOptions:[
                    { value: "updated_datetime", title: "Deleted Date Time" },
                    { value: "patient_document.source_author", title: "From" },
                    { value: "patient_document.description", title: "Fax Subject" },
                    { value: "patient_document.source_type", title: "Type" },
                ],
                searchFieldText:'',
                searchTimeout: null,
                form: {
                    keyword: "",
                    advanceFilter: []
                },
                defaultFormData:{
                    keyword: "",
                    advanceFilter: []
                },

                recentInsertIds: [],
            }
        },
        components: {
            sidebar,
            CustomFilter
        },
        methods:{
            debouncedSearch() {
                clearTimeout(this.searchTimeout);
                this.searchTimeout = setTimeout(() => {
                    this.getFilesList();
                }, 1000);
            },
            getFilesList() {
                this.$store.state.loader = true;
                let viewStatus = ''
                if (this.$route.name === 'InboxDeletedList') {
                    viewStatus = 'deleted'
                }

                axios.post("patient/document/list", {
                    patient_id: this.$route.params.patient_id,
                    keyword: this.form.keyword,
                    advanceFilter: this.form.advanceFilter,
                    sort_by: this.sortBy,
                    sort_order: this.sortOrder,
                    status: 'unlabeled',
                    view_status:viewStatus,
                }).then((response) => {
                    this.selectAllChk = false
                    this.selectedItems = []
                    this.allItems = []

                    this.filesList = response.data.data;
                    this.filesList.forEach(item => {
                        this.allItems.push(item.id)
                    })
                    this.$store.state.loader = false;
                    if (this.recentInsertIds.length > 0) {
                        this.$nextTick(() => {
                            this.scrollToInsertedRows();
                        });
                    }
                });
            },
            isChecked(id){
                return this.selectedItems.includes(id);
            },
            checkSelectAll(){
                if (this.selectAllChk) {
                    this.selectedItems = [...this.allItems];
                } else {
                    this.selectedItems = []
                }
            },
            checkSelect(id){
                if (this.selectedItems) {
                    const index = this.selectedItems.indexOf(id);
                    if (index !== -1) {
                        this.selectedItems.splice(index, 1);
                    } else {
                        this.selectedItems.push(id);
                    }
                }

                if (this.selectedItems.length == this.allItems.length) {
                    this.selectAllChk = true;
                } else if (this.selectedItems.length == 0) {
                    this.selectAllChk = false;
                } else {
                    this.selectAllChk = false;
                }
            },
            addFilter(){
                this.isShowCustomFilter = !this.isShowCustomFilter;
            },
            handleCustomField(values){
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    let value = item.value
                    if (item.value == 'Not Set') {
                        value = ''
                    }
                    advanceFilter[item.field] = value
                });
                this.form.advanceFilter = advanceFilter
            },
            changeSort(fieldName){
                if (fieldName != this.sortBy) {
                    this.sortOrder = 'desc';
                } else if (this.sortOrder == 'desc') {
                    this.sortOrder = 'asc';
                } else{
                    this.sortOrder = 'desc';
                }
                this.sortBy = fieldName;
                this.getFilesList();
            },
            restoreMultiRecord(){
                this.$swal({
					title: 'Are you sure to RESTORE records?',
					text: '',
					icon: '',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Restore',
					cancelButtonText: 'Cancel',
					closeOnConfirm: false,
					closeOnCancel: false,
                    reverseButtons: true, // This will swap the Confirm and Cancel buttons
                    customClass: {
                        container: 'my-swal-container',
                        popup: 'my-swal-popup delete-popup',
                        header: 'my-swal-header',
                        title: 'my-swal-title',
                        closeButton: 'my-swal-close-button',
                        icon: 'my-swal-icon',
                        image: 'my-swal-image',
                        content: 'my-swal-content',
                        input: 'my-swal-input',
                        actions: 'my-swal-actions',
                        confirmButton: 'my-swal-confirm-button',
                        cancelButton: 'my-swal-cancel-button',
                        footer: 'my-swal-footer'
                    }
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/document/restore";
						axios.post(url, {'ids':this.selectedItems})
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
								this.getFilesList();
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						})
					}
				});
            },
            deleteMultiRecord(){
                this.$swal({
					title: 'Are you sure you want to delete records?',
					text: '',
					icon: '',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Delete',
					cancelButtonText: 'Cancel',
					closeOnConfirm: false,
					closeOnCancel: false,
                    reverseButtons: true, // This will swap the Confirm and Cancel buttons
                    customClass: {
                        container: 'my-swal-container',
                        popup: 'my-swal-popup delete-popup',
                        header: 'my-swal-header',
                        title: 'my-swal-title',
                        closeButton: 'my-swal-close-button',
                        icon: 'my-swal-icon',
                        image: 'my-swal-image',
                        content: 'my-swal-content',
                        input: 'my-swal-input',
                        actions: 'my-swal-actions',
                        confirmButton: 'my-swal-confirm-button',
                        cancelButton: 'my-swal-cancel-button',
                        footer: 'my-swal-footer'
                    }
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/document/delete";
						axios.post(url, {'ids':this.selectedItems})
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
								this.getFilesList();
                                this.selectedItems = [];
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}) 
					}
				});
            },
            deleteRecord(id){
				this.$swal({
					title: 'Are you sure to DELETE this record?',
					text: '',
					icon: '',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Delete',
					cancelButtonText: 'Cancel',
					closeOnConfirm: false,
					closeOnCancel: false,
                    reverseButtons: true, // This will swap the Confirm and Cancel buttons
                    customClass: {
                        container: 'my-swal-container',
                        popup: 'my-swal-popup delete-popup',
                        header: 'my-swal-header',
                        title: 'my-swal-title',
                        closeButton: 'my-swal-close-button',
                        icon: 'my-swal-icon',
                        image: 'my-swal-image',
                        content: 'my-swal-content',
                        input: 'my-swal-input',
                        actions: 'my-swal-actions',
                        confirmButton: 'my-swal-confirm-button',
                        cancelButton: 'my-swal-cancel-button',
                        footer: 'my-swal-footer'
                    }
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/document/delete";
						axios.post(url, {'ids':[id]})
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
								this.getFilesList()
                                this.selectedItems = [];
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}) 
						// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
					} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
					}
				});
			},
            restoreRecord(id){
				this.$swal({
					title: 'Are you sure to RESTORE this record?',
					text: '',
					icon: '',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Restore',
					cancelButtonText: 'Cancel',
					closeOnConfirm: false,
					closeOnCancel: false,
                    reverseButtons: true, // This will swap the Confirm and Cancel buttons
                    customClass: {
                        container: 'my-swal-container',
                        popup: 'my-swal-popup delete-popup',
                        header: 'my-swal-header',
                        title: 'my-swal-title',
                        closeButton: 'my-swal-close-button',
                        icon: 'my-swal-icon',
                        image: 'my-swal-image',
                        content: 'my-swal-content',
                        input: 'my-swal-input',
                        actions: 'my-swal-actions',
                        confirmButton: 'my-swal-confirm-button',
                        cancelButton: 'my-swal-cancel-button',
                        footer: 'my-swal-footer'
                    }
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/document/restore";
						axios.post(url, {'ids':[id]})
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
								this.getFilesList()
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}) 
						// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
					} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
					}
				});
			},
            handleClickOutside(event) {
                if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.faxes-deleted-filter-row')) {
                    this.getFilesList();
                    this.isShowCustomFilter = false;
                }
            },
            resetFormData(){
                Object.assign(
                    this.form,
                    this.defaultFormData,
                );
            },
            scrollToInsertedRows() {
                this.$nextTick(() => {
                    const firstId = this.recentInsertIds[0];
                    if (firstId) {
                        const rowRef = this.$refs[`row-${firstId}`];
                        const row = rowRef?.$el || rowRef;

                        if (row) {
                            const target = row[0] || row;
                            this.$smoothScroll({
                                scrollTo: target,
                            });
                        } else {
                            console.warn(`Row with id ${firstId} not found`);
                        }
                    }
                });
            }
        },
        mounted(){
            this.resetFormData()
            document.addEventListener('click', this.handleClickOutside);
            if (this.$route.name === 'InboxDeletedList') {
                this.sortBy = 'updated_datetime'
            }
            this.getFilesList()
            if (this.$route.name === 'InboxDeletedList') {
                this.searchFieldText = 'Search Faxes Deleted'
            } else {
                this.searchFieldText = 'Search Faxes Received'
            }
            // Get the ids from the query parameters
            this.recentInsertIds = this.$route.query.ids
                ? atob(this.$route.query.ids).split(',')
                : [];
        },
        computed: {
        },
}
</script>

<style scoped>
   /* .recent-update-animation .rcivd_date_line{text-decoration-thickness: calc(var(--scale-ratio) * 3px);} */
   .rct-update-round{background: #DC1B3E;top: calc(var(--scale-ratio) * -3px);right: calc(var(--scale-ratio) * -18px);width: calc(var(--scale-ratio) * 12px);height: calc(var(--scale-ratio) * 12px);}

/*continue blinking*/

/*.recent-update-animation{animation: myanimation 10s infinite;}
@keyframes myanimation {
    0% {background-color: rgb(236 227 209 / 0%);}
    25%{background-color:rgb(236 227 209 / 15%);}
    50%{background-color:rgb(236 227 209 / 25%);}
    75%{background-color:rgb(236 227 209 / 35%);}
    100%{background-color: rgb(236 227 209 / 50%);}
}*/

/*one time continue blinking*/

.recent-update-animation{animation: myAnimation 10s ease 0s;animation-iteration-count: 1;}
.recent-update-animation .rcivd_date_line{animation: blinkAnimation 10s ease 0s;animation-iteration-count: 1;}
@keyframes myAnimation {
    0% {background-color: rgb(236 227 209 / 50%);}
    25%{background-color: rgb(236 227 209 / 35%);}
    50%{background-color: rgb(236 227 209 / 25%);}
    75%{background-color: rgb(236 227 209 / 15%);}
    100%{background-color: rgb(236 227 209 / 0%);}
}
@keyframes blinkAnimation {
    0% { text-decoration-thickness: calc(var(--scale-ratio, 1) * 3px); }
    10% { text-decoration-thickness: calc(var(--scale-ratio, 1) * 1px); }
    20% { text-decoration-thickness: calc(var(--scale-ratio, 1) * 3px); }
    30% { text-decoration-thickness: calc(var(--scale-ratio, 1) * 1px); }
    40% { text-decoration-thickness: calc(var(--scale-ratio, 1) * 3px); }
    50% { text-decoration-thickness: calc(var(--scale-ratio, 1) * 1px); }
    60% { text-decoration-thickness: calc(var(--scale-ratio, 1) * 3px); }
    70% { text-decoration-thickness: calc(var(--scale-ratio, 1) * 1px); }
    80% { text-decoration-thickness: calc(var(--scale-ratio, 1) * 3px); }
    90% { text-decoration-thickness: calc(var(--scale-ratio, 1) * 1px); }
    100% { text-decoration-thickness: calc(var(--scale-ratio, 1) * 1px); }
}
</style >
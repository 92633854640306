<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies files_overview">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" placeholder="Search Files" v-model="form.keyword" @keydown.enter="documentSearch"  @input="handleInput"  ref="documentSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length }}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getFilesList"></CustomFilter>
                        <button class="comman_brdr_btn consexport-btn" :disabled="this.selectedItems.length == 0"  v-if="(this.$route.name !== 'PatientEChartFilesDelete' && this.$route.name !== 'EChartFilesDelete')" @click="exportconsults()"> Export </button>
                        <button class="comman_brdr_btn" v-if="(this.$route.name === 'PatientEChartFilesDelete' || this.$route.name === 'EChartFilesDelete') && this.selectedItems.length > 0" @click="restore()"> Restore </button>
                    </span>
                </div>
                <div v-if="this.$route.name !== 'PatientEChartFilesDelete' && this.$route.name !== 'EChartFilesDelete'">
                    <label for="fileInput" class="comman_btn cursor-pointer">Browse</label>
                    <input id="fileInput" type="file" @change="handleFileChange" class="d-none" accept=".pdf, .jpeg, .jpg, .png">
                </div>
            </div>
            <div class="table-responsive consult-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-50px"><input class="form-check-input" type="checkbox" v-model="selectAllChk" @change="checkSelectAll"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'updated_datetime' }"  @click="changeSort('updated_datetime')">Date <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'description' }" @click="changeSort('description')">Description <img src="/images/down-arrow-new.svg" class="img-fluid" ></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'patient_name' }" @click="changeSort('patient_name')">Demographic <img src="/images/down-arrow-new.svg" class="img-fluid" ></th>
                            <th scope="col"  :class="{ 'active': this.sortBy == 'report_class' }" @click="changeSort('report_class')">Type <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col">Associated Requisition/Subtype <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.filesList" :key="index" :class="{ 'active': (selectedItems.includes(item.id) ) }">
                            <td><input class="form-check-input" type="checkbox" :checked="isChecked(item.id)" @change="checkSelect(item.id)"></td>
                            <td>{{ $filters.inputDateTime(item.updated_datetime, 'YYYY-MM-DD') }}</td>
                            <td>{{ item.description }}</td>
                            <td>{{ item.full_name }}</td>
                            <td>{{ item.report_class }}</td>
                            <td v-if="item.report_class != 'Others' && item.report_class != 'Rx'"> {{item.tracking_request}}</td>
                            <td v-else> {{item.report_sub_class}}</td>
                            <td><img src="/images/dots-icon.svg" class="dot_icon" @click="edit(item.id)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal fade" id="add-exportconsultation-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl files-overview-exprt">
			<div class="modal-content">
				<div class="modal-body">					
                        <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="box">
                        <ul class="export_tabs">
                            <li :class="{ active: isFaxFormVisible }" @click="changeExportOpt('fax')">Fax</li>
                            <li :class="{ active: isEmailFormVisible }" @click="changeExportOpt('email')">Email</li>
                            <li :class="{ active: isMailFormVisible }" @click="changeExportOpt('mail')">Mail</li>
                        </ul>
                        <section class="con_detl_sec">
                            <div class="fax_menu" v-if="isFaxFormVisible">
                                <div class="row align-items-center">
                                    <label for="text" class="col-sm-4 col-form-label right-bor py-0 cursor-text fs-18">Specialist(s)</label>
                                    <div class="col-sm-8 position-relative selact_dropdown cunlt_specialist_option">
                                        <selectCheckBox :dataSource="specialist_option" :labelName="tagSpecialistLabel" @clicked="selectedSpecialist" :displayDiv="false" :isUnselectAll="true" unselectLabel="None" :isShowDoctorIcon="false"></selectCheckBox>
                                    </div>
                                 </div>   

                                <div class="or_line"><span>OR</span></div>

                                <!-- disable-fax -->
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="faxForm.fax_number" @input="formatFaxNumber" @keydown="handleKeyDown" @paste="handlePaste">
                                        <span class="multi-rc-fax-nmber white-space-nowrap d-inline-block me-2 mt-2" v-for="(phone, index) in this.faxForm.phoneNumbers" :key="index" style="display: flex; align-items: center;">
                                            {{ phone }}
                                            <img src="/images/close-icon.svg" @click="removeSelectedFax(phone)" class="cursor-pointer ml-10" style="margin-left: calc(var(--scale-ratio) * 10px); height: calc(var(--scale-ratio) * 10px);">
                                        </span>
                                        <label class="form-label" for="typeunique_idX-2">Recipient Fax Number</label>
                                        <ValidationErrorMessageList :errors="v$.faxForm.phoneNumbers.$errors" />
                                    </div>
                                </div>

                                
                                <div class="error-msg" v-if="this.faxValidationMsg">
                                    <ul class="mb-0 p-0 fs-14">
                                        <li class="d-block text-danger">
                                        {{ this.faxValidationMsg }}
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div class="email_menu" v-if="isEmailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_id">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Email</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_id.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_header">
                                        <label class="form-label" for="typeunique_idX-2">Email Header</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_header.$errors" />
                                    </div>
                                </div>

                            </div>
                            <div class="mail_menu" v-if="isMailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.name">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Name</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.name.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.address">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Address</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.address.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.city">
                                        <label class="form-label" for="typeunique_idX-2">Recipient City</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.city.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.state">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Province</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.state.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.zip">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Postal Code</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.zip.$errors" />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="btn_part text-center">
                            <button class="comman_brdr_btn big_btn mx36" @click="closeExportModel"> Cancel </button>
                            <button class="comman_btn big_btn mx36" @click="exportSubmit">Confirm</button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import sidebar from '../sidebar.vue';
import axios from "@/scripts/axios.js";
import CustomFilter from '../../../base/CustomFilter.vue';
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../../../base/ValidationErrorMessageList.vue';
import selectCheckBox from "@/components/base/selectCheckBox.vue";

export default {
        setup: () => {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                filesList:[],
                selectedItems:[],
                allItems:[],
                selectChk:[],
                isFaxFormVisible:true,
                isEmailFormVisible:false,
                isMailFormVisible:false,
                sortBy:'',
                sortOrder:'',
                docTypeList: [],
                trackingDocTypeRole:{
					Consultation : [
                        { value: 'Created', title: 'Created' },
						{ value: 'Booked', title: 'Booked' },
						{ value: 'Rejected', title: 'Rejected' },
						{ value: 'Completed', title: 'Completed' }
					],
					Imaging:[
                        { value: 'Created', title: 'Created' },
						{ value: 'Booked', title: 'Booked' },
						{ value: 'Rejected', title: 'Rejected' },
						{ value: 'Completed', title: 'Completed' }
					],
					Lab:[
						{ value: 'Requisition Created', title: 'Requisition Created' },
						{ value: 'Result Received', title: 'Result Received' },
					],
					Rx:[
						{ value: 'Requisition Created', title: 'Requisition Created' },
						{ value: 'Result Received', title: 'Result Received' },
					]
				},
                filterList:[],
                isShowCustomFilter:false,
                filterOptions:[
                    { value: "updated_datetime", title: "Date" },
                    { value: "patient_document.description", title: "Description" },
                    { value: "demographic", title: "Demographic" },
                    { value: "patient_document.report_class", title: "Type" },
                    { value: "tracking_request", title: "Associated Requisition" },
                    { value: "patient_document.report_sub_class", title: "Subtype" },
                    
                ],
                loader:false,
                faxValidationMsg:'',
                specialist_option:[],
                tagSpecialistLabel: "",
                searchTimeout: null,
            }
        },
        components: {
            sidebar,
            CustomFilter,
            ValidationErrorMessageList,
            selectCheckBox
        },
        methods:{
            handleInput() {
                // Clear the previous timer if it exists
                clearTimeout(this.searchTimeout);

                // Start a new timer that triggers after 2 seconds of idle time
                this.searchTimeout = setTimeout(() => {
                    this.getFilesList();
                }, 1000); // 1-second idle time
            },
            getFilesList(){
                clearTimeout(this.searchTimeout);
                this.$store.state.loader = true;
                let viewStatus = ''
                if (this.$route.name === 'PatientEChartFilesDelete' || this.$route.name === 'EChartFilesDelete')  {
                    viewStatus = 'deleted'
                }
                
                axios.post("patient/document/list", 
                {
                     patient_id: this.$route.params.patient_id,
                     view_status:viewStatus,
                     keyword: this.form.keyword,
                     advanceFilter:this.form.advanceFilter,
                     sort_by: this.sortBy,
                     sort_order:this.sortOrder,
                     status:'labeled'
                })
                .then((response) => {
                    this.selectAllChk = false
                    this.selectedItems = []
                    this.allItems = []

                    this.filesList = response.data.data;
                    this.filesList.forEach(item => {
                    this.allItems.push(item.id)
                })

                    this.$store.state.loader = false;
                });
            },
            isChecked(id){
                return this.selectedItems.includes(id);
            },
            checkSelectAll(){
                if (this.selectAllChk && this.allItems.length > 0) {
                    this.selectedItems = [...this.allItems];
                } else {
                    this.selectedItems = []
                }
            },
            checkSelect(id){
                // Handle individual item selection/deselection
                const index = this.selectedItems.indexOf(id); // Find index of id in selectedItems
                if (index !== -1) {
                    this.selectedItems.splice(index, 1); // Remove id from selectedItems
                } else {
                    this.selectedItems.push(id)
                }

                // Update selectAllChk based on selectedItems length
                this.selectAllChk = this.selectedItems.length === this.allItems.length;
            },
            exportconsults(){
                this.v$['faxForm'].$reset();
                this.v$['emailForm'].$reset();
                this.v$['mailForm'].$reset();
                Object.assign(
                    this.$store.state.patient_document.faxForm,
                    this.$store.state.patient_document.defaultexportfrm
                );
                Object.assign(
                    this.$store.state.patient_document.emailForm,
                    this.$store.state.patient_document.defaultexportfrm
                );
                Object.assign(
                    this.$store.state.patient_document.mailForm,
                    this.$store.state.patient_document.defaultexportfrm
                );
                this.tagSpecialistLabel = ''
                this.specialist_option.map((item) => {
                    item.checked = false;
                });
                this.isFaxFormVisible=true
                this.isEmailFormVisible=false
                this.isMailFormVisible=false
                window.$("#add-exportconsultation-model").modal("show");
            },
            changeExportOpt(type){
                this.isFaxFormVisible=false
                this.isEmailFormVisible=false
                this.isMailFormVisible=false

                if (type == 'fax') {
                    this.isFaxFormVisible=true
                } else if (type == 'email') {
                    this.isEmailFormVisible=true
                } else if (type == 'mail') {
                    this.isMailFormVisible=true
                }
            },
            closeExportModel(){
                this.selectedItems = []
                this.selectAllChk = false
                window.$("#add-exportconsultation-model").modal("hide");
            },
            exportSubmit(){
                let form = null
                let formName = 'faxForm'
                if (this.isFaxFormVisible) {
                    form = { ...this.faxForm };
                    form.type = "fax"
                    formName = 'faxForm';
                    form.fax_number = this.faxForm['phoneNumbers'].join(', ');
                } else if(this.isEmailFormVisible){
                    form = this.emailForm
                    form.type = "email"
                    formName = 'emailForm';
                } else if (this.isMailFormVisible){
                    form = this.mailForm
                    form.type = "mail"
                    formName = 'mailForm';
                }
                form.ids = this.selectedItems
                form.patient_id = this.$route.params.patient_id
                this.v$[formName].$validate();
                this.loader = true

                if (!this.v$[formName].$error) {
                    
                    this.loader = true

                    let url = "patient/document/export"
                    axios.post(url, form)
                        .then((response) => {
                            this.faxForm.fax_number = null
                            if (response.status == 200) {
                                this.selectedItems = []
                                this.selectAllChk = false
                                this.$filters.moshaToast(response.data.message, "success");
                                this.loader = false;
                                window.$("#add-exportconsultation-model").modal("hide");
                            } else {
                                this.$filters.moshaToast(response.data.message, "error");
                            }
                        }).catch(error => {
                            this.faxForm.fax_number = null
                            this.$filters.moshaToast(error.response.data.message, "error");
                        });
                } else {
                    this.faxForm.fax_number = null
                    for (const [key] of Object.entries(this.v$[formName])) {
                        if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
                            const myEl = document.getElementById(key);
                            if (myEl) {
                                this.$smoothScroll({
                                    scrollTo: myEl,
                                });
                                break;
                            }
                        }
                    }
                }

            },
            handleFileChange(event) {
                this.$store.state.loader = true;
                const selectedFile = event.target.files[0];
                if (selectedFile && (selectedFile.type === 'application/pdf' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpg' || selectedFile.type === 'image/jpeg') ) {
                    // Handle the PDF file
                    const formData = new FormData();
                    if (this.$route.name === "PatientEChartFilesOverview") {
                        formData.append('patient_id', this.$route.params.patient_id)
                    }
                    formData.append('pdfData', selectedFile);
                    axios.post('patient/document/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                    }).then(response => {
                        let detail = response.data.data
                        if (this.$route.name === "EChartFilesOverview" || this.$route.name === "EChartFilesDelete") {
                            if(detail.id){
                                this.$router.push({ name: 'EChartFilesEdit', params: { id: detail.id} });
                            } else {
                                this.$router.push({ name: 'EChartFilesCreate', query: { 'file_path': detail.file_path } });
                            }
                        } else {
                            if(detail.id){
                                this.$router.push(`/patient/e-chart/${this.$route.params.patient_id}/files/retrieve/${detail.id}`);
                            } else {
                                this.$router.push({ name: 'PatientEChartFilesCreate', query: { 'file_path': detail.file_path } });
                            }
                        }
                        this.$store.state.loader = false;
                    }).catch(error => {
                    // Handle errors
                    console.error('Error uploading file:', error);
                    });
                } else {
                    // Notify the user that only PDF files are allowed
                    this.$filters.moshaToast('Please select a PDF, jpg, jpeg or png file.', "error");
                }
            },
            edit(id) {
                if (this.$route.name === "EChartFilesOverview" || this.$route.name === "EChartFilesDelete") {
                    const url = this.$router.resolve({ name: 'EChartFilesEdit', params: { id: id} }).href;
                    window.open(url, '_blank');
                } else {
                    const url = this.$router.resolve(`/patient/e-chart/${this.$route.params.patient_id}/files/retrieve/${id}`).href;
                    window.open(url, '_blank');
                }
            },
            documentSearch(){
                this.getFilesList();
            },
            addFilter(){
                this.isShowCustomFilter = !this.isShowCustomFilter;
            },
            handleCustomField(values){
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    let value = item.value
                    if (item.value == 'Not Set') {
                        value = ''
                    }
                    advanceFilter[item.field] = value
                });
                this.form.advanceFilter = advanceFilter
            },
            changeSort(fieldName){
                if (fieldName != this.sortBy) {
                    this.sortOrder = 'desc'
                } else if (this.sortOrder == 'desc') {
                    this.sortOrder = 'asc'
                } else{
                    this.sortOrder = 'desc'
                }

                this.sortBy = fieldName

                this.getFilesList()
            },
            restore(){
                this.$swal({
					title: 'Are you sure to RESTORE this records?',
					text: '',
					icon: '',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Restore',
					cancelButtonText: 'Cancel',
					closeOnConfirm: false,
					closeOnCancel: false,
                    reverseButtons: true, // This will swap the Confirm and Cancel buttons
                    customClass: {
                        container: 'my-swal-container',
                        popup: 'my-swal-popup delete-popup',
                        header: 'my-swal-header',
                        title: 'my-swal-title',
                        closeButton: 'my-swal-close-button',
                        icon: 'my-swal-icon',
                        image: 'my-swal-image',
                        content: 'my-swal-content',
                        input: 'my-swal-input',
                        actions: 'my-swal-actions',
                        confirmButton: 'my-swal-confirm-button',
                        cancelButton: 'my-swal-cancel-button',
                        footer: 'my-swal-footer'
                    }
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/document/restore";
						axios.post(url, {'ids':this.selectedItems})
						.then((response) => {
							if (response.status == 200) {
								this.getFilesList();
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}) 
						// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
					} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
					}
				});
            },
            handleItemSelected(values){
                this.faxForm.specialist_id = values.item.id
            },
            getSpecialistList(){
                axios.post("custom-specialists/list", {specialist_type:this.form.service_id})
                    .then((response) => {
                        if (response.status == 200) {
                            const details = response.data.data;
							details.sort((a, b) => {
								return (b.is_preferred_specialist == true) - (a.is_preferred_specialist == true);
							});
							const specialistTempData = details.map((item) => {
								let name = item.name;
								if (item.is_preferred_specialist) {
									name += "<p class='preferred-label float-end mb-0 mt-10'>(Preferred)</p>"
								}
                                if (item.address){
                                    name += "<p class='address-line mb-0'>"+item.address+"</p>"
                                }
								return { id: item.id, name: item.name, checked: false, displayLabel: name };
							});
                            this.specialist_option = specialistTempData;
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        this.loader = false;
                        console.log(error)
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });

            },
            handleClickOutside(event) {
                if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
                    this.getFilesList()
                    this.isShowCustomFilter = false;
                }

            },
            selectedSpecialist(value){
                this.specialist_option = value;
				const specialist_list = this.specialist_option.map((item) => {
					return (item.checked == true) ? item.id : null;
				});
				this.faxForm.specialist_id = specialist_list.filter(n => n);
				if (this.faxForm.specialist_id.length > 0) {
                    if (this.faxForm.specialist_id.length == 1) {
                        this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist Selected`;
                    } else {
                        this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist(s) Selected`;
                    }
                } else {
					this.tagSpecialistLabel = "";
				}
			
            },
            handlePaste(event) {
                // Get the pasted content
                const pasteData = (event.clipboardData || window.clipboardData).getData("text");
                // Allow only digits and "-"
                let sanitizedData = pasteData.replace(/[^0-9-]/g, "");

                // If the sanitized data is empty, reject the paste
                if (!sanitizedData) {
                    console.log("Invalid input: Only numbers and '-' are allowed.");
                    event.preventDefault();
                    return;
                }

                // Remove "-" from the sanitized data
                sanitizedData = sanitizedData.replace(/-/g, "");

                // Determine max length based on presence of "-"
                const currentLength = this.faxForm.fax_number ? this.faxForm.fax_number.length : 0;
                const allowedLength = this.maxLength - currentLength;
                // Trim pasted content to fit the remaining allowed length
                const trimmedData = sanitizedData.slice(0, allowedLength);

                // Update the input value
                this.faxForm.fax_number = (this.faxForm.fax_number || "") + trimmedData;
            },
            handleKeyDown(event) {
                if (event.key == 'Backspace' && this.faxForm.fax_number) {
                    this.faxForm.fax_number = this.faxForm.fax_number.slice(0, -1);
                    event.preventDefault();
                }

                const allowedKeys = [
                    "Backspace",
                    "ArrowLeft",
                    "ArrowRight",
                    "Tab",
                    "-",
                ];
                const isAllowedKey = allowedKeys.includes(event.key);
                const isDigit = event.key >= "0" && event.key <= "9";
                const isPasteShortcut = (event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "v";
                if (!isDigit && !isAllowedKey && !isPasteShortcut) {
                    event.preventDefault();
                }

            },
            formatFaxNumber(){

                const { fax_number, phoneNumbers } = this.faxForm;

                // Check the length of phoneNumbers after formatting
                if (this.faxForm.phoneNumbers.length >= 5 && this.faxForm.fax_number) {
                    this.faxForm.fax_number = ''; // Clear the fax_number
                    this.$filters.moshaToast("You cannot add more than five recipient fax numbers for sending.", "error");
                    return false; // Prevent further processing
                }

                // Call the globally defined formatFaxNumber
                const result = this.$filters.formatFaxNumber(fax_number, phoneNumbers);

                // Update the model based on the result
                this.faxForm.fax_number = result.faxNumber;
                this.faxForm.phoneNumbers = result.phoneNumbers;
            },
            removeSelectedFax(numberToRemove){
                const updatedPhoneNumbers = this.faxForm.phoneNumbers.filter(phone => phone !== numberToRemove);
                this.faxForm.phoneNumbers = updatedPhoneNumbers
            }
        },
        mounted(){
            document.addEventListener('click', this.handleClickOutside);
            this.getFilesList()
            this.getSpecialistList()
        },
        computed: {
            form() {
                return this.$store.state.patient_document.form;
            },
            faxForm(){
                return this.$store.state.patient_document.faxForm;
            },
            emailForm(){
                return this.$store.state.patient_document.emailForm;
            },
            mailForm(){
                return this.$store.state.patient_document.mailForm;
            }
        },
        validations() {
            return {
                faxForm: this.$store.state.patient_document.validationRules.faxForm,
                emailForm: this.$store.state.patient_document.validationRules.emailForm,
                mailForm: this.$store.state.patient_document.validationRules.mailForm,
            };

        },
}
</script>
<style scoped>
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: calc(var(--scale-ratio) * 6px) calc(var(--scale-ratio) * 12px);
  cursor: pointer;
  background-color: #f8f8f8;
  border-radius: 4px;
}
</style>
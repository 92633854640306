import {
	helpers,
	required,
	email,
    maxLength,
    minLength,
    // sameAs,
    requiredIf,
    numeric
} from "@vuelidate/validators";

// Custom validator to check for at least one uppercase letter, one lowercase letter, and one digit
const passwordComplexity = helpers.withMessage(
    'Password must contain at least one uppercase letter, one lowercase letter, and one digit',
    value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(value)
);

const userNameFormat = helpers.withMessage(
    "Username must only contain letters, numbers, and hyphens(-) (no spaces).",
    value => /^[A-Za-z0-9-]+$/.test(value)
  );

const noSpacesPassword = helpers.withMessage(
    'Password must not contain spaces.',
    value => !/\s/.test(value)
  );

const alpha = helpers.withMessage(
    'Please enter only alphabets.',
    value => /^[A-Za-z]*$/.test(value)
);

  const user = {
    namespaced: true,

    state: {
		defaultFormData: {
            id:null,
            user_id:null,
            provider_id:null,
            specialty_code:null,
            specialty_description:null,
            visit_location:'0000 - [] No Location',
            first_name:null,
            last_name:null,
            roleId:null,
            address:null,
            phone_number:null,
            provincial_billing_no:null,
            group_billing_no:null,
            cpsid:null,
            cnoid:null,
            administration_access:0,
            prescription_access:0,
            billing_province_code:'ON',
            gosecure_email:null,
            gosecure_password:null,
            olis_first_name:null,
            olis_last_name:null,
            olis_provider_role:null,
            one_id:null,
            user_role_name:'Select User Type',
            username:null,
            status:'AC',
            signatureData:null,
            province_code_text:'ON - Ontario',
            specialty:'Family Practice & Practice In General',
            email:null,
            pin:null,
            password:null,
            service_location:'',
            specialty_code_desc:null,
            service_location_text:"None",
            visit_location_text:"0000"
        },
        form:{
            id:null,
            user_id:null,
            provider_id:null,
            specialty_code:null,
            specialty_description:null,
            visit_location:'0000 - [] No Location',
            first_name:null,
            last_name:null,
            roleId:null,
            address:null,
            phone_number:null,
            provincial_billing_no:null,
            group_billing_no:null,
            cpsid:null,
            cnoid:null,
            administration_access:0,
            prescription_access:0,
            billing_province_code:'ON',
            gosecure_email:null,
            gosecure_password:null,
            olis_first_name:null,
            olis_last_name:null,
            olis_provider_role:null,
            one_id:null,
            user_role_name:'Select User Type',
            username:null,
            status:'AC',
            signatureData:null,
            province_code_text:'ON - Ontario',
            specialty:'Family Practice & Practice In General',
            email:null,
            pin:null,
            password:null,
            service_location:'',
            specialty_code_desc:null,
            service_location_text:"None",
            visit_location_text:"0000"
        },
        changePwdForm:{
            old_password:null,
            new_password:null,
            new_password_confirmation:null,
        },
        defaultChangePwdForm:{
            old_password:null,
            new_password:null,
            new_password_confirmation:null,
        },
        changePinForm:{
            old_pin:null,
            new_pin:null,
            new_pin_confirmation:null,
        },
        defaultChangePinForm:{
            old_pin:null,
            new_pin:null,
            new_pin_confirmation:null,
        },
        validationRules: {
			form: {
				roleId: {
					required: helpers.withMessage("Please select user type.", required),
				},
                username:{
                    required: helpers.withMessage("Please enter username.", required),
                    maxLength:helpers.withMessage("Please enter username max 16 char.", maxLength(16)),
                    minLength:helpers.withMessage("Please enter username atleast 8 char.", minLength(8)),
                    userNameFormat
                    // pattern: helpers.withMessage(
					// 	"Username must only contain letters, numbers, and hyphens (no spaces).",
					// 	value => /^[A-Za-z0-9-]+$/.test(value)
					// ),
                },
                first_name:{
                    required: helpers.withMessage("Please enter first name.", required),
                    alphapattern: helpers.withMessage(
						"Please enter valid first name",
                        value => /^(?:[A-Za-z]+\.\s)?[A-Za-z0-9-]+$/.test(value)
					),
                },
                last_name:{
                    required: helpers.withMessage("Please enter last name.", required),
                    alpha
                },
                email:{
                    required: helpers.withMessage("Please enter email.", required),
                    email: helpers.withMessage("Please enter valid email.", email),
                },
                phone_number:{
                    required: helpers.withMessage("Please enter phone number.", required),
                    maxLength:helpers.withMessage("Please enter phone number max 20 char.", maxLength(10)),
                    numeric: helpers.withMessage("Please enter digits only.", numeric),
                    minLength: helpers.withMessage("Please enter phone number at least 10 numbers.", minLength(10)),
                },
                cpsid: {
                    required: helpers.withMessage("Please enter cps id.", requiredIf(function() {
                        return this.$store.state.user.form.roleId == 2 ? true : false;
                    })),
					maxLength:helpers.withMessage("Maximum 6 digits allowed.", maxLength(6)),
					numeric: helpers.withMessage("Please enter digits only.", numeric),
                },
                cnoid: {
                    required: helpers.withMessage("Please enter cno id.", requiredIf(function() {
                        return this.$store.state.user.form.roleId == 3 ? true : false;
                    })),
					maxLength:helpers.withMessage("Maximum 6 characters allowed.", maxLength(6)),
					numeric: helpers.withMessage("Please enter digits only.", numeric),
                },
                billing_province_code:{
                    required: helpers.withMessage("Please select service province.", requiredIf(function() {
                        return (this.$store.state.user.form.roleId == 2 || this.$store.state.user.form.roleId == 3) ? true : false;
                    })),
                },
                provincial_billing_no:{
                    required: helpers.withMessage("Please enter provincial billing #.", requiredIf(function() {
                        return (this.$store.state.user.form.roleId == 2 || this.$store.state.user.form.roleId == 3) ? true : false;
                    })),
                },
                specialty_code:{
                    required: helpers.withMessage("Please select specialty code.", requiredIf(function() {
                        return (this.$store.state.user.form.roleId == 2 || this.$store.state.user.form.roleId == 3) ? true : false;
                    })),
                },
                gosecure_email:{
                    required: helpers.withMessage("Please enter go-secure email.", requiredIf(function() {
                        return (this.$store.state.user.form.roleId == 2 ) ? true : false;
                    })),
                },
                gosecure_password:{
                    required: helpers.withMessage("Please enter go-secure password.", requiredIf(function() {
                        return (this.$store.state.user.form.roleId == 2) ? true : false;
                    })),
                },
                address:{
                    required: helpers.withMessage("Please enter address.", required),
                },
                visit_location:{
                    required: helpers.withMessage("Please enter visit location.", requiredIf(function() {
                        return (this.$store.state.user.form.roleId == 2 || this.$store.state.user.form.roleId == 3) ? true : false;
                    })),
                },
                service_location:{
                    required: helpers.withMessage("Please enter location indicator.", requiredIf(function() {
                        return (this.$store.state.user.form.roleId == 2 || this.$store.state.user.form.roleId == 3) ? true : false;
                    })),
                }

                
            },
            passwordChangeForm:{
                old_password:{
                    required: helpers.withMessage("Please enter old password.", required),
                },
                new_password:{
                    required: helpers.withMessage("Please enter new password.", required),
                    // maxLength:helpers.withMessage("Please enter password max 16 char.", maxLength(16)),
                    minLength:helpers.withMessage("Please enter new password atleast 8 char.", minLength(8)),
                    passwordComplexity,
                    noSpacesPassword
                },
                new_password_confirmation: {
                    required: helpers.withMessage("Please enter confirm new password.", required),
                    minLength:helpers.withMessage("Please enter new password atleast 8 char.", minLength(8)),
                    // sameAsPassword: helpers.withMessage(() => 'Password confirmation does not match.', sameAs('new_password')),
                }
            },
            changePinForm:{
                old_pin:{
                    required: helpers.withMessage("Please enter old pin.", required),
                },
                new_pin:{
                    required: helpers.withMessage("Please enter new pin.", required),
                    maxLength:helpers.withMessage("Please enter pin 4 char.", maxLength(4)),
                    minLength:helpers.withMessage("Please enter pin 4 char.", minLength(4)),
                    numeric: helpers.withMessage("Please enter new pin digit only.", numeric),
                },
                new_pin_confirmation: {
                    required: helpers.withMessage("Please enter confirm new pin.", required),
                    maxLength:helpers.withMessage("Please enter pin 4 char.", maxLength(4)),
                    minLength:helpers.withMessage("Please enter pin 4 char.", minLength(4)),
                    numeric: helpers.withMessage("Please enter confirm new pin digit only.", numeric),
                    // sameAsPassword: helpers.withMessage(() => 'Pin confirmation does not match.', sameAs('new_pin')),
                }
            }
        },
        
        filter:{

        }
    }
}

export default user;

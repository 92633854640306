<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 dashboard dashboard-billing">
			<div class="header_boxes">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
						<div class="d-flex align-items-center">
							<h3 class="d-h3head white-space-nowrap" style="margin-top: calc(var(--scale-ratio) * -10px);">Total Billing</h3>
							<div class="billing-date-calendar">
								<date-range-picker ref="dashboardPicker" opens="center" :locale-data="{
									direction: 'ltr',
									format: 'yyyy-mm-dd',
									separator: ' - ',
									applyLabel: 'Apply',
									cancelLabel: 'Cancel',
									weekLabel: 'W',
									customRangeLabel: 'Custom Range',
									daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
									monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
									firstDay: 0
								}" singleDatePicker="range" v-model="dateRange" :dateRange="dateRange" :ranges="ranges" :key="dateRangeKey">
									<template v-slot:input="dashboardPicker">
										<slot v-if="dashboardPicker.startDate != null && dashboardPicker.endDate != null">
											{{ formatDateRange(dashboardPicker.startDate) }} - {{ formatDateRange(dashboardPicker.endDate) }}
										</slot>
										<slot v-else>
											All Time Data
										</slot>
									</template>
									<template v-slot:footer="{ rangeText, clickCancel, clickApply }">
										<div class="drp-buttons d-flex justify-content-between align-items-end">
											<div class="selected-date-billig">
												<span class="start-date-blng" v-if="extractDates(rangeText, 'start') != ''">
													<label class="float-start fw-600" style="padding: calc(var(--scale-ratio) * 6px);">START</label>
													<input type="text" :value="extractDates(rangeText, 'start')" @change="handleDate('startDatePicker')" @blur="handleDate('startDatePicker')" ref="startDatePicker" @keyup="this.keyupdate('startDatePicker')"  id="startDatePicker">
												</span>
												<span class="end-date-blng" v-if="extractDates(rangeText, 'end') != ''">
													<label class="float-start fw-600" style="padding: calc(var(--scale-ratio) * 6px);">END</label>
													<input type="text" :value="extractDates(rangeText, 'end')" @change="handleDate('endDatePicker')" @blur="handleDate('endDatePicker')" ref="endDatePicker" @keyup="this.keyupdate('endDatePicker')"  id="endDatePicker">
												</span>
											</div>
											<div>
												<button class="cancelBtn btn btn-sm btn-secondary" type="button"
													@click="clickCancel">Cancel</button>
												<button class="dashboardApplyBtn applyBtn btn btn-sm btn-success" type="button"
													@click="clickApply">Apply</button>
											</div>
										</div>
									</template>
								</date-range-picker>
							</div>
						</div>
					</div>
				</div>
				<div class="t-box-b-brd d-xl-flex">
					<div class="w-pric-box d-inline-block">
						<div class="inv-price-bx">
							<div class="prc-t-invc">
								<p class="mb-0">Total # of Invoice</p><span>{{ this.staticstic_data['total_no_invoice']
								}}</span>
							</div>
						</div>
					</div>
					<div class="w-pric-box d-inline-block">
						<div class="inv-price-bx">
							<div class="prc-t-invc">
								<p class="mb-0">Total Amount</p><span>{{ this.staticstic_data['total_amount'] }}</span>
							</div>
						</div>
					</div>
					<div class="w-pric-box d-inline-block">
						<div class="inv-price-bx">
							<div class="prc-t-invc">
								<p class="mb-0">Total Rejection</p><span>{{ this.staticstic_data['total_rejection_amount']
								}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="stts-chart-bar">
				<div class="row">
					<div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4 invoice mb-40">
						<div class="">
							<div id="chart">
								<ul id="numbers" v-if="this.staticstic_data.status_distr_total_invoice_config">
									<li v-for="(item, index) in this.staticstic_data.status_distr_total_invoice_config.ranges"
										:key="index">
										<span>{{ item }}</span>
									</li>
								</ul>
								<div>
									<div class="chart_title">Status Distribution of # of Invoice</div>
									<ul id="bars">
										<li v-for="(item, index) in this.staticstic_data['status_distr_total_invoice']"
											:key="index">
											<div class="bar" :style="{ height: `${item.percentage}%` }"><label
													class="progres_tooltip">{{ item.label }}: {{ item.value }}</label></div>
											<span>{{ item.label }}</span>
										</li>
										<li class="crt_lines">
											<div></div>
											<div></div>
											<div></div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4 amount mb-40">
						<div class="ttl-amunt-chart">
							<div id="chart">
								<ul id="numbers" v-if="this.staticstic_data.status_distr_total_amount_config">
									<li v-for="(item, index) in this.staticstic_data.status_distr_total_amount_config.ranges"
										:key="index">
										<span>${{ formatCurrency(item) }}</span>
									</li>
								</ul>
								<div>
									<div class="chart_title">Status Distribution of Total Amount</div>
									<ul id="bars">
										<li v-for="(item, index) in this.staticstic_data['status_distr_total_amount']"
											:key="index">
											<div class="bar" :style="{ height: `${item.percentage}%` }">
												<label
													class="progres_tooltip">{{ item.label }}:
													${{ formatCurrency(item.value) }}</label>
											</div>
											<span>{{ item.label	}}</span>
										</li>
										<li class="crt_lines">
											<div></div>
											<div></div>
											<div></div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4 reason mb-40">
						<div class="top-rjct-chart">
							<div id="chart">
								<ul id="numbers" v-if="this.staticstic_data.top_rejection_reasons_config">
									<li v-for="(item, index) in this.staticstic_data.top_rejection_reasons_config.ranges"
										:key="index">
										<span>{{ item }}</span>
									</li>

								</ul>
								<div>
									<div class="chart_title">Top Rejection Reasons</div>
									<ul id="bars">
										<li v-for="(item, index) in this.staticstic_data['top_rejection_reasons']"
											:key="index">
											<div class="bar" :style="{ height: `${item.percentage}%` }"><label
													class="progres_tooltip">{{ item.label }}:
													{{ item.value }}</label></div><span>{{ item.label
													}}</span>
										</li>

										<li class="crt_lines">
											<div></div>
											<div></div>
											<div></div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from './sidebar.vue'
import $ from "jquery";
import axios from "@/scripts/axios.js";
import DateRangePicker from 'vue3-daterange-picker'
import moment from "moment";

export default {
	data() {
		return {
			dateRangeKey: 0,
			dateRange: {
				startDate: this.getMonthStartDate(),
				endDate: this.getMonthEndDate()
			},
			staticstic_data: [],
		}
	},
	components: {
		sidebar,
		DateRangePicker,
	},
	methods: {
		getMonthStartDate() {
			const now = new Date();
			const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
			return startDate;
		},
		getMonthEndDate() {
			const now = new Date();
			const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
			return endDate;
		},
		keyupdate(obj) {
			const inputValue = $(`#${obj}`).val();
			var sanitizedInput = inputValue.replace(/[^\d]/g, '');
			if (sanitizedInput.length > 8) {
				sanitizedInput = sanitizedInput.substring(0, 8);
			}
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});
			$(`#${obj}`).val(formattedDate);
		},
		isValidDate(dateString) {
			const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
			const date = new Date(year, month - 1, day);
			return date.getFullYear() === year && (month === 0 || date.getMonth() + 1 === month) && (day === 0 || date.getDate() === day);
		},
		handleDate(obj) {
			const sanitizedInput = $(`#${obj}`).val();
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});

			// Get or create the error message element
			let errorElement = $(`#${obj}-error`);
			if (errorElement.length === 0) {
				errorElement = $(`<div id="${obj}-error" class="text-red mt-5p"></div>`);
				$(`#${obj}`).after(errorElement);
			}
			const isValidDate = (formattedDate!='') ? this.isValidDate(formattedDate) : false;
			if (isValidDate) {
				errorElement.text(''); // Clear error message if the date is valid
				if(obj=='startDatePicker'){
					this.dateRange.startDate = formattedDate;
				}else{
					this.dateRange.endDate = formattedDate;
				}
				const startDate = new Date(this.dateRange.startDate);
				const endDate = new Date(this.dateRange.endDate);

				if (endDate < startDate) {
					this.$filters.moshaToast("End Date should be greater than Start Date", "danger");
					if(obj=='startDatePicker'){
						this.dateRange.startDate = null;
					}else{
						this.dateRange.endDate = null;
					}
					$(`#${obj}`).val("");
				}
			} else {
				if(formattedDate!=''){
					errorElement.text('Invalid Date');
				}
				$(`#${obj}`).val("");
			}
		},
		extractDates(dateRange, type) {
			const [startDate, endDate] = dateRange.split(" - ");
			return (type == "start") ? startDate : endDate;
		},
		formatDateRange(date) {
			if (!date) return '';
			return moment(date).local().format('YYYY/MM/DD');
		},
		getdate() {
			const invoiceFilter = {
				start_date: this.dateRange.startDate,
				end_date: this.dateRange.endDate,
			};

			const cookieName = "invoice_dashboard_filter";
			const existingCookie = document.cookie.split("; ").find(row => row.startsWith(cookieName));
			
			let updatedInvoiceFilter;
			if (existingCookie) {
				updatedInvoiceFilter = JSON.parse(existingCookie.split("=")[1]);
				updatedInvoiceFilter.start_date = this.dateRange.startDate;
				updatedInvoiceFilter.end_date = this.dateRange.endDate;
			} else {
				updatedInvoiceFilter = invoiceFilter;
			}

			const expiryDate = new Date();
			expiryDate.setDate(expiryDate.getDate() + 1);
			const cookieValue = JSON.stringify(updatedInvoiceFilter);
			document.cookie = `${cookieName}=${cookieValue}; expires=${expiryDate.toUTCString()}; path=/`;

			this.fetchStaticsData();
		},
		changeDateRangePicker() {
			this.getdate();
		},
		fetchStaticsData() {
			this.$store.state.loader = true;
			var dateRange = null;
			if (this.dateRange.startDate && this.dateRange.endDate) {
				dateRange = { date_range: `${moment(this.dateRange.startDate).format('YYYY/MM/DD')} - ${moment(this.dateRange.endDate).format('YYYY/MM/DD')}` };
			}
			axios.post('invoice/fetch-dashboard-data', dateRange)
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						this.staticstic_data = response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		formatCurrency(value) {
			return value.toFixed(2);
		},
		getCookie(name) {
			const value = `; ${document.cookie}`;
			const parts = value.split(`; ${name}=`);
			if (parts.length === 2) return parts.pop().split(';').shift();
			return null;
		}
	},
	mounted() {
		/* Invoice filter configuration data get */
		const invoiceFilterCookie = this.getCookie('invoice_dashboard_filter');
		if (invoiceFilterCookie) {
			const invoiceFilter = JSON.parse(invoiceFilterCookie);
			this.dateRange.startDate = (invoiceFilter.start_date != undefined || invoiceFilter.start_date != '') ? invoiceFilter.start_date : null;
			this.dateRange.endDate = (invoiceFilter.end_date != undefined || invoiceFilter.end_date != '') ? invoiceFilter.end_date : null;
			this.dateRangeKey = this.dateRangeKey+1;
		}
		this.fetchStaticsData();
		let $this = this;
		$(document).ready(() => {
			$(document).off("click", ".dashboardApplyBtn");
			$(document).on("click", ".dashboardApplyBtn", () => {
				$this.changeDateRangePicker();
			});
		});
	},
	computed: {
		ranges() {
			let today = new Date();
			today.setHours(0, 0, 0, 0);

			let yesterday = new Date(today);
			yesterday.setDate(today.getDate() - 1);

			// Define the start and end of the current month
			let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
			let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

			// Define the start and end of the last month
			let lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
			let lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

			// Define the start of the week (Sunday) and end of the week (Saturday)
			let thisWeekStart = new Date(today);
			thisWeekStart.setDate(today.getDate() - today.getDay());
			let thisWeekEnd = new Date(thisWeekStart);
			thisWeekEnd.setDate(thisWeekStart.getDate() + 6);

			// Define the start and end of the last week
			let lastWeekStart = new Date(thisWeekStart);
			lastWeekStart.setDate(thisWeekStart.getDate() - 7);
			let lastWeekEnd = new Date(lastWeekStart);
			lastWeekEnd.setDate(lastWeekStart.getDate() + 6);

			// Define the date range for the last 7 days
			let last7DaysStart = new Date(today);
			last7DaysStart.setDate(today.getDate() - 6);

			// Define the date range for the last 30 days
			let last30DaysStart = new Date(today);
			last30DaysStart.setDate(today.getDate() - 29);

			// Define the date range for the last 90 days
			let last90DaysStart = new Date(today);
			last90DaysStart.setDate(today.getDate() - 89);

			// Define the date range for the last 180 days
			let last180DaysStart = new Date(today);
			last180DaysStart.setDate(today.getDate() - 179);

			let range = {
				'Today': [today, today],
				'Yesterday': [yesterday, yesterday],
				'This Week': [thisWeekStart, thisWeekEnd],
				'Last Week': [lastWeekStart, lastWeekEnd],
				'Last 7 Days': [last7DaysStart, today],
				'Last 30 Days': [last30DaysStart, today],
				'Last 90 Days': [last90DaysStart, today],
				'Last 180 Days': [last180DaysStart, today],
				'This Month': [thisMonthStart, thisMonthEnd],
				'Last Month': [lastMonthStart, lastMonthEnd],
				'This Year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
				'All Time': [null, null],
			};
			return range;
		}
	},
}
</script>

<style>
/* @import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
 .wrapper {
	max-width: 250px;
	margin: 0 auto;
  } */
#total_invoice_container {height: calc(var(--scale-ratio) * 120px);}
#total_amt_container {height: calc(var(--scale-ratio) * 120px);}
#top_rejection_container {height: calc(var(--scale-ratio) * 120px);}
.e-daterangepicker.e-popup.e-preset-wrapper {top: calc(var(--scale-ratio) * 190px) !important;}

.e-daterangepicker.e-popup.e-preset-wrapper {min-width: calc(var(--scale-ratio) * 820px);}
.e-date-range-container {width: calc(var(--scale-ratio) * 660px);}

.e-daterangepicker.e-popup .e-range-header {
	position: relative;
}

.e-list-parent .e-list-item {color: #0E2641;text-align: center;font-size: calc(var(--scale-ratio) * 18px) !important;font-weight: 600;line-height: normal !important;border-radius: 5px !important;border: 0.5px solid #0E2641;margin: calc(var(--scale-ratio) * 5px) calc(var(--scale-ratio) * 10px);padding: calc(var(--scale-ratio) * 5px) calc(var(--scale-ratio) * 2px) !important;height: auto !important;}

.e-daterangepicker.e-popup .e-presets .e-list-item.e-hover,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-list-item.e-hover,
.e-bigger.e-small.e-daterangepicker.e-popup .e-presets .e-list-item.e-hover {
	background: rgba(236, 227, 209, 0.50);
	color: #0E2641;
}

.e-daterangepicker.e-popup .e-presets .e-list-item.e-active,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-list-item.e-active,
.e-bigger.e-small.e-daterangepicker.e-popup .e-presets .e-list-item.e-active {
	color: #0E2641;
}



.e-daterangepicker.e-popup.e-preset-wrapper .e-presets,
.e-bigger.e-small .e-daterangepicker.e-popup.e-preset-wrapper .e-presets {height: auto !important;padding: calc(var(--scale-ratio) * 8px) 0 calc(var(--scale-ratio) * 8px) 0;}

.e-daterangepicker.e-popup,
.e-bigger.e-small .e-daterangepicker.e-popup {
	max-height: none !important;
}

.e-daterangepicker.e-popup .e-calendar .e-content table,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content table {
	border-bottom: 1px solid #e0e0e0;
}

.e-daterangepicker.e-popup,
.e-bigger.e-small .e-daterangepicker.e-popup {
	left: 534.023px;
}

.e-daterangepicker.e-popup .e-presets,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets {max-height: calc(var(--scale-ratio) * 452px);}
</style>
<template>
	<div class="container-fluid login_header">
		<div class="logo">
			<img src="/images/login_logo.png" alt="" class="img-fluid">
		</div>
		<a href="javascript:void(0);" class="text-decoration-none" @click="openContactUsModel"><div class="contact_btn fs-18">Contact Us</div></a>
	</div>
	<section class="pb-40 sign-up-section">
		<div class="container h-100">
			<div class="row d-flex justify-content-center align-items-center h-100">
				<div class="col-12 col-md-8 col-lg-6 col-xl-6">
					<div class="box">
						<div class="box-body p-20 custom-form">
							<h3 class="main_heading mb-40 l-h-n">Let’s create your account.</h3>
							<p class="fw-300 l-h-n mb-30">Fill out the form below to start your <span class="fs-18 fw-600">Free 14 Days Trial !</span></p>
							<p class="fs-20 fw-600 mb-20 l-h-n">Clinic’s Contact Representative</p>
							<div class="input_box mb-15">
								<input type="text" required class="form-control form-control-lg" v-model="form.full_name" autocomplete="off" />
								<label class="form-label">Full Name (First & Last Name)</label>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
									v-for="(error, index) of v$.form?.full_name?.$errors" :key="index">
									<div v-if="index === 0">
										{{ error.$message }}
									</div>
								</div>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.full_name">
									{{ errors.full_name[0] }}
								</div>
							</div>
							<div class="input_box mb-15">
								<input type="text" required class="form-control form-control-lg" v-model="form.email" autocomplete="off" />
								<label class="form-label">Email</label>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
									v-for="(error, index) of v$.form?.email?.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.email">
									{{ errors.email[0] }}
								</div>
							</div>
							<div class="input_box mb-20">
								<input type="text" required class="form-control form-control-lg" v-model="form.phone" autocomplete="off" @input="form.phone = form.phone.replace(/\D/g, '')" />
								<label class="form-label">Phone Number</label>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
									v-for="(error, index) of v$.form?.phone?.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.phone">
									{{ errors.phone[0] }}
								</div>
							</div>
							<p class="fs-20 fw-600 mb-20 l-h-n">Clinic’s Basic Information</p>
							<div class="input_box mb-20">
								<input type="text" required class="form-control form-control-lg" v-model="form.clinic_name" autocomplete="off" />
								<label class="form-label">Clinic’s official name. (eg. York Mills Walk-in Clinic)</label>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
									v-for="(error, index) of v$.form?.clinic_name?.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.clinic_name">
									{{ errors.clinic_name[0] }}
								</div>
							</div>
							<div class="input_box mb-20">
								<input type="text" required class="form-control form-control-lg" v-model="form.clinic_unique_id" autocomplete="off" />
								<label class="form-label">Clinic ID for EMR login. (eg. ym-clinic) </label>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
									v-for="(error, index) of v$.form?.clinic_unique_id?.$errors" :key="index">
									<div v-if="index === 0">
										{{ error.$message }}
									</div>
								</div>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.clinic_unique_id">
									{{ errors.clinic_unique_id[0] }}
								</div>
							</div>
							<div class="input_box mb-20">
								<input type="text" required class="form-control form-control-lg" v-model="form.username"  autocomplete="new-username" />
								<label class="form-label">Username</label>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
									v-for="(error, index) of v$.form?.username?.$errors" :key="index">
									<div v-if="index === 0">
										{{ error.$message }}
									</div>
								</div>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.username">
									{{ errors.username[0] }}
								</div>
							</div>
							<div class="input_box mb-20">
								<div class="password-wrapper position-relative">
									<input :type="inputPasswordType" required class="form-control form-control-lg" v-model="form.password" autocomplete="off" />
									<label class="form-label">Password</label>
									<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="inputPasswordType!='password'" @click="changePasEle('enc')">
									<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changePasEle('desc')">
								</div>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
									v-for="(error, index) of v$.form?.password?.$errors" :key="index">
									<div v-if="index === 0">
										{{ error.$message }}
									</div>
								</div>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.password">
									{{ errors.password[0] }}
								</div>
							</div>
							<div class="input_box mb-30">
								<div class="password-wrapper position-relative">
									<input :type="inputPinType" required class="form-control form-control-lg" v-model="form.pin" @input="form.pin = form.pin.replace(/\D/g, '')"  autocomplete="off" maxlength="4"/>

									<label class="form-label">Security Code</label>
									<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="inputPinType!='password'" @click="changePinEle('enc')">
									<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changePinEle('desc')">
								</div>

								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
									v-for="(error, index) of v$.form?.pin?.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.pin">
									{{ errors.pin[0] }}
								</div>
							</div>
							<p class="agree_agreement_line l-h-n mb-30">By clicking <span class="fst-italic">Continue</span>, you agree to our <a href="javascript:void(0);" class="fs-18 fw-600">Service Agreement.</a></p>
							<div class="mb-30">
								<button class="comman_btn fs-18 w-100 pt-15 pb-15" @click="submitForm" >
									Continue <img src="images/loader.gif" v-if="loader" style="width: calc(var(--scale-ratio) * 18px);" />
								</button>
							</div>
							<p class="agree_agreement_line l-h-n text-end mb-0">Already have a Verismo account? <span @click="this.$router.push(`/login`);" class="fs-18 fw-600 ml-10 cursor-pointer text-decoration-underline">Login</span></p>
                        </div>
					</div>					
				</div>
			</div>
		</div>
	</section>

	<div class="modal fade" id="contact_us_modal" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl contact_create_modal">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div>
							<h3 class="main_heading mb-40 l-h-n">Contact Us</h3>
							<p class="pt-15 pb-15 fs-16 l-h-n text-center fw-300 mb-35">We’re here to answer any question about our services. Please complete the following form to get connected directly.</p>
							<div class="connected-form-section">
								<div class="row">
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">First Name</label>
											<input type="text" class="form-control cursor-text" v-model="contactForm.first_name">
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
												v-for="(error, index) of v$.contactForm?.first_name?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.first_name">
												{{ errors.first_name[0] }}
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">Last Name</label>
											<input type="text" class="form-control cursor-text" v-model="contactForm.last_name">
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
												v-for="(error, index) of v$.contactForm?.last_name?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.last_name">
												{{ errors.last_name[0] }}
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">Email</label>
											<input type="email" class="form-control cursor-text" v-model="contactForm.email">
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
												v-for="(error, index) of v$.contactForm?.email?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.email">
												{{ errors.first_name[0] }}
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">Phone</label>
											<input type="text" class="form-control cursor-text" v-model="contactForm.phone">
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
												v-for="(error, index) of v$.contactForm?.phone?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.phone">
												{{ errors.phone[0] }}
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-12 col-lg-12">
										<div class="contact-form mb-40 l-h-n">
											<label class="form-label cursor-text mb-5p">How can we help you?</label>
											<textarea class="form-control" rows="3" v-model="contactForm.message"></textarea>
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
												v-for="(error, index) of v$.contactForm?.message?.$errors" :key="index">
												{{ error.$message }}
											</div>
											<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;" v-if="errors && errors.message">
												{{ errors.message[0] }}
											</div>
										</div>
									</div>
								</div>
								<div class="btn_part text-center">
									<button class="comman_btn fs-18" @click="submitContactForm">Submit <img src="images/loader.gif" v-if="this.loader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<VerificationOtpModal
		ref="VerificationOtpModal"
		:title="'Verification Code'"
		:email="this.form.email"
	/>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
import {
	required,
	helpers,
	email,
	minLength,
	maxLength,
	numeric
} from "@vuelidate/validators";
import VerificationOtpModal from "../components/base/VerificationCodeModal.vue";

export default {
	name: "Sign Up",
	setup() {
		return { v$: useVuelidate() };
	},
	components: {
		VerificationOtpModal
	},
	data() {
		return {
			errors: {},
			form: {
				full_name: null,
				email: null,
				phone: null,
				clinic_name: null,
				clinic_unique_id: null,
				username: null,
				password: null,
				pin: null
			},
			contactForm: {
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
				message: '',
				from: 'Sign-Up Page',
			},
			loader: false,
			backEndError: '',
			inputPasswordType:'password',
			inputPinType:'password',
		};
	},
	created() {
		this.$store.state.loader = true;
		setTimeout(() => {
			this.$store.state.loader = false;
		}, 500);
	},
	mounted() { },
	methods: {
		openContactUsModel(){
			window.$("#contact_us_modal").modal("show")
		},
		submitContactForm() {
			this.v$.contactForm.$validate();
			if (!this.v$.contactForm.$error) {
				this.contactForm.from = "Sign-Up Page";
				this.loader = true;
				axios.post('/auth/contact-us', this.contactForm)
					.then(response => {
						this.loader = false;
						if (response.data.success) {
							this.$filters.moshaToast(response.data.message, "success");
							this.contactForm = { first_name: '', last_name: '', email: '', phone: '', message: '' };
							this.v$.contactForm.$reset();
							this.contactForm.from = "Sign-Up Page";
							window.$("#contact_us_modal").modal("hide");
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
					})
					.catch(error => {
						this.loader = false;
						if (error.response.data.errors) {
							console.log(error.response.data.errors);
							// this.errors = error.response.data.errors; // Display server-side validation errors
						} else {
							console.log("An error occurred. Please try again later.");
						}
					});
			} else {
				for (const [key] of Object.entries(this.v$.contactForm)) {
					if (this.v$.contactForm[key].$errors && this.v$.contactForm[key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}
		},
		submitForm() {
			const formName = 'form';
			this.v$[formName].$validate();
			if (!this.v$[formName].$error) {
				this.loader = true;
				this.$store.state.loader = true;
				const form = { ...this.form }; // Create a shallow copy of the form

				// Trim leading and trailing spaces for all string fields
				Object.keys(form).forEach(key => {
					if (typeof form[key] === 'string') {
						form[key] = form[key].trim();
					}
				});

				axios.post("auth/sign-up", form)
					.then((response) => {
						this.$store.state.loader = false;
						this.loader = false;
						if (response.status == 200) {
							this.$refs.VerificationOtpModal.sendCode();
							this.$refs.VerificationOtpModal.openModal();
						}
					})
					.catch((error) => {
						this.loader = false;
						this.$store.state.loader = false;
						console.log(error)
						if (error.response.status === 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					})
			} else {
				console.log(Object.entries(this.v$.form))
				for (const [key] of Object.entries(this.v$.form)) {
					if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}
		},
		changePasEle(action) {
			if (action == 'enc') {
				this.inputPasswordType = 'password'
			} else {
				this.inputPasswordType = 'text'
			}
		},
		changePinEle(action) {
			if (action == 'enc') {
				this.inputPinType = 'password'
			} else {
				this.inputPinType = 'text'
			}
		},
	},
	validations() {
		return {
			form: {
				full_name: {
					required: helpers.withMessage("Please enter Full Name.", required),
					minLength: helpers.withMessage("Please enter Full Name must be at least 4 char.", minLength(4)),
					maxLength: helpers.withMessage("Please enter Full Name must be max. 50 char.", maxLength(50)),
					pattern: helpers.withMessage(
						"Please enter Full Name in 'First & Last Name' format (Prefix optional)",
						value => /^[A-Za-zÀ-ÿ]+\.?\s?[A-Za-zÀ-ÿ]+(?:\s[A-Za-zÀ-ÿ]+)$/.test(value)
					),
				},
				email: {
					required: helpers.withMessage("Please enter Email.", required),
					email: helpers.withMessage("Please enter a valid Email.", email),
				},
				phone: {
					required: helpers.withMessage("Please enter Phone.", required),
					minLength: helpers.withMessage("Phone must be at least 10 numbers.", minLength(10)),
					maxLength: helpers.withMessage("Phone must be max. 10 numbers.", maxLength(10)),
					pattern: helpers.withMessage("Phone must contain only numbers.", /^\d+$/),
				},
				clinic_name: {
					required: helpers.withMessage("Please enter Clinic Name.", required),
					minLength: helpers.withMessage("Please enter Clinic Name must be at least 4 char.", minLength(4)),
					maxLength: helpers.withMessage("Please enter Clinic Name must be max. 50 char.", maxLength(50)),
					pattern: helpers.withMessage(
						"Clinic Name must only contain letters, space and hyphens(-). First and last character cannot be a space.",
						value => /^[A-Za-z-]+( [A-Za-z-]+)*$/.test(value)
					),
				},
				clinic_unique_id: {
					required: helpers.withMessage("Please enter Clinic ID.", required),
					minLength: helpers.withMessage("Please enter Clinic ID must be at least 4 char.", minLength(4)),
					maxLength: helpers.withMessage("Please enter Clinic ID must be max. 20 char.", maxLength(20)),
					pattern: helpers.withMessage(
						"Clinic ID must only contain letters and hyphens(-) (no spaces).",
						value => /^[A-Za-z-]+$/.test(value)
					),
				},
				username: {
					required: helpers.withMessage("Please enter Username.", required),
					minLength:helpers.withMessage("Please enter username atleast 8 char.", minLength(8)),
					maxLength: helpers.withMessage("Please enter Username must be max. 16 char.", maxLength(16)),
					pattern: helpers.withMessage(
						"Username must only contain letters, numbers, and hyphens(-) (no spaces).",
						value => /^[A-Za-z0-9-]+$/.test(value)
					),
				},
				password: {
					required: helpers.withMessage("Please enter Password.", required),
					minLength: helpers.withMessage("Password must be at least 8 characters long.", minLength(8)),
					passwordComplexity: helpers.withMessage(
						'Password must contain at least one uppercase letter, one lowercase letter, and one digit',
						value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(value)
					),
				},
				pin: {
					required: helpers.withMessage("Please enter Security Code.", required),
					minLength: helpers.withMessage("Security Code must be at least 4 numbers.", minLength(4)),
					maxLength: helpers.withMessage("Security Code must be max. 4 numbers.", maxLength(4)),
					pattern: helpers.withMessage("Security Code must contain only numbers.", /^\d+$/),
				},
			},
			contactForm: {
				first_name: { required: helpers.withMessage("Please enter First Name.", required) },
				last_name: { required: helpers.withMessage("Please enter Last Name.", required) },
				email: {
					required: helpers.withMessage("Please enter Email.", required),
					email: helpers.withMessage("Please enter a valid Email.", email),
				},
				phone: {
					required: helpers.withMessage("Please enter Phone.", required),
					maxLength:helpers.withMessage("Please enter phone number max 20 char.", maxLength(20)),
					minLength:helpers.withMessage("Please enter phone number min 10 char.", minLength(10)),
                    numeric: helpers.withMessage("Please enter digits only.", numeric),
				},
				message: { required: helpers.withMessage("Please enter Message.", required) },
			},
		};
	},
	computed:{
	}
};
</script>

<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 px-0">
			<div class="private-codes-pg">
				<div class="d-flex align-items-center justify-content-between">
					<h1 class="h1-admin_head mb-0">Private Billing Custom Codes</h1>
					<div class="text-center private-edit-btn">
						<button class="comman_btn crt-user-bttn big_btn_new" @click="editForm"
							v-if="!this.isShowEditFrom">
							Edit Custom Code
						</button>
						<button class="comman_brdr_btn big_btn mr-80" style="margin-top: calc(var(--scale-ratio) * 4.59px);"
							v-if="this.isShowEditFrom" @click="cancelForm">
							Cancel
						</button>
						<button class="comman_btn big_btn" v-if="this.isShowEditFrom"
							@click="storeBillingCustomCodes">
							Save
							<img src="images/loader.gif" v-if="loader" style="width: calc(var(--scale-ratio) * 18px);" />
						</button>
					</div>
				</div>
				<div class="private_codes-edit mt-30" :class="{ 'private-codes-change-inpt': !this.isShowEditFrom }">
					<div class="">
						<table class="table table-borderless">
							<thead>
								<tr>
									<th style="width: calc(var(--scale-ratio) * 27px);"></th>
									<th style="width: calc(var(--scale-ratio) * 190px);">CODES</th>
									<th style="width: calc(var(--scale-ratio) * 222px);">PRICE</th>
									<th>DESCRIPTION</th>
								</tr>
							</thead>
							<tbody v-if="this.form.billing_custom_code_list.length > 0">
								<tr v-for="(item, index) in this.form.billing_custom_code_list" :key="index">
									<input type="hidden" v-model="item.id">
									<td>
										<div class="close-iconClick" v-if="this.isShowEditFrom"
											@click="removeCustomCode(index, item.id)">
											<img src="images/close-icon.svg">
										</div>
									</td>
									<td>
										<input type="text" placeholder=""
											class="form-control codesEdit-inpt" style="width: calc(var(--scale-ratio) * 150px);"
											:readonly="!this.isShowEditFrom" v-model="item.code">
									</td>
									<td>
										<input type="text" placeholder=""
											class="form-control codesEdit-inpt" style="width: calc(var(--scale-ratio) * 150px);"
											:readonly="!this.isShowEditFrom" v-model="item.fee">
									</td>
									<td>
										<input type="text" placeholder=""
											class="form-control codesEdit-inpt" maxlength="50"
											:readonly="!this.isShowEditFrom" v-model="item.description">
									</td>
								</tr>
								<tr v-if="this.isShowEditFrom">
									<td></td>
									<td class="w-auto border-0" colspan="4">
										<div class="plus_sign_row" @click="addCustomCodeField">+</div>
									</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr v-if="this.isShowEditFrom">
									<td></td>
									<td class="w-auto border-0" colspan="4">
										<div class="plus_sign_row" @click="addCustomCodeField">+</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import sidebar from './sidebar.vue';
import axios from "@/scripts/axios.js";
export default {
	data() {
		return {
			isShowEditFrom: false,
			form: {
				billing_custom_code_list: [],
				remove_ids: []
			},
			loader: false,
		}
	},
	components: {
		sidebar,
	},
	mounted() {
		this.billingCustomCodes();
	},
	methods: {
		editForm() {
			this.isShowEditFrom = true
		},
		cancelForm() {
			this.isShowEditFrom = false
			this.billingCustomCodes();
		},
		removeCustomCode(index, id) {
			if (index > 0 && index < this.form.billing_custom_code_list.length) {
				this.form.billing_custom_code_list.splice(index, 1);
				if(id != null)
				{
					this.form.remove_ids.push(id);
				}
			}
		},
		addCustomCodeField() {
			if (!this.validateData()) {
				return false;
			}
			this.form.billing_custom_code_list.push({
				id: null,
				code: '',
				fee: '$',
				description: '',
			});
		},
		billingCustomCodes() {
			this.$store.state.loader = true;
			axios.post('admin/billing-custom-codes')
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						const data = response.data.data;
						this.form.billing_custom_code_list = data;
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		validateData() {
			if (this.form.billing_custom_code_list.length > 0) {
				for (let i = 0; i < this.form.billing_custom_code_list.length; i++) {
					const item = this.form.billing_custom_code_list[i];

					const code = item.code ? item.code.trim() : '';
					const sanitizedFee  = item.fee ? item.fee.toString().replace(/[$,]/g, '').trim() : '';
					const description = item.description ? item.description.trim() : '';

					if (code == '') {
						this.$filters.moshaToast('Required to input code value', "error");
						return false;
					}
					if (code.length > 5) {
						this.$filters.moshaToast('Code value cannot exceed 5 characters', "error");
						return false;
					}
					if (isNaN(sanitizedFee) || sanitizedFee === '') {
						this.$filters.moshaToast('Required to input a valid fee value', "error");
						return false;
					}

					let feeValue = parseFloat(sanitizedFee);
					if (isNaN(feeValue)) {
						this.$filters.moshaToast('Required to input a valid fee value', "error");
						return false;
					}
					const feePattern = /^\d{1,6}(\.\d{2})?$/;

					if (!feePattern.test(sanitizedFee)) {
						this.$filters.moshaToast('Fee value must be a number up to 6 digits before the decimal point and exactly 2 digits after', "error");
						return false;
					}

					if (description === '') {
						this.$filters.moshaToast('Required to input description text', "error");
						return false;
					}
					if (description.length > 50) {
						this.$filters.moshaToast('Description text cannot exceed 50 characters', "error");
						return false;
					}
				}
			}
			return true;
		},
		storeBillingCustomCodes() {
			this.loader = true;
			if (!this.validateData()) {
				this.loader = false;
				return false;
			} else {
				this.$store.state.loader = true;
				axios.post('admin/billing-custom-codes/store', this.form)
					.then((response) => {
						this.$store.state.loader = false;
						if (response.status === 200) {
							this.loader = false;
							const data = response.data.data;
							this.form.billing_custom_code_list = data;
							this.isShowEditFrom = false;
							this.$filters.moshaToast("Billing Custom Codes has been updated successfully", "success");
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
					}).catch(error => {
						this.$store.state.loader = false;
						if (error.response.status === 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					});
			}
		}
	}
};
</script>
<template>
	<div class="row patient_chart add_local_files">
		<div class="col-lg-5 col-xl-5 edit_record_page">
			<div>
				<div class="box-body containt_detail mt-30">
					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">Template Description<small
								class="asterisksign">*</small></label>
						<div class="col-sm-7">
							<input type="text" :ref="`template_description`" v-model="form.title" class="form-control">
							<ValidationErrorMessageList :errors="v$.form.title.$errors" />
						</div>
					</div>
					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">Module<small
								class="asterisksign">*</small></label>
						<div class="col-sm-7">
							<div class="moduleList_dropdown">
								<CustomDropDown :options="moduleList" :initialValue="form.module" :fieldName="`module`"
									@item-selected="handleItemSelected"
									@dropdown-clicked="preventDropDownBackSpaceEvent" v-model="form.module"
									ref="module">
								</CustomDropDown>
							</div>
							<ValidationErrorMessageList :errors="v$.form.module.$errors" />
						</div>
					</div>
					<div class="row pd-bottom pb-0">
						<label for="text" class="col-sm-5 col-form-label right-bor">Notes</label>
						<div class="col-sm-7"><input type="text"  :ref="`notes`" class="form-control" v-model="form.additional_info">
						</div>
					</div>
				</div>
				<p class="signature-poplated-line text-end mt-40 mb-50" v-if="selectedOption == 'signature'">Signature
					is populated
					based on the user of access (Doctor / Nurse only)</p>
				<div class="box-body containt_detail mt-30"
					v-if="selectedTextBoxId !== null && selectedOption === 'textbox' && Object.keys(selectedTextBox).length > 0">
					<!-- <div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">
							Text Box Name
							<small class="asterisksign">*</small>
						</label>
						<div class="col-sm-7">
							<input type="text" v-model="selectedTextBox.text_box_name" class="form-control">
						</div>
					</div> -->
					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">
							Database Tag
							<!-- <small class="asterisksign">*</small> -->
						</label>
						<div class="col-sm-7">
							<div class="database_tag_drop_box position-relative" ref="dropdownContainer">
								<div class="input_box w-100">
									<input type="text" class="form-control form-control-lg" :ref="`database_tag`"
										v-model="selectedTextBox.database_tag" @click="showFilteredTags"
										@keyup="tagSearch" :readonly="selectedTextBox.isDatabaseTagReadOnly" required>
								</div>
								<div class="search_drop_box" v-if="filteredTags.length > 0">
									<div v-for="(tag, index) in filteredTags" :key="index" class="con_drop_line"
										@click="selectTag(tag)">
										<div class="man_haeding mb-0">
											<span>{{ tag.category }} | <span class="fw-300">{{ tag.label
													}}</span></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="or-devider-line position-relative text-center z-index-1 mb-20">
						<span>OR</span>
					</div>
					<div class="row pd-bottom pb-0">
						<label for="text" class="col-sm-5 col-form-label right-bor">Plain Text</label>
						<div class="col-sm-7">
							<input :ref="`plain_text`" type="text" class="form-control" v-model="selectedTextBox.placeholder"
								:readonly="selectedTextBox.isPlainTextReadOnly">
						</div>
					</div>
				</div>

				<div class="box-body containt_detail mt-30"
					v-if="(selectedDropDownId !== null && selectedOption == 'dropdown' && Object.keys(selectedDropDown).length > 0)">
					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">Dropdown Options<small
								class="asterisksign">*</small></label>
						<div class="col-sm-7">
							<input type="text" v-model="selectedDropDown.placeholder" class="form-control">
						</div>
					</div>
					<span class="comma-separate-tags d-block mb-0 mr-20 text-end">use comma to separate dropdown options
						ex: 1, 2, 3</span>
				</div>

				<div class="mt-20" v-if="(selectedCheckBoxId !== null && selectedOption == 'checkbox' && Object.keys(selectedCheckBox).length > 0)">
					<div class="row">
						<div class="col-sm-5">
							<label for="text" class="form-label fs-18 mb-0 ml-15">Pre-fill Checkbox</label>
						</div>
						<div class="col-sm-7">
							<div class="usr-dtls-switch pre-fill-switch">
								<label class="switch d-block">
									<input type="checkbox" :id="`flexSwitchCheckDefault-${selectedCheckBoxId}`" @click.stop="toggleCheckbox(selectedCheckBoxId)" :checked="selectedCheckBox.placeholder === true">
									<span class="sw-slider"></span>
								</label>
							</div>
						</div>
					</div>
				</div>

				<div class="div-button-container  mt-30 d-flex justify-content-between">
					<div>
						<div class="blankpages-list" v-if="addBlankPagesNumbers.length > 0">
							<p v-for="(page, index) in addBlankPagesNumbers" :key="index" class="blank-page-name mb-15">
							<img src="/images/close-icon.svg" class="cursor-pointer" @click="!isProcessing ? removeBlankPage(index) : null">Blank Page {{ index + 1 }}</p>
						</div>
					</div>
					<div class="text-end">
						<span class="add-page" @click="addBlankPage">Add Blank Page</span>
						<span class="delete-element" 
							@click="deleteElement"
							v-if="selectedElement !== '' && selectedElementId !== null" 
							:disabled="selectedElement === '' && selectedElementId === null">Delete Element</span>
					</div>
				</div>
			</div>

			<div class="btn_part text-center" style="margin-top:0;margin-bottom: calc(var(--scale-ratio) * 30px);">
				<button class="comman_brdr_btn big_btn mx30" @click="backToList">Cancel</button>
				<button class="comman_btn big_btn mx30" @click="uploadDocument">Save <img src="images/loader.gif"
						v-if="this.isShowLoader"  style="width: calc(var(--scale-ratio) * 18px);"/></button>
			</div>
		</div>
		<div class="col-lg-7 col-xl-7 pdf-view">
			<div class="t-pdf-edit-button text-center" v-if="documentPath">
				<button id="addTextBoxButton" class="comman_brdr_btn btn ms-0 fs-18"
					:class="{ active: selectedOption === 'textbox' }" @click="addTextBox">
					Text Box
				</button>
				<button id="addDropdownButton" class="comman_brdr_btn btn fs-18"
					:class="{ active: selectedOption === 'dropdown' }" @click="addDropDown">
					Dropdown
				</button>
				<button id="addImageButton" class="comman_brdr_btn btn fs-18" :class="{ active: selectedOption === 'image' }"
					@click="addImage">
					Image
				</button>
				<button id="addSignatureButton" class="comman_brdr_btn btn fs-18"
					:class="{ active: selectedOption === 'signature' }" @click="addSignature">
					Signature
				</button>
				<button id="addCheckboxButton" class="comman_brdr_btn btn fs-18 me-0"
					:class="{ active: selectedOption === 'checkbox' }" @click="addCheckbox">
					Check Box
				</button>
			</div>
			<div class="slide_deatil_box pdf-show-part overflow-auto" ref="pdfContainer" @scroll="handleScroll($event)" v-if="documentPath != null">
				<div class="text-center position-relative"
					style="width: 100%; height: 100%; margin: 0 auto;">
					<div id="renderAllCanvasPages" ref="pdfCanvas" style="width: 100%; height: 100%;">
						<div class="pdf-view">
							<div v-if="showContextMenu && selectedElementId != null" :style="{ top: `${contextMenuY}px`, left: `${contextMenuX}px` }"
								class="context-menu">
								<ul>
									<li v-if="isContextShowFromCanvas" @click="pasteElement($event)">Paste</li>
									<template v-else>
										<li @click="copyElement">Copy</li>
										<li @click="cutElement">Cut</li>
										<!-- <li @click="pasteElement(e)">Paste</li> -->
										<li @click="deleteElement">Delete</li>
									</template>
								</ul>
							</div>
							<div v-for="textBox in allTextBoxes" :key="textBox.id">
								<div class="text-box" :ref="`textBox-${textBox.id}`" v-if="textBox.isShowTextBox" :style="{
									left: `${textBox.x_loc}px`,
									top: `${textBox.y_loc}px`,
									width: `${textBox.width}px`,
									height: `${textBox.height}px`,
									fontSize: textBox.database_tag ? '10px' : `${textBox.fontSize}px`,
									padding: 0 + 'px',
									display: 'block',
									border: ((this.selectedElementId === textBox.id) && (this.selectedTextBoxId === textBox.id)) ? '2.5px dashed #000' : '1px dashed #848484',
									zIndex: ((this.selectedElementId === textBox.id) && (this.selectedTextBoxId === textBox.id)) ? 1 : '',
									// display: textBox.onPage === currentPage ? 'block' : 'none'
								}" @click="onClickSelectElement($event, textBox.id, 'textbox')" @contextmenu="handleElementRightClick">
									<div v-if="this.selectedElementId === textBox.id && this.selectedTextBoxId === textBox.id"
										class="resize-handle top-left" :style="{ backgroundColor : ((this.selectedElementId === textBox.id) && (this.selectedTextBoxId === textBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, textBox.id, 'top-left', 'textbox')"></div>
									<div v-if="this.selectedElementId === textBox.id && this.selectedTextBoxId === textBox.id" 
										class="resize-handle top-right" :style="{ backgroundColor : ((this.selectedElementId === textBox.id) && (this.selectedTextBoxId === textBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, textBox.id, 'top-right', 'textbox')"></div>
									<div v-if="this.selectedElementId === textBox.id && this.selectedTextBoxId === textBox.id"
										class="resize-handle bottom-left" :style="{ backgroundColor : ((this.selectedElementId === textBox.id) && (this.selectedTextBoxId === textBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, textBox.id, 'bottom-left', 'textbox')"></div>
									<div v-if="this.selectedElementId === textBox.id && this.selectedTextBoxId === textBox.id"
										class="resize-handle bottom-right" :style="{ backgroundColor : ((this.selectedElementId === textBox.id) && (this.selectedTextBoxId === textBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, textBox.id, 'bottom-right', 'textbox')"></div>
									<textarea class="text-box-content" :ref="`textarea-${textBox.id}`"
										:value="formatTextBoxContent(textBox)" :style="{
											cursor: 'move',
											boxSizing: 'border-box',
											padding: '0px',
											verticalAlign: 'top',
											color: textBox.database_tag ? '#007BFF' : '',
											fontFamily: 'Helvetica',
											backgroundColor: textBox.database_tag ? '#ACC7EF' : (this.wrappedTextBoxVal[textBox.id] ? '' : '#F6F1E8')
										}" @mousedown="dragHandle($event, textBox.id, 'textbox')" readonly></textarea>
								</div>
							</div>
							<div v-for="dropDown in allDropDowns" :key="dropDown.id">
								<div class="pdf-dropdown-drag" :ref="`dropDown-${dropDown.id}`"
									v-if="dropDown.isShowDropDown" :style="{
									left: `${dropDown.x_loc}px`,
									top: `${dropDown.y_loc}px`,
									width: `${dropDown.width}px`,
									height: `${dropDown.height}px`,
									fontSize: (dropDown.fontSize) + 'px',
									display: 'block',
									border: ((this.selectedElementId === dropDown.id) && (this.selectedDropDownId === dropDown.id)) ? '2.5px dashed #000' : '1px dashed #848484',
									zIndex: ((this.selectedElementId === dropDown.id) && (this.selectedDropDownId === dropDown.id)) ? 1 : '',
									// display: dropDown.onPage === currentPage ? 'block' : 'none'
								}" @click="onClickSelectElement($event, dropDown.id, 'dropdown')" @contextmenu="handleElementRightClick">
									<div v-if="this.selectedElementId === dropDown.id && this.selectedDropDownId === dropDown.id" class="resize-handle top-left" :style="{ backgroundColor : ((this.selectedElementId === dropDown.id) && (this.selectedDropDownId === dropDown.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, dropDown.id, 'top-left', 'dropdown')"></div>
									<div v-if="this.selectedElementId === dropDown.id && this.selectedDropDownId === dropDown.id" class="resize-handle top-right" :style="{ backgroundColor : ((this.selectedElementId === dropDown.id) && (this.selectedDropDownId === dropDown.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, dropDown.id, 'top-right', 'dropdown')"></div>
									<div v-if="this.selectedElementId === dropDown.id && this.selectedDropDownId === dropDown.id" class="resize-handle bottom-left" :style="{ backgroundColor : ((this.selectedElementId === dropDown.id) && (this.selectedDropDownId === dropDown.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, dropDown.id, 'bottom-left', 'dropdown')"></div>
									<div v-if="this.selectedElementId === dropDown.id && this.selectedDropDownId === dropDown.id" class="resize-handle bottom-right" :style="{ backgroundColor : ((this.selectedElementId === dropDown.id) && (this.selectedDropDownId === dropDown.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, dropDown.id, 'bottom-right', 'dropdown')"></div>
									<div v-if="this.selectedElementId === dropDown.id && this.selectedDropDownId === dropDown.id" class="name-selcet-w-pdf d-flex justify-content-center align-items-center h-100 position-relative"
										@mousedown="dragHandle($event, dropDown.id, 'dropdown')" style="cursor: move;">
										<div class="custom-select position-relative" @click="toggleDropdown(dropDown.id)">
											<div class="select-display d-flex justify-content-between align-items-center"
												:style="{
													height: `${dropDown.height}px`,
													width: `${dropDown.width}px`,
												}">
												<span class="selected-list-actv" :style="{
													fontSize: (dropDown.fontSize) + 'px',
												}"></span>
												<img src="/images/down-arrow-new.svg" class="arrow open rotate180">
											</div>
											<ul class="select-options" v-if="dropDown.isOpen">
												<li v-for="option in dropDown.database_tag" :key="option"
													class="is-selected" :value="option" :style="{
													fontSize: (dropDown.fontSize) + 'px'}">
													{{ option }}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div v-for="imageBox in allImages" :key="imageBox.id">
								<div class="image-box" :ref="`imageBox-${imageBox.id}`" v-if="imageBox.isShowImageBox"
									:style="{
									left: `${imageBox.x_loc}px`,
									top: `${imageBox.y_loc}px`,
									width: `${imageBox.width}px`,
									height: `${imageBox.height}px`,
									display: 'block',
									border: ((this.selectedElementId === imageBox.id) && (this.selectedImageBoxId === imageBox.id)) ? '2.5px dashed #000' : '1px dashed #848484',
									zIndex: ((this.selectedElementId === imageBox.id) && (this.selectedImageBoxId === imageBox.id)) ? 1 : '',
									// display: imageBox.onPage === currentPage ? 'block' : 'none'
								}" @click="onClickSelectElement($event, imageBox.id, 'image')" @contextmenu="handleElementRightClick">
									<div v-if="this.selectedElementId === imageBox.id && this.selectedImageBoxId === imageBox.id" class="resize-handle top-left" :style="{ backgroundColor : ((this.selectedElementId === imageBox.id) && (this.selectedImageBoxId === imageBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, imageBox.id, 'top-left', 'image')"></div>
									<div v-if="this.selectedElementId === imageBox.id && this.selectedImageBoxId === imageBox.id" class="resize-handle top-right" :style="{ backgroundColor : ((this.selectedElementId === imageBox.id) && (this.selectedImageBoxId === imageBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, imageBox.id, 'top-right', 'image')"></div>
									<div v-if="this.selectedElementId === imageBox.id && this.selectedImageBoxId === imageBox.id" class="resize-handle bottom-left" :style="{ backgroundColor : ((this.selectedElementId === imageBox.id) && (this.selectedImageBoxId === imageBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, imageBox.id, 'bottom-left', 'image')"></div>
									<div v-if="this.selectedElementId === imageBox.id && this.selectedImageBoxId === imageBox.id" class="resize-handle bottom-right" :style="{ backgroundColor : ((this.selectedElementId === imageBox.id) && (this.selectedImageBoxId === imageBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, imageBox.id, 'bottom-right', 'image')"></div>
									<img :src="imageBox.placeholder" @mousedown="dragHandle($event, imageBox.id, 'image')"
										:style="{
										cursor: 'move',
										boxSizing: 'border-box',
										width: (imageBox.width - 3) + 'px',
										height: (imageBox.height - 3) + 'px',
									}">
								</div>
							</div>
							<div v-for="signatureBox in allSignatures" :key="signatureBox.id">
								<div class="signature-box" :ref="`signatureBox-${signatureBox.id}`"
									v-if="signatureBox.isShowSignatureBox" :style="{
									left: `${signatureBox.x_loc}px`,
									top: `${signatureBox.y_loc}px`,
									width: `${signatureBox.width}px`,
									height: `${signatureBox.height}px`,
									display: 'block',
									border: ((this.selectedElementId === signatureBox.id) && (this.selectedSignatureBoxId === signatureBox.id)) ? '2.5px dashed #000' : '1px dashed #848484',
									zIndex: ((this.selectedElementId === signatureBox.id) && (this.selectedSignatureBoxId === signatureBox.id)) ? 1 : '',
									// display: signatureBox.onPage === currentPage ? 'block' : 'none'
								}" @click="onClickSelectElement($event, signatureBox.id, 'signature')" @contextmenu="handleElementRightClick">
									<div v-if="this.selectedElementId === signatureBox.id && this.selectedSignatureBoxId === signatureBox.id" class="resize-handle top-left" :style="{ backgroundColor : ((this.selectedElementId === signatureBox.id) && (this.selectedSignatureBoxId === signatureBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, signatureBox.id, 'top-left', 'signature')"></div>
									<div v-if="this.selectedElementId === signatureBox.id && this.selectedSignatureBoxId === signatureBox.id" class="resize-handle top-right" :style="{ backgroundColor : ((this.selectedElementId === signatureBox.id) && (this.selectedSignatureBoxId === signatureBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, signatureBox.id, 'top-right', 'signature')"></div>
									<div v-if="this.selectedElementId === signatureBox.id && this.selectedSignatureBoxId === signatureBox.id" class="resize-handle bottom-left" :style="{ backgroundColor : ((this.selectedElementId === signatureBox.id) && (this.selectedSignatureBoxId === signatureBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, signatureBox.id, 'bottom-left', 'signature')"></div>
									<div v-if="this.selectedElementId === signatureBox.id && this.selectedSignatureBoxId === signatureBox.id" class="resize-handle bottom-right" :style="{ backgroundColor : ((this.selectedElementId === signatureBox.id) && (this.selectedSignatureBoxId === signatureBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, signatureBox.id, 'bottom-right', 'signature')">
									</div>
									<img class="signature-image"
										@mousedown="dragHandle($event, signatureBox.id, 'signature')" :style="{
										cursor: 'move',
										width: '100%',
										height: '100%',
										position: 'relative',
										background: '#fff',
									}" />
									<!-- Text indicating signature requirement -->
									<div class="signature-required-text" :style="{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										color: '#888',
										fontSize: '12px',
										textAlign: 'center',
										pointerEvents: 'none'
									}">
										Signature Required
									</div>
								</div>
							</div>
							<div v-for="checkBox in allCheckBoxes" :key="checkBox.id">
								<div class="checkbox-box" :ref="`checkBox-${checkBox.id}`" v-if="checkBox.isShowCheckBox"
									:style="{
									left: checkBox.x_loc + 'px',
									top: checkBox.y_loc + 'px',
									width: (checkBox.width) + 'px',
									height: (checkBox.height) + 'px',
									padding: 0 + 'px',
									display: 'block',
									border: ((this.selectedElementId === checkBox.id) && (this.selectedCheckBoxId === checkBox.id)) ? '2.5px dashed #000' : '1px dashed #848484',
									zIndex: ((this.selectedElementId === checkBox.id) && (this.selectedCheckBoxId === checkBox.id)) ? 1 : '',
									// display: checkBox.onPage === currentPage ? 'block' : 'none'
								}" @click="onClickSelectElement($event, checkBox.id, 'checkbox')" @contextmenu="handleElementRightClick">
									<div v-if="this.selectedElementId === checkBox.id && this.selectedCheckBoxId === checkBox.id"
										class="resize-handle top-left" :style="{ backgroundColor : ((this.selectedElementId === checkBox.id) && (this.selectedCheckBoxId === checkBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, checkBox.id, 'top-left', 'checkbox')"></div>
									<div v-if="this.selectedElementId === checkBox.id && this.selectedCheckBoxId === checkBox.id" 
										class="resize-handle top-right" :style="{ backgroundColor : ((this.selectedElementId === checkBox.id) && (this.selectedCheckBoxId === checkBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, checkBox.id, 'top-right', 'checkbox')"></div>
									<div v-if="this.selectedElementId === checkBox.id && this.selectedCheckBoxId === checkBox.id" 
										class="resize-handle bottom-left" :style="{ backgroundColor : ((this.selectedElementId === checkBox.id) && (this.selectedCheckBoxId === checkBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, checkBox.id, 'bottom-left', 'checkbox')"></div>
									<div v-if="this.selectedElementId === checkBox.id && this.selectedCheckBoxId === checkBox.id" 
										class="resize-handle bottom-right" :style="{ backgroundColor : ((this.selectedElementId === checkBox.id) && (this.selectedCheckBoxId === checkBox.id)) ? '#000' : 'rgb(0, 0, 0, 0.5)' }"
										@mousedown="startResize($event, checkBox.id, 'bottom-right', 'checkbox')"></div>
									<div class="pdf-checkbox" @mousedown="dragHandle($event, checkBox.id, 'checkbox')">
										<input type="checkbox" :checked="checkBox.placeholder"
											class="form-check-input m-0" :style="{
												cursor: 'move',
												height: (checkBox.height - 4) + 'px',
												width: (checkBox.width - 4) + 'px',
												border: '0px !important',
												padding: '0px',
											}" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- <canvas id="pdfCanvas" ref="pdfCanvas" style="width: 100%; height: 100%;"
						@contextmenu="handleCanvasRightClick"></canvas> -->
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import CustomDropDown from '../../base/formFields/CustomDropDown.vue';
import axios from "@/scripts/axios.js";
import { databaseTags } from '../../../databaseTags.js';
import $ from "jquery";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { PDFDocument } from 'pdf-lib';
import ValidationErrorMessageList from '../../base/ValidationErrorMessageList.vue';

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			isOpen: false,
			form: {
				title: null,
				additional_info: null,
				module: null,
				dynamicFields: [],
				annotations: '',
				annotatedPdfBytes: new Uint8Array(),
			},
			moduleList: [
				{ value: "Consultation", title: "Consultation" },
				{ value: "Imaging", title: "Imaging" },
				{ value: "Lab", title: "Lab" },
				{ value: "Others", title: "Others" },
			],
			documentPath: null,

			pdfDoc: null,
			existingPdfBytes: null,
			totalPages: 0,
			currentPage: 1,
			selectedOption: '',

			selectedElement: '',
			selectedElementId: null,
			onClickedSelectElement: null,

			/* Textbox Props */
			nextTextBoxId: 0,
			showTagsList: false,
			filteredTags: [],
			databaseTags,
			allTextBoxes: [],
			selectedTextBox: {},
			selectedTextBoxId: null,
			wrappedTextBoxVal: [],

			/* Dropdown Props */
			nextDropDownId: 0,
			allDropDowns: [],
			selectedDropDown: {},
			selectedDropDownId: null,

			/* Image Props */
			nextImageBoxId: 0,
			allImages: [],
			selectedImageBox: {},
			selectedImageBoxId: null,

			/* Signature Props */
			nextSignatureBoxId: 0,
			allSignatures: [],
			selectedSignatureBox: {},
			selectedSignatureBoxId: null,

			/* CheckBox Props */
			nextCheckBoxId: 0,
			allCheckBoxes: [],
			selectedCheckBox: {},
			selectedCheckBoxId: null,

			isShowLoader: false,

			showContextMenu: false,
			isContextShowFromCanvas: false,
			contextMenuX: 0,
			contextMenuY: 0,

			clipboardElement: null,
			copiedElement: {},
			cutElementBox: false,

			maxFontSize: 18,
			minFontSize: 8,
			addBlankPagesNumbers: [],
			isProcessing: false,

			pageOffsets : [],

			defaultCanvasWidth: null,
			defaultCanvasHeight: null,

			pdfContainerPadding: 10,
			pdfRenderScale: null,
			annotationPdfScale: null,

			pdfContainerWidth: null,
			pdfContainerHeight: null,
			
			pdfFilePath: null,
			isSilentUpdate: false,

			existingAnnotations: {},
			mouseX: null,
			mouseY: null,
			isUsingShortCutKey: false,

			zipAnnotationPngWidth: 850,
			zipAnnotationPngHeight: 1100,

			isDocumentUploadedAsZip: false,

			pngFileWidth: null,
			pngFileHeight: null,
		}
	},
	components: {
		CustomDropDown,
		ValidationErrorMessageList
	},
	validations() {
		return {
			form: {
				title: {
					required: helpers.withMessage("Please enter document title.", required),
				},
				module: {
					required: helpers.withMessage("Please select the module.", required),
				},
			},
		};
	},
	methods: {
		formatTextBoxContent(textBox) {
			if (textBox.database_tag) {
				return `{${textBox.database_tag}}`; // Wrap with curly braces for template module
			}
			return textBox.placeholder;
		},
		async edit() {
			this.$store.state.loader = true;
			if (this.$route.query.file_path) {
				this.documentPath = `${process.env.VUE_APP_STORAGE_URL}/api/assets/${this.$route.query.file_path}`;
				let isFromUploaded = false;
				if (this.$route.query.is_from_uploaded_zip) {
					this.isDocumentUploadedAsZip = true;
					isFromUploaded = this.$route.query.is_from_uploaded_zip;
					// this.pngFileWidth = this.$route.query.file_width;
					// this.pngFileHeight = this.$route.query.file_height;
					this.documentPath = this.documentPath.replace("&is_from_uploaded_zip=true", "");
					// this.documentPath = this.documentPath.replace(/(&file_width=)[^&]*/, "");
					// this.documentPath = this.documentPath.replace(/(&file_height=)[^&]*/, "");
				}
				if (this.documentPath) {
					try {
						const encryptedFilePath = this.$route.query.file_path.split('?')[0];
						if (isFromUploaded) {
							await this.annotationFromUploadedZip(encryptedFilePath, isFromUploaded);
						} else {
							await this.getExistingAnnotations(encryptedFilePath);
						}
					} catch (error) {
						this.$store.state.loader = false;
						this.$filters.moshaToast(error.message || 'Error processing file path', 'error');
					}
					this.$store.state.loader = true;
					if (isFromUploaded) {
						await this.modifyPdf(isFromUploaded);
					} else {
						await this.modifyPdf();
					}
					this.$store.state.loader = false;
				}
			} else {
				axios.post("e-forms/retrieve", { 'id': this.$route.params.template_id })
					.then(async (response) => {
						if (response.data.status === 200) {
							this.form.title = response.data.data.title;
							this.form.additional_info = response.data.data.additional_info;
							this.form.module = response.data.data.module;
							this.pdfFilePath = response.data.data.file_name;
							this.documentPath = this.documentUrl(response.data.data.file_path);
							this.dynamicFields = response.data.data.formFields;

							if (this.documentPath) {
								await this.modifyPdf();
							}
							
							if (this.dynamicFields.blank_pages && Array.isArray(this.dynamicFields.blank_pages)) {
								this.addBlankPagesNumbers = [...this.dynamicFields.blank_pages];
							}
							this.$store.state.loader = false;
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
					})
					.catch(error => {
						this.$store.state.loader = false;
						this.$filters.moshaToast(error.message, "error");
					})
			}
		},
		async annotationFromUploadedZip(encryptedFilePath, isUploadedFrom) {
			await axios.post('/e-forms/get-existing-annotations', {encrypted_path: encryptedFilePath, is_from_uploaded_zip: isUploadedFrom})
				.then((response) => {
					if (response.status == 200) {
						this.$store.state.loader = false;
						this.existingAnnotations = response.data.data.annotations_data;
						
					} else {
						this.$filters.moshaToast(response.data.message, "error");
						this.$store.state.loader = false;
					}
				}).catch(error => {
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		async getExistingAnnotations(encryptedFilePath) {
			await axios.post('/e-forms/get-existing-annotations', {encrypted_path: encryptedFilePath})
				.then((response) => {
					if (response.status == 200) {
						this.$store.state.loader = false;
						this.existingAnnotations = response.data.data.annotations_data;
						
					} else {
						this.$filters.moshaToast(response.data.message, "error");
						this.$store.state.loader = false;
					}
				}).catch(error => {
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		documentUrl(documentPath) {
			// Assuming your documents are stored in the 'public' directory
			return `${process.env.VUE_APP_STORAGE_URL}/e-forms/document/view/${documentPath}`;
		},
		async modifyPdf(isFromUploaded = false) {
			try {
				if (!this.documentPath) {
					throw new Error('Document path is not defined.');
				}

				this.form.annotatedPdfBytes = await fetch(this.documentPath).then(res => res.arrayBuffer());
				this.existingPdfBytes = this.form.annotatedPdfBytes;
				this.pdfDoc = await PDFDocument.load(this.existingPdfBytes);
				await this.pdfDoc.save();

				this.totalPages = this.pdfDoc.getPageCount();
				// this.renderPage(this.currentPage);
				await this.renderAllPages(isFromUploaded);
			} catch (error) {
				console.error('Error modifying PDF:', error);
			}
		},
		async getPdfPageScale(width) {
			const pdfPageWidth = 612;
			let scale = 1;
			const maxScale = 5;

			// Loop to find the appropriate scale
			while (scale <= maxScale) {
				const measureScaleWidth = pdfPageWidth * scale;

				if (measureScaleWidth >= width) {
					return Math.max(scale - 0.1, 1);
				}

				scale += 0.1;
			}

			return maxScale;
		},
		async renderAllPages(isFromUploaded = false) {
			try {
				await this.$nextTick(); // Ensure DOM is updated

				const pdfContainer = await this.$refs.pdfContainer;
				const pdfContainerEle = pdfContainer.getBoundingClientRect();
				this.pdfRenderScale = (pdfContainerEle.width / 612);
				console.log('this.pdfRenderScale:- ', this.pdfRenderScale);

				const pdfCanvas = await this.$refs.pdfCanvas;
				if (!pdfCanvas) {
					console.error('PDF container reference not found.');
					return;
				}
				
				const loadingTask = window.pdfjsLib.getDocument({ data: this.existingPdfBytes });
				const pdf = await loadingTask.promise;

				// Loop through each page in the PDF
				for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
					const page = await pdf.getPage(pageNum);

					const  scale = this.pdfRenderScale;
					const viewport = page.getViewport({ scale });

					const outputScale = Math.max(window.devicePixelRatio, 1); // HiDPI support
					
					const pdfViewDiv = pdfCanvas.querySelector('.pdf-view');
					const canvas = document.createElement('canvas');
					const context = canvas.getContext('2d');
					
					canvas.width = Math.floor(viewport.width * outputScale);
					canvas.height = Math.floor(viewport.height * outputScale);
					// canvas.style.width = Math.floor(viewport.width) + 'px';
					// canvas.style.height = Math.floor(viewport.height) + 'px';
					canvas.style.width = '100%';

					const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

					canvas.style.backgroundColor = '#fff';
					canvas.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.5)';
					// canvas.style.borderRadius = '5px';

					if (pageNum !== this.totalPages) {
						canvas.style.marginBottom = '10px';
						canvas.style.borderRadius = '5px';
					} else {
						canvas.style.borderBottomLeftRadius = '5px';
						canvas.style.borderBottomRightRadius = '5px';
					}

					// Handle right-click event
					canvas.addEventListener('contextmenu', (event) => {
						event.preventDefault();
						this.handleCanvasRightClick(event, pageNum);
					});

					// Handle click to deselect element
					canvas.addEventListener('click', (event) => {
						this.deselectElement(event);  // Call method to deselect element
					});

					const renderContext = {
						canvasContext: context,
						transform,
						viewport,
					};
					await page.render(renderContext).promise;

					pdfCanvas.insertBefore(canvas, pdfViewDiv);

					// Store each page's top offset for later reference
					const offsetTop = canvas.offsetTop;
					const offsetBottom = offsetTop + canvas.offsetHeight;
					const offsetLeft = canvas.offsetLeft;
					const offsetRight = offsetLeft + canvas.offsetWidth;
					this.pageOffsets.push({
						pageNum,
						offsetTop,
						offsetBottom,
						offsetLeft,
						offsetRight,
						height: canvas.height,
						width: canvas.width,
					});
				}
				// console.log('this.pageOffsets:- ', this.pageOffsets);

				await this.renderEditAnnotation(isFromUploaded);

				// this.$store.state.loader = false;
			} catch (error) {
				console.error('Error rendering all pages:', error);
			}
		},
		handleScroll(event) {
			const pdfContainer = event.target;
			const scrollTop = pdfContainer.scrollTop;

			const currentPage = this.pageOffsets.find(({ offsetTop, height }) =>
				scrollTop >= offsetTop && scrollTop < offsetTop + height
			);

			if (currentPage) {
				// if(this.currentPage != currentPage.pageNum) {
				// 	this.selectedOption = '';
				// }
				this.currentPage = currentPage.pageNum;
				console.log('Current page:', this.currentPage);
			}
		},
		async renderPage(pageNumber, from = '') {
			try {
				await this.$nextTick();

				const canvas = this.$refs.pdfCanvas;
				if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
					// await this.retryGetCanvas();
					throw new Error('Canvas element is not found or is not a valid HTMLCanvasElement.');
				}

				const context = canvas.getContext('2d');
				if (!context) {
					throw new Error('Unable to get 2D context of canvas.');
				}

				const loadingTask = window.pdfjsLib.getDocument({ data: this.existingPdfBytes });
				const pdf = await loadingTask.promise;
				const page = await pdf.getPage(pageNumber);

				const viewport = page.getViewport({ scale: 1 });
				canvas.width = viewport.width;
				canvas.height = viewport.height;

				const renderContext = {
					canvasContext: context,
					viewport: viewport,
				};
				await page.render(renderContext).promise;

				if (from != "next" && from != "previous" && from != "add-page") {
					await this.renderEditAnnotation();
				}
				this.$store.state.loader = false;

				if(!this.copiedElement) {
					this.resetSelectedElementObjAndId();
				}
			} catch (error) {
				console.error('Error rendering page:', error);
			}
		},
		async renderSinglePage(pageNum) {
			try {
				const pdfCanvas = this.$refs.pdfCanvas;
				const loadingTask = window.pdfjsLib.getDocument({ data: this.existingPdfBytes });
				const pdf = await loadingTask.promise;

				const page = await pdf.getPage(pageNum);
				const scale = this.pdfRenderScale;
				const viewport = page.getViewport({ scale });
				const outputScale = Math.max(window.devicePixelRatio, 1); // HiDPI support

				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d');

				const pdfViewDiv = await pdfCanvas.querySelector('.pdf-view');

				canvas.width = Math.floor(viewport.width * outputScale);
				canvas.height = Math.floor(viewport.height * outputScale);
				canvas.style.width = '100%';

				const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

				canvas.style.backgroundColor = '#fff';
				canvas.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.5)';
				// canvas.style.borderRadius = '5px';

				if (pageNum !== this.totalPages) {
					canvas.style.marginBottom = '10px';
					canvas.style.borderRadius = '5px';
				} else {
					canvas.style.borderBottomLeftRadius = '5px';
					canvas.style.borderBottomRightRadius = '5px';
				}

				canvas.addEventListener('contextmenu', (event) => {
					event.preventDefault();
					this.handleCanvasRightClick(event, pageNum);
				});

				const renderContext = {
					canvasContext: context,
					transform,
					viewport,
				};

				if (!pdfCanvas) {
					console.error('PDF container reference not found.');
					return;
				}

				await page.render(renderContext).promise;

				pdfCanvas.insertBefore(canvas, pdfViewDiv);

				const offsetTop = canvas.offsetTop;
				const offsetBottom = offsetTop + canvas.offsetHeight;
				const offsetLeft = canvas.offsetLeft;
				const offsetRight = offsetLeft + canvas.offsetWidth;

				this.pageOffsets.push({
					pageNum,
					offsetTop,
					offsetBottom,
					offsetLeft,
					offsetRight,
					height: canvas.height,
					width: canvas.width,
				});

				console.log('New page rendered:', pageNum);

				// Scroll to the newly rendered page
				this.scrollToPage(pageNum);
			} catch (error) {
				console.error('Error rendering single page:', error);
			}
		},
		scrollToPage(pageNum) {
			const pageOffset = this.pageOffsets.find(page => page.pageNum === pageNum);
			if (pageOffset) {
				const pdfContainer = this.$refs.pdfContainer;
				pdfContainer.scrollTop = pageOffset.offsetTop;
			} else {
				console.warn(`Page ${pageNum} not found in offsets.`);
			}
		},
		async renderEditAnnotation(isExisting = false) {
			if(isExisting) {

				// Default PDF dimensions
				// const defaultPdfWidth = 612;
				// const defaultPdfHeight = 792;

				// Calculate the ratio if at least one value is present
				// let ratio = null;
				// if (this.pngFileWidth && !this.pngFileHeight) {
				// 	this.pngFileHeight = (this.pngFileWidth / defaultPdfWidth) * defaultPdfHeight; // Calculate this.pngFileHeight
				// 	ratio = this.pngFileWidth / defaultPdfWidth;
				// } else if (this.pngFileHeight && !this.pngFileWidth) {
				// 	this.pngFileWidth = (this.pngFileHeight / defaultPdfHeight) * defaultPdfWidth; // Calculate this.pngFileWidth
				// 	ratio = this.pngFileHeight / defaultPdfHeight;
				// } else if (this.pngFileWidth && this.pngFileHeight) {
				// 	// If both are provided, calculate the ratio based on width
				// 	ratio = this.pngFileWidth / defaultPdfWidth;
				// }

				// if (ratio !== null) {
				// 	console.log("Ratio:", ratio);
				// } else {
				// 	console.log("Cannot calculate ratio as both fileWidth and fileHeight are null.");
				// }
				
				for (const key in this.existingAnnotations) {
					if (Object.prototype.hasOwnProperty.call(this.existingAnnotations, key)) {
						const exitsAnnotation = this.existingAnnotations[key];
						const annotation = {};

						const annotationPdfScale = 1;
	
						// Ensure annotation is an object before assigning properties
						if (typeof exitsAnnotation === 'object' && typeof exitsAnnotation.coordinates === 'object' && exitsAnnotation.coordinates !== null && exitsAnnotation.page) {
							annotation.x = (this.pdfRenderScale * exitsAnnotation.coordinates.x) / annotationPdfScale;
							annotation.y = (this.pdfRenderScale * exitsAnnotation.coordinates.y) / annotationPdfScale;
							annotation.width = (this.pdfRenderScale * exitsAnnotation.coordinates.width) / annotationPdfScale;
							annotation.height = (this.pdfRenderScale * exitsAnnotation.coordinates.height) / annotationPdfScale;
							annotation.fontSize = this.pdfRenderScale * this.minFontSize;
							annotation.page = exitsAnnotation.pageNum ?? 1;
							if (exitsAnnotation.type == "textbox") {
								await this.addExistingTextBox(null, annotation);
							} else if (exitsAnnotation.type == 'checkbox') {
								await this.addExistingCheckBox(null, annotation);
							} else if (key.includes('image')) {
								await this.dynamicImageUpload(annotation);
							} else if (key.includes('signature')) {
								await this.addSignature(null, annotation);
							} else {
								// console.warn(`Unknown key type in annotation: ${key}`);
							}
						} else if(isExisting) {
							annotation.x = ((this.pageOffsets[0].offsetRight + 10) * exitsAnnotation.x) / this.zipAnnotationPngWidth;
							annotation.y = ((this.pageOffsets[0].offsetBottom + 8) * exitsAnnotation.y) / this.zipAnnotationPngHeight;
							annotation.width = ((this.pageOffsets[0].offsetRight + 10) * exitsAnnotation.width) / this.zipAnnotationPngWidth;
							annotation.height = ((this.pageOffsets[0].offsetBottom + 8) * exitsAnnotation.height) / this.zipAnnotationPngHeight;
							annotation.fontSize = this.pdfRenderScale * this.minFontSize;
							annotation.page = exitsAnnotation.pageNum ?? 1;
							if (exitsAnnotation.type === "textbox" || exitsAnnotation.type === "textarea") {
								annotation.placeholder = exitsAnnotation.placeholder;
								await this.addExistingTextBox(null, annotation);
							} else if (exitsAnnotation.type === 'checkbox') {
								await this.addExistingCheckBox(null, annotation);
							} else if (exitsAnnotation.type === 'image') {
								annotation.placeholder = exitsAnnotation.placeholder;
								await this.addExistingImageBox(annotation);
							} else if (exitsAnnotation.type === 'signature') {
								annotation.placeholder = exitsAnnotation.placeholder;
								await this.addSignature(null, annotation);
							} else {
								// console.warn(`Unknown key type in annotation: ${key}`);
							}
						} else {
							console.warn(`Invalid annotation type for key: ${key}`, annotation);
						}
					}
				}
			} else {
				for (const key in this.dynamicFields) {
					if (Object.prototype.hasOwnProperty.call(this.dynamicFields, key)) {
	
						const annotation = this.dynamicFields[key];
						if(key === 'pdf_scale') {
							this.annotationPdfScale = annotation;
						}
					}
				}
				for (const key in this.dynamicFields) {
					if (Object.prototype.hasOwnProperty.call(this.dynamicFields, key)) {
						const annotation = this.dynamicFields[key];
	
						// Ensure annotation is an object before assigning properties
						if (typeof annotation === 'object' && annotation !== null) {
							annotation.id = key;
	
							annotation.x_loc = (this.pdfRenderScale * annotation.x_loc) / this.annotationPdfScale;
							annotation.y_loc = (this.pdfRenderScale * annotation.y_loc) / this.annotationPdfScale;
							annotation.width = (this.pdfRenderScale * annotation.width) / this.annotationPdfScale;
							annotation.height = (this.pdfRenderScale * annotation.height) / this.annotationPdfScale;
							annotation.fontSize = (this.pdfRenderScale * annotation.fontSize) / this.annotationPdfScale;
							if (key.includes('textBox')) {
								await this.addTextBox(null, annotation);
							} else if (key.includes('dropDown')) {
								await this.addDropDown(null, annotation);
							} else if (key.includes('image')) {
								await this.addImage(null, annotation);
							} else if (key.includes('signature')) {
								await this.addSignature(null, annotation);
							} else if (key.includes('checkBox')) {
								await this.addCheckbox(null, annotation);
							} else {
								console.warn(`Unknown key type in annotation: ${key}`);
							}
						} else {
							console.warn(`Invalid annotation type for key: ${key}`, annotation);
						}
					}
				}
			}
		},
		resetSelectedElementObjAndId() {
			this.selectedTextBox = {};
			this.selectedTextBoxId = null;
			this.selectedDropDown = {};
			this.selectedDropDownId = null;
			this.selectedImageBox = {};
			this.selectedImageBoxId = null;
			this.selectedSignatureBox = {};
			this.selectedSignatureBoxId = null;
			this.selectedCheckBox = {};
			this.selectedCheckBoxId = null;
			this.selectedElement = '';
			this.selectedElementId = null;
		},
		async addBlankPage() {
			if (this.isProcessing) return;

			if (!this.pdfDoc) {
				alert('PDF document is not initialized.');
				return;
			}

			this.isProcessing = true;

			try {
				this.selectedOption = '';
				this.selectedElement = '';
				this.selectedElementId = null;
	
				const lastPageIndex = await this.pdfDoc.getPageCount() - 1;
				const lastPage = this.pdfDoc.getPage(lastPageIndex);
				const { width, height } = lastPage.getSize();
	
				this.pdfDoc.insertPage(lastPageIndex + 1, [width, height]);
				this.totalPages = this.pdfDoc.getPageCount();
				this.currentPage = lastPageIndex + 2;

				this.addBlankPagesNumbers.push(this.currentPage);
	
				this.existingPdfBytes = await this.pdfDoc.save();
	
				await this.renderSinglePage(this.currentPage);
				// await this.renderAllPages();
				// await this.renderPage(this.currentPage, 'add-page');
			} catch (error) {
				console.error('Error while add blank page:', error);
			} finally {
				this.isProcessing = false;
			}
		},
		async removeBlankPage(index) {
			if (this.isProcessing) return;
			if (index < 0 || index >= this.addBlankPagesNumbers.length) {
				console.error('Invalid index for blank page removal.');
				return;
			}

			this.isProcessing = true;

			try {
				const pageNumberToRemove = this.addBlankPagesNumbers[index];

				// Loop through all subsequent pages to adjust their y_loc
				for (let i = pageNumberToRemove - 1; i < this.pageOffsets.length; i++) {
					if(i+1 != this.pageOffsets.length) {
						const currentOffset = this.pageOffsets[i];
						const previousOffset = this.pageOffsets[i - 1];
	
						// Calculate the offset difference between the current and previous page
						const offsetDifference = currentOffset.offsetBottom - previousOffset.offsetBottom;
	
						// Adjust the y_loc of elements on the current page
						await this.adjustElementYLoc(i+1, offsetDifference);
					}
				}

				// Remove elements from the page being deleted
				await this.removeElementOnRemovePage(pageNumberToRemove);

				// Remove the page from the PDF document
				this.addBlankPagesNumbers.forEach((pageNum, i) => {
					if(i > index) {
						this.addBlankPagesNumbers[i] -= 1;
					}
				});
				this.addBlankPagesNumbers.splice(index, 1);
				
				// Adjust only the page numbers after the removed page
				this.pageOffsets.pop();

				// Update total page count and current page
				this.totalPages = await this.pdfDoc.getPageCount();
				this.currentPage = Math.min(this.currentPage, this.totalPages);

				// Remove the corresponding canvas from the DOM
				const pdfContainer = this.$refs.pdfCanvas;
				const canvasToRemove = pdfContainer.querySelectorAll('canvas')[pageNumberToRemove - 1];
				if (canvasToRemove) {
					pdfContainer.removeChild(canvasToRemove);
				}

				this.pdfDoc.removePage(pageNumberToRemove - 1);

				// Save the modified PDF bytes
				this.existingPdfBytes = await this.pdfDoc.save();
			} catch (error) {
				console.error('Error removing page:', error);
			} finally {
				this.isProcessing = false;
			}
		},
		async adjustElementYLoc(pageNumber, offsetDifference) {

			const adjustY = (elementsArray) => {
				for (const element of elementsArray) {
					if (element.onPage > pageNumber) {
						element.y_loc -= offsetDifference; // Update y_loc with the offset difference
						break;
					}
				}
			};

			// Apply the adjustment to each array type
			adjustY(this.allTextBoxes);
			adjustY(this.allDropDowns);
			adjustY(this.allImages);
			adjustY(this.allSignatures);
			adjustY(this.allCheckBoxes);
		},
		async removeElementOnRemovePage(pageNumber) {

			const removeElementsFromPage = (elementType, elementsArray, nextIdProp, resetProperties) => {
				// Find and remove elements matching the pageNumber
				const toRemoveIndexes = [];

				if(elementsArray.length > 0) {

					for (let i = 0; i < elementsArray.length; i++) {
						if (elementsArray[i].onPage === pageNumber) {
							toRemoveIndexes.push(i);
						}
					}
	
					// Remove elements in reverse to avoid index shifting issues
					for (let i = toRemoveIndexes.length - 1; i >= 0; i--) {
						const index = toRemoveIndexes[i];
						elementsArray.splice(index, 1); // Remove from elementsArray
	
						// Decrease the IDs for all elements after the removed index
						for (let j = index; j < elementsArray.length; j++) {
							elementsArray[j].id -= 1;
							elementsArray[j].onPage -= 1;
						}
	
						if(elementType === 'textBox') {
							if (this.wrappedTextBoxVal) this.wrappedTextBoxVal.splice(index, 1);
						}
					}
	
					// Update the next ID counter
					this[nextIdProp] = elementsArray.length > 0 ? elementsArray.length + 1 : 1;
	
					// Reset the element's properties
					resetProperties();
				}
			};

			// Apply the removal logic to each element type
			removeElementsFromPage(
				'textBox',
				this.allTextBoxes,
				'nextTextBoxId',
				this.resetTextBoxProperties.bind(this),
			);

			removeElementsFromPage(
				'dropDown',
				this.allDropDowns,
				'nextDropDownId',
				this.resetDropdownProperties.bind(this)
			);

			removeElementsFromPage(
				'imageBox',
				this.allImages,
				'nextImageBoxId',
				this.resetImageProperties.bind(this)
			);

			removeElementsFromPage(
				'signatureBox',
				this.allSignatures,
				'nextSignatureBoxId',
				this.resetSignatureProperties.bind(this)
			);

			removeElementsFromPage(
				'checkBox',
				this.allCheckBoxes,
				'nextCheckBoxId',
				this.resetCheckboxProperties.bind(this)
			);

			// Clear the selected option after all removals
			this.selectedOption = '';
		},
		updateOnPageValuesAfterRemoval(removedPage) {
			// Function to decrement onPage values of elements on pages after the removed page
			const adjustOnPage = (elementsArray) => {
				for (const element of elementsArray) {
					if (element.onPage > removedPage) {
						element.onPage -= 1; // Decrease the onPage value
					}
				}
			};

			// Adjust for each type of element
			adjustOnPage(this.allTextBoxes);
			adjustOnPage(this.allDropDowns);
			adjustOnPage(this.allImages);
			adjustOnPage(this.allSignatures);
			adjustOnPage(this.allCheckBoxes);

			console.log(`Adjusted onPage values for elements after page ${removedPage}.`);
		},
		// validateTextBoxes() {
		// 	let isValid = true;

		// 	if (Array.isArray(this.allTextBoxes) && this.allTextBoxes.length > 0) {
		// 		for (let textBox of this.allTextBoxes) {
		// 			console.log('!textBox.database_tag || !textBox.placeholder')
		// 			if (!textBox.database_tag && !textBox.placeholder) {
		// 				this.$filters.moshaToast(`Either 'database tag' or 'plain Text' is required for the text box ${textBox.id}.`, "error");
		// 				isValid = false;
		// 				break;
		// 			}

		// 			if (!textBox.text_box_name) {
		// 				this.$filters.moshaToast(`The 'text box name' is required for the text box ${textBox.id}.`, "error");
		// 				isValid = false;
		// 				break;
		// 			}
		// 		}
		// 	}
		// 	return isValid;
		// },
		validateDropdowns() {
			let isValid = true;

			if (Array.isArray(this.allDropDowns) && this.allDropDowns.length > 0) {
				for (let dropDown of this.allDropDowns) {
					if (!dropDown.dropdown_box_name) {
						this.$filters.moshaToast(`The name is required for the dropdown.`, "error");
						isValid = false;
						break;
					}

					if (!dropDown.database_tag) {
						this.$filters.moshaToast(`Options are required for the dropdown.`, "error");
						isValid = false;
						break;
					}
				}
			}
			return isValid;
		},
		validateAll() {
			// const isTextBoxesValid = this.validateTextBoxes();
			// return isTextBoxesValid && isDropdownsValid;
			const isDropdownsValid = this.validateDropdowns();
			return isDropdownsValid;
		},
		onClickSelectElement(event, elementId, elementType) {
			event.preventDefault();
			event.stopPropagation();
			this.isUsingShortCutKey = false;

			// Enable silent update
			this.isSilentUpdate = true;

			// Map element type to corresponding arrays and properties
			const elementMapping = {
				textbox: { array: this.allTextBoxes, selectedId: 'selectedTextBoxId', selectedObj: 'selectedTextBox' },
				dropdown: { array: this.allDropDowns, selectedId: 'selectedDropDownId', selectedObj: 'selectedDropDown' },
				image: { array: this.allImages, selectedId: 'selectedImageBoxId', selectedObj: 'selectedImageBox' },
				signature: { array: this.allSignatures, selectedId: 'selectedSignatureBoxId', selectedObj: 'selectedSignatureBox' },
				checkbox: { array: this.allCheckBoxes, selectedId: 'selectedCheckBoxId', selectedObj: 'selectedCheckBox' },
			};

			const mapping = elementMapping[elementType];
			if (mapping) {
				// Update selected element properties
				this.selectedOption = elementType;
				const element = mapping.array.find(el => el.id === elementId);
				this[mapping.selectedId] = elementId;
				this[mapping.selectedObj] = element;
				this.selectedElement = elementType;
				this.selectedElementId = elementId;

				// Clear other selections
				Object.keys(elementMapping).forEach(type => {
					if (type !== elementType) {
						const { selectedId, selectedObj } = elementMapping[type];
						this[selectedId] = null;
						this[selectedObj] = {};
					}
				});
			}

			// Set active element and add keydown listener
			this.onClickedSelectElement = event.target;
			document.activeElement.blur();
			document.addEventListener('keydown', this.handleKeydown);

			// Disable silent update
			setTimeout(() => {
				this.isSilentUpdate = false;
			}, 300);

		},
		deselectElement() {
			this.selectedOption = "";
			this.selectedTextBoxId = null;
			this.selectedDropDownId = null;
			this.selectedImageBoxId = null;
			this.selectedSignatureBoxId = null;
			this.selectedCheckBoxId = null;

			this.selectedTextBox = {}
			this.selectedDropDown = {};
			this.selectedImageBox = {};
			this.selectedSignatureBox = {};
			this.selectedCheckBox = {};

			this.selectedElement = "";
			this.selectedElementId = null;
			document.removeEventListener('mousemove', this.trackMousePosition);
		},
		handleCanvasRightClick(event) {
			event.preventDefault();

			const canvas = this.$refs.pdfCanvas;
			const canvasRect = canvas.getBoundingClientRect();

			this.isContextShowFromCanvas = true;
			if (event.button === 2) {
				this.contextMenuX = event.clientX - canvasRect.left;
				this.contextMenuY = event.clientY - canvasRect.top;
				this.showContextMenu = true;
				document.addEventListener('click', this.hideContextMenu);
			} else {
				this.showContextMenu = false;
			}
		},
		handleElementRightClick(event) {
			event.preventDefault();

			const canvas = this.$refs.pdfCanvas;
			this.isContextShowFromCanvas = false;
			const canvasRect = canvas.getBoundingClientRect();

			// Show your custom context menu
			this.showContextMenu = true;
			this.contextMenuX = event.clientX - canvasRect.left;
			this.contextMenuY = event.clientY - canvasRect.top;

			// Add a listener to hide the context menu on a click outside
			document.addEventListener('click', this.hideContextMenu);

			// Add a global listener to block the browser's context menu
			document.addEventListener('contextmenu', this.preventDefaultContextMenu);
		},
		preventDefaultContextMenu(event) {
			event.preventDefault();
		},
		hideContextMenu() {
			this.showContextMenu = false;

			document.removeEventListener('contextmenu', this.preventDefaultContextMenu);
			document.removeEventListener('click', this.hideContextMenu);
		},
		handleKeydown(event) {
			this.isUsingShortCutKey = false;

			// List of refs to exclude from shortcut operations
			const excludedRefs = ['plain_text', 'notes', 'module', 'database_tag', 'template_description'];

			// Check if the active element corresponds to any excluded refs
			let isExcludedRef = false;

			for (const refName of excludedRefs) {
				const refElement = this.$refs[refName];
				// Ensure refElement is a DOM node
				const domElement = refElement?.$el || refElement;

				if (domElement instanceof HTMLElement && domElement.contains(document.activeElement)) {
					isExcludedRef = true;
					break;
				}
			}

			if (isExcludedRef) {
				return; // Skip handling shortcut keys if the focus is on excluded refs
			}

			if (event.ctrlKey && event.key === "c") {
				this.isUsingShortCutKey = true;
				this.copyElement();
			} else if (event.ctrlKey && event.key === "x") {
				this.isUsingShortCutKey = true;
				this.cutElement();
			} else if (event.ctrlKey && event.key === "v") {
				this.isUsingShortCutKey = true;
				this.pasteElement(event);
			}

			// Check if the event is a key event
			if (event.type === 'keydown') {
				let refName, ref, element, elementRefs;

				switch (this.selectedElement) {
					case 'textbox':
						refName = `textBox-${this.selectedElementId}`;
						break;
					case 'dropdown':
						refName = `dropDown-${this.selectedElementId}`;
						break;
					case 'image':
						refName = `imageBox-${this.selectedElementId}`;
						break;
					case 'signature':
						refName = `signatureBox-${this.selectedElementId}`;
						break;
					case 'checkbox':
						refName = `checkBox-${this.selectedElementId}`;
						break;
					default:
						refName = null;
						break;
				}

				// If refName is found, get the corresponding element reference
				if (refName) {
					ref = this.$refs[refName];
					element = ref?.$el || ref;
					elementRefs = Array.isArray(element) ? element[0] : element;
				}

				// Check if the right-clicked element is the element itself or a child of it
				if (event.key === 'Backspace' && this.selectedElementId != null) {
					if ((this.onClickedSelectElement === elementRefs || elementRefs?.contains(this.onClickedSelectElement)) && (document.activeElement.tagName !== "INPUT")) {
						this.deleteElement();
						this.showContextMenu = false;
					}
				}
			}
		},
		copyElement() {
			if (this.selectedElementId !== null && this.selectedElementId !== undefined && this.selectedElement != "") {
				switch (this.selectedElement) {
					case 'textbox':
						this.copiedElement = this.allTextBoxes.find(textBox => textBox.id === this.selectedElementId);
						break;
					case 'dropdown':
						this.copiedElement = this.allDropDowns.find(dropDown => dropDown.id === this.selectedElementId);
						break;
					case 'image':
						this.copiedElement = this.allImages.find(image => image.id === this.selectedElementId);
						break;
					case 'signature':
						this.copiedElement = this.allSignatures.find(signature => signature.id === this.selectedElementId);
						break;
					case 'checkbox':
						this.copiedElement = this.allCheckBoxes.find(checkbox => checkbox.id === this.selectedElementId);
						break;
					default:
						console.error("Unknown element type:", this.selectedElement);
						break;
				}
			} else {
				console.warn("No element selected to copy.");
			}

			this.hideContextMenu();
			document.addEventListener('mousemove', this.trackMousePosition);
		},
		trackMousePosition() {
			const canvas = this.$refs.pdfCanvas;
			const canvasRect = canvas.getBoundingClientRect();
			this.mouseX = event.clientX - canvasRect.left;
			this.mouseY = event.clientY - canvasRect.top;
		},
		pasteElement(event = null) {
			if (event) {
				event.preventDefault();
			}

			if (!this.copiedElement || this.selectedElementId == undefined) {
				console.log("No element copied or cut to paste.");
				return;
			}

			if (this.copiedElement && Object.keys(this.copiedElement).length != 0) {
				let newElement = {}; 
				if(this.isUsingShortCutKey) {
					newElement = {
						...this.copiedElement,
						
						x_loc: this.mouseX,
						y_loc: this.mouseY,
						onPage: this.currentPage
					};
				} else {
					newElement = {
						...this.copiedElement,
						
						x_loc: this.contextMenuX,
						y_loc: this.contextMenuY,
						onPage: this.currentPage
					};
				}

				switch (this.selectedElement) {
					case 'textbox':
						newElement.id = this.nextTextBoxId++;
						newElement.text_box_name = `TextBox ${this.nextTextBoxId}`,
						newElement.isShowTextBox = true,
						newElement.isShowTextBoxFields = true,
						this.wrappedTextBoxVal[newElement.id] = this.wrappedTextBoxVal[this.selectedElementId];
						this.allTextBoxes.push(newElement);
						break;
					case 'dropdown':
						newElement.id = this.nextDropDownId++;
						newElement.dropdown_box_name = 'DropDown - ' + this.nextDropDownId,
						newElement.isShowDropDown = true,
						newElement.isShowDropDownFields = true,
						this.allDropDowns.push(newElement);
						break;
					case 'image':
						newElement.id = this.nextImageBoxId++;
						newElement.isShowImageBox = true;
						this.allImages.push(newElement);
						break;
					case 'signature':
						newElement.id = this.nextSignatureBoxId++;
						newElement.isShowSignatureBox = true;
						this.allSignatures.push(newElement);
						break;
					case 'checkbox':
						newElement.id = this.nextCheckBoxId++;
						newElement.isShowCheckBox = true;
						this.allCheckBoxes.push(newElement);
						break;
					default:
						console.error("Unknown element type:", this.selectedElement);
						break;
				}
				// this.copiedElement = {};
				// this.selectedElementId = null;
			} else {
				if(this.cutElementBox) {
					this.cutElementBox = false;
				}
				switch (this.selectedElement) {
					case 'textbox': {
						const index = this.allTextBoxes.findIndex(el => el.id === this.selectedElementId);
						if (index !== -1) {
							this.allTextBoxes[index].isShowTextBox = true;
							this.allTextBoxes[index].onPage = this.currentPage;
							if(this.isUsingShortCutKey) {
								this.allTextBoxes[index].x_loc = this.mouseX;
								this.allTextBoxes[index].y_loc = this.mouseY;
							} else {
								this.allTextBoxes[index].x_loc = this.contextMenuX;
								this.allTextBoxes[index].y_loc = this.contextMenuY;
							}
						}
						break;
					}
					case 'dropdown': {
						const index = this.allDropDowns.findIndex(el => el.id === this.selectedElementId);
						if (index !== -1) {
							this.allDropDowns[index].isShowDropDown = true;
							this.allDropDowns[index].onPage = this.currentPage;
							if(this.isUsingShortCutKey) {
								this.allDropDowns[index].x_loc = this.mouseX;
								this.allDropDowns[index].y_loc = this.mouseY;
							} else {
								this.allDropDowns[index].x_loc = this.contextMenuX;
								this.allDropDowns[index].y_loc = this.contextMenuY;
							}
						}
						break;
					}
					case 'image': {
						const index = this.allImages.findIndex(el => el.id === this.selectedElementId);
						if (index !== -1) {
							this.allImages[index].isShowImageBox = true;
							this.allImages[index].onPage = this.currentPage;
							if(this.isUsingShortCutKey) {
								this.allImages[index].x_loc = this.mouseX;
								this.allImages[index].y_loc = this.mouseY;
							} else {
								this.allImages[index].x_loc = this.contextMenuX;
								this.allImages[index].y_loc = this.contextMenuY;
							}
						}
						break;
					}
					case 'signature': {
						const index = this.allSignatures.findIndex(el => el.id === this.selectedElementId);
						if (index !== -1) {
							this.allSignatures[index].isShowSignatureBox = true;
							this.allSignatures[index].onPage = this.currentPage;
							if(this.isUsingShortCutKey) {
								this.allSignatures[index].x_loc = this.mouseX;
								this.allSignatures[index].y_loc = this.mouseY;
							} else {
								this.allSignatures[index].x_loc = this.contextMenuX;
								this.allSignatures[index].y_loc = this.contextMenuY;
							}
						}
						break;
					}
					case 'checkbox': {
						const index = this.allCheckBoxes.findIndex(el => el.id === this.selectedElementId);
						if (index !== -1) {
							this.allCheckBoxes[index].isShowCheckBox = true;
							this.allCheckBoxes[index].onPage = this.currentPage;
							if(this.isUsingShortCutKey) {
								this.allCheckBoxes[index].x_loc = this.mouseX;
								this.allCheckBoxes[index].y_loc = this.mouseY;
							} else {
								this.allCheckBoxes[index].x_loc = this.contextMenuX;
								this.allCheckBoxes[index].y_loc = this.contextMenuY;
							}
						}
						break;
					}
					default:
						console.error("Unknown element type:", this.selectedElement);
						break;
				}
			}
		},
		cutElement() {
			this.copiedElement = {};
			if (this.selectedElementId == null || this.selectedElement == '') {
				console.warn("No element selected to cut.");
				return;
			}

			switch (this.selectedElement) {
				case 'textbox': {
					const index = this.allTextBoxes.findIndex(el => el.id === this.selectedElementId);
					if (index !== -1) {
						this.allTextBoxes[index].isShowTextBox = false;
						this.cutElementBox = true;
					} else {
						console.warn("Textbox not found with ID:", this.selectedElementId);
					}
					break;
				}
				case 'dropdown': {
					const index = this.allDropDowns.findIndex(el => el.id === this.selectedElementId);
					if (index !== -1) {
						this.allDropDowns[index].isShowDropDown = false;
						this.cutElementBox = true;
					} else {
						console.warn("Dropdown not found with ID:", this.selectedElementId);
					}
					break;
				}
				case 'image': {
					const index = this.allImages.findIndex(el => el.id === this.selectedElementId);
					if (index !== -1) {
						this.allImages[index].isShowImageBox = false;
						this.cutElementBox = true;
					} else {
						console.warn("Image not found with ID:", this.selectedElementId);
					}
					break;
				}
				case 'signature': {
					const index = this.allSignatures.findIndex(el => el.id === this.selectedElementId);
					if (index !== -1) {
						this.allSignatures[index].isShowSignatureBox = false;
						this.cutElementBox = true;
					} else {
						console.warn("Signature not found with ID:", this.selectedElementId);
					}
					break;
				}
				case 'checkbox': {
					const index = this.allCheckBoxes.findIndex(el => el.id === this.selectedElementId);
					if (index !== -1) {
						this.allCheckBoxes[index].isShowCheckBox = false;
						this.cutElementBox = true;
					} else {
						console.warn("Checkbox not found with ID:", this.selectedElementId);
					}
					break;
				}
				default:
					console.error("Unknown element type:", this.selectedElement);
					return;
			}
			document.addEventListener('mousemove', this.trackMousePosition);
		},
		deleteElement() {
			if (this.selectedElement == 'textbox' && this.selectedElementId != null) {
				const index = this.allTextBoxes.findIndex(tb => tb.id === this.selectedElementId);
				if (index !== -1) {
					this.allTextBoxes.splice(index, 1);
					this.wrappedTextBoxVal.splice(index, 1);

					if (this.nextTextBoxId > 1) {
						this.nextTextBoxId -= 1;
					}

					for (let i = index; i < this.allTextBoxes.length; i++) {
						this.allTextBoxes[i].id -= 1;
					}

					this.selectedOption = '';
					this.resetTextBoxProperties();
				}
			} else if (this.selectedElement == 'dropdown' && this.selectedElementId != null) {
				const index = this.allDropDowns.findIndex(ddw => ddw.id === this.selectedElementId);
				if (index !== -1) {
					this.allDropDowns.splice(index, 1);
					for (let i = index; i < this.allDropDowns.length; i++) {
						this.allDropDowns[i].id -= 1;
					}

					if (this.nextDropDownId > 1) {
						this.nextDropDownId -= 1;
					}
					this.selectedOption = '';
					this.resetDropdownProperties();
				}
			} else if (this.selectedElement == 'image' && this.selectedElementId != null) {
				const index = this.allImages.findIndex(im => im.id === this.selectedElementId);
				if (index !== -1) {
					this.allImages.splice(index, 1);
					for (let i = index; i < this.allImages.length; i++) {
						this.allImages[i].id -= 1;
					}

					if (this.nextImageBoxId > 1) {
						this.nextImageBoxId -= 1;
					}
					this.selectedOption = '';
					this.resetImageProperties();
				}
			} else if (this.selectedElement == 'signature' && this.selectedElementId != null) {
				const index = this.allSignatures.findIndex(sb => sb.id === this.selectedElementId);
				if (index !== -1) {
					this.allSignatures.splice(index, 1);
					for (let i = index; i < this.allSignatures.length; i++) {
						this.allSignatures[i].id -= 1;
					}

					if (this.nextSignatureBoxId > 1) {
						this.nextSignatureBoxId -= 1;
					}
					this.selectedOption = '';
					this.resetSignatureProperties();
				}
			} else if (this.selectedElement == 'checkbox' && this.selectedElementId != null) {
				const index = this.allCheckBoxes.findIndex(cb => cb.id === this.selectedElementId);
				if (index !== -1) {
					this.allCheckBoxes.splice(index, 1);
					for (let i = index; i < this.allCheckBoxes.length; i++) {
						this.allCheckBoxes[i].id -= 1;
					}

					if (this.nextCheckBoxId > 1) {
						this.nextCheckBoxId -= 1;
					}
					this.selectedOption = '';
					this.resetCheckboxProperties();
				}
			}
		},
		resetTextBoxProperties() {
			this.selectedElement = '';
			this.selectedElementId = null;

			this.selectedTextBox = {};
			this.selectedTextBoxId = null;

			this.showTagsList = false;
			this.filteredTags = [];
		},
		resetDropdownProperties() {
			this.selectedElement = '';
			this.selectedElementId = null;

			this.selectedDropDown = {};
			this.selectedDropDownId = null;
		},
		resetImageProperties() {
			this.selectedElement = '';
			this.selectedElementId = null;

			this.selectedImageBox = {};
			this.selectedImageBoxId = null;
		},
		resetSignatureProperties() {
			this.selectedElement = '';
			this.selectedElementId = null;

			this.selectedSignatureBox = {};
			this.selectedSignatureBoxId = null;
		},
		resetCheckboxProperties() {
			this.selectedElement = '';
			this.selectedElementId = null;

			this.selectedCheckBox = {};
			this.selectedCheckBoxId = null;
		},
		getElementCenter(top, bottom, left, right, elementWidth, elementHeight) {
			// Calculate horizontal center
			const centerX = ((right - left) / 2) - (elementWidth / 2);
			
			// Calculate vertical center
			const centerY = ((bottom - top) / 2) - (elementHeight / 2);
			
			// Return the center coordinates
			return { centerX, centerY };
		},
		calculateFontSize(maxWidth, maxHeight) {
			let fontSize = Math.min(maxWidth / 10, maxHeight / 2);
			const minFontSize = this.minFontSize;

			if (fontSize < minFontSize) {
				fontSize = minFontSize;
			}

			return fontSize;
		},
		handleItemSelected(obj) {
			if (obj.fieldName === 'dropdown_name') {
				const dropdownIndex = this.allDropDowns.findIndex(
					(dropdown) => dropdown.id === this.selectedDropDownId
				);
				if (dropdownIndex !== -1) {
					this.allDropDowns[dropdownIndex].selectedValue = obj.item.value;
				}
			}
			else {
				this.form.module = obj.item.value;
			}
		},
		// async retryGetCanvas(retryCount = 5) {
		// 	for (let i = 0; i < retryCount; i++) {
		// 		await this.$nextTick();
		// 		const canvas = this.$refs.pdfCanvas;
		// 		if (canvas && canvas instanceof HTMLCanvasElement) {
		// 			return;
		// 		}
		// 		await new Promise(resolve => setTimeout(resolve, 100));
		// 	}
		// 	throw new Error('Failed to retrieve canvas element after multiple attempts.');
		// },
		removeSelectedElement(from) {
			if(from == 'text-box') {
				this.selectedDropDown = {};
				this.selectedDropDownId = null;
				this.selectedImageBox = {};
				this.selectedImageBoxId = null;
				this.selectedSignatureBox = {};
				this.selectedSignatureBoxId = null;
				this.selectedCheckBox = {};
				this.selectedCheckBoxId = null;

			} else if(from == 'drop-down') {
				this.selectedTextBox = {};
				this.selectedTextBoxId = null;
				this.selectedImageBox = {};
				this.selectedImageBoxId = null;
				this.selectedSignatureBox = {};
				this.selectedSignatureBoxId = null;
				this.selectedCheckBox = {};
				this.selectedCheckBoxId = null;

			} else if(from == 'image-box') {
				this.selectedTextBox = {};
				this.selectedTextBoxId = null;
				this.selectedDropDown = {};
				this.selectedDropDownId = null;
				this.selectedSignatureBox = {};
				this.selectedSignatureBoxId = null;
				this.selectedCheckBox = {};
				this.selectedCheckBoxId = null;

			} else if(from == 'signature-box') {
				this.selectedTextBox = {};
				this.selectedTextBoxId = null;
				this.selectedDropDown = {};
				this.selectedDropDownId = null;
				this.selectedImageBox = {};
				this.selectedImageBoxId = null;
				this.selectedCheckBox = {};
				this.selectedCheckBoxId = null;

			} else if(from == 'check-box') {
				this.selectedTextBox = {};
				this.selectedTextBoxId = null;
				this.selectedDropDown = {};
				this.selectedDropDownId = null;
				this.selectedImageBox = {};
				this.selectedImageBoxId = null;
				this.selectedSignatureBox = {};
				this.selectedSignatureBoxId = null;

			}
		},
		async addExistingTextBox(event = null, annotation = {}) {
			if (event) {
				this.resetTextBoxProperties();
			}
			this.selectedOption = 'textbox';

			let isTagReadOnly = false, isTextReadOnly = false;

			// Ensure pdfCanvas ref exists and access the canvas element
			await this.$nextTick();
			const canvasContainer = this.$refs.pdfCanvas;
			if (!canvasContainer || !canvasContainer.children) {
				console.error('Canvas container or children not found:', canvasContainer);
				throw new Error('Canvas container is not properly referenced.');
			}

			const canvas = canvasContainer.children[annotation.page -1];
			if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
				console.error('Canvas not found or invalid for page:', annotation.page);
				throw new Error('At AddTextBox - Canvas element is not found or is not a valid HTMLCanvasElement.');
			}

			const currentPageOffset = this.pageOffsets[annotation.page - 1];
			if (!currentPageOffset) {
				console.error('Page offset not found for page:', annotation.page);
				throw new Error('At AddTextBox - Page offset is undefined.');
			}

			// Define the new text box properties
			const newTextBox = {
				id: this.nextTextBoxId++,
				isShowTextBox: true,
				isShowTextBoxFields: false,
				isDragging: false,
				isResizing: false,
				isPlainTextReadOnly: isTextReadOnly,
				isDatabaseTagReadOnly: isTagReadOnly,
				x_loc: annotation.x + currentPageOffset.offsetLeft - 3,
				y_loc: annotation.page !== 1 ? currentPageOffset.offsetTop + annotation.y : annotation.y + currentPageOffset.offsetTop - 2,
				width: annotation.width,
				height: annotation.height,
				fontSize: this.minFontSize,
				database_tag: '',
				placeholder: (annotation.placeholder && annotation.placeholder !== "") ? annotation.placeholder : "",
				onPage: annotation.page,
				text_box_name: `TextBox ${this.nextTextBoxId}`,
				wrap_text_content: '',
				position: {
					clientX: undefined,
					clientY: undefined,
					dragStartX: 0,
					dragStartY: 0,
					resizeStartX: 0,
					resizeStartY: 0,
					boxStartLeft: 0,
					boxStartTop: 0,
					boxStartWidth: 0,
					boxStartHeight: 0,
					resizeDirection: '',
				}
			};

			this.removeSelectedElement('text-box'); // Deselect any other text box
			// Add the new text box to state
			this.wrappedTextBoxVal = {
				...this.wrappedTextBoxVal,
				[newTextBox.id]: (annotation.placeholder && annotation.placeholder !== "") ? annotation.placeholder : "",
			};
			this.selectedTextBox = newTextBox;
			this.selectedTextBoxId = newTextBox.id;
			this.selectedElement = 'textbox';
			this.selectedElementId = newTextBox.id;

			this.allTextBoxes.push(newTextBox);

			// Log the new text box for debugging
			// this.$nextTick(() => {
			// 	console.log(
			// 		'New TextBox - Style:',
			// 		`left: ${newTextBox.x_loc}px`,
			// 		`top: ${newTextBox.y_loc}px`,
			// 		newTextBox
			// 	);
			// });
		},
		async addExistingCheckBox(_, annotation = {}) {
			this.selectedCheckBox = {};
			this.selectedCheckBoxId = null;
			this.selectedElement = '';
			this.selectedElementId = null;


			this.selectedOption = 'checkbox';
			const checkBoxCurrentPage = annotation.page;

			// Reference the canvas for the current page
			const canvas = this.$refs.pdfCanvas.children[checkBoxCurrentPage - 1];
			if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
				throw new Error('At AddTextBox - Canvas element is not found or is not a valid HTMLCanvasElement.');
			}

			let currentPageOffset = this.pageOffsets[checkBoxCurrentPage - 1];

			const newCheckBox = {
				id: this.nextCheckBoxId++,
				isShowCheckBox: true,
				isDragging: false,
				isResizing: false,
				position: {
					clientX: undefined,
					clientY: undefined,
					dragStartX: 0,
					dragStartY: 0,
					resizeStartX: 0,
					resizeStartY: 0,
					boxStartLeft: 0,
					boxStartTop: 0,
					boxStartWidth: 0,
					boxStartHeight: 0,
					resizeDirection: '',
				},
				x_loc: annotation.x + currentPageOffset.offsetLeft - 3.5,
				y_loc: annotation.page !== 1 ? currentPageOffset.offsetTop + annotation.y : annotation.y + currentPageOffset.offsetTop - 4.5,
				width: annotation.width,
				height: annotation.height,
				placeholder: false,
				onPage: annotation.page,
				isShowOnCurrentPage: this.currentPage === annotation.page,
			};

			// this.removeSelectedElement('check-box');
			this.selectedCheckBox = newCheckBox;
			this.selectedCheckBoxId = newCheckBox.id;
			this.selectedElement = 'checkbox';
			this.selectedElementId = newCheckBox.id;
			this.allCheckBoxes.push(newCheckBox);
			// this.$nextTick(() => {
			// 	console.log('New newCheckBox - Style:- ', `left: ${newCheckBox.x_loc}px`, `top: ${newCheckBox.y_loc}px`, newCheckBox);
			// });
		},
		async addExistingImageBox(annotation) {
		console.log('annotation:- ', annotation);
			this.selectedOption = '';
			const imageBoxCurrentPage = annotation ? annotation.page : this.currentPage;

			const newImageBox = {
				id: this.nextImageBoxId++,
				isShowImageBox: true,
				isDragging: false,
				isResizing: false,
				position: {
					clientX: undefined,
					clientY: undefined,
					dragStartX: 0,
					dragStartY: 0,
					boxStartTop: 0,
					boxStartLeft: 0,
					resizeStartX: 0,
					resizeStartY: 0,
					boxStartWidth: 0,
					boxStartHeight: 0,
					resizeDirection: '',
				},
				imageResizeStart: {
					width: 0,
					height: 0,
					mouseX: 0,
					mouseY: 0,
				},
				placeholder: annotation.placeholder,
				x_loc: annotation.x,
				y_loc: annotation.y,
				width: annotation.width,
				height: annotation.height,
				onPage: imageBoxCurrentPage,
			};

			// if(!isAnnotationPresent) {
			// 	this.selectedImageBox = newImageBox;
			// 	this.selectedImageBoxId = newImageBox.id;
			// 	this.selectedElement = 'image';
			// 	this.selectedElementId = newImageBox.id;
			// }
			this.allImages.push(newImageBox);
			// this.$nextTick(() => {
			// 	console.log(`dynamic ImageBox - X: ${newImageBox.x_loc}, Y: ${newImageBox.y_loc}, Width: ${newImageBox.width}, Height: ${newImageBox.height}`, newImageBox);
			// });
		},
		async addTextBox(event = null, annotation = {}) {
			if (event) {
				this.resetTextBoxProperties();
			}

			const isAnnotationPresent = Object.keys(annotation).length > 0;
			this.selectedOption = isAnnotationPresent ? '' : 'textbox';
			const textboxCurrentPage = isAnnotationPresent ? annotation.pageNum : this.currentPage;
			
			let newX, newY, newWidth, newHeight, isTagReadOnly, isTextReadOnly;
			if(!isAnnotationPresent) {
				newWidth = 120;
				newHeight = 40;

				// Reference the canvas for the current page
				const canvas = this.$refs.pdfCanvas.children[textboxCurrentPage - 1];
				if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
					throw new Error('At AddTextBox - Canvas element is not found or is not a valid HTMLCanvasElement.');
				}
				const canvasRect = canvas.getBoundingClientRect();

				let currentPageOffset = this.pageOffsets[textboxCurrentPage - 1];

				const { centerX: leftOffset, centerY: topOffset } = this.getElementCenter(currentPageOffset.offsetTop, currentPageOffset.offsetBottom, canvasRect.left, canvasRect.right, newWidth, newHeight);
				newX = leftOffset;
				newY = textboxCurrentPage != 1 ? currentPageOffset.offsetTop + topOffset : topOffset;

				isTagReadOnly = false;
				isTextReadOnly = false;
			} else {
				isTagReadOnly = annotation.database_tag ? false : true;
				isTextReadOnly = annotation.placeholder ? false : true;
				if(!annotation.database_tag && !annotation.placeholder) {
					isTagReadOnly = false;
					isTextReadOnly = false;
				}
			}
			const newTextBox = {
				id: this.nextTextBoxId++,
				isShowTextBox: true,
				isShowTextBoxFields: isAnnotationPresent ? false : true,
				isDragging: false,
				isResizing: false,
				isPlainTextReadOnly: isTextReadOnly,
				isDatabaseTagReadOnly: isTagReadOnly,
				x_loc: isAnnotationPresent ? annotation.x_loc : newX,
				y_loc: isAnnotationPresent ? annotation.y_loc : newY,
				width: isAnnotationPresent ? annotation.width : newWidth,
				height: isAnnotationPresent ? annotation.height : newHeight,
				fontSize: isAnnotationPresent ? annotation.fontSize : this.minFontSize,
				database_tag: isAnnotationPresent ? annotation.database_tag : '',
				placeholder: isAnnotationPresent ? annotation.placeholder : '',
				onPage: textboxCurrentPage,
				text_box_name: isAnnotationPresent ? annotation.field_type : `TextBox ${this.nextTextBoxId}`,
				wrap_text_content: '',
				position: {
					clientX: undefined,
					clientY: undefined,
					dragStartX: 0,
					dragStartY: 0,
					resizeStartX: 0,
					resizeStartY: 0,
					boxStartLeft: 0,
					boxStartTop: 0,
					boxStartWidth: 0,
					boxStartHeight: 0,
					resizeDirection: '',
				}
			};
			
			let result = {};
			if(newTextBox.database_tag) {
				newTextBox.wrap_text_content = newTextBox.database_tag;
				this.wrappedTextBoxVal[newTextBox.id] = newTextBox.database_tag;
			} else if (newTextBox.placeholder) {
				result = await this.wrapText(newTextBox.placeholder, newTextBox.width, newTextBox.height, this.maxFontSize);

				newTextBox.wrap_text_content = result.wrappedText;
				newTextBox.fontSize = result.fontSize;
				this.wrappedTextBoxVal[newTextBox.id] = result.wrappedText;
				newTextBox.wrap_text_array = result.wrapTextArr;
			} else {
				this.wrappedTextBoxVal[newTextBox.id] = '';
			}

			if(!isAnnotationPresent) {
				this.selectedTextBox = newTextBox;
				this.selectedTextBoxId = newTextBox.id;
				this.selectedElement = 'textbox';
				this.selectedElementId = newTextBox.id;
				this.removeSelectedElement('text-box');
			}

			this.allTextBoxes.push(newTextBox);
			this.$nextTick(() => {
				// console.log('New TextBox - Style:- ', `left: ${newTextBox.x_loc}px`, `top: ${newTextBox.y_loc}px`, newTextBox);
			});
		},
		async addDropDown(event = null, annotation = {}) {
			if (event) {
				this.resetDropdownProperties();
			}

			const isAnnotationPresent = Object.keys(annotation).length > 0;

			this.selectedOption = isAnnotationPresent ? '' : 'dropdown';
			const dropdownCurrentPage = isAnnotationPresent ? annotation.pageNum : this.currentPage;

			this.$nextTick(() => {
				let newX, newY, newWidth, newHeight;

				if(!isAnnotationPresent) {
					newWidth = 120;
					newHeight = 40;

					// Reference the canvas for the current page
					const canvas = this.$refs.pdfCanvas.children[dropdownCurrentPage - 1];
					if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
						throw new Error('At AddTextBox - Canvas element is not found or is not a valid HTMLCanvasElement.');
					}
					const canvasRect = canvas.getBoundingClientRect();

					let currentPageOffset = this.pageOffsets[dropdownCurrentPage - 1];

					const { centerX: leftOffset, centerY: topOffset } = this.getElementCenter(currentPageOffset.offsetTop, currentPageOffset.offsetBottom, canvasRect.left, canvasRect.right, newWidth, newHeight);
					newX = leftOffset;
					newY = dropdownCurrentPage != 1 ? currentPageOffset.offsetTop + topOffset : topOffset;
				}

				const newDropDown = {
					id: this.nextDropDownId++,
					isShowDropDown: true,
					isShowDropDownFields: isAnnotationPresent ? false : true,
					isOpen: false,
					isDragging: true,
					isResizing: true,
					dropdown_box_name: isAnnotationPresent ? annotation.field_type : 'DropDown - ' + this.nextDropDownId,
					database_tag: isAnnotationPresent ? annotation.database_tag : '',
					placeholder: isAnnotationPresent ? annotation.placeholder : '',
					fontSize: this.minFontSize,
					position: {
						clientX: undefined,
						clientY: undefined,
						dragStartX: 0,
						dragStartY: 0,
						boxStartTop: 0,
						boxStartLeft: 0,
						resizeStartX: 0,
						resizeStartY: 0,
						boxStartWidth: 0,
						boxStartHeight: 0,
						resizeDirection: '',
					},
					x_loc: isAnnotationPresent ? annotation.x_loc : newX,
					y_loc: isAnnotationPresent ? annotation.y_loc : newY,
					width: isAnnotationPresent ? annotation.width : 120,
					height: isAnnotationPresent ? annotation.height : 40,
					onPage: dropdownCurrentPage,
				};

				if(!isAnnotationPresent) {
					this.selectedDropDown = newDropDown;
					this.selectedDropDownId = newDropDown.id;
					this.selectedElement = 'dropdown';
					this.selectedElementId = newDropDown.id;
					this.removeSelectedElement('drop-down');
				}
				this.allDropDowns.push(newDropDown);
				this.$nextTick(() => {
					// console.log('New Dropdown - Style:- ', `left: ${newDropDown.x_loc}`, `top: ${newDropDown.y_loc}`, newDropDown);
				});
			});
		},
		addImage(_, annotation = {}) {

			this.selectedOption = 'image';
			const isAnnotationPresent = Object.keys(annotation).length > 0;

			if (isAnnotationPresent) {
				this.dynamicImageUpload(annotation);
			} else {
				const input = document.createElement('input');
				input.type = 'file';
				input.accept = 'image/*';
				input.addEventListener('change', this.handleImageUpload);
				input.click();
			}
		},
		handleImageUpload(event) {
			this.resetImageProperties();

			const file = event.target.files[0];
			if (file) {
				const reader = new FileReader();
				reader.onload = (e) => {
					// Reference the canvas for the current page
					const canvas = this.$refs.pdfCanvas.children[this.currentPage - 1];
					if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
						throw new Error('At AddTextBox - Canvas element is not found or is not a valid HTMLCanvasElement.');
					}
					const canvasRect = canvas.getBoundingClientRect();

					let currentPageOffset = this.pageOffsets[this.currentPage - 1];
					const newWidth = 120;
					const newHeight = 120;
					const { centerX: leftOffset, centerY: topOffset } = this.getElementCenter(currentPageOffset.offsetTop, currentPageOffset.offsetBottom, canvasRect.left, canvasRect.right, newWidth, newHeight);
					const newX = leftOffset;
					const newY = this.currentPage != 1 ? currentPageOffset.offsetTop + topOffset : topOffset;

					this.selectedOption = 'image';

					const newImageBox = {
						id: this.nextImageBoxId++,
						isShowImageBox: true,
						isDragging: false,
						isResizing: false,
						position: {
							clientX: undefined,
							clientY: undefined,
							dragStartX: 0,
							dragStartY: 0,
							boxStartTop: 0,
							boxStartLeft: 0,
							resizeStartX: 0,
							resizeStartY: 0,
							boxStartWidth: 0,
							boxStartHeight: 0,
							resizeDirection: '',
						},
						imageResizeStart: {
							width: 0,
							height: 0,
							mouseX: 0,
							mouseY: 0,
						},
						placeholder: '',
						x_loc: newX,
						y_loc: newY,
						width: newWidth,
						height: newHeight,
						onPage: this.currentPage,
					};

					this.selectedImageBox = newImageBox;
					this.selectedImageBoxId = newImageBox.id;
					this.selectedElement = 'image';
					this.selectedElementId = newImageBox.id;
					this.removeSelectedElement('image-box');
					this.allImages.push(newImageBox);

					newImageBox.placeholder = e.target.result;
					this.$nextTick(() => {
						this.$nextTick(() => {
							// console.log(`ImageBox x_loc: ${newImageBox.x_loc}, y_loc: ${newImageBox.y_loc}, Width: ${newImageBox.width}, Height: ${newImageBox.height}`, newImageBox);
						});
					});
				};
				reader.readAsDataURL(file);
			}
		},
		dynamicImageUpload(annotation) {
			this.selectedOption = '';
			const imageBoxCurrentPage = annotation ? annotation.pageNum : this.currentPage;

			const newImageBox = {
				id: this.nextImageBoxId++,
				isShowImageBox: true,
				isDragging: false,
				isResizing: false,
				position: {
					clientX: undefined,
					clientY: undefined,
					dragStartX: 0,
					dragStartY: 0,
					boxStartTop: 0,
					boxStartLeft: 0,
					resizeStartX: 0,
					resizeStartY: 0,
					boxStartWidth: 0,
					boxStartHeight: 0,
					resizeDirection: '',
				},
				imageResizeStart: {
					width: 0,
					height: 0,
					mouseX: 0,
					mouseY: 0,
				},
				placeholder: annotation.placeholder,
				x_loc: annotation.x_loc,
				y_loc: annotation.y_loc,
				width: annotation.width,
				height: annotation.height,
				onPage: imageBoxCurrentPage,
			};

			// if(!isAnnotationPresent) {
			// 	this.selectedImageBox = newImageBox;
			// 	this.selectedImageBoxId = newImageBox.id;
			// 	this.selectedElement = 'image';
			// 	this.selectedElementId = newImageBox.id;
			// }
			this.allImages.push(newImageBox);
			this.$nextTick(() => {
				// console.log(`dynamic ImageBox - X: ${newImageBox.x_loc}, Y: ${newImageBox.y_loc}, Width: ${newImageBox.width}, Height: ${newImageBox.height}`, newImageBox);
			});
		},
		async addSignature(_, annotation = {}) {
			this.resetSignatureProperties();
			try {
				const isAnnotationPresent = Object.keys(annotation).length > 0;

				this.selectedOption = isAnnotationPresent ? '' : 'signature';
				const signatureBoxCurrentPage = isAnnotationPresent ? annotation.pageNum : this.currentPage;

				let newX, newY, newWidth, newHeight;
				if(!isAnnotationPresent) {
					newWidth = 120;
					newHeight = 40;

					// Reference the canvas for the current page
					const canvas = this.$refs.pdfCanvas.children[signatureBoxCurrentPage - 1];
					if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
						throw new Error('At AddTextBox - Canvas element is not found or is not a valid HTMLCanvasElement.');
					}
					const canvasRect = canvas.getBoundingClientRect();

					let currentPageOffset = this.pageOffsets[signatureBoxCurrentPage - 1];

					const { centerX: leftOffset, centerY: topOffset } = this.getElementCenter(currentPageOffset.offsetTop, currentPageOffset.offsetBottom, canvasRect.left, canvasRect.right, newWidth, newHeight);
					newX = leftOffset;
					newY = signatureBoxCurrentPage != 1 ? currentPageOffset.offsetTop + topOffset : topOffset;
				}

				// const authUserRole = localStorage.getItem('authUserRole');
				// const clinicId = localStorage.getItem('clinicId');
				const userId = isAnnotationPresent ? annotation.database_tag : localStorage.getItem('userId');

				// if (!authUserRole || !clinicId || !userId) {
				// 	throw new Error('Missing required parameters.');
				// }

				// if (authUserRole === 'Doctor' || authUserRole === 'Nurse') {
					// this.$store.state.loader = true;
					// const response = await axios.post('user/get-signature', { clinicId, userId });

					// let signatureSrc = response.data.data.base64_sign;
					// // Ensure the signatureSrc has the correct format
					// if (!signatureSrc.startsWith('data:image/png;base64,')) {
					// 	console.error('Invalid base64 signature format');
					// 	return;
					// }
					// let signatureSrc = '';
					const signatureBox = {
						id: this.nextSignatureBoxId++,
						isShowSignatureBox: true,
						isDragging: false,
						isResizing: false,
						position: {
							clientX: undefined,
							clientY: undefined,
							dragStartX: 0,
							dragStartY: 0,
							resizeStartX: 0,
							resizeStartY: 0,
							boxStartLeft: 0,
							boxStartTop: 0,
							boxStartWidth: 0,
							boxStartHeight: 0,
							resizeDirection: '',
						},
						database_tag : userId,
						// src: signatureSrc,
						x_loc: isAnnotationPresent? (annotation.x_loc !== undefined ? annotation.x_loc : annotation.x) : newX,
						y_loc: isAnnotationPresent?  (annotation.y_loc !== undefined ? annotation.y_loc : annotation.y) : newY,
						width: isAnnotationPresent? annotation.width : newWidth,
						height: isAnnotationPresent? annotation.height : newHeight,
						onPage: isAnnotationPresent ? (annotation.pageNum ?? 1) : signatureBoxCurrentPage,
					};

					if(!isAnnotationPresent) {
						this.selectedSignatureBox = signatureBox;
						this.selectedSignatureBoxId = signatureBox.id;
						this.selectedElement = 'signature';
						this.selectedElementId = signatureBox.id;
						this.removeSelectedElement('signature-box');
					}
					this.allSignatures.push(signatureBox);
					this.$nextTick(() => {
						// this.$store.state.loader = false;
						console.log('New signatures - Style:- ', `left: ${signatureBox.x_loc}px`, `top: ${signatureBox.y_loc}px`, signatureBox);
					});
				// } else {
				// 	console.log('User role is not Doctor or Nurse. No signature requested.');
				// }
			} catch (error) {
				console.error('Error getting signature:', error);
			}
		},
		async addCheckbox(_, annotation = {}) {
			this.selectedCheckBox = {};
			this.selectedCheckBoxId = null;
			this.selectedElement = '';
			this.selectedElementId = null;

			const isAnnotationPresent = Object.keys(annotation).length > 0;

			this.selectedOption = isAnnotationPresent ? '' : 'checkbox';
			const checkBoxCurrentPage = isAnnotationPresent ? annotation.pageNum : this.currentPage;

			// Reference the canvas for the current page
			const canvas = this.$refs.pdfCanvas.children[checkBoxCurrentPage - 1];
			if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
				throw new Error('At AddTextBox - Canvas element is not found or is not a valid HTMLCanvasElement.');
			}
			const canvasRect = canvas.getBoundingClientRect();

			let currentPageOffset = this.pageOffsets[checkBoxCurrentPage - 1];

			let newX, newY, newWidth, newHeight;
			
			// if(isAnnotationPresent) {
			// 	newX = annotation.ele_x_loc;
			// 	newY = annotation.ele_y_loc;
			// 	newWidth = annotation.ele_width;
			// 	newHeight = annotation.ele_height;
			// } else {
			// 	newWidth = 60;
			// 	newHeight = 60;
			// 	const { centerX: leftOffset, centerY: topOffset } = this.getElementCenter(canvasRect.top, canvasRect.bottom, canvasRect.left, canvasRect.right, newWidth, newHeight);
			// 	newX = canvasRect.left + leftOffset;
			// 	newY = canvasRect.top + topOffset;
			// }

			if(!isAnnotationPresent) {
				newWidth = 30;
				newHeight = 30;
				const { centerX: leftOffset, centerY: topOffset } = this.getElementCenter(currentPageOffset.offsetTop, currentPageOffset.offsetBottom, canvasRect.left, canvasRect.right, newWidth, newHeight);
				newX = leftOffset;
				newY = checkBoxCurrentPage != 1 ? currentPageOffset.offsetTop + topOffset : topOffset;
			}

			const newCheckBox = {
				id: this.nextCheckBoxId++,
				isShowCheckBox: true,
				isDragging: false,
				isResizing: false,
				position: {
					clientX: undefined,
					clientY: undefined,
					dragStartX: 0,
					dragStartY: 0,
					resizeStartX: 0,
					resizeStartY: 0,
					boxStartLeft: 0,
					boxStartTop: 0,
					boxStartWidth: 0,
					boxStartHeight: 0,
					resizeDirection: '',
				},
				x_loc: isAnnotationPresent ? annotation.x_loc : newX,
				y_loc: isAnnotationPresent ? annotation.y_loc : newY,
				width: isAnnotationPresent ? annotation.width : newWidth,
				height: isAnnotationPresent ? annotation.height : newHeight,
				placeholder: isAnnotationPresent ? annotation.placeholder : false,
				onPage: isAnnotationPresent ? annotation.pageNum : checkBoxCurrentPage,
				isShowOnCurrentPage: this.currentPage === checkBoxCurrentPage,
			};

			if(!isAnnotationPresent) {
				this.selectedCheckBox = newCheckBox;
				this.selectedCheckBoxId = newCheckBox.id;
				this.selectedElement = 'checkbox';
				this.selectedElementId = newCheckBox.id;
				this.removeSelectedElement('check-box');
			}
			this.allCheckBoxes.push(newCheckBox);
			this.$nextTick(() => {
				// console.log('New newCheckBox - Style:- ', `left: ${newCheckBox.x_loc}px`, `top: ${newCheckBox.y_loc}px`, newCheckBox);
			});
		},
		toggleCheckbox(id) {
			const checkBox = this.allCheckBoxes.find(cb => cb.id === id);
			if (checkBox) {
				checkBox.placeholder = !checkBox.placeholder;
			} else {
				console.error(`Checkbox with id ${id} not found.`);
			}
		},
		dragHandle(event, elementId, elementType) {
			event.preventDefault();
			this.onClickSelectElement(event, elementId, elementType);
			if (elementType === 'textbox') {
				this.selectedOption = 'textbox';
				const textBox = this.allTextBoxes.find(tb => tb.id === elementId);
				this.selectedTextBoxId = elementId;
				this.selectedTextBox = textBox;
				this.selectedElement = 'textbox';
				this.selectedElementId = elementId;
				if (textBox) {
					textBox.isDragging = true;
					textBox.position.clientX = event.clientX;
					textBox.position.clientY = event.clientY;
					textBox.position.dragStartX = event.clientX;
					textBox.position.dragStartY = event.clientY;
					textBox.position.boxStartLeft = textBox.x_loc;
					textBox.position.boxStartTop = textBox.y_loc;
					document.addEventListener('mousemove', (e) => this.handleMouseMove(textBox, e, elementType));
					document.addEventListener('mouseup', () => this.handleMouseUp(textBox, elementType));
				}
			} else if (elementType === 'dropdown') {
				this.selectedOption = 'dropdown';
				const dropDown = this.allDropDowns.find(dw => dw.id === elementId);
				this.selectedDropDownId = elementId;
				this.selectedDropDown = dropDown;
				this.selectedElement = 'dropdown';
				this.selectedElementId = elementId;

				if (dropDown) {
					dropDown.isDragging = true;
					dropDown.position.clientX = event.clientX;
					dropDown.position.clientY = event.clientY;
					dropDown.position.dragStartX = event.clientX
					dropDown.position.dragStartY = event.clientY
					dropDown.position.boxStartLeft = dropDown.x_loc;
					dropDown.position.boxStartTop = dropDown.y_loc;
					document.addEventListener('mousemove', (e) => this.handleMouseMove(dropDown, e, elementType));
					document.addEventListener('mouseup', () => this.handleMouseUp(dropDown, elementType));
				}
			} else if (elementType === 'image') {
				this.selectedOption = 'image';
				const imageBox = this.allImages.find(im => im.id === elementId);
				this.selectedImageBoxId = elementId;
				this.selectedImageBox = imageBox;
				this.selectedElement = 'image';
				this.selectedElementId = elementId;

				if (imageBox) {
					imageBox.isDragging = true;
					imageBox.position.clientX = event.clientX;
					imageBox.position.clientY = event.clientY;
					imageBox.position.dragStartX = event.clientX;
					imageBox.position.dragStartY = event.clientY;
					imageBox.position.boxStartLeft = imageBox.x_loc;
					imageBox.position.boxStartTop = imageBox.y_loc;
					document.addEventListener('mousemove', (e) => this.handleMouseMove(imageBox, e, elementType));
					document.addEventListener('mouseup', () => this.handleMouseUp(imageBox, elementType));
				}
			} else if (elementType === 'signature') {
				this.selectedOption = 'signature';
				const signatureBox = this.allSignatures.find(sb => sb.id === elementId);
				this.selectedSignatureBoxId = elementId;
				this.selectedSignatureBox = signatureBox;
				this.selectedElement = 'signature';
				this.selectedElementId = elementId;

				if (signatureBox) {
					signatureBox.isDragging = true;
					signatureBox.position.clientX = event.clientX;
					signatureBox.position.clientY = event.clientY;
					signatureBox.position.dragStartX = event.clientX;
					signatureBox.position.dragStartY = event.clientY;
					signatureBox.position.boxStartLeft = signatureBox.x_loc;
					signatureBox.position.boxStartTop = signatureBox.y_loc;
					document.addEventListener('mousemove', (e) => this.handleMouseMove(signatureBox, e, elementType));
					document.addEventListener('mouseup', () => this.handleMouseUp(signatureBox, elementType));
				}
			} else if (elementType === 'checkbox') {
				this.selectedOption = 'checkbox';
				const checkBox = this.allCheckBoxes.find(cb => cb.id === elementId);
				this.selectedCheckBoxId = elementId;
				this.selectedCheckBox = checkBox;
				this.selectedElement = 'checkbox';
				this.selectedElementId = elementId;

				if (checkBox) {
					checkBox.isDragging = true;
					checkBox.position.clientX = event.clientX;
					checkBox.position.clientY = event.clientY;
					checkBox.position.dragStartX = event.clientX;
					checkBox.position.dragStartY = event.clientY;
					checkBox.position.boxStartLeft = checkBox.x_loc;
					checkBox.position.boxStartTop = checkBox.y_loc;
					document.addEventListener('mousemove', (e) => this.handleMouseMove(checkBox, e, elementType));
					document.addEventListener('mouseup', () => this.handleMouseUp(checkBox, elementType));
				}
			}
		},
		handleMouseMove(getElement, event, elementType) {
			event.preventDefault();

			// Early exit if element is not available or not being dragged
			if (!getElement || !getElement.isDragging) return;

			// Get the current page offset based on the active page
			const currentPageOffset = this.pageOffsets[getElement.onPage - 1];
			if (!currentPageOffset) {
				console.error('Page offset not found for the current page.');
				return;
			}

			const currentCanvas = this.$refs.pdfCanvas.children[getElement.onPage - 1];

			// Ensure the current element is a valid canvas
			if (!(currentCanvas instanceof HTMLCanvasElement)) {
				console.warn('Current element is not a canvas.');
				return;
			}

			const canvasRect = currentCanvas.getBoundingClientRect();
			// const scaleX = currentCanvas.width / canvasRect.width; 
			// const scaleY = currentCanvas.height / canvasRect.height;

			// Helper to apply boundary constraints using all sides
			const applyBoundaryConstraints = (x, y, width, height) => {
				const constrainedX = Math.max(
					currentPageOffset.offsetLeft,
					Math.min(x, currentPageOffset.offsetRight - width)
				);

				const constrainedY = Math.max(
					currentPageOffset.offsetTop,
					Math.min(y, currentPageOffset.offsetBottom - height)
				);

				return { constrainedX, constrainedY };
			};

			// Helper to get mouse coordinates relative to the canvas
			const getEventCoordinates = (event) => {
				// const x = (event.clientX - canvasRect.left) * scaleX;
				// const y = (event.clientY - canvasRect.top + currentPageOffset.offsetTop) * scaleY;
				const x = (event.clientX - canvasRect.left);
				const y = (event.clientY - canvasRect.top + currentPageOffset.offsetTop);
				return { x, y };
			};

			const { x: mouseX, y: mouseY } = getEventCoordinates(event);

			// Move the element based on type and apply boundary constraints
			const handleElementMove = (element) => {
				const newElementX = mouseX - element.width / 2;
				const newElementY = mouseY - element.height / 2;

				// console.log(`Calculated New Position - NewX: ${newElementX}, NewY: ${newElementY}`);

				const { constrainedX, constrainedY } = applyBoundaryConstraints(
					newElementX, newElementY, element.width, element.height
				);

				// Update the element's position with the constrained values
				element.x_loc = constrainedX;
				element.y_loc = constrainedY;

				// console.log(`Updated Element Position - X: ${element.x_loc}, Y: ${element.y_loc}`);
				this.updateElementPosition(element, elementType);
			};

			const movableElementTypes = ["textbox", "dropdown", "image", "signature", "checkbox"];
			if (movableElementTypes.includes(elementType)) {
				handleElementMove(getElement);
			} else {
				console.warn(`Element type "${elementType}" is not supported for dragging.`);
			}
		},
		handleMouseUp(element) {
			if (!element.isDragging) return;
			element.isDragging = false;
			document.removeEventListener('mousemove', (e) => this.handleMouseMove(element, e));
			document.removeEventListener('mouseup', () => this.handleMouseUp(element));
		},
		startResize(event, elementId, direction, elementType) {
			event.preventDefault();
			let targetElement, targetArray;

			switch (elementType) {
				case 'textbox':
					targetArray = this.allTextBoxes;
					targetElement = targetArray.find(tb => tb.id === elementId);
					break;
				case 'dropdown':
					targetArray = this.allDropDowns;
					targetElement = targetArray.find(ddw => ddw.id === elementId);
					break;
				case 'image':
					targetArray = this.allImages;
					targetElement = targetArray.find(im => im.id === elementId);
					break;
				case 'signature':
					targetArray = this.allSignatures;
					targetElement = targetArray.find(isb => isb.id === elementId);
					break;
				case 'checkbox':
					targetArray = this.allCheckBoxes;
					targetElement = targetArray.find(cb => cb.id === elementId);
					break;
				default:
					console.warn(`Element type "${elementType}" is not supported for resizing.`);
					return;
			}

			if (!targetElement) return;

			targetElement.isResizing = true;
			targetElement.position.resizeDirection = direction;
			targetElement.position.resizeStartX = event.clientX;
			targetElement.position.resizeStartY = event.clientY;
			targetElement.position.boxStartWidth = targetElement.width;
			targetElement.position.boxStartHeight = targetElement.height;
			targetElement.position.boxStartLeft = targetElement.x_loc;
			targetElement.position.boxStartTop = targetElement.y_loc;

			this[targetArray] = targetArray.map(el =>
				el.id === elementId ? { ...targetElement } : el
			);

			this.currentResizeMouseMoveHandler = (e) => this.resizeMouseMove(targetElement, e, elementType);
			this.currentResizeMouseUpHandler = (e) => this.resizeMouseUp(targetElement, e, elementType);

			document.addEventListener('mousemove', this.currentResizeMouseMoveHandler);
			document.addEventListener('mouseup', this.currentResizeMouseUpHandler);
		},
		resizeMouseMove(getElement, event, elementType) {
			if (getElement.isResizing) {
				const canvas = this.$refs.pdfCanvas;
				const canvasRect = canvas.getBoundingClientRect();

				const deltaX = event.clientX - getElement.position.resizeStartX;
				const deltaY = event.clientY - getElement.position.resizeStartY;

				let newWidth, newHeight, newLeft, newTop;

				const currentPageOffset = this.pageOffsets[getElement.onPage - 1]; // Page-specific boundary

				switch (getElement.position.resizeDirection) {
					case 'top-left':
						newWidth = Math.min(
							Math.max(20, getElement.position.boxStartWidth - deltaX),
							currentPageOffset.offsetRight - getElement.position.boxStartLeft
						);
						newHeight = Math.max(12, getElement.position.boxStartHeight - deltaY);
						newLeft = getElement.position.boxStartLeft + deltaX;
						newTop = getElement.position.boxStartTop + deltaY;
						break;

					case 'top-right':
						newWidth = Math.min(
							getElement.position.boxStartWidth + deltaX,
							currentPageOffset.offsetRight - getElement.position.boxStartLeft
						);
						newHeight = Math.max(12, getElement.position.boxStartHeight - deltaY);
						newLeft = getElement.position.boxStartLeft;
						newTop = getElement.position.boxStartTop + deltaY;
						break;

					case 'bottom-left':
						newWidth = Math.min(
							Math.max(20, getElement.position.boxStartWidth - deltaX),
							currentPageOffset.offsetRight - getElement.position.boxStartLeft
						);
						newHeight = Math.min(
							getElement.position.boxStartHeight + deltaY,
							currentPageOffset.offsetBottom - getElement.position.boxStartTop
						);
						newLeft = getElement.position.boxStartLeft + deltaX;
						newTop = getElement.position.boxStartTop;
						break;

					case 'bottom-right':
						newWidth = Math.min(
							getElement.position.boxStartWidth + deltaX,
							currentPageOffset.offsetRight - getElement.position.boxStartLeft
						);
						newHeight = Math.min(
							getElement.position.boxStartHeight + deltaY,
							currentPageOffset.offsetBottom - getElement.position.boxStartTop
						);
						newLeft = getElement.position.boxStartLeft;
						newTop = getElement.position.boxStartTop;
						break;

					default:
						return;
				}

				// Apply boundary constraints for position
				const { constrainedX, constrainedY } = this.applyBoundaryConstraints(
					newLeft, newTop, newWidth, newHeight, canvasRect, currentPageOffset
				);

				getElement.x_loc = constrainedX;
				getElement.y_loc = constrainedY;
				getElement.width = newWidth;
				getElement.height = newHeight;

				// Adjust font size for specific element types
				if (elementType === 'textbox') {
					this.allTextBoxes = this.allTextBoxes.map(tb =>
						tb.id === getElement.id ? { ...getElement } : tb
					);
				} else if (elementType === 'dropdown') {
					this.allDropDowns = this.allDropDowns.map(ddw =>
						ddw.id === getElement.id ? { ...getElement } : ddw
					);
				} else if (elementType === 'image') {
					this.allImages = this.allImages.map(im =>
						im.id === getElement.id ? { ...getElement } : im
					);
				} else if (elementType === 'signature') {
					this.allSignatures = this.allSignatures.map(isb =>
						isb.id === getElement.id ? { ...getElement } : isb
					);
				} else if (elementType === 'checkbox') {
					this.allCheckBoxes = this.allCheckBoxes.map(cb =>
						cb.id === getElement.id ? { ...getElement } : cb
					);
				}
			}
		},
		applyBoundaryConstraints(x, y, width, height, canvasRect, currentPageOffset) {
			const constrainedX = Math.max(
				currentPageOffset.offsetLeft,
				Math.min(x, currentPageOffset.offsetRight - width)
			);

			const constrainedY = Math.max(
				currentPageOffset.offsetTop,
				Math.min(y, currentPageOffset.offsetBottom - height)
			);

			return { constrainedX, constrainedY };
		},
		resizeMouseUp(getElement, event) {
			event.preventDefault();
			if (!getElement.isResizing) return;
			getElement.isResizing = false;

			// Remove event listeners using stored handlers
			document.removeEventListener('mousemove', this.currentResizeMouseMoveHandler);
			document.removeEventListener('mouseup', this.currentResizeMouseUpHandler);

			// Clear stored handlers
			this.currentResizeMouseMoveHandler = null;
			this.currentResizeMouseUpHandler = null;
		},
		updateElementPosition(getElement, elementType) {
			let refName = undefined;
			switch (elementType) {
				case 'textbox':
					refName = `textBox-${getElement.id}`;
					break;
				case 'dropdown':
					refName = `dropDown-${getElement.id}`;
					break;
				case 'image':
					refName = `imageBox-${getElement.id}`;
					break;
				case 'signature':
					refName = `signatureBox-${getElement.id}`;
					break;
				case 'checkbox':
					refName = `checkBox-${getElement.id}`;
					break;

				default:
					break;
			}
			const ref = this.$refs[refName];
			const element = ref?.$el || ref;
			const resolvedElement = Array.isArray(element) ? element[0] : element;
			if (resolvedElement && resolvedElement.style) {
				this.$nextTick(() => {
					try {
						resolvedElement.style.left = `${getElement.x_loc}px`;
						resolvedElement.style.top = `${getElement.y_loc}px`;
						resolvedElement.style.width = `${getElement.width}px`;
						resolvedElement.style.height = `${getElement.height}px`;
						// resolvedElement.style.fontSize = `${getElement.fontSize}px`;
					} catch (e) {
						console.error(`Failed to set styles for element:`, e);
					}
				});
			} else {
				console.error(
					`Ref for ${refName} - ${getElement.id} is not a valid DOM element or lacks a style property.`,
					resolvedElement
				);
			}
		},
		showFilteredTags() {
			if(!this.selectedTextBox.isDatabaseTagReadOnly) {
				this.filteredTags =  this.databaseTags;
			}
		},
		tagSearch(event) {
			const query = event.target.value.toLowerCase();
			if(query) {
				this.filteredTags =  this.databaseTags.filter(tag =>
					`${tag.category} | ${tag.label}`.toLowerCase().includes(query)
				);
			} else {
				this.selectedTextBox.isPlainTextReadOnly = false;
			}
		},
		async selectTag(tag) {
			this.filteredTags = [];

			// Set the database tag based on the selected tag's category and label
			this.selectedTextBox.database_tag = `${tag.category} | ${tag.label}`;
			const textBox = this.allTextBoxes.find(tb => tb.id === this.selectedElementId);
			if(textBox) {
				this.wrappedTextBoxVal[textBox.id] = this.selectedTextBox.database_tag;
				this.selectedTextBox.isDatabaseTagReadOnly = false;
				this.selectedTextBox.isPlainTextReadOnly = true;
			}
		},
		toggleDropdown(id) {
			this.allDropDowns.forEach(dropDown => {
				if (dropDown.id === id) {
					dropDown.isOpen = !dropDown.isOpen;
				} else {
					dropDown.isOpen = false;
				}
			});
		},
		async wrapText(text, maxWidth, maxHeight, initialFontSize) {
			let fontSize = initialFontSize;
			const minFontSize = this.minFontSize || 8;
			let lines = [];
			let iterations = 0;
			const MAX_ITERATIONS = 100;

			const calculateDimensions = (fontSize) => {
				const lineHeight = Math.ceil(fontSize * 1.3);
				const totalLines = Math.floor(maxHeight / lineHeight);
				const charPerLine = Math.floor(maxWidth / (fontSize * 0.5) - 2);
				return { totalLines, charPerLine };
			};

			const truncateTextWithEllipsis = (line) => {
				let truncatedLine = line;
				while (
					this.getTextDimensions(truncatedLine + "...", fontSize).width > maxWidth &&
					truncatedLine.length > 0
				) {
					truncatedLine = truncatedLine.slice(0, -1);
				}
				return truncatedLine + "...";
			};

			const splitTextIntoLines = (text) => {
				const words = text.split(" ");
				let currentLine = "";

				words.forEach(word => {
					const testLine = currentLine.length === 0 ? word : currentLine + " " + word;

					// Check if the current line with the word exceeds the max width
					if (this.getTextDimensions(testLine, fontSize).width > maxWidth) {
						lines.push(currentLine);  // Push the current line to the lines array
						currentLine = word;       // Start a new line with the current word
					} else {
						currentLine = testLine;   // Add the word to the current line
					}
				});

				// Push the last line if it's not empty
				if (currentLine.length > 0) {
					lines.push(currentLine);
				}
			};

			while (fontSize >= minFontSize) {
				if (iterations++ >= MAX_ITERATIONS) {
					console.warn("Max iterations reached, exiting loop.");
					break;
				}

				lines = [];
				const { totalLines, charPerLine } = calculateDimensions(fontSize);

				splitTextIntoLines(text, charPerLine);

				if (lines.length <= totalLines) {
					break;
				}

				fontSize = Math.max(minFontSize, fontSize - 1.5);
			}

			if (fontSize === minFontSize && lines.length > calculateDimensions(minFontSize).totalLines) {
				lines = lines.slice(0, calculateDimensions(minFontSize).totalLines);
				lines[lines.length - 1] = truncateTextWithEllipsis(lines[lines.length - 1]);
			}

			lines = lines.filter(line => line.trim() !== "");

			return {
				fontSize: fontSize,
				wrappedText: lines.join('\n'),
				wrapTextArr: lines,
			};
		},
		getTextDimensions(text, fontSize) {
			const span = $("<span>").text(text).css({
				"fontSize": fontSize + "px",
				"fontFamily": "Helvetica",
				"position": "absolute",
				"visibility": "hidden",
				"display": "inline-block"
			});

			$("body").append(span);

			const width = span.width();
			const height = span.height();
			span.remove();

			return { width, height };
		},
		// getTextDimensions(text, numberOfLines, fontSize) {
		// 	const span = $("<span>");
		// 	span.text(text);
		// 	span.css({
		// 		"fontSize": fontSize + "px",
		// 		"position": "absolute",
		// 		"visibility": "hidden",
		// 		// "white-space": "nowrap",
		// 		"display": "inline-block"
		// 	});

		// 	$("body").append(span);

		// 	const width = span.width() * 1.2 ; // 1.2 scaling factor for width
		// 	const height = span.height() * numberOfLines; // Height multiplied by number of lines

		// 	span.remove();

		// 	return { width, height };
		// },
		// getTextDimensions(text, fontSize, fontFamily = "Inter") {
		// 	const canvas = document.createElement("canvas");
		// 	const context = canvas.getContext("2d");
		// 	context.font = `${fontSize}px ${fontFamily}`;

		// 	const width = context.measureText(text).width;
		// 	const lineHeight = fontSize * 1.2; // Typical line height is 1.2 times font size

		// 	return { width, height: lineHeight };
		// },
		async uploadDocument() {
			this.isShowLoader = true;
			await this.savePdf();

			await this.v$.$validate();

			if (this.v$.$error) {
				this.isShowLoader = false;

				Object.keys(this.v$).forEach((field) => {
					if (this.v$[field]?.$errors) {
						this.v$[field].$errors.forEach((error) => {
							console.log(`Field: ${field}, Error: ${error.$message}`);
						});
					}
				});

				return false;
			}

			// Proceed with form submission if there are no errors
			let $this = this;
			const formData = new FormData();
			const pdfBlob = new Blob([this.existingPdfBytes], { type: 'application/pdf' });


			if (pdfBlob) {
				formData.append('pdfData', pdfBlob, 'annotated_file.pdf');
			}

			if (this.$route.params.template_id) {
				formData.append('id', this.$route.params.template_id);
				formData.append('file_path', this.pdfFilePath);
			} else if (this.$route.query.file_path) {
				formData.append('file_path', this.$route.query.file_path);
			}
			formData.append('title', this.form.title);
			formData.append('additional_info', this.form.additional_info);
			formData.append('module', this.form.module);
			formData.append('annotation', this.form.annotations);

			// console.log('FormData Payload:');
			// formData.forEach((value, key) => {
			// 	console.log(`${key}:`, value);
			// });

			try {
				const config = {
					headers: {
						'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
						'Content-Type': 'multipart/form-data'
					},
				};
				const response = await axios.post('e-forms/upload', formData, config);

				if (response.status === 200) {
					this.isShowLoader = false;
					$this.$filters.moshaToast(response.data.message, "success");
					if ($this.$route.name !== 'templateMasterEdit') {
						$this.$router.push({ name: 'patientTemplateList', params: { patient_id: $this.$route.params.patient_id, module: $this.form.module.toLowerCase() } });
					} else {
						$this.$router.push({ name: 'templateMasterList' });
					}
				}
			} catch (error) {
				console.error('Error saving PDF with notes on the server:', error);
			}
		},
		async savePdf() {
			try {
				this.existingPdfBytes = await this.pdfDoc.save();
				const pdfLibDoc = await PDFDocument.load(this.existingPdfBytes);
				// const pages = pdfLibDoc.getPages();

				const annotations = {};
				// const canvas = this.$refs.pdfCanvas;
				// const canvasRect = canvas.getBoundingClientRect();

				if (!this.validateAll()) {
					this.isShowLoader = false;
					return;
				}

				// Handle text-box
				if (this.allTextBoxes.length) {
					this.allTextBoxes.forEach((textBox, index) => {

						const refName = `textBox-${textBox.id}`;
						const ref = this.$refs[refName];
						const element = ref?.$el || ref;
						const textBoxElement = Array.isArray(element) ? element[0] : element;

						if (textBoxElement) {
							const offset = this.pageOffsets.find(offset => offset.pageNum === textBox.onPage);
							const offsetTop = offset ? offset.offsetTop : 0;
							const placeholder = textBox.placeholder === "\n" ? "" : (textBox.placeholder || "");

							annotations[`textBox${index}`] = {
								field_type: textBox.text_box_name,
								database_type: 'string',
								database_tag: textBox.database_tag || '',
								placeholder: placeholder,
								x_loc: textBox.x_loc,
								y_loc: textBox.y_loc,
								height: textBox.height,
								width: textBox.width,
								pageNum: textBox.onPage,
								fontSize: textBox.database_tag? 10 : textBox.fontSize,
								offsetTop: offsetTop,
								wrapped_val: textBox.wrap_text_array,
							};

							console.log(`TextBox Annotation for index ${index}:`, annotations[`textBox${index}`], textBox.placeholder, textBox.database_tag);
						} else {
							console.error(`Ref for TextBox ${index} not found.`);
						}
					});
				}

				// Handle dropdown
				if (this.allDropDowns.length) {
					this.allDropDowns.forEach((dropDown, index) => {

						const refName = `dropDown-${dropDown.id}`;
						const ref = this.$refs[refName];
						const element = ref?.$el || ref;
						const dropDownElement = Array.isArray(element) ? element[0] : element;

						if (dropDownElement) {
							const offset = this.pageOffsets.find(offset => offset.pageNum === dropDown.onPage);
							const offsetTop = offset ? offset.offsetTop : 0;

							annotations[`dropDown${index}`] = {
								field_type: dropDown.dropdown_box_name,
								database_type: 'array',
								database_tag: dropDown.database_tag,
								placeholder: dropDown.placeholder,
								x_loc: dropDown.x_loc,
								y_loc: dropDown.y_loc,
								width: dropDown.width,
								height: dropDown.height,
								pageNum: dropDown.onPage,
								fontSize: dropDown.fontSize,
								offsetTop: offsetTop,
							};
							console.log(`DropDown Annotation for index ${index}:`, annotations[`dropDown${index}`]);
						} else {
							console.error(`Ref for DropDown ${index} not found.`);
						}
					});
				}

				// Handle image
				if (this.allImages.length) {
					this.allImages.forEach(async (imageBox, index) => {
						if (imageBox.placeholder) {

							const refName = `imageBox-${imageBox.id}`;
							const ref = this.$refs[refName];
							const element = ref?.$el || ref;
							const imageBoxElement = Array.isArray(element) ? element[0] : element;

							if (imageBoxElement) {
								const offset = this.pageOffsets.find(offset => offset.pageNum === imageBox.onPage);
								const offsetTop = offset ? offset.offsetTop : 0;
								try {
									annotations[`imageBox${index}`] = {
										field_type: 'image',
										database_type: 'string',
										database_tag: '',
										placeholder: imageBox.placeholder,
										x_loc: imageBox.x_loc,
										y_loc: imageBox.y_loc,
										width: imageBox.width,
										height: imageBox.height,
										pageNum: imageBox.onPage,
										offsetTop: offsetTop,
									};
									console.log(`Image Annotation for index ${index}:`, annotations[`imageBox${index}`]);
								} catch (error) {
									console.error(`Error processing imageBox ${index}:`, error);
								}
							} else {
								console.error(`Element for ImageBox ${index} not found.`);
							}
						} else {
							console.error(`Invalid src or onPage value for ImageBox ${index}`);
						}
					});
				}

				// Handle signature
				if (this.allSignatures.length) {
					this.allSignatures.forEach(async (signatureBox, index) => {

						const refName = `signatureBox-${signatureBox.id}`;
						const ref = this.$refs[refName];
						const element = ref?.$el || ref;
						const signatureBoxElement = Array.isArray(element) ? element[0] : element;

						if (signatureBoxElement) {
							const offset = this.pageOffsets.find(offset => offset.pageNum === signatureBox.onPage);
							const offsetTop = offset ? offset.offsetTop : 0;

							annotations[`signatureBox${index}`] = {
								field_type: 'signature',
								database_type: 'string',
								database_tag: localStorage.getItem('userId'),
								placeholder: 'user_id',
								x_loc: signatureBox.x_loc,
								y_loc: signatureBox.y_loc,
								width: signatureBox.width,
								height: signatureBox.height,
								pageNum: signatureBox.onPage,
								offsetTop: offsetTop,
							};
							console.log(`Signature Annotation for index ${index}:`, annotations[`signatureBox${index}`]);
						} else {
							console.error(`Element for SignatureBox ${index} not found.`);
						}
					});
				}

				// Handle checkbox	
				if (this.allCheckBoxes.length) {
					this.allCheckBoxes.forEach(async (checkBox, index) => {

						const refName = `checkBox-${checkBox.id}`;
						const ref = this.$refs[refName];
						const element = ref?.$el || ref;
						const checkBoxElement = Array.isArray(element) ? element[0] : element;

						if (checkBoxElement) {
							const offset = this.pageOffsets.find(offset => offset.pageNum === checkBox.onPage);
							const offsetTop = offset ? offset.offsetTop : 0;

							annotations[`checkBox${index}`] = {
								field_type: 'checkbox',
								database_type: 'boolean',
								database_tag: '',
								placeholder: checkBox.placeholder,
								x_loc: checkBox.x_loc,
								y_loc: checkBox.y_loc,
								width: checkBox.width,
								height: checkBox.height,
								pageNum: checkBox.onPage,
								offsetTop: offsetTop,
							};
							console.log(`checkbox Annotation for index ${index}:`, annotations[`checkBox${index}`]);
						} else {
							console.error(`Ref for checkBox ${index} not found.`);
						}
					});
				}

				// Handle blank pages
				if(this.addBlankPagesNumbers.length) {
					annotations[`blank_pages`] = this.addBlankPagesNumbers;
				}

				// Handle pdf page scale
				if(this.pdfRenderScale != null) {
					annotations[`pdf_scale`] = this.pdfRenderScale;
				}

				if(this.isDocumentUploadedAsZip) {
					annotations[`uploaded_from_zip`] = 1;
				}

				// Saving the final annotated PDF
				const annotatedPdfBytes = await pdfLibDoc.save();
				this.form.annotatedPdfBytes = annotatedPdfBytes;

				// Trigger PDF download
				// this.downloadPdf(annotatedPdfBytes);

				// this.downloadJson(annotations);
				this.form.annotations = JSON.stringify(annotations, null, 2);

			} catch (error) {
				console.error("Error saving PDF:", error);
			}
		},
		downloadPdf(pdfBytes) {
			const blob = new Blob([pdfBytes], { type: 'application/pdf' });
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = 'modified_pdf.pdf';
			link.click();
		},
		downloadJson(annotations) {
			const formattedJson = JSON.stringify(annotations, null, 2); // Formatting JSON with indentation
			const blob = new Blob([formattedJson], { type: 'application/json' });
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = 'annotations.json';
			link.click();
		},
		backToList() {
			if (this.$route.name !== 'templateMasterEdit') {
				this.$router.push({ name: 'patientTemplateList', params: { patient_id: this.$route.params.patient_id, module: this.form.module.toLowerCase() } });
			} else {
				this.$router.push({ name: 'templateMasterList', params: {} });
			}
		},
		beforeTooltipRender(args) {
			if (args.element.classList.contains('e-pv-annotation-icon')) {
				args.content = 'Custom tooltip content for annotation icon';
			} else if (args.element.classList.contains('e-pv-bookmark-icon')) {
				args.content = 'Custom tooltip content for bookmark icon';
			} else {
				args.content = 'Default tooltip content';
			}
		},
		handleOutsideClick(event) {
			const pdfContainer = this.$refs.pdfContainer;
			if (pdfContainer && !pdfContainer?.contains(event.target)) {
				this.onClickedSelectElement = null;
			}
			const dropdownContainer = this.$refs.dropdownContainer;
			if (dropdownContainer && !dropdownContainer.contains(event.target)) {
				this.filteredTags = [];
			}
		},
		preventDropDownBackSpaceEvent() {
			this.onClickedSelectElement = null;
		}
		// initializePdf() {
		// 	if (window.pdfjsLib) {
		// 		window.pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';
		// 	}
		// },
	},
	mounted() {
		const pdfScript = document.createElement('script');
		pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.min.js';
		// Letest
		// pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.min.js';
		// client
		// pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.min.js';

		pdfScript.onload = () => {
			// const workerScript = document.createElement('script');
			// workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';
			window.pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';
			// Letest
			// workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.worker.min.js';
			// client
			// workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf-lib/1.17.1/pdf-lib.min.js';

			// workerScript.onload = () => {
			// 	// Initialize your PDF logic here after both scripts are loaded
			// 	this.edit();  // Call this.edit() after both scripts are fully loaded
			// };
			this.edit(); 

			// document.head.appendChild(workerScript);
		};

		document.head.appendChild(pdfScript);
		document.addEventListener('click', this.handleOutsideClick);
		this.isKeydownListenerAdded = false;
	},
	unmounted() {
		document.removeEventListener("click", this.handleOutsideClick);
		document.removeEventListener('keydown', this.handleKeydown);
		document.removeEventListener('mousemove', this.trackMousePosition);
	},
	watch: {
		'selectedTextBox.text_box_name'(newValue) {
			if (!this.selectedTextBox) return;
			if (newValue) {
				this.selectedTextBox.text_box_name = newValue;
			}
		},
		'selectedTextBox.placeholder'(newValue) {
			if (this.selectedTextBox && newValue) {
				this.selectedTextBox.isDatabaseTagReadOnly = !!newValue;
				this.selectedTextBox.isPlainTextReadOnly = false;

				this.selectedTextBox.placeholder = newValue;
				this.wrapText(newValue, this.selectedTextBox.width, this.selectedTextBox.height, this.maxFontSize)
					.then(result => {
						const textBox = this.allTextBoxes.find(tb => tb.id === this.selectedElementId);
						if (textBox) {
							textBox.wrap_text_content = result.wrappedText;
							textBox.fontSize = result.fontSize;
							this.wrappedTextBoxVal[textBox.id] = result.wrappedText;
							textBox.wrap_text_array = result.wrapTextArr;
						}
					})
					.catch(error => {
						console.error('Error in wrapText:', error);
					})
			} else {
				if (this.isSilentUpdate) {
					return;
				}
				this.selectedTextBox.isDatabaseTagReadOnly = false;

				const textBox = this.allTextBoxes.find(tb => tb.id === this.selectedElementId);
				if (textBox) {
					textBox.wrap_text_content = "";
					textBox.fontSize = this.maxFontSize;
					this.wrappedTextBoxVal[textBox.id] = "";
				}
			}
		},
		'selectedTextBox.database_tag'(newValue) {
			if (this.selectedTextBox && newValue) {
				this.selectedTextBox.isPlainTextReadOnly = !!newValue;
				this.selectedTextBox.isDatabaseTagReadOnly = false;

				// this.selectedTextBox.database_tag = newValue;

				// this.wrapText(newValue, this.selectedTextBox.width, this.selectedTextBox.height, this.maxFontSize)
				// 	.then(result => {
				// 		const textBox = this.allTextBoxes.find(tb => tb.id === this.selectedElementId);
				// 		if (textBox) {
				// 			textBox.wrap_text_content = result.wrappedText;
				// 			textBox.fontSize = result.fontSize;
				// 			this.wrappedTextBoxVal[textBox.id] = result.wrappedText;
				// 		}
				// 	})
				// 	.catch(error => {
				// 		console.error('Error in wrapText:', error);
				// 	});
			} else {
				this.selectedTextBox.isPlainTextReadOnly = false;

				const textBox = this.allTextBoxes.find(tb => tb.id === this.selectedElementId);
				if (textBox) {
					textBox.wrap_text_content = "";
					textBox.fontSize = this.maxFontSize;
					this.wrappedTextBoxVal[textBox.id] = "";
				}
			}
		},
		'selectedDropDown.dropdown_box_name'(newValue) {
			if (!this.selectedDropDown) return;
			if (newValue) {
				this.selectedDropDown.dropdown_box_name = newValue;
				const dropdownIndex = this.allDropDowns.findIndex(
					(dropdown) => dropdown.id === this.selectedDropDownId
				);
				if (dropdownIndex !== -1) {
					this.allDropDowns[dropdownIndex].dropdown_box_name = newValue;
				}
			} else {
				const dropdownIndex = this.allDropDowns.findIndex(
					(dropdown) => dropdown.id === this.selectedDropDownId
				);
				if (dropdownIndex !== -1) {
					this.allDropDowns[dropdownIndex].dropdown_box_name = '';
				}
			}
		},
		'selectedDropDown.placeholder'(newValue) {
			if (this.selectedDropDownId !== null) {
				const options = newValue.split(',').map((option) => {
					const trimmedOption = option.trim();
					return trimmedOption;
				});

				const dropdownIndex = this.allDropDowns.findIndex(
					(dropdown) => dropdown.id === this.selectedDropDownId
				);
				if (dropdownIndex !== -1) {
					this.allDropDowns[dropdownIndex].database_tag = options;
					this.allDropDowns[dropdownIndex].placeholder = newValue;
				}
			}
		}
	},
}
</script>
<style scoped>

#pdfViewer_fileUploadElement {
	display: none !important;
}

.e-checkbox-wrapper {
	display: none !important;
}

.pdf_search_section {
	position: absolute;
	top: 31px;
	width: 100%;
	background: #f5f5f5;
	padding: 10px;
	box-shadow: 0px 0px 6px 1px #e1e1e1;
	border-radius: 2px;
	border-top: none;
}

.pdf_search {
	padding-left: 0;
	margin-bottom: -10px;
	margin-top: -7px;
}

.pdf_search_option {
	list-style-type: none;
	line-height: normal;
	border-bottom: 1px solid #e7e4e4;
	padding: 10px;
	cursor: pointer;
}

.pdf_search_option:hover {
	background: #e1e1e1;
}

.text-box {
	position: absolute;
	border: 1px dashed #000;
	background-color: rgba(255, 255, 255, 0.8);
	box-sizing: border-box;
}

/* Styles for the text area */
.text-box-content {
	resize: none;
	width: 100%;
	height: 100%;
	border: 0px solid #0e2641;
	/* padding: 5px; */
	box-sizing: border-box;
	overflow: hidden;
	line-height: normal;
	box-shadow: none;
	outline: none;position:relative;color:#0E2641;
	/* border-radius: 5px; */
}

/* Styles for the resize handle */
.resize-handle {
	position: absolute;
	width: 11px;
	height: 11px;
	background: rgba(0, 0, 0, 0.5);
	cursor: nwse-resize;
}

.resize-handle.top-left {
	top: -5px;
	left: -5px;
	cursor: nw-resize;
}

.resize-handle.top-right {
	top: -5px;
	right: -5px;
	cursor: ne-resize;
}

.resize-handle.bottom-left {
	bottom: -5px;
	left: -5px;
	cursor: sw-resize;
}

.resize-handle.bottom-right {
	bottom: -5px;
	right: -5px;
	cursor: se-resize;
}

.pdf-dropdown-drag {
	position: absolute;
	border: 1px dashed #000;
	/*	padding: 5px;*/
	background-color: rgba(255, 255, 255, 0.8);
	box-sizing: border-box;
	/*z-index: 1; */
}

.dragHandle {
	position: absolute;
	/* width: 100%; */
	height: 5px;
	background: rgba(0, 0, 0, 0.3);
	cursor: move;
	top: -6px !important;
	left: 12px;
	right: 12px;
}

/* .resize-handle {
	position: absolute;
	width: 11px;
	height: 11pxpx;
	background: rgba(0, 0, 0, 0.5);
	cursor: nwse-resize;
} */

/* .resize-handle.bottom-right {
	bottom: -6px;
	right: -6px;
} */

#pdfCanvas {
	/*	border : 1px solid black;*/
}

.image-box,
.signature-box {
	position: absolute;
	border: 1px dashed #000;
	padding: 0;
	background-color: rgba(255, 255, 255, 0.8);
	display: none;
	box-sizing: border-box;
}
.image-box img{background: #fff;position: relative;}
.drag-handle {
	width: 10px;
	height: 10px;
	background-color: red;
	position: absolute;
	right: 0;
	bottom: 0;
	cursor: move;
}

.draggable-box {
	position: absolute;
	display: none;
	width: 250px;
	height: 250px;
	cursor: move;
}

.signature-image {
	display: block;
}

.checkbox-box {
	position: absolute;
	border: 1px dashed #000;
	background-color: rgba(255, 255, 255, 0.8);
	/* display: none; */
	/* box-sizing: border-box;z-index: 1; */
}

.checkbox-box input[type="checkbox"] {
	width: 25px;
	height: 25px;
	cursor: pointer;
}


/* Container for buttons */
.div-button-container {}

/* Style for clickable span elements */
.div-button-container span {
	transition: background-color 0.3s, color 0.3s;
}

/* Style for 'Add Blank Page' button */
.div-button-container .add-page {
	color: #0E2641;
	font-size: calc(var(--scale-ratio) * 18px);
	font-weight: 600;
	line-height: normal;
	text-decoration-line: underline;
	cursor: pointer;
	margin-right: calc(var(--scale-ratio) * 35px);
	text-underline-offset: calc(var(--scale-ratio) * 4px);
	text-decoration-thickness: 1px;vertical-align: top;
}


/* Style for 'Delete Element' button */
.div-button-container .delete-element {
	color: #CC0000;
	font-size: calc(var(--scale-ratio) * 18px);
	font-weight: 600;
	line-height: normal;
	text-decoration-line: underline;
	cursor: pointer;
	margin-right: calc(var(--scale-ratio) * 35px);
	text-underline-offset: calc(var(--scale-ratio) * 4px);
	text-decoration-thickness: 1px;vertical-align: top;
}

.heading_selact_drodwn {
	width: 200px;
	/* Adjust as needed */
	height: auto;
	/* Adjust as needed */
	position: absolute;
	cursor: move;
}

.drop-container {
	position: relative;
	padding: 10px;
	border-radius: 4px;
	background-color: #f9f9f9;
	width: 250px;
}

.dropdown-select {
	width: 100%;
	font-size: 14px;
}

.blankpages-list{max-height: calc(var(--scale-ratio) * 200px);overflow: auto;}
.blank-page-name{color: #000;font-size: calc(var(--scale-ratio) * 18px);font-weight: 400;line-height: normal;margin-left:calc(var(--scale-ratio) * 14px);}
.blank-page-name img{width: calc(var(--scale-ratio) * 12px);margin-right: calc(var(--scale-ratio) * 20px);}


/*------dropDown-------*/

.name-selcet-w-pdf{background: #fff;}
.name-selcet-w-pdf .select-options {padding: 0;margin: 0;background: #fff;box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);border-radius: 0 0 5px 5px;position: absolute;width: 100%;overflow: auto;max-height: calc(var(--scale-ratio) * 180px);}

.name-selcet-w-pdf .custom-select .select-display {
	color: #0E2641;
	padding: 7px 12px;
	border-radius: 5px;
	line-height: normal;
	cursor: pointer;
}

/*.name-selcet-w-pdf .custom-select .select-display .selected-list-actv{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 50px;display: inline-block;vertical-align: middle;}*/
.name-selcet-w-pdf .custom-select .select-display img {margin-left: calc(var(--scale-ratio)* 6px);width: calc(var(--scale-ratio)* 25px);}

.name-selcet-w-pdf .select-options li {
	display: block;
	border-bottom: 0.5px solid #ECE3D1;
	color: #0E2641;
	font-size: 16px;
	padding: calc(var(--scale-ratio) * 7px) calc(var(--scale-ratio) * 12px);
	line-height: normal;
	cursor: pointer;
	text-align: center;
}
.name-selcet-w-pdf .select-options li:hover{background: #F6F1E8;}

.context-menu {position: absolute;
  background-color: #f6f6f7;
  border: 1px solid #ccc;
  padding: 0px;
  z-index: 1000;
  box-shadow: 0 1px 13px rgba(0, 0, 0, 0.1);
}

.pdf-show-part {
	overflow: auto;
	max-height: 100vh;
}

.context-menu ul {list-style: none;margin: 0;padding: 0;}
.context-menu li {padding: calc(var(--scale-ratio) * 2px) calc(var(--scale-ratio) * 20px);cursor: pointer;font-size: calc(var(--scale-ratio) * 14px);border-bottom: 1px solid #dcdcdc;}
.context-menu li:last-child{border-bottom: 0;}
.context-menu li:hover {background-color: #ece3d180;}

</style>
<template>
	<div class="d-flex justify-content-between align-items-center e_c-header">
		<div class="d-flex align-items-center">
			<div class="selact_dropdown echartelement-select">
				<selectCheckBox :dataSource='echartElement' labelName="Select Your E-Chart Elements" @clicked="selected_provider" :displayDiv="displayDiv" :key="select_box_key" :isShowDoctorIcon="false" :isUnselectAll="isUnselectAll"/>
			</div>
			<span class="text_link ps-3 cursor-pointer" v-if="isShowSelectAllButton" @click="handleButtonClick">{{ boxElementToggleBtnText }}</span>
		</div>
		<div class="search e-chart-searchUpdate">
			<img class="serch_icon" src="/images/search-icon.svg" alt="">
			<!-- <input type="text" @keyup="patientSearch" class="form-control" placeholder="Search UpdateToDate" v-model="patient_search" @blur="patientSearch" ref="globalSearch"> -->
			<input type="text" @keyup.enter="querySearch" class="form-control" placeholder="Search UpdateToDate" v-model="query_search" ref="globalSearch">
		</div>
		<button class="comman_brdr_btn" @click="echarTransfer"> TRANSFER </button>
	</div>

	<div class="modal fade" id="e-chart-transfer" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeEChartTransferPop"></button>
                    <div class="box">
						<h2 class="popup_title">E-CHART TRANSFER</h2>
						<div class="hdng_con">
							<div class="heading">Date Range</div>
							<span class="con_tx">Please select the date range you would like the E-Chart report to cover.</span>
						</div>
						<div class="row align-items-center justify-content-center time_set_part">
							<div class="col-md-6 text-center">
								<div class="contain_bx">
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_all_time_range" @change="handleAllTImeRangeChange" ></span>
										<label>All Time Range</label>
									</div>
								</div>
							</div>
							<div class="col-md-6 text-center left_con_box">
								<div class="position-relative mx-4" :class="{'time-range-disabled-form': this.form.e_chart_transfer.is_all_time_range}">
									<div class="contain_bx">
										<label for="text"><span class="cursor-text">From</span></label>
										<div class="calendar e-chart-clnder" @click="openDatePicker('start')">
											<ejs-datepicker class="text-center e-field form-control" ref="startDatePicker"
												:format="{ skeleton: 'full' }" :showClearButton="false"
												v-model="this.selectedStartDate" @change="updateStartDate($event.value)"
												:max="eChartTransferMaxDate"
												:value="selectedStartDate" 
												@keyup="this.keyupdate('selectedStartDate')" :strictMode="true" id="selectedStartDate"/>
										</div>
									</div>
									<div class="contain_bx">
										<label for="text"><span class="cursor-text">To</span></label>
										<div class="calendar e-chart-clnder" @click="openDatePicker('end')">
											<ejs-datepicker class="text-center e-field form-control" ref="endDatePicker"
												:format="{ skeleton: 'full' }" :showClearButton="false"
												v-model="this.selectedREndDate" @change="updateEndDate($event.value)" 
												:max="eChartTransferMaxDate"
												:min="eChartTransferMinDate"
												:value="selectedEndDate"
												@keyup="this.keyupdate('selectedREndDate')" :strictMode="true" id="selectedREndDate"/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="hdng_con">
							<div class="heading">E-Chart Report Content</div>
							<span class="con_tx">Please select the content you would like the E-chart report to include.</span>
						</div>
						<div class="chart-report-check">
							<div class="d-flex justify-content-center selact_box_part">
								<div class="select_report_chart_first">
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_demographic" checked disabled></span>
										<label>Demographic</label>
									</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_encounter_notes" checked></span>
										<label>Encounter Notes</label>
									</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_files" checked></span>
										<label>Files</label>
									</div>
								</div>
								<div class="select_report_chart_second">
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_measurements" checked></span>
										<label>Measurements</label>
									</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_medication" checked></span>
										<label>Medications</label>
									</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_other_infos" checked></span>
										<label>Other Infos</label>
									</div>
								</div>
								<div class="select_report_chart_thrd">
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_preventions" checked></span>
										<label>Preventions</label>
									</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_screening" checked></span>
										<label>Screenings</label>
									</div>
								</div>
							</div>
						</div>
                        <div class="btn_part text-center">
                            <button class="comman_brdr_btn mx36" @click="closeEChartTransferPop"> Cancel </button>
                            <button class="comman_btn mx36" @click="printEchart">Print <img src="images/loader.gif" v-if="loaderPrint"
								style="width: calc(var(--scale-ratio) * 18px);" /></button>
                            <button class="comman_btn mx36" @click="exportEchart">Fax <img src="images/loader.gif" v-if="loaderFax"
								style="width: calc(var(--scale-ratio) * 18px);" /></button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

    <div class="modal fade e-chart-fax-model" id="add-exportconsultation-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                        <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
						<div class="box">
							<ul class="export_tabs">
								<li :class="{ active: isFaxFormVisible }" @click="changeExportOpt('fax')">Fax</li>
								<li :class="{ active: isEmailFormVisible }" @click="changeExportOpt('email')">Email</li>
								<li :class="{ active: isMailFormVisible }" @click="changeExportOpt('mail')">Mail</li>
							</ul>
							<section class="con_detl_sec">
								<div class="fax_menu" v-if="isFaxFormVisible">
									<div class="row align-items-center">
										<label for="text" class="col-sm-4 col-form-label right-bor py-0 fs-18">Specialist(s)</label>
										<div class="col-sm-8 position-relative selact_dropdown cunlt_specialist_option">
											<selectCheckBox :dataSource="specialist_option" :labelName="tagSpecialistLabel" @clicked="selectedSpecialist" :displayDiv="false" :isUnselectAll="true" unselectLabel="None" :isShowDoctorIcon="false"></selectCheckBox>
										</div>
									</div>

									<div class="or_line"><span>OR</span></div>

									<!-- disable-fax -->
									<div class="custom-form">
										<div class="input_box">
											<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="faxForm.fax_number" @input="handleFaxInput" @keydown="handleKeyDown" @paste="handlePaste" :maxlength="this.maxLength">
											<span class="multi-rc-fax-nmber white-space-nowrap d-inline-block me-2 mt-2" v-for="(phone, index) in this.faxForm.phoneNumbers" :key="index" style="display: flex; align-items: center;">
												{{ phone }}
												<img src="/images/close-icon.svg" @click="removeSelectedFax(phone)" class="cursor-pointer ml-10" style="margin-left: calc(var(--scale-ratio) * 10px); height: calc(var(--scale-ratio) * 10px);">
											</span>
											<label class="form-label" for="typeunique_idX-2">Recipient Fax Number</label>
											<ValidationErrorMessageList :errors="v$.faxForm.phoneNumbers.$errors" />
										</div>
									</div>
								</div>
								<div class="email_menu" v-if="isEmailFormVisible">
									<div class="custom-form">
										<div class="input_box">
											<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_id">
											<label class="form-label" for="typeunique_idX-2">Recipient Email</label>
											<ValidationErrorMessageList :errors="v$.emailForm.email_id.$errors" />
										</div>
										<div class="input_box">
											<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_header">
											<label class="form-label" for="typeunique_idX-2">Email Header</label>
											<ValidationErrorMessageList :errors="v$.emailForm.email_header.$errors" />
										</div>
									</div>

								</div>
								<div class="mail_menu" v-if="isMailFormVisible">
									<div class="custom-form">
										<div class="input_box">
											<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.name">
											<label class="form-label" for="typeunique_idX-2">Recipient Name</label>
											<ValidationErrorMessageList :errors="v$.mailForm.name.$errors" />
										</div>
										<div class="input_box">
											<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.address">
											<label class="form-label" for="typeunique_idX-2">Recipient Address</label>
											<ValidationErrorMessageList :errors="v$.mailForm.address.$errors" />
										</div>
										<div class="input_box">
											<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.city">
											<label class="form-label" for="typeunique_idX-2">Recipient City</label>
											<ValidationErrorMessageList :errors="v$.mailForm.city.$errors" />
										</div>
										<div class="input_box">
											<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.state">
											<label class="form-label" for="typeunique_idX-2">Recipient Province</label>
											<ValidationErrorMessageList :errors="v$.mailForm.state.$errors" />
										</div>
										<div class="input_box">
											<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.zip">
											<label class="form-label" for="typeunique_idX-2">Recipient Postal Code</label>
											<ValidationErrorMessageList :errors="v$.mailForm.zip.$errors" />
										</div>
									</div>
								</div>
							</section>
							<div class="btn_part text-center">
								<button class="comman_brdr_btn big_btn mx36" @click="closeExportModel"> Cancel </button>
								<button class="comman_btn big_btn mx36" @click="exportSubmit">Confirm
									<img src="images/loader.gif" v-if="faxLoader"  style="width: calc(var(--scale-ratio) * 18px);" />
								</button>
							</div>
						</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import $ from "jquery";
import moment from "moment";
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../base/ValidationErrorMessageList.vue';

export default {
	setup: () => {
		return { v$: useVuelidate() };
	},
	props: [
		'echartElement'
	],
	data() {
		return {
			query_search: '',
			displayDiv: false,
			select_box_key: 0,
			isUnselectAll:false,
			boxElementToggleBtnText: 'Show All Elements',
			selectedStartDate: new Date(),
			selectedEndDate: new Date(),
			form: {
				e_chart_transfer : {
					is_all_time_range: true,
					from_date: null,
					to_date: null,
					is_demographic: true,
					is_encounter_notes: true,
					is_files: true,
					is_measurements: true,
					is_medication: true,
					is_other_infos: true,
					is_preventions: true,
					is_screening: true
				},
			},
			eChartTransferMaxDate: new Date(),
			eChartTransferMinDate: new Date(),
			isShowSelectAllButton: true,
			isFaxFormVisible:true,
			isEmailFormVisible:false,
			isMailFormVisible:false,
			tagSpecialistLabel: "",
			specialist_option:[],
			loaderPrint: false,
			loaderFax: false,
		};
	},
	watch: {
		'form.e_chart_transfer': {
			handler(newVal) {
				console.log('e_chart_transfer object updated:', newVal);
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		document.addEventListener('click', this.handleClickOutside);

	},
	methods: {
		keyupdate(obj){
			if($(`#${obj}-error`)){
				$(`#${obj}-error`).text("");
			}
			const inputValue = $(`#${obj}`).val();
			var sanitizedInput = inputValue.replace(/[^\d]/g, '');
			if (sanitizedInput.length > 8) {
				sanitizedInput = sanitizedInput.substring(0, 8);
			}
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function(_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});
			$(`#${obj}`).val(formattedDate);
		},
		closeEChartTransferPop() {
			window.$("#e-chart-transfer").modal("hide");
			this.selectedStartDate = new Date();
			this.selectedEndDate = new Date();
			this.form.e_chart_transfer.is_all_time_range = true;

			this.form.e_chart_transfer.from_date = null;
			this.form.e_chart_transfer.to_date = null;
			this.form.e_chart_transfer.is_demographic = true;
			this.form.e_chart_transfer.is_encounter_notes = true;
			this.form.e_chart_transfer.is_files = true;
			this.form.e_chart_transfer.is_measurements = true;
			this.form.e_chart_transfer.is_medication = true;
			this.form.e_chart_transfer.is_other_infos = true;
			this.form.e_chart_transfer.is_preventions = true;
			this.form.e_chart_transfer.is_screening = true;
		},
		formatDatePickerDate(date) {
			const d = new Date(date);
			const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			return d.toLocaleDateString('en-US', options);
		},
		updateStartDate(value) {
			this.selectedStartDate = this.formatDatePickerDate(value);
			this.form.e_chart_transfer.from_date = moment(value).format('YYYY-MM-DD');
			this.validateDates()
		},
		updateEndDate(value) {
			this.selectedEndDate = this.formatDatePickerDate(value);
			this.form.e_chart_transfer.to_date = moment(value).format('YYYY-MM-DD');
			this.validateDates()
		},
		validateDates() {
			if (this.form.e_chart_transfer.from_date && this.form.e_chart_transfer.to_date) {
				this.eChartTransferMinDate = new Date(this.form.e_chart_transfer.from_date)
				if (new Date(this.form.e_chart_transfer.to_date) < new Date(this.form.e_chart_transfer.from_date)) {
					this.$filters.moshaToast("'To Date' must be greater than 'From Date'", "error");
					return false;
				}
			}

			return true
		},
		openDatePicker(type) {
			if (type === 'start') {
				this.$refs.startDatePicker.show();
			} else if (type === 'end') {
				this.$refs.endDatePicker.show();
			}
		},
		handleClickOutside(event) {
			const globalSearchElement = this.$refs.globalSearch;
			if (globalSearchElement && !globalSearchElement.contains(event.target)) {
				this.query_search = '';
			}
		},
		querySearch() {
			const query = encodeURIComponent(this.query_search.trim());
			if (query) {
				const searchUrl = process.env.VUE_APP_QUERY_SEARCH_URL + query + "&sp=0&searchType=PLAIN_TEXT&source=USER_INPUT&searchControl=TOP_PULLDOWN&autoComplete=false";
				window.open(searchUrl, '_blank');
			}
		},
		selected_provider(result) {
			const elementType = [
				'medical-history',
				'social-history',
				'family-history',
				'ongoing-concerns',
				'disease-registry',
				'risk-factor',
				'reminder'
			];

			const falseCount = result.filter(item => 
				elementType.includes(item.id) && item.checked === false
			).length;

			if(falseCount > 0) {
				this.isShowSelectAllButton = true;
			} else {
				this.isShowSelectAllButton = false;
			}

			this.$emit('clicked', result);
		}, 
		echarTransfer(){
			window.$("#e-chart-transfer").modal("show");
		},
		handleButtonClick() {
			this.$emit('buttonClick');
			const requiredToCheckedTrue = ['medical-history', 'social-history', 'family-history', 'reminder', 'ongoing-concerns', 'disease-registry', 'risk-factor'];
			this.echartElement.forEach(element => {
				if (requiredToCheckedTrue.includes(element.id)) {
					element.checked = true;
				}
			});
			this.isShowSelectAllButton = false;
			// this.boxElementToggleBtnText = (this.boxElementToggleBtnText == "Return To Selected")? "Show All Elements" : "Return To Selected";
		},
		async printEchart(){
			if(this.form.e_chart_transfer.is_all_time_range === false)
			{
				if(this.form.e_chart_transfer.from_date === null)
				{
					this.$filters.moshaToast('Please select from date.', "error");
					return false;
				}
				if(this.form.e_chart_transfer.to_date === null)
				{
					this.$filters.moshaToast('Please select from date.', "error");
					return false;
				}
				
				if(!this.validateDates()) {
					return false;
				}
			}
			else
			{
				this.form.e_chart_transfer.from_date = null;
				this.form.e_chart_transfer.to_date = null;
			}

			if(this.form.e_chart_transfer.is_demographic === false && this.form.e_chart_transfer.is_encounter_notes === false &&
				this.form.e_chart_transfer.is_measurements === false && this.form.e_chart_transfer.is_files === false && 
				this.form.e_chart_transfer.is_medication === false && this.form.e_chart_transfer.is_other_infos === false && 
				this.form.e_chart_transfer.is_preventions === false && this.form.e_chart_transfer.is_screening === false)
			{
				this.$filters.moshaToast('Please select e-chart report content', "error");
				return false;
			}

			const $this = this;
			$this.loaderPrint = true;

			try {
				const response = await axios.post("patient/e-chart/pdf-preview", {
					patient_id: this.$route.params.patient_id,
					data: this.form.e_chart_transfer,
					is_render_multi: ($this.form.e_chart_transfer.is_files ? 1 : 0)
				});

				if (response.status === 200) {
					const detail = response.data.data;
					const patient_detail = detail.patient_detail;
					let title = "";

					if ($this.form.e_chart_transfer.is_files) {
					let proccedFileCnt = 0;

					try {
						if (detail.enc_pdf_path) {
							proccedFileCnt++;
							const pdfUrls = detail.enc_pdf_path; // Assume this is an array of file paths.

							pdfUrls.forEach((pdfUrl) => {
								const title = `${patient_detail.full_name} E-Chart Transfer PDF`;
								const fileName = `${title}.pdf`;
								$this.openPdfInNewTab(pdfUrl, title, fileName);
							});
						} else {

							// Process all files in parallel with Promise.all
							await Promise.all(
								detail.files_data.map(async (fileDetail, index) => {
									const detailResponse = await axios.post(
									"patient/e-chart/render-multi-files",
									{
										filesData: fileDetail,
										patient_detail: patient_detail,
										pdf_base64: detail.pdf_base64,
										page: index,
									}
									);
		
									if (detailResponse.status === 200) {
										proccedFileCnt++;
										const fileData = detailResponse.data.data;
										const pdfUrls = fileData.enc_pdf_path; // Assume this is an array of file paths.
			
										pdfUrls.forEach((pdfUrl) => {
											const title =
											index === 0
												? `${patient_detail.full_name} E-Chart Transfer PDF`
												: `${patient_detail.full_name} Files Bulk ${index}`;
											const fileName = `${title}.pdf`;
											$this.openPdfInNewTab(pdfUrl, title, fileName);
										});
									}
								})
							);

						}	
						console.log(`All files processed successfully. Total: ${proccedFileCnt}`);
					} catch (error) {
						console.error("Error processing individual files:", error);
					} finally {
						$this.loaderPrint = false;
					}
					} else {
						$this.loaderPrint = false;
						const pdfUrls = detail.enc_pdf_path; // Assume this is an array of file paths.
						title = `${patient_detail.full_name} E-Chart Transfer PDF`;
						const fileName = `${title}.pdf`;
						$this.openPdfInNewTab(pdfUrls[0], title, fileName);
					}
				} else {
					$this.loaderPrint = false;
					$this.$filters.moshaToast(response.data.message, "error");
				}
			} catch (error) {
				$this.loaderPrint = false;
				console.error("Error during initial API call:", error);
				if (error.response?.status === 422) {
					$this.$filters.moshaToast(
					Object.values(error.response.data.data).join(", "),
					"error"
					);
				} else {
					$this.$filters.moshaToast(error.response?.data?.message || "Error", "error");
				}
			}


		},
		openPdfInNewTab(pdfUrl, title, fileName){
			// Create a custom HTML page to display the PDF
			const customPage = `
				<!DOCTYPE html>
				<html lang="en">
				<head>
					<meta charset="UTF-8">
					<meta name="viewport" content="width=device-width, initial-scale=1.0">
					<title>${title}</title>
					<style>
						body {
							margin: 0;
							display: flex;
							flex-direction: column;
							height: 100vh;
							justify-content: center;
							align-items: center;
						}
						iframe {
							width: 100%;
							height: calc(100% - 50px);
							border: none;
						}
						.download-btn {
							position: fixed;
							bottom: 10px;
							background-color: #007bff;
							color: white;
							padding: 10px 20px;
							border: none;
							border-radius: 5px;
							cursor: pointer;
							text-decoration: none;
							font-size: 16px;
						}
						.download-btn:hover {
							background-color: #0056b3;
						}
						.navigation-btns {
							position: fixed;
							top: 10px;
							display: flex;
							justify-content: center;
							width: 100%;
							gap: 10px;
						}
						.navigation-btns button {
							padding: 10px 20px;
							background-color: #28a745;
							color: white;
							border: none;
							border-radius: 5px;
							cursor: pointer;
						}
						.navigation-btns button:hover {
							background-color: #218838;
						}
					</style>
				</head>
				<body>
					<iframe src="${pdfUrl}"></iframe>
					<a href="${pdfUrl}" download="${fileName}" class="download-btn">Download ${fileName}</a>
				</html>
			`;

			// Open a new tab and write the content
			const newTab = window.open();
			if (newTab) {
				newTab.document.write(customPage);
				newTab.document.close();
			} else {
				console.error('Pop-up blocked. Please allow pop-ups to view the PDFs.');
			}
		},
		handleAllTImeRangeChange(event){
			if (event.target.checked){
				this.form.e_chart_transfer.from_date = null;
				this.form.e_chart_transfer.to_date = null;
			} else {
				this.form.e_chart_transfer.from_date = moment().format("YYYY-MM-DD");
				this.form.e_chart_transfer.to_date = moment().format("YYYY-MM-DD");
			}
		},
		exportEchart(){
			this.loaderFax = true
			this.getSpecialistList()
			this.v$['faxForm'].$reset();
			this.v$['emailForm'].$reset();
			this.v$['mailForm'].$reset();
			Object.assign(
				this.$store.state.consultations.faxForm,
				this.$store.state.consultations.defaultexportfrm
			);
			Object.assign(
				this.$store.state.consultations.emailForm,
				this.$store.state.consultations.defaultexportfrm
			);
			Object.assign(
				this.$store.state.consultations.mailForm,
				this.$store.state.consultations.defaultexportfrm
			);
			this.tagSpecialistLabel = ''
			this.specialist_option.map((item) => {
				item.checked = false;
			});
			this.isFaxFormVisible=true
			this.isEmailFormVisible=false
			this.isMailFormVisible=false
			this.loaderFax = false

			window.$("#add-exportconsultation-model").modal("show");
		},
		changeExportOpt(type){
			this.isFaxFormVisible=false
			this.isEmailFormVisible=false
			this.isMailFormVisible=false

			if (type == 'fax') {
				this.isFaxFormVisible=true
			} else if (type == 'email') {
				this.isEmailFormVisible=true
			} else if (type == 'mail') {
				this.isMailFormVisible=true
			}
		},
		selectedSpecialist(value){
			this.specialist_option = value;
			const specialist_list = this.specialist_option.map((item) => {
				return (item.checked == true) ? item.id : null;
			});

			this.faxForm.specialist_id = specialist_list.filter(n => n);
			if (this.faxForm.specialist_id.length > 0) {
				if (this.faxForm.specialist_id.length == 1) {
					this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist Selected`;
				} else {
					this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist(s) Selected`;
				}
			} else {
				this.tagSpecialistLabel = "";
			}
		},
		handleFaxInput() {
                if (this.faxForm.phoneNumbers.length >= 5) {
                    // If the limit is exceeded, use the debounced version
                    this.debounceFormatFaxNumber();
                } else {
                    // Otherwise, call the method directly
                    this.formatFaxNumber();
                }
		},
		handlePaste(event) {
			// Get the pasted content
			const pasteData = (event.clipboardData || window.clipboardData).getData("text");
			// Allow only digits and "-"
			let sanitizedData = pasteData.replace(/[^0-9-]/g, "");

			// If the sanitized data is empty, reject the paste
			if (!sanitizedData) {
				console.log("Invalid input: Only numbers and '-' are allowed.");
				event.preventDefault();
				return;
			}

			// Remove "-" from the sanitized data
			sanitizedData = sanitizedData.replace(/-/g, "");

			// Determine max length based on presence of "-"
			const currentLength = this.faxForm.fax_number ? this.faxForm.fax_number.length : 0;
			const allowedLength = this.maxLength - currentLength;
			// Trim pasted content to fit the remaining allowed length
			const trimmedData = sanitizedData.slice(0, allowedLength);

			// Update the input value
			this.faxForm.fax_number = (this.faxForm.fax_number || "") + trimmedData;
		},
		handleKeyDown(event) {
			if (event.key == 'Backspace' && this.faxForm.fax_number) {
				this.faxForm.fax_number = this.faxForm.fax_number.slice(0, -1);
				event.preventDefault();
			}
			if (this.faxForm.fax_number && this.faxForm.fax_number.length >= this.maxLength){
				event.preventDefault();
			}

			const allowedKeys = [
				"Backspace",
				"ArrowLeft",
				"ArrowRight",
				"Tab",
				"-",
			];
			const isAllowedKey = allowedKeys.includes(event.key);
			const isDigit = event.key >= "0" && event.key <= "9";
			const isPasteShortcut = (event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "v";
			if (!isDigit && !isAllowedKey && !isPasteShortcut) {
				event.preventDefault();
			}

		},
		formatFaxNumber() {
			const { fax_number, phoneNumbers } = this.faxForm;

			if (this.faxForm.phoneNumbers.length >= 5 && this.faxForm.fax_number) {
				this.$filters.moshaToast("You cannot add more than five recipient fax numbers for sending.", "error");
				this.faxForm.fax_number = '';
				return;
			}

			// Ensure no trailing commas and trim input
			if (fax_number.endsWith(',')) {
				this.faxForm.fax_number = fax_number.slice(0, -1).trim();
				return;
			}

			// Format the fax numbers
			const result = this.formatFaxWithCheckDuplicateNumber(fax_number, phoneNumbers);

			// Show the toast message, if try to add duplicate fax number
			if (result.hasDuplicates) {
				this.$filters.moshaToast("You cannot add recipient duplicate fax numbers.", "error");
			}

			// Update the model
			this.faxForm.fax_number = result.faxNumber;
			this.faxForm.phoneNumbers = result.phoneNumbers;
		},
		formatFaxWithCheckDuplicateNumber(faxNumber, phoneNumbers) {
			let faxRegex = /^(1[ .-]?)?(\d{3}|\(\d{3}\))[ .-]?\d{3}[ .-]?\d{4}$/; // Pattern for fax numbers
			faxNumber = faxNumber.trim(); // Trim spaces

			let faxNumbersArray = faxNumber.split(',').map(fax => fax.trim()); // Split input by comma

			// Check for invalid numbers if the last character is a comma
			if (faxNumber.endsWith(',')) {
				const lastFaxNumber = faxNumbersArray[faxNumbersArray.length - 2];
				if (lastFaxNumber && lastFaxNumber.length === 0 || !faxRegex.test(lastFaxNumber)) {
					faxNumbersArray.splice(faxNumbersArray.length - 2, 1); // Remove invalid number
					faxNumber = faxNumbersArray.join(', ');
					return { faxNumber, phoneNumbers };
				}
			}

			// Format and validate fax numbers
			const formattedFaxNumbers = faxNumbersArray.map(number => {
				const numericFax = number.replace(/\D/g, ""); // Remove non-numeric characters
				if (numericFax.length >= 3 && numericFax.length <= 6) {
					return `${numericFax.slice(0, 3)}-${numericFax.slice(3)}`;
				} else if (numericFax.length > 6) {
					return `${numericFax.slice(0, 3)}-${numericFax.slice(3, 6)}-${numericFax.slice(6, 10)}`;
				}
				return number; // Keep it as is if length < 3
			});

			let remainingFaxNumbers = [];
			let hasDuplicates = false;

			// Check for duplicates and validate numbers
			formattedFaxNumbers.forEach(formattedFax => {
				if (faxRegex.test(formattedFax)) {
					if (phoneNumbers.includes(formattedFax)) {
						hasDuplicates = true; // Detect duplicates
					} else {
						phoneNumbers.push(formattedFax); // Add valid number
					}
				} else {
					remainingFaxNumbers.push(formattedFax); // Store invalid numbers
				}
			});

			// Remove duplicates and sort numbers
			phoneNumbers = Array.from(new Set(phoneNumbers)).sort((a, b) => {
				const numA = a.replace(/\D/g, '');
				const numB = b.replace(/\D/g, '');
				return numB.localeCompare(numA);
			});

			// Join remaining invalid numbers back with commas
			faxNumber = remainingFaxNumbers.join(', ');

			return { faxNumber, phoneNumbers, hasDuplicates };
		},
		removeSelectedFax(numberToRemove){
			const updatedPhoneNumbers = this.faxForm.phoneNumbers.filter(phone => phone !== numberToRemove);
			this.faxForm.phoneNumbers = updatedPhoneNumbers
		},
		closeExportModel(){
			this.selectedItems = []
			this.selectAllChk = false
			const exportId = this.$route.query.exportId;
			if (exportId && Number(exportId) > 0) {
				this.$router.push({ name: 'PatientEChartConsultList', params: {patient_id: this.$route.params.patient_id} });
			}
			window.$("#add-exportconsultation-model").modal("hide");
		},
		getSpecialistList(){
                axios.post("custom-specialists/list", {specialist_type:this.form.service_id})
                    .then((response) => {
                        if (response.status == 200) {
                            const details = response.data.data;
							details.sort((a, b) => {
								return (b.is_preferred_specialist == true) - (a.is_preferred_specialist == true);
							});

                            const specialistTempData = details.map((item) => {
								let name = item.name;
								if (item.is_preferred_specialist) {
									name += "<p class='preferred-label float-end mb-0 mt-10'>(Preferred)</p>"
								}
                                if (item.address){
                                    name += "<p class='address-line mb-0'>"+item.address+"</p>"
                                }
								return { id: item.id, name: item.name, checked: false, displayLabel: name };
							});
                            this.specialist_option = specialistTempData;
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        this.loader = false;
                        console.log(error)
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
		},
		exportSubmit(){
			let form = null
			let formName = 'faxForm'
			if (this.isFaxFormVisible) {
				form = { ...this.faxForm };
				form.type = "fax"
				formName = 'faxForm';
				form.fax_number = this.faxForm['phoneNumbers'].join(', ');
			} else if(this.isEmailFormVisible){
				form = this.emailForm
				form.type = "email"
				formName = 'emailForm';
			} else if (this.isMailFormVisible){
				form = this.mailForm
				form.type = "mail"
				formName = 'mailForm';
			}
			form.ids = this.selectedItems
			form.patient_id = (this.$route.params.patient_id) ? this.$route.params.patient_id : null;
			form.patient_id = this.$route.params.patient_id,
			form.data = this.form.e_chart_transfer

			this.v$[formName].$validate();

			if (!this.v$[formName].$error) {
				this.faxLoader = true
				this.faxForm.fax_number = '';

				let url = "patient/e-chart/export"
					axios.post(url, form)
						.then((response) => {
							this.faxForm.fax_number = null
							this.faxLoader = false
							if (response.status == 200) {

								if (formName == 'faxForm') {
									let allSuccess = true
									for (const detail of response.data.data) {
										if (detail.status == 'success'){
											this.$filters.moshaToast(detail.msg, "success");
										} else {
											allSuccess = false
											this.$filters.moshaToast(detail.msg, "error");
										}
									}
									if (allSuccess) {
										this.selectedItems = []
										this.selectAllChk = false
										this.faxForm.phoneNumbers = [];
										window.$("#add-exportconsultation-model").modal("hide");
									}
									const exportId = this.$route.query.exportId;
									if (exportId && Number(exportId) > 0) {
										this.$router.push({ name: 'PatientEChartConsultList', params: {patient_id: this.$route.params.patient_id} });
									}
								} else {
									this.selectedItems = []
									this.selectAllChk = false
									this.$filters.moshaToast(response.data.message, "success");
									window.$("#add-exportconsultation-model").modal("hide");
								}

							} else {
								this.faxLoader = false
								this.$filters.moshaToast(response.data.message, "error");
							}
						}).catch(error => {
							this.faxLoader = false
							this.faxForm.fax_number = null
							this.$filters.moshaToast(error.response.data.message, "error");
						});
			} else {
				this.faxLoader = false
				this.faxForm.fax_number = null
				for (const [key] of Object.entries(this.v$[formName])) {
					if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}

		},
	},
	components: {
		selectCheckBox,
		'ejs-datepicker': DatePickerComponent,
		ValidationErrorMessageList
	},
	computed: {
		faxForm(){
			return this.$store.state.consultations.faxForm;
		},
		emailForm(){
			return this.$store.state.consultations.emailForm;
		},
		mailForm(){
			return this.$store.state.consultations.mailForm;
		},
		maxLength() {
			return (this.faxForm.fax_number && this.faxForm.fax_number.includes("-")) ? 12 : 10;
		},
	},
	validations() {
		return {
			faxForm: this.$store.state.consultations.validationRules.faxForm,
			emailForm: this.$store.state.consultations.validationRules.emailForm,
			mailForm: this.$store.state.consultations.validationRules.mailForm,
		};
	},
};
</script>
<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 px-0">
			<div class="admin-left-content account_seeting">
				<div class="d-flex align-items-center justify-content-between">
					<h1 class="h1-admin_head mb-0">Username & Password Settings</h1>
					<button class="comman_btn crt-user-bttn big_btn_new" @click="createUser" v-if="this.checkPermission()">Create User</button>
				</div>
				<div class="mt-30">
					<div class="table-responsive acct-stng-tbl user_passwrd_st_table">
						<table class="table table-borderless text-center mb-0">
							<thead>
								<tr>
									<th style="width: calc(var(--scale-ratio) * 130px);">USER ID</th>
									<th style="width: calc(var(--scale-ratio) * 360px);">USERNAME</th>
									<th style="width: calc(var(--scale-ratio) * 292px);">LAST NAME</th>
									<th style="width: calc(var(--scale-ratio) * 300px);">FIRST NAME</th>
									<th style="width: calc(var(--scale-ratio) * 278px);">USER TYPE</th>
									<th style="width: calc(var(--scale-ratio) * 244px);">ACTIVITY</th>
									<th style="width: calc(var(--scale-ratio) * 115px);"></th>
								</tr>
							</thead>
							<tbody v-if="userList.length">
								<tr v-for="(item,index) in userList" :key="index">
									<td>{{ item.provider_id }}</td>
									<td>{{ item.username }}</td>
									<td>{{ item.last_name }}</td>
									<td>{{ item.first_name }}</td>
									<td>{{ item.role }}</td>
									<td>
										<span class="gry_color" v-if="item.status_text=='Inactive'">{{ item.status_text }}</span>
										<span v-else>{{ item.status_text }}</span>
									</td>
									<td><img src="/images/dots-icon.svg" class="dot_icon" :class="{ 'invisible': !this.checkPermission() && (item.user_id!=this.authUserId) }" @click="this.editUser(item.user_id)"></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="text-end" v-if="this.lastpage!=1">
					<ul class="page-pagination p-0">
						<li style="margin-right: calc(var(--scale-ratio) * 40px);"><img src="/images/left-arrow-pagen.svg" class="img-fluid" @click="fetchUserList(this.currentPage - 1 )" v-if="this.currentPage != 1"></li>
						<li>{{ this.currentPage }}</li>
						<li>/</li>
						<li>{{ this.lastpage }}</li>
						<li style="margin-left: calc(var(--scale-ratio) * 40px);"><img src="/images/right-arrow-pagen.svg" class="img-fluid" @click="fetchUserList(this.currentPage + 1 )" v-if="this.currentPage != this.lastpage"></li>
					</ul>
				</div>
				
			</div>
		</div>
	</div>
	<div class="modal fade" id="create-user" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl crtusr-modal">
			<div class="modal-content auto_height_popup">
				<div class="modal-body adminuser-slide">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="arrow_img left-arrow-slide" v-if="this.isShowPrevIcon">
						<img src="/images/down-arrow_l.svg" class="img-fluid" @click="switchPrevTab">
					</div>
					<div class="box">
						<div>
							<ul class="comn_tab_menu">
								<li :class="{ 'active': this.isShowLogin }" @click="changeActiveTab('login_info')">
									<span class="step-tabs-user">1</span> Login Information</li>
								<li :class="{ 'active': this.isShowContact }" @click="changeActiveTab('contact_info')">
									<span class="step-tabs-user">2</span> Contact Information</li>
								<li :class="{ 'active': this.isShowBilling }" @click="changeActiveTab('billing_info')">
									<span class="step-tabs-user">3</span> Billing Information</li>
							</ul>
							<div class="login-info-cont" v-if="this.isShowLogin">
								<div class="left-specs">
									<div class="d-flex align-items-center mb-25">
										<div class="lft-ttl-de">
											<p class="mb-0 ">USER TYPE <span class="asterisk-red">*</span></p>
										</div>
										<div class="admn-usr-selct-box position-relative">
											<CustomDropDown :options="user_role_opt" :initialValue="form.user_role_name" @item-selected="handleItemSelected" fieldName="roleId" :isDisabled="this.form.id" :defaultSelectedClass="defaultSelectedClass"></CustomDropDown>
											<ValidationErrorMessageList :errors="v$.form.roleId.$errors" />
										</div>
									</div>
								</div>
								<div class="left-specs">
									<div class="d-flex mb-30">
										<div class="lft-ttl-de">
											<p class="mb-0 mt-10">USERNAME <span class="asterisk-red">*</span></p>
										</div>
										<div class="login-username-inp position-relative">
											<input type="text" class="form-control cursor-auto" v-model="form.username" @focusout="checkUsername" :readonly="this.form.id"  @input="this.usernameValidate">
											<div class="position-absolute l-h-n mb-20">
												<div class="invalid-feedback fs-14 l-h-n text-red" style="display:block;"
													v-for="(error, index) of v$.form?.username?.$errors" :key="index">
													<div v-if="index === 0 && error.$message !== 'Please enter username atleast 8 char.'">
														{{ error.$message }}
													</div>
												</div>
												<div class="error-msg" v-if="this.userNameAvailbilityErrorMsg && v$.form.username.$errors.length == 0">
													<ul class="mb-0 p-0 fs-14"><li class="d-block text-red">{{ this.userNameAvailbilityErrorMsg }}</li></ul>
												</div>
												<div class="success-msg" v-if="this.userNameAvailbilitySuccessMsg && v$.form.username.$errors.length == 0">
													<ul class="mb-0 p-0 fs-14"><li class="d-block text-success">{{ this.userNameAvailbilitySuccessMsg }}</li></ul>
												</div>
											</div>
										</div>
										<div>
											<p class="mb-0 lst-8-chract mt-10 white-space-pre" :class="{ 'text-red': this.usernameValidationErrorMsg && this.usernameValidationErrorMsg != 'Username must only contain letters, numbers, and hyphens(-) (no spaces).' && this.usernameValidationErrorMsg != 'Please enter username must be  max. 16 char.' }">at least 8 characters</p>
										</div>
									</div>
								</div>
								<div class="mb-25 position-relative admin-password-group">
									<div class="d-flex align-items-center">
										<p class="change-pass-pin mb-0"><span class="cursor-pointer" @click="this.changePassModelShow" v-if="this.form.id">Change Password</span></p>
										<div class="d-flex align-items-center">
											<div class="lft-ttl-de">
												<p class="mb-0 ">PASSWORD <span class="asterisk-red">*</span></p>
											</div>
											<div class="usr-dtls-inp psw-inpt-fill position-relative" :class="{ 'psw-ipt-disabled': this.form.id }">
												<input :type="inputPasswordType" class="form-control cursor-auto" v-if="this.form.id" v-model="form.password"  :disabled="this.form.id">
												<input :type="inputPasswordType" class="form-control cursor-auto" v-else v-model="form.password" @input="this.passwordValidate" :disabled="this.form.id">

												<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="!this.isPasswordEnc" @click="changePasEle('enc')">
												<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changePasEle('desc')">
											</div>
											<div>
												<p class="mb-0 lst-8-chract  white-space-pre" :class="{ 'text-red': this.passwordValidationErrorMsg && this.passwordValidationErrorMsg != 'Password must contain at least one uppercase letter, one lowercase letter, and one digit' }">at least 8 characters</p>
											</div>
										</div>
									</div>
									<div class="validtn-sect ml-20 position-relative">
										<p class="accs-evr-usr-tagline mb-0" :class="{ 'text-red': this.passwordValidationErrorMsg == 'Password must contain at least one uppercase letter, one lowercase letter, and one digit' }">(Must include 1 upper-case, 1 lower-case, and 1 number)</p>
										<!-- <div class="error-msg  position-absolute" v-if="this.passwordValidationErrorMsg"><ul class="mb-0 p-0 fs-14"><li class="d-block text-red">{{ this.passwordValidationErrorMsg }}</li></ul></div> -->

									</div>
								</div>
								<div class="d-flex align-items-center mb-35 position-relative">
									<p class="change-pass-pin mb-0"><span class="cursor-pointer" @click="this.changePinModelShow" v-if="this.form.id">Change Pin</span></p>
									<div class="d-flex align-items-center">
										<div class="lft-ttl-de">
											<p class="mb-0 ">PIN <span class="asterisk-red">*</span></p>
										</div>
										<div class="usr-dtls-inp position-relative " :class="{ 'psw-ipt-disabled': this.form.id }">
											<input :type="inputPinType" class="form-control cursor-auto" v-model="form.pin" @input="this.pinValidate" :disabled="this.form.id" maxlength="4">
											<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="!this.isPinEnc" @click="changePinEle('enc')">
											<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changePinEle('desc')">
											<!-- <ValidationErrorMessageList :errors="v$.form.pin.$errors" /> -->
											<div class="error-msg  position-absolute" v-if="this.pinValidationErrorMsg && this.pinValidationErrorMsg !='Please enter pin atleast 4 char.'"><ul class="mb-0 p-0 fs-14"><li class="d-block text-red">{{ this.pinValidationErrorMsg }}</li></ul></div>
										</div>
										<div>
											<p class="mb-0 lst-8-chract  white-space-pre" :class="{ 'text-red': this.pinValidationErrorMsg =='Please enter pin atleast 4 char.' }">at least 4 characters</p>
										</div>
									</div>
								</div>
								<div class="left-specs">
									<div class="accse-sect-infom">
										<div class="d-flex align-items-center stts-actv-div">
											<div class="lft-ttl-bigs">
												<p class="mb-0 ">STATUS <span class="asterisk-red">*</span></p>
											</div>
											<!-- <div class="usr-dtls-switch">
												<div class="acv-switch-btn l-h-n d-flex align-items-center">
													<input class='input-switch' type="checkbox" :checked="form.status === 'AC'" id="user_status" @change="changeStatus" :disabled="!this.checkPermission()"/>
													<label class="label-switch" for="user_status"></label>
													<span class="info-text">{{ displayStatus }}</span>
												</div>
											</div> -->
											<div class="statusSwitch d-flex align-items-center">
												<div class="usr-dtls-switch accs-swtch">
													<label class="switch l-h-n">
														<input class='input-switch' type="checkbox" :checked="form.status === 'AC'" id="user_status" @change="changeStatus"/>
														<span class="sw-slider"></span>
													</label>
												</div>
												<span class="info-text">{{ displayStatus }}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="left-specs">
									<div class="mb-30">
										<div class="d-flex align-items-center">
											<div class="lft-ttl-bigs">
												<p class="mb-0 ">ADMINISTRATION ACCESS</p>
											</div>
											<div class="usr-dtls-switch accs-swtch">
												<label class="switch d-block">
													<input type="checkbox" id="administration_access_status" :checked="this.form.administration_access==1" @change="changeAdminStatus">
													<span class="sw-slider"></span>
												</label>
											</div>
										</div>
										<div class="ml-20">
											<p class="accs-evr-usr-tagline  mb-0">(Access to every user / management settings, Payment Preferences, Audit Logs. Suggest Admin only)</p>
										</div>
									</div>
								</div>
								<div class="left-specs">
									<div class="d-flex align-items-center">
										<div class="lft-ttl-bigs">
											<p class="mb-0 ">PRESCRIPTION ACCESS</p>
										</div>
										<div class="usr-dtls-switch accs-swtch">
											<label class="switch d-block">
												<input type="checkbox" id="prescription_access_status" :checked="this.form.prescription_access==1" @change="changePresStatus">
												<span class="sw-slider"></span>
											</label>
										</div>
									</div>
									<div class="ml-20">
										<p class="accs-evr-usr-tagline  mb-0">(Ability to create, update, delete prescriptions. Suggest Doctor / Nurse only)</p>
									</div>
								</div>
							</div>

							<div class="contact-info-cont" v-if="this.isShowContact">
								<div class="ctct-details-fill">
									<div class="d-flex align-items-center mb-25">
										<div class="contact-frm-label">
											<p class="mb-0 ">FIRST NAME <span class="asterisk-red">*</span></p>
										</div>
										<div class="contact-dtls-inp position-relative">
											<input type="text" v-model="form.first_name" class="form-control cursor-auto">
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.first_name.$errors" />
											</div>
										</div>
									</div>
									<div class="d-flex align-items-center mb-25">
										<div class="contact-frm-label">
											<p class="mb-0 ">LAST NAME <span class="asterisk-red">*</span></p>
										</div>
										<div class="contact-dtls-inp position-relative">
											<input type="text" v-model="form.last_name" class="form-control cursor-auto">
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.last_name.$errors" />
											</div>
										</div>
									</div>
									<div class="d-flex align-items-center mb-30">
										<div class="contact-frm-label">
											<p class="mb-0 ">PHONE NUMBER <span class="asterisk-red">*</span></p>
										</div>
										<div class="contact-dtls-inp position-relative">
											<input type="text" v-model="form.phone_number" class="form-control cursor-auto">
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.phone_number.$errors" />
											</div>
										</div>
									</div>
									<div class="d-flex align-items-center mb-30">
										<div class="contact-frm-label">
											<p class="mb-0 ">EMAIL <span class="asterisk-red">*</span></p>
										</div>
										<div class="contact-dtls-inp position-relative">
											<input type="text" v-model="form.email" class="form-control cursor-auto">
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.email.$errors" />
											</div>
										</div>
									</div>
									<div class="d-flex align-items-center mb-35">
										<div class="contact-frm-label">
											<p class="mb-0 ">ADDRESS <span class="asterisk-red">*</span></p>
										</div>
										<div class="contact-dtls-inp">
											<input type="text" v-model="form.address" class="form-control cursor-auto">
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.address.$errors" />
											</div>
										</div>
									</div>
									<div class="d-flex" v-if="this.form.roleId == 2 || this.form.roleId == 3">
										<div class="contact-frm-label">
											<p class="mb-0 ">SIGNATURE <span class="asterisk-red" v-if="this.form.roleId == 2 || this.form.roleId == 3">*</span></p>
										</div>
										<div class="d-flex align-items-end">
											<div>
												<div class="signature-div d-flex justify-content-center align-items-center">
													<ejs-signature id="signature" ref="signatureObj" width="430" height="115" @change="onDraw"></ejs-signature>
												</div>
												<span class="d-block text-red mt-1 fs-14 position-absolute" v-if="this.signatureValidationMsg">{{ this.signatureValidationMsg }}</span>
											</div>
											<p class="signclear-link text-underline cursor-pointer" @click="clearSignature">clear</p>
										</div>
									</div>
								</div>
								
								<p class="signtags mb-0" v-if="this.form.roleId == 2 || this.form.roleId == 3">This signature will be used in paperwork such as Files or Templates for Doctors & Nurses</p>
							</div>

							<div class="billing-info-cont" v-if="this.isShowBilling && this.isBillingVisible">
								<div class="blng-from-sect">
									<div class="d-inline-block w-100">
										<div class="d-flex align-items-center justify-content-lg-center justify-content-xxl-start">
											<div class="billing-cps-left d-flex align-items-center mb-30">
												<div class="billing-info-label">
													<p class="mb-0">CPS ID / CNO ID <span class="asterisk-red">*</span></p>
												</div>
												<div class="billing-dtls-inp w-100 position-relative">
													<input type="text" class="form-control cursor-auto" v-model="form.cpsid"  @input="validateField('cpsid')" v-if="form.roleId==2" :readonly="!this.isBillingVisible" v-limit-length="6">
													<input type="text" class="form-control cursor-auto" v-model="form.cnoid"  @input="validateField('cnoid')" v-else :readonly="!this.isBillingVisible" v-limit-length="6">
													<div class="position-absolute">
														<ValidationErrorMessageList :errors="v$.form.cpsid.$errors" v-if="form.roleId==2"/>
														<ValidationErrorMessageList :errors="v$.form.cnoid.$errors" v-else />
													</div>
												</div>
											</div>
											<div class="billing-service-right d-flex align-items-center mb-30">
												<div class="billing-info-label" style="min-width: calc(var(--scale-ratio) * 210px);">
													<p class="mb-0">SERVICE PROVINCE <span class="asterisk-red">*</span></p>
												</div>
												<div class="service-pro-selct-box">
													<CustomDropDown :options="stateData"
													:initialValue="form.province_code_text" :isDisabled="!this.isBillingVisible"
													v-model="form.billing_province_code" @item-selected="handleItemSelected"
													fieldName="billing_province_code"></CustomDropDown>
													<ValidationErrorMessageList :errors="v$.form.billing_province_code.$errors" v-if="form.roleId == 2 | form.roleId == 3"/>
												</div>
											</div>
										</div>
									</div>
									<div class="d-inline-block w-100">
										<div class="d-flex align-items-center justify-content-lg-center justify-content-xxl-start">
											<div class="billing-cps-left d-flex align-items-center mb-25">
												<div class="billing-info-label">
													<p class="mb-0">PROVINCIAL BILLING # <span class="asterisk-red">*</span></p>
												</div>
												<div class="billing-dtls-inp w-100 position-relative">
													<input type="text" class="form-control cursor-auto" v-model="form.provincial_billing_no" :readonly="!this.isBillingVisible" v-limit-length="6"  @input="validateField('provincial_billing_no')">
													<div class="position-absolute">
														<ValidationErrorMessageList :errors="v$.form.provincial_billing_no.$errors" v-if="form.roleId == 2 | form.roleId == 3"/>
													</div>
												</div>
											</div>
											<div class="billing-service-right d-flex align-items-center mb-25">
												<div class="billing-info-label" style="min-width: calc(var(--scale-ratio) * 210px);">
													<p class="mb-0">GROUP BILLING # </p>
												</div>
												<div class="billing-dtls-inp w-100">
													<input type="text" class="form-control cursor-auto" v-model="form.group_billing_no" :readonly="!this.isBillingVisible" v-limit-length="6" @input="validateField('group_billing_no')">
												</div>
											</div>
										</div>
									</div>
									<div class="d-inline-block w-100">
										<div class="d-flex align-items-center justify-content-lg-center justify-content-xxl-start">
											<div class="billing-cps-left d-flex align-items-center mb-30">
												<div class="billing-info-label">
													<p class="mb-0">LOCATION INDICATOR <span class="asterisk-red">*</span></p>
												</div>

												<!-- Do not remove location_indicator_ele -->
												<div class="billing-info-loctn w-100 location_indicator_ele position-relative">
													<input type="text" class="form-control cursor-auto" v-model="form.service_location_text" :readonly="!this.isBillingVisible" @keyup="searchServiceLocation" @focus="searchServiceLocation">

													<div class="position-absolute">
														<ValidationErrorMessageList :errors="v$.form.service_location.$errors" v-if="form.roleId == 2 | form.roleId == 3"/>
													</div>

													<div class="search_drop_box new_big_drop billing-code-tbl-drpdown"
														v-if="filteredServiceLocations.length > 0" ref="serviceLocationRef">
														<slot v-for="(item, index) in filteredServiceLocations" :key="index">
															<div class="con_drop_line text-start" @click="selectServicelocation(item)">
																<div
																	class="man_haeding d-flex align-items-center">
																	<p class="mb-0">{{ item.title }}</p>
																</div>
															</div>
														</slot>
													</div>
												</div>
											</div>
											<div class="billing-service-right d-flex align-items-center mb-30">
												<div class="billing-info-label" style="min-width: calc(var(--scale-ratio) * 210px);">
													<p class="mb-0">VISIT LOCATION <span class="asterisk-red">*</span></p>
												</div>

												<div class="billing-visit-location w-100 visit_location_ele position-relative">
													<input type="text" class="form-control cursor-auto" v-model="form.visit_location_text" :readonly="!this.isBillingVisible" @keyup="searchVisitLocation" @focus="searchVisitLocation">

													<div class="position-absolute">
														<ValidationErrorMessageList :errors="v$.form.visit_location.$errors" v-if="form.roleId == 2 | form.roleId == 3"/>
													</div>

													<div class="search_drop_box new_big_drop billing-code-tbl-drpdown"
														v-if="filteredVisitLocations.length > 0" ref="visitLocationRef">
														<slot v-for="(item, index) in filteredVisitLocations" :key="index">
															<div class="con_drop_line text-start" @click="selectVisitlocation(item)">
																<div class="man_haeding d-flex align-items-center">
																	<p class="mb-0">{{ item.title }}</p>
																</div>
															</div>
														</slot>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
								<div class="blng-from-second">
									<div class="d-flex align-items-center mb-28">
										<div class="billing-info-admin-label">
											<p class="mb-0">SPECIALTY <span class="asterisk-red">*</span></p>
										</div>
										
										<div class="billing-specialty-select w-100 position-relative speciality_ele">
											<input type="text" class="form-control cursor-auto" v-model="form.specialty_code_desc" :readonly="!this.isBillingVisible" @keyup="searchSpecialityCode" @focus="searchSpecialityCode">

											<div class="position-absolute">
													<ValidationErrorMessageList :errors="v$.form.specialty_code.$errors" v-if="form.roleId == 2 | form.roleId == 3"/>
												</div>

											<div class="search_drop_box new_big_drop billing-code-tbl-drpdown"
												v-if="filteredSpecialityCode.length > 0" ref="specialityRef">
												<slot v-for="(item, index) in filteredSpecialityCode" :key="index">
													<div class="con_drop_line" @click="selectSpecialityCode(item)">
														<div class="man_haeding text-center">
															<p class="mb-0">{{ item.title }}</p>
														</div>
													</div>
												</slot>
											</div>
										</div>

									</div>
									<div class="d-flex align-items-center mb-28">
										<div class="billing-info-admin-label">
											<p class="mb-0">GO-SECURE EMAIL <span class="asterisk-red" v-if="this.form.roleId == 2">*</span></p>
										</div>
										<div class="billing-admin-inp w-100 position-relative">
											<input type="text" class="form-control cursor-auto" v-model="form.gosecure_email" :readonly="!this.isBillingVisible">
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.gosecure_email.$errors" v-if="form.roleId == 2 | form.roleId == 3"/>
											</div>
										</div>
									</div>
									
									<div class="d-flex align-items-center mb-28">
										<div class="billing-info-admin-label">
											<p class="mb-0">GO-SECURE PASSWORD <span class="asterisk-red" v-if="this.form.roleId == 2">*</span></p>
										</div>
										<div class="go-secure-psw-inpt w-100 position-relative">
											<input :type="inputGoSecurePasswordType" class="form-control cursor-auto" v-model="form.gosecure_password" :readonly="!this.isBillingVisible">
											<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="this.inputGoSecurePasswordType!='password'" @click="changeGoSecurePasEle('enc')">
											<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="changeGoSecurePasEle('desc')">
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.gosecure_password.$errors" v-if="form.roleId == 2 | form.roleId == 3"/>
											</div>
										</div>
									</div>
									<div class="d-flex align-items-center mb-28">
										<div class="billing-info-admin-label">
											<p class="mb-0">OLIS FIRST NAME</p>
										</div>
										<div class="billing-admin-inp w-100">
											<input type="text" class="form-control cursor-auto" v-model="form.olis_first_name" :readonly="!this.isBillingVisible">
										</div>
									</div>
									<div class="d-flex align-items-center mb-28">
										<div class="billing-info-admin-label">
											<p class="mb-0">OLIS LAST NAME</p>
										</div>
										<div class="billing-admin-inp w-100">
											<input type="text" class="form-control cursor-auto" v-model="form.olis_last_name" :readonly="!this.isBillingVisible">
										</div>
									</div>
									<div class="d-flex align-items-center mb-28">
										<div class="billing-info-admin-label">
											<p class="mb-0">OLIS PROVIDER ROLE</p>
										</div>
										<div class="billing-admin-inp w-100">
											<input type="text" class="form-control cursor-auto" v-model="form.olis_provider_role" :readonly="!this.isBillingVisible">
										</div>
									</div>
									<div class="d-flex align-items-center">
										<div class="billing-info-admin-label">
											<p class="mb-0">ONEID</p>
										</div>
										<div class="billing-admin-inp w-100">
											<input type="text" class="form-control cursor-auto" v-model="form.one_id" :readonly="!this.isBillingVisible">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="not-required-admin" v-if="this.isShowBilling && !this.isBillingVisible">
							<p class="not-required-line mb-0">This section is not required for Admin or Staff users.</p>
						</div>
						<div class="btn_part text-center">
							<button class="comman_brdr_btn big_btn mx40" v-if="this.isShowBtn" @click="cancelModel">Cancel</button>
							<button class="comman_btn big_btn mx40" v-if="this.isShowBtn" @click="save">Save <img src="images/loader.gif" v-if="this.loader"  style="width: calc(var(--scale-ratio) * 18px);" /></button>
						</div>
					</div>
					<div class="arrow_img right-arrow-slide" v-if="this.isShowNextIcon">
						<img src="/images/down-arrow_r_new.svg" class="img-fluid" @click="switchNextTab">
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal fade main_popup" id="change-password-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl chng-password_model">
			<div class="modal-content manage_cmn_pup ">
				<div class="modal-body adminuser-slide">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div class="password-new-update mb-30">
							<div class="d-flex align-items-center old-password-row">
								<p class="mb-0 password-label ">OLD PASSWORD</p>
								<div class="change-sicur-pass position-relative">
									<input :type="inputPasswordTypeChngPwd" class="form-control cursor-auto" v-model="changePwdForm.old_password">
									<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="this.inputPasswordTypeChngPwd != 'password'" @click="chngInputTypeChngePassForm('old_password','enc')">
									<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="chngInputTypeChngePassForm('old_password','desc')">
									<div class="position-absolute">
										<ValidationErrorMessageList :errors="v$.changePwdForm.old_password.$errors" v-if="form.id"/>
									</div>
								</div>
							</div>
							<div class="new-password-row">
								<div class="d-flex">
									<p class="mb-0 password-label  mt-5p">NEW PASSWORD</p>
									<div class="change-sicur-pass position-relative">
										<input :type="inputNewPasswordTypeChngPwd" id="new_password" class="form-control cursor-auto" v-model="changePwdForm.new_password" @input="this.newPasswordValidate()">
										<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="this.inputNewPasswordTypeChngPwd != 'password'" @click="chngInputTypeChngePassForm('new_password','enc')">
										<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="chngInputTypeChngePassForm('new_password', 'desc')">
										<div class="error-msg" v-if="form.id && v$.changePwdForm.new_password.$errors.length > 0">
											<ul class="mb-0 p-0 fs-14">
												<li v-for="(error, index) of v$.changePwdForm.new_password.$errors" :key="index" class="d-block text-red">
												<slot v-if="error.$message!='Password must contain at least one uppercase letter, one lowercase letter, and one digit'">{{ error.$message }}</slot>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<p class="accs-evr-usr-tagline  mb-0" :class="{ 'text-red': this.changepasswordValidationErrorMsg }">(At least 8 characters. Must include 1 upper-case, 1 lower-case, and 1 number)</p>
							</div>

							<div class="confirm-password-row">
								<div class="d-flex align-items-center">
									<p class="mb-0 password-label ">CONFIRM NEW PASSWORD</p>
									<div class="change-sicur-pass position-relative">
										<input :type="inputNewConfPasswordTypeChngPwd" class="form-control cursor-auto" v-model="changePwdForm.new_password_confirmation">
										<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="this.inputNewConfPasswordTypeChngPwd != 'password'" @click="chngInputTypeChngePassForm('new_password_confirmation','enc')">
										<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="chngInputTypeChngePassForm('new_password_confirmation','desc')">
										<div class="position-absolute">
											<ValidationErrorMessageList :errors="v$.changePwdForm.new_password_confirmation.$errors" v-if="form.id"/>
										</div>
									</div>
								</div>
							</div>
							<p class="mb-0 text-end">
								<span class="forgot-psswd-link cursor-pointer" @click="this.forgotPassword">Forgot Password?</span>
							</p>
						</div>
						<div class="btn_part text-center">
							<button class="comman_brdr_btn big_btn mx40" @click="cancelchangePasswordModel">Cancel</button>
							<button class="comman_btn big_btn mx40" @click="changePassword">Save <img src="images/loader.gif" v-if="this.changePasswordloader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade main_popup" id="change-pin-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl chng-pin_model">
			<div class="modal-content manage_cmn_pup ">
				<div class="modal-body adminuser-slide">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div class="password-new-update mb-30">
							<div class="d-flex align-items-center old-password-row">
								<p class="mb-0 pin-label ">OLD PIN</p>
								<div class="change-sicur-pass position-relative">
									<input :type="inputPasswordTypeChngPin" class="form-control cursor-auto" v-model="changePinForm.old_pin">
									<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="this.inputPasswordTypeChngPin != 'password'" @click="chngInputTypeChngePinForm('old_pin','enc')">
									<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="chngInputTypeChngePinForm('old_pin','desc')">
									<div class="position-absolute">
										<ValidationErrorMessageList :errors="v$.changePinForm.old_pin.$errors" v-if="form.id"/>
									</div>
								</div>
							</div>
							<div class="new-pin-row">
								<div class="d-flex">
									<p class="mb-0 pin-label  mt-5p">NEW PIN</p>
									<div class="change-sicur-pass position-relative">
										<input :type="inputNewPasswordTypeChngPin" id="new_pin" class="form-control cursor-auto" v-model="changePinForm.new_pin">
										<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="this.inputNewPasswordTypeChngPin != 'password'" @click="chngInputTypeChngePinForm('new_pin','enc')">
										<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="chngInputTypeChngePinForm('new_pin', 'desc')">
										<ValidationErrorMessageList :errors="v$.changePinForm.new_pin.$errors" v-if="form.id"/>
									</div>
								</div>
							</div>
							<div class="confirm-password-row">
								<div class="d-flex align-items-center">
									<p class="mb-0 pin-label ">CONFIRM NEW PIN</p>
									<div class="change-sicur-pass position-relative">
										<input :type="inputNewConfPasswordTypeChngPin" class="form-control cursor-auto" v-model="changePinForm.new_pin_confirmation">
										<img src="/images/eye-icon.svg" class="eyeicon-psw cursor-pointer" v-if="this.inputNewConfPasswordTypeChngPin != 'password'" @click="chngInputTypeChngePinForm('new_pin_confirmation','enc')">
										<img src="/images/eye-slash.svg" class="eyeicon-psw cursor-pointer" v-else @click="chngInputTypeChngePinForm('new_pin_confirmation','desc')">
										<div class="position-absolute">
											<ValidationErrorMessageList :errors="v$.changePinForm.new_pin_confirmation.$errors" v-if="form.id"/>
										</div>
									</div>
								</div>
							</div>
							<p class="mb-0 text-end"><span class="forgot-psswd-link cursor-pointer" @click="forgotPin">Forgot PIN?</span></p>
						</div>
						<div class="btn_part text-center">
							<button class="comman_brdr_btn big_btn mx40" @click="cancelchangePinModel">Cancel</button>
							<button class="comman_btn big_btn mx40" @click="changePin">Save <img src="images/loader.gif" v-if="this.changePinloader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import sidebar from './sidebar.vue'
	import axios from "@/scripts/axios.js";
	import CustomDropDown from '../base/formFields/CustomDropDown.vue';
	import useVuelidate from "@vuelidate/core";
	import ValidationErrorMessageList from '../base/ValidationErrorMessageList.vue';
	import { SignatureComponent } from "@syncfusion/ej2-vue-inputs";

	const limitLengthDirective = {
		beforeMount(el, binding) {
			el.addEventListener('input', () => {
			if (el.value.length > binding.value) {
				el.value = el.value.slice(0, binding.value)
				el.dispatchEvent(new Event('input'))
			}
			})
		}
	}

	export default {
		directives: {
			limitLength: limitLengthDirective
		},
		setup: () => {
			return { v$: useVuelidate() };
		},
		data(){
			return{
				isShowLogin:true,
				isShowContact:false,
				isShowBilling:false,
				userList:[],
				currentPage:1,
				lastpage:1,
				user_role_opt:[
					{ value: 1, title: "Administrator" },
					{ value: 2, title: "Doctor" },
					{ value: 3, title: "Nurse" },
					{ value: 4, title: "Staff" },
				],
				isPasswordEnc:true,
				isPinEnc:true,
				inputPasswordType:'password',
				inputPinType:'password',
				isShowBtn:false,
				isBillingVisible:false,
				isShowPrevIcon:false,
				isShowNextIcon:true,
				passwordValidationErrorMsg:null,
				usernameValidationErrorMsg:null,
				pinValidationErrorMsg:null,
				loader:false,
				stateData:[
					{ value: "AB", title: "AB - Alberta" },
					{ value: "BC", title: "BC - British Columbia" },
					{ value: "MB", title: "MB - Manitoba" },
					{ value: "NB", title: "NB - New Brunswick" },
					{ value: "NL", title: "NL - Newfoundland" },
					{ value: "NS", title: "NS - Nova Scotia" },
					{ value: "ON", title: "ON - Ontario" },
					{ value: "PE", title: "PE - Prince Edward Island" },
					{ value: "QC", title: "QC - Quebec" },
					{ value: "SK", title: "SK - Saskatchewan" },
					{ value: "NT", title: "NT - Northwest Territories" },
					{ value: "NU", title: "NU - Nunavut" },
					{ value: "YT", title: "YT - Yukon" },
					{ value: "OT", title: "OT - Other" },
				],
				specialityCodes:[],
				inputGoSecurePasswordType:"password",
				userNameAvailbilityErrorMsg:null,
				userNameAvailbilitySuccessMsg:null,
				permissionList:[],
				authUserId:null,
				signatureImage: null,
				isImageLoaded:true,
				isSignatureValidate:false,
				signatureValidationMsg:null,
				inputPasswordTypeChngPwd:"password",
				inputNewPasswordTypeChngPwd:"password",
				inputNewConfPasswordTypeChngPwd:"password",
				inputPasswordTypeChngPin:"password",
				inputNewPasswordTypeChngPin:"password",
				inputNewConfPasswordTypeChngPin:"password",
				changePinloader:false,
				changePasswordloader:false,
				defaultSelectedClass:null,
				changepasswordValidationErrorMsg:null,
				serviceLocationData:[
					{ "value": "None", "title": "None" },
					{ "value": "HDS", "title": "HDS - Hospital Day Surgery" },
					{ "value": "HED", "title": "HED - Hospital Emergency Department" },
					{ "value": "HIP", "title": "HIP - Hospital In-Patient" },
					{ "value": "HOP", "title": "HOP - Hospital Out-Patient" },
					{ "value": "HRP", "title": "HRP - Hospital Referred Patient" },
					{ "value": "IHF", "title": "IHF - Independent Health Facility" },
					{ "value": "OFF Physician", "title": "OFF - Office of Community Physician" },
					{ "value": "OTN", "title": "OTN - Ontario Telemedicine Network" },
					{ "value": "PDF", "title": "PDF - Private Diagnostic Facility" },
					{ "value": "RTF", "title": "RTF - Rehabilitation Treatment Facility" }
				],
				filteredServiceLocations: [],
				filteredVisitLocations:[],
				filteredSpecialityCode:[],
				visitLocationData:[]
			}
		},
		components:{
			sidebar,
			CustomDropDown,
			ValidationErrorMessageList,
			"ejs-signature": SignatureComponent,
		},
		methods: {
			validateField(field){
				this.v$.form[field].$touch();
			},
			createUser(){
				this.isImageLoaded = true
				this.resetForm()
				if ([1,4].includes(this.form.roleId)) {
					this.isBillingVisible = false
				} else {
					this.isBillingVisible = true
				}
				window.$("#create-user").modal("show")
			},
			editUser(id){
				this.resetForm()
				this.edit(id)
				this.isShowBtn = true
			},
			changeActiveTab(type){
				this.isShowBtn = false
				this.isShowLogin = false
				this.isShowContact = false
				this.isShowBilling = false
				if (type == 'login_info'){
					let roleId = this.form.roleId
					this.form.roleId = null
					this.form.roleId = roleId
					this.form.user_role_name = (this.user_role_opt.find(item => item.value === roleId) || {}).title || "Select User Type";
					this.isShowLogin = true
					this.isShowPrevIcon = false
					this.isShowNextIcon = true
				}else if (type == 'contact_info'){
					this.isShowContact = true
					this.isShowPrevIcon = true
					this.isShowNextIcon = true

					if (this.form.id && !this.isImageLoaded) {
						setTimeout(() => {
							this.openSignature()
							this.isImageLoaded = true
						}, 200);
					} else {
						setTimeout(() => {
							this.openSignature()
						}, 200);
					}
				}if (type == 'billing_info'){
					this.isShowBilling = true
					this.isShowBtn = true
					this.isShowPrevIcon = true
					this.isShowNextIcon = false					
				}
				if (this.form.id) {
					this.isShowBtn = true
				}
			},
			fetchUserList(pageNo=1){
				if (pageNo <= 1) {
					pageNo = 1
				}

				let params = {
					'page':pageNo
				}
				this.$store.state.loader = true;
				axios.post('user/list', params)
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						let responseDetail = response.data.data;
						this.userList = responseDetail.data;
						this.currentPage = responseDetail.current_page
						this.lastpage = responseDetail.last_page
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
			},
			handleItemSelected(values) {
				this.form[values.fieldName] = values.item.value
				if (values.fieldName == 'roleId') {
					this.defaultSelectedClass = 'active'
					this.form['user_role_name'] = values.item.title
					if ([1,4].includes(this.form.roleId)) {
						this.form.cpsid = null
						this.form.cnoid = null
						this.form.provincial_billing_no=null
						this.form.group_billing_no=null
						this.form.gosecure_password=null
						this.inputGoSecurePasswordType='password'
						this.form.visit_location='0000 - [] No Location'
						this.form.province_code_text='ON - Ontario'
						this.form.specialty_code_text='Select Specialty Code'
						this.form.gosecure_email=null
						this.form.olis_first_name=null
						this.form.olis_last_name=null
						this.form.olis_provider_role=null
						this.form.one_id=null


						this.isBillingVisible = false
						this.isSignatureValidate = false
						this.signatureValidationMsg = null
					} else {
						this.isBillingVisible = true
					}
				} else if(values.fieldName == "billing_province_code"){
					this.form.province_code_text = values.item.title
				} else if (values.fieldName == 'specialty_code') {
					this.form.specialty_code_text = values.item.title
				}
			},
			changePasEle(action) {
				if (this.form.id) {
					return false
				}
				if (action == 'enc') {
					this.isPasswordEnc = true
					this.inputPasswordType = 'password'
				} else {
					this.isPasswordEnc = false
					this.inputPasswordType = 'text'
				}
			},
			changePinEle(action) {
				if (this.form.id) {
					return false
				}
				if (action == 'enc') {
					this.isPinEnc = true
					this.inputPinType = 'password'
				} else {
					this.isPinEnc = false
					this.inputPinType = 'text'
				}

			},
			chngInputTypeChngePassForm(field,action){
				if (field == 'old_password' ) {
					if (action == 'enc') {
						this.inputPasswordTypeChngPwd = 'password'
					} else {
						this.inputPasswordTypeChngPwd = 'text'
					}

				}else if(field == 'new_password' ) {
					if (action == 'enc') {
						this.inputNewPasswordTypeChngPwd = 'password'
					} else {
						this.inputNewPasswordTypeChngPwd = 'text'
					}
				}else if(field == 'new_password_confirmation' ) {
					if (action == 'enc') {
						this.inputNewConfPasswordTypeChngPwd = 'password'
					} else {
						this.inputNewConfPasswordTypeChngPwd = 'text'
					}
				}
			},
			chngInputTypeChngePinForm(field,action){
				if (field == 'old_pin' ) {
					if (action == 'enc') {
						this.inputPasswordTypeChngPin = 'password'
					} else {
						this.inputPasswordTypeChngPin = 'text'
					}

				}else if(field == 'new_pin' ) {
					if (action == 'enc') {
						this.inputNewPasswordTypeChngPin = 'password'
					} else {
						this.inputNewPasswordTypeChngPin = 'text'
					}
				}else if(field == 'new_pin_confirmation' ) {
					if (action == 'enc') {
						this.inputNewConfPasswordTypeChngPin = 'password'
					} else {
						this.inputNewConfPasswordTypeChngPin = 'text'
					}
				}
			},
			resetForm(){
				Object.assign(
					this.$store.state.user.form,
					this.$store.state.user.defaultFormData,
				);
				this.v$.$reset();
				this.changeActiveTab('login_info')
				this.pinValidationErrorMsg = null
				this.passwordValidationErrorMsg = null
				this.userNameAvailbilityErrorMsg = null
				this.userNameAvailbilitySuccessMsg = null
				this.isSignatureValidate = false
				this.signatureValidationMsg = null
				this.isPasswordEnc = true
				this.isPinEnc = true
				this.inputPasswordType = 'password'
				this.inputPinType = 'password'
				this.defaultSelectedClass = null
				this.filteredServiceLocations = []
				this.filteredVisitLocations = []
				this.clearSignature()
			},
			cancelModel(){
				this.resetForm()
				window.$("#create-user").modal("hide")
			},
			async save(){

				const $this = this
				let formName = 'form'
				let isValidate = true
				this.v$[formName].$validate();
				isValidate = !this.v$[formName].$error
				if (!this.form.id) {
					if (!this.passwordValidate()) {
						this.changeActiveTab('login_info')
						return false
					}
					if (!this.pinValidate()){
						this.changeActiveTab('login_info')
						return false
					}
				}	

				if (this.userNameAvailbilityErrorMsg) {
					this.changeActiveTab('login_info')
					return false
				}		

				if ((this.form.roleId == 2 || this.form.roleId == 3) && !this.isSignatureValidate) {
					this.signatureValidationMsg = "Signature Required";
					this.changeActiveTab('contact_info');
					return false
				}
				
				if (isValidate) {
					this.loader = true
					const form = this.form;

					let url;
					let message;
					if (this.form.id) {
						url = "user/update";
						message = "Account Settings has been updated successfully";
					} else {
						url = "user/store";
						message = "Account Settings has been added successfully";
					}

					axios.post(url, form,  {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
						})
						.then((response) => {
							if (response.status == 200) {
								this.loader = false
								$this.$filters.moshaToast(message, "success")
								$this.cancelModel();
								$this.fetchUserList(this.currentPage)
							} else {
								this.loader = false
								this.$filters.moshaToast(response.data.message, "error")
							}
						}).catch(error => {
							this.loader = false
							if (error.response.status === 422) {
								this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
								console.log(Object.values(error.response.data.data).join(', '))
							} else {
								this.$filters.moshaToast(error.response.data.message, "error");
							}
						})
				} else {
					let validateform = null;
					console.log(Object.entries(this.v$[formName]))
					for (const [key] of Object.entries(this.v$[formName])) {
						if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
							if (['roleId', 'username', 'password', 'pin'].includes(key)){
								validateform = 'login_info'
							} else if (validateform != 'login_info' && ['first_name', 'last_name', 'email'].includes(key)){
								if (this.passwordValidationErrorMsg || this.pinValidationErrorMsg) {
									validateform = 'login_info'
								} else {
									validateform = 'contact_info'
								}
							}

							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}
					if (validateform) {
						this.changeActiveTab(validateform)
					}
				}
			},
			edit(id){
				this.isImageLoaded = false
				this.$store.state.loader = true;
				let url = "user/retrieve";

				axios.post(url, {id:id})
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						let detail = response.data.data
                        this.$store.state.user.form = response.data.data;
						detail.user_role_name = (this.user_role_opt.find(item => item.value === detail.roleId) || {}).title || "Select User Type";
						detail.specialty_code_desc = detail.specialty_description+' ('+detail.specialty_code+')'
						if (detail.service_location) {
							detail.service_location_text = detail.service_location.split(' - ')[0]
						}
						if (detail.visit_location) {
							this.form.visit_location_text = detail.visit_location.split(' - ')[0]
						}

						if (detail.user_role_name != 'Select User Type') {
							this.defaultSelectedClass = 'active'
						}
						this.form.province_code_text = (this.stateData.find(item => item.value == detail.billing_province_code) || {}).title || "Select Service Province";

						this.signatureImage = detail.base64_sign
						if (detail.base64_sign) {
							this.isSignatureValidate = true
						} else {
							this.isSignatureValidate = false
						}
						
						if ([1,4].includes(this.form.roleId)) {
							this.isBillingVisible = false
						} else {
							this.isBillingVisible = true
						}
						this.passwordValidationErrorMsg = null
						window.$("#create-user").modal("show");

                    }
                }).catch(error => {
					this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
			},
			switchPrevTab(){
				if (this.isShowBilling) {
					this.changeActiveTab('contact_info')
				} else {
					this.changeActiveTab('login_info')
				}
			},
			switchNextTab(){
				if (this.isShowLogin) {
					this.changeActiveTab('contact_info')
				} else {
					this.changeActiveTab('billing_info')
				}
			},
			changePassModelShow(){
				this.resetChangePasswordForm()
				window.$("#change-password-model").modal("show")
			},
			changePinModelShow(){
				this.resetChangePinForm()
				window.$("#change-pin-model").modal("show")
			},
			changeStatus(event){
				if(localStorage.getItem("authUserRole") == "Admin") {
					this.form.status = event.target.checked ? 'AC' : 'IN';	
				} else {
					event.target.checked = this.form.status === 'AC';
					this.$filters.moshaToast("You must be admin to perform this action", "error");
				}
			},
			changeAdminStatus(event){
				if(localStorage.getItem("authUserRole") == "Admin") {
					this.form.administration_access = event.target.checked ? 1 : 0;
				} else {
					event.target.checked = this.form.administration_access === 1;
					this.$filters.moshaToast("You must be admin to perform this action", "error");
				}
			},
			changePresStatus(event){
				if(localStorage.getItem("authUserRole") == "Admin") {
					this.form.prescription_access = event.target.checked ? 1 : 0;
				} else {
					event.target.checked = this.form.prescription_access === 1;
					this.$filters.moshaToast("You must be admin to perform this action", "error");
				}
			},
			clearSignature(){
				if (this.$refs.signatureObj) {
					this.$refs.signatureObj.clear();
					this.onDraw()
				}
			},
			newPasswordValidate(){
				const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^\s]+$/;
					if(!pattern.test(this.changePwdForm.new_password)){
						this.changepasswordValidationErrorMsg = 'Password must contain at least one uppercase letter, one lowercase letter, and one digit'
						return false
				}
				this.changepasswordValidationErrorMsg=null
				return true
			},
			passwordValidate(){
				this.passwordValidationErrorMsg = null
				if (!this.form.id){
					if (!this.form.password) {
						this.passwordValidationErrorMsg = 'Please enter password.'
						return false
					} else {
						if (this.form.password.length < 8) {
							this.passwordValidationErrorMsg = 'Please enter password atleast 8 char.'
							return false
						}
	
						const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^\s]+$/;
						if(!pattern.test(this.form.password)){
							this.passwordValidationErrorMsg = 'Password must contain at least one uppercase letter, one lowercase letter, and one digit'
							return false
						}
	
					}
				}

				return true
			},
			usernameValidate(){
				if (!this.form.username) {
					this.usernameValidationErrorMsg = 'Please enter username.'
					return false
				} else {
					if (this.form.username && this.form.username.length < 8) {
						this.usernameValidationErrorMsg = 'Please enter username atleast 8 char.'
						return false
					}
					if (this.form.username && this.form.username.length > 16) {
						this.usernameValidationErrorMsg = 'Please enter username must be  max. 16 char.'
						return false
					}
				}
				this.usernameValidationErrorMsg = null
				return true
			},
			pinValidate(){
				if (!this.form.pin) {
					this.pinValidationErrorMsg = 'Please enter pin.'
					return false
				} else {
					if (this.form.pin.length < 4) {
						this.pinValidationErrorMsg = 'Please enter pin atleast 4 char.'
						return false
					}
					if (this.form.pin.length > 4) {
						this.pinValidationErrorMsg = 'Please enter pin max 4 char.'
						return false
					}
					if (!this.checkIfDigitsOnly(this.form.pin)) {
						this.pinValidationErrorMsg = 'Please enter pin digit only.'
						return false
					}

				}

				this.pinValidationErrorMsg = ''
				return true
			},
			checkIfDigitsOnly(value) {
				// Regular expression to match only digits
				const digitPattern = /^\d+$/;
				
				// Check if the value consists only of digits
				return digitPattern.test(value);
			},
			async onDraw(){
				// Get a reference to the ejs-signature component
				const signatureComponent = this.$refs.signatureObj;

				// Check if the ref is defined
				if (signatureComponent) {
					// Save the signature data as a Blob
					const signatureData = signatureComponent.saveAsBlob();
					console.log(signatureData);

					this.signatureValidationMsg = null
					if (signatureData.size <= 1224) {
						this.isSignatureValidate = false
					} else {
						this.isSignatureValidate = true
					}

					const signatureBase64 = await this.blobToBase64(signatureData);
					this.signatureImage = signatureBase64;
					console.log(signatureBase64);


					// Update local form data
					this.form.signatureData = signatureData;
				} else {
					console.error("Signature component is not mounted or ref is not set");
				}
			},
			blobToBase64(blob) {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = () => {
						resolve(reader.result);
					};
					reader.onerror = reject;
					reader.readAsDataURL(blob);
				});
			},
			base64FileSize(base64String) {
				// Decode base64 string to binary data
				let decodedString = atob(base64String.split(',')[1]);

				// Calculate the length in bytes
				let fileSizeInBytes = decodedString.length;

				// Convert to kilobytes
				let fileSizeInKB = fileSizeInBytes / 1024;

				return fileSizeInKB;
			},
			fetchSpecialityCodes(){
				axios.get("json_autocomplete/speciality-code?is_front_format=1")
						.then((response) => {
							this.specialityCodes = response.data.data;

					})
			},
			changeGoSecurePasEle(action){
				if (action == 'enc') {
					this.inputGoSecurePasswordType = 'password'
				} else {
					this.inputGoSecurePasswordType = 'text'
				}
			},
			checkUsername(){
				if (this.form.id || this.usernameValidationErrorMsg) {
					return true;
				}

				this.userNameAvailbilityErrorMsg = this.userNameAvailbilitySuccessMsg = null
				if (!this.form.username.includes(' ') && this.form.username.length > 7) {
					axios.post('user/check-username-availbility', {'username':this.form.username, 'id':this.form.user_id})
					.then((response) => {
						this.$store.state.loader = false;
						if (response.status == 200) {
							this.userNameAvailbilitySuccessMsg = response.data.message
						} else {
							this.userNameAvailbilityErrorMsg = response.data.message
						}
					}).catch(error => {
						this.$store.state.loader = false;
						if (error.response.status === 422) {
							console.log(Object.values(error.response.data.data).join(', '))
						} else {
							this.userNameAvailbilityErrorMsg = error.response.data.message
						}
					});
				}
			},
			getPermissionList(){
                axios.post('user/get-permission')
                .then((response) => {
                    if (response.status == 200) {
                        this.permissionList = response.data.data
						console.log(this.permissionList)
                    } else {
                        this.permissionList = []
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.permissionList = []
                    } else {
                        this.permissionList = []
                    }
                });
            },
            checkPermission(){
				if (!this.permissionList) {
					this.getPermissionList()
				}
                if (this.permissionList && this.permissionList.administration_access) {
                    return true
                } else {
                    return false
                }
            },
			openSignature() {
				try {
					const signatureComponent = this.$refs.signatureObj;
					if (signatureComponent) {
						const ej2Instance = signatureComponent.ej2Instances;
						if (ej2Instance) {
							if (this.signatureImage) {
								ej2Instance.load(this.signatureImage);
								this.errorMessage = null;  // Clear error message if successful
								this.isImageLoaded = true
							} else {
								this.isImageLoaded = false
								this.errorMessage = 'No signature to load.';
								console.log("outside if3")
							}
						} else {
							this.isImageLoaded = false
							console.log("outside if2")
						}
					} else {
						this.isImageLoaded = false
						console.log("outside if")
					}
				} catch (error) {
					this.errorMessage = 'Failed to load signature.';
					console.error(error);
				}
			},
			changePassword(){
				const $this = this
				let formName = 'changePwdForm'
				this.v$[formName].$validate();

				if (!this.newPasswordValidate()) {
					return false
				}

				if (!this.v$[formName].$error) {
					if (this.changePwdForm.new_password_confirmation != this.changePwdForm.new_password) {
						this.$filters.moshaToast("Password confirmation does not match with new password.", "error");
						return false
					}

					this.changePasswordloader = true
					this.changePwdForm.id = this.form.user_id

					axios.post('user/change-password', this.changePwdForm)
					.then((response) => {
						this.changePasswordloader = false
						if (response.status == 200) {
							window.$("#change-password-model").modal("hide")
							$this.$filters.moshaToast(response.data.message, "success")
						} else {
							$this.$filters.moshaToast(response.data.message, "error")
						}
					}).catch(error => {
						this.changePasswordloader = false
						if (error.response.status === 422) {
							console.log(Object.values(error.response.data.data).join(', '))
							$this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error")
						} else {
							$this.$filters.moshaToast(error.response.data.message, "error")
						}
					});

				}else {
					console.log(Object.entries(this.v$[formName]))
					for (const [key] of Object.entries(this.v$[formName])) {
						if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}

					return false

				}
			},
			resetChangePasswordForm(){
				Object.assign(
					this.$store.state.user.changePwdForm,
					this.$store.state.user.defaultChangePwdForm,
				);
				this.v$['changePwdForm'].$reset();
				this.changepasswordValidationErrorMsg = null
				this.inputNewConfPasswordTypeChngPwd = 'password'
				this.inputPasswordTypeChngPwd="password"
				this.inputNewPasswordTypeChngPwd="password"
			},
			resetChangePinForm(){
				Object.assign(
					this.$store.state.user.changePinForm,
					this.$store.state.user.defaultChangePinForm,
				);
				this.v$['changePinForm'].$reset();
				this.inputPasswordTypeChngPin = 'password'
				this.inputNewPasswordTypeChngPin="password"
				this.inputNewConfPasswordTypeChngPin="password"
			},
			cancelchangePasswordModel(){
				window.$("#change-password-model").modal("hide")
			},
			cancelchangePinModel(){
				window.$("#change-pin-model").modal("hide")
			},
			changePin(){
				const $this = this
				let formName = 'changePinForm'
				this.v$[formName].$validate();
				if (!this.v$[formName].$error) {
					if (this.changePinForm.new_pin_confirmation != this.changePinForm.new_pin) {
						this.$filters.moshaToast("New Pin confirmation does not match with new pin.", "error");
						return false
					}

					this.changePinloader = true
					this.changePinForm.id = this.form.user_id

					axios.post('user/change-pin', this.changePinForm)
					.then((response) => {
						this.changePinloader = false
						if (response.status == 200) {
							window.$("#change-pin-model").modal("hide")
							$this.$filters.moshaToast(response.data.message, "success")
						} else {
							$this.$filters.moshaToast(response.data.message, "error")
						}
					}).catch(error => {
						this.changePinloader = false
						if (error.response.status === 422) {
							console.log(Object.values(error.response.data.data).join(', '))
							$this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error")
						} else {
							$this.$filters.moshaToast(error.response.data.message, "error")
						}
					});

				}else {
					console.log(Object.entries(this.v$[formName]))
					for (const [key] of Object.entries(this.v$[formName])) {
						if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}

					return false

				}
			},
			forgotPassword(){
				let $this = this
				axios.post('user/reset-password', {id:this.form.user_id})
					.then((response) => {
						if (response.status == 200) {
							window.$("#change-password-model").modal("hide")
							$this.$filters.moshaToast(response.data.message, "success")
						} else {
							$this.$filters.moshaToast(response.data.message, "error")
						}
					}).catch(error => {
						this.changePinloader = false
						if (error.response.status === 422) {
							console.log(Object.values(error.response.data.data).join(', '))
							$this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error")
						} else {
							$this.$filters.moshaToast(error.response.data.message, "error")
						}
					});
			},
			forgotPin(){
				let $this = this
				axios.post('user/reset-pin', {id:this.form.user_id})
					.then((response) => {
						if (response.status == 200) {
							window.$("#change-pin-model").modal("hide")
							$this.$filters.moshaToast(response.data.message, "success")
						} else {
							$this.$filters.moshaToast(response.data.message, "error")
						}
					}).catch(error => {
						this.changePinloader = false
						if (error.response.status === 422) {
							console.log(Object.values(error.response.data.data).join(', '))
							$this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error")
						} else {
							$this.$filters.moshaToast(error.response.data.message, "error")
						}
					});
			},
			searchServiceLocation(){
				this.resetSeachDropdown()

				if (this.form.service_location_text == 'None' || !this.form.service_location_text) {
					this.form.service_location_text = ''
					this.form.service_location = ''
				}
				this.filteredServiceLocations =  this.serviceLocationData.filter(item =>
					item.title.toLowerCase().includes(this.form.service_location_text.toLowerCase())
				);
			},
			selectServicelocation(item){
				this.form.service_location_text = item.value
				this.form.service_location = item.value
				this.filteredServiceLocations = []
			},
			searchVisitLocation(){
				this.resetSeachDropdown()

				if (!this.form.visit_location_text) {
					this.form.visit_location_text = ''
					this.form.visit_location = ''
				}

				this.filteredVisitLocations =  this.visitLocationData.filter(item =>
					item.title.toLowerCase().includes(this.form.visit_location_text.toLowerCase())
				);
			},
			selectVisitlocation(item){
				this.form.visit_location_text = item.value
				this.form.visit_location = item.value
				this.filteredVisitLocations = []
			},
			searchSpecialityCode(){
				this.resetSeachDropdown()

				if (!this.form.specialty_code_desc) {
					this.form.specialty_code_desc = ''
					this.form.specialty_code = ''
					this.form.specialty_description = ''
				}
				this.filteredSpecialityCode =  this.specialityCodes.filter(item =>
					item.title.toLowerCase().includes(this.form.specialty_code_desc.toLowerCase())
				);
			},
			selectSpecialityCode(item){
				this.form.specialty_code_desc = item.title
				this.form.specialty_code = item.value
				this.form.specialty_description = item.desc
				this.filteredSpecialityCode = []
			},

			handleClickOutside(event) {
				const serviceLocationRefEle = this.$refs.serviceLocationRef;
				if (serviceLocationRefEle && !serviceLocationRefEle.contains(event.target) && !event.target.closest(`.location_indicator_ele`)) {
					if (this.filteredServiceLocations.length > 0) {
						this.filteredServiceLocations = [];
					}
				}

				const visitLocationRefEle = this.$refs.visitLocationRef;
				if (visitLocationRefEle && !visitLocationRefEle.contains(event.target) && !event.target.closest(`.visit_location_ele`)) {
					if (this.filteredVisitLocations.length > 0) {
						this.filteredVisitLocations = [];
					}
				}

				const specialityRefEle = this.$refs.specialityRef;
				if (specialityRefEle && !specialityRefEle.contains(event.target) && !event.target.closest(`.speciality_ele`)) {
					if (this.filteredSpecialityCode.length > 0) {
						this.filteredSpecialityCode = [];
					}
				}

			},
			resetSeachDropdown(){
				this.filteredServiceLocations = []
				this.filteredSpecialityCode = []
				this.filteredVisitLocations = []
			},
			fetchVisitLocationMaster(){
				axios.get("json_autocomplete/visit-location-master")
						.then((response) => {
							this.visitLocationData = [
								{ value: "0000", title: "0000 - [] No Location" }, // Add the first row
								...response.data.data.map(item => {
										const [value, title] = item.split('-');
										console.log(title)
										return {
											title: item, // Use the title part or the entire item if no title part is found
											value: value || '',  // Default to an empty string if no value part is found
										};
									})
								];
							

					})

			}

		},
		mounted(){
			this.authUserId = localStorage.getItem("userId")
			this.getPermissionList()
			this.fetchSpecialityCodes()
			this.fetchUserList()
			this.fetchVisitLocationMaster()
			document.addEventListener('click', this.handleClickOutside);
		},
		computed: {
			form() {
				return this.$store.state.user.form;
			},
			displayStatus() {
				return this.form.status === 'AC' ? 'Active' : 'Inactive';
			},
			changePwdForm() {
				return this.$store.state.user.changePwdForm;
			},
			changePinForm(){
				return this.$store.state.user.changePinForm;
			},
			// filteredErrors() {
			// 	return this.changePwdForm.new_password.$errors.some(error => error.$message === 'Password must contain at least one uppercase letter, one lowercase letter, and one digit');
			// }
		},
		validations() {
			return {
				form:this.$store.state.user.validationRules.form,
				changePwdForm:this.$store.state.user.validationRules.passwordChangeForm,
                changePinForm:this.$store.state.user.validationRules.changePinForm,
			}
		},
	};
</script>
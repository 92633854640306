<template>
    <div
      class="modal fade"
      id="verification_code_modal"
      tabindex="-1"
      aria-hidden="true"
      aria-labelledby="modal-title"
      role="dialog"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl verify_code_modal">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="btn-close popup_close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="box">
              <div>
                <h3 class="main_heading mb-40 l-h-n" id="modal-title">{{ title }}</h3>
                <div class="verify_digit_code text-center">
                  <p class="text-center fs-16 mb-35 pb-15 pt-15">
                    {{ description }}
                  </p>
                  <div class="verifyCode-box">
                    <input
                      type="text"
                      class="form-control"
                      v-model="otpCode"
                      autocomplete="off"
                      maxlength="8"
                      @input="validateOtp"
                    />
                    <ValidationErrorMessageList :errors="v$.otpForm.otp_code.$errors" />
                  </div>
                </div>
                <div class="btn_part text-center verify-button-section">
                  <button
                    class="comman_btn fs-18 d-block w-100 mb-20"
                    @click="onSubmit"
                  >
                    Submit
                    <img
                      src="images/loader.gif"
                      v-if="isLoading"
                      style="width: calc(var(--scale-ratio) * 18px);"
                    />
                  </button>
                  <button
                    class="comman_brdr_btn fs-18 d-block w-100"
                    @click="sendCode"
                  >
                    Re-send Code
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/scripts/axios.js";
  import {
    required,
    helpers,
    email,
    minLength,
    maxLength,
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import ValidationErrorMessageList from '../base/ValidationErrorMessageList.vue';

export default {
    setup() {
      return { v$: useVuelidate() };
    },
    components: {
      ValidationErrorMessageList
    },
    props: {
      title: {
        type: String,
        default: "Verification Code",
      },
      email: {
        type: String,
        required: true,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        otpCode: "",
        description:"Please verify the 8-digit code sent to your email.",
        otpForm:{
          email:null,
          otp_code: null
        }
      };
    },
    computed: {
    },
    methods: {
      validateOtp() {
        // Validate OTP (only numbers allowed)
        this.otpCode = this.otpCode.replace(/[^0-9]/g, "");
      },
      onSubmit() {
        let $this = this
        this.otpForm.email = this.email
        this.otpForm.otp_code = this.otpCode
        const formName = 'otpForm';
        this.v$[formName].$validate();
        if (!this.v$[formName].$error) {
            this.isVerifying = true;
            axios.post("auth/verify-otp", {email: this.otpForm.email, otp_code: this.otpForm.otp_code})
            .then((response) => {
              $this.isVerifying = false;
              if (response.status == 200) {

                localStorage.setItem("authToken", response.data.data.token);
                localStorage.setItem("userId", response.data.data.user.id);
                localStorage.setItem("userName", response.data.data.user.username);
                // localStorage.setItem("email", response.data.data.user.email);
                localStorage.setItem("authUserName", response.data.data.user.full_name);
                localStorage.setItem("clinicId", response.data.data.user.clinic_id);
                localStorage.setItem("authUserRole", response.data.data.user.role);
                localStorage.setItem("preference", response.data.data.user.preference);
                localStorage.setItem("userFirstName", response.data.data.user.first_name);
                localStorage.setItem("userLastName", response.data.data.user.last_name);

                $this.$store.state.userInfo = response.data.data.user
                
                $this.$store.dispatch('updateAuthData', response.data.data.user);

                $this.$filters.moshaToast(response.data.message, "success");
                $this.$nextTick(() => {
                  $this.closeModal();
                });

                setTimeout(function () {
                  const url = $this.$router.resolve({
                    name: 'clinicSettings', params: { }
                  }).href;

                  window.open(url, '_blank');

                  location.reload(true);
                }, 100);
                
            }
          }).catch((error) => {
              this.isVerifying = false;
              this.$store.state.loader = false;

              if (error.response.status === 422) {
                  this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
              } else {
                  this.$filters.moshaToast(error.response.data.message, "error");
              }
          })
        } else {
            console.log(Object.entries(this.v$[formName]))
            for (const [key] of Object.entries(this.v$[formName])) {
              if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
                const myEl = document.getElementById(key);
                if (myEl) {
                  this.$smoothScroll({
                    scrollTo: myEl,
                  });
                  break;
                }
              }
            }
        }
      },
      async openModal() {
        this.v$['otpForm'].$reset();
        this.description = 'Please verify the 8-digit code sent to ' + this.obfuscateEmail(this.email); // ❌ Invalid
        this.otpCode = null;
        window.$("#verification_code_modal").modal("show")
      },
      async closeModal() {
        window.$("#verification_code_modal").modal("hide")
      },
      sendCode(){
        axios.post("auth/send-otp", {email: this.email})
          .then((response) => {
            if (response.status == 200) {
              this.otpCode = null;
              this.$filters.moshaToast("The verification code has been sent successfully.", "success");
            }
          }).catch((error) => {
            this.loader = false;
            this.$store.state.loader = false;

            if (error.response.status === 422) {
              this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
            } else {
              this.$filters.moshaToast(error.response.data.message, "error");
            }
          })
      },
      obfuscateEmail(email) {
        if (email) {
          const [localPart, domain] = email.split("@");
          if (!localPart || !domain) return email;

          // Ensure localPart is at least 5 characters long
          if (localPart.length <= 5) {
            const start = localPart.charAt(0); // First character
            const obfuscatedPart = "*".repeat(localPart.length - 1);
            return `${start}${obfuscatedPart}@${domain}`;
          }

          const start = localPart.slice(0, 3); // First 3 characters
          const end = localPart.slice(-2); // Last 2 characters before @
          const obfuscatedPart = "*".repeat(localPart.length - start.length - end.length);

          return `${start}${obfuscatedPart}${end}@${domain}`;
        }
        return '';
      }
    },
    mounted: () => {
    },
    validations() {
      return {
        otpForm:{
          email: {
            required: helpers.withMessage("Please enter Email.", required),
            email: helpers.withMessage("Please enter a valid Email.", email),
          },
          otp_code:{
            required: helpers.withMessage("Please enter Otp Code.", required),
            minLength: helpers.withMessage("Otp Code must be at least 8 numbers.", minLength(8)),
            maxLength: helpers.withMessage("Otp Code must be max. 8 numbers.", maxLength(8)),
            pattern: helpers.withMessage("Otp Code must contain only numbers.", /^\d+$/),

          }
        }
      }
    }
  };
  </script>
  
<template>
	<div class="wrapper" :class="$store.state.appointmentProviderCustomClass"  v-auto-scrollbar>
		<div class="page-loader" v-show="$store.state.loader">
				<img src="images/loader.svg" />
				<span class="load_txt">Loading ...</span>
		</div>
		<router-view :key="$route.fullPath" />
		<ChatWidget v-if="isChatWidgetShow" />
	</div>
</template>

<script>
import ChatWidget from "./components/chat/chatWidget.vue";

export default {	
	name: 'App',
	components: {
		ChatWidget,
	},
	computed: {
		isLogin() {
			return localStorage.getItem("authToken") ? true : false;
		},
		isChatWidgetShow() {
			if (this.isLogin) {
				const myArray = ['Chat','BillingReceiptPrint'];
				if (myArray.includes(this.$route.name)) {
					return false;
				} else {
					return true;
				}
			}
			return false;
		},
	},
	mounted() {
		this.$socket.setMessageCallback(this.handleReceivedData);
	},
	methods:{
		handleReceivedData(socketData) {
			this.$store.dispatch('updateSocketData', socketData);
		},
	}
}
</script>
<style>

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css";
</style>
import {
	helpers,
	required,
	maxLength,
    minLength,
} from "@vuelidate/validators";

const user_site_details = {
    namespaced: true,
    state: {
        defaultFormData: {
            'id':null,
            'clinic_unique_id':null,
            'location_name':null,
            'address':null,
            'city':null,
            'province':null,
            'postal':null,
            'phone_number':null,
            'fax_number':null,
        },
        form:{
            'id':null,
            'clinic_unique_id':null,
            'location_name':null,
            'address':null,
            'city':null,
            'province':null,
            'postal':null,
            'phone_number':null,
            'fax_number':null,
        },
        validationRules: {
			form: {
				clinic_unique_id: {
					required: helpers.withMessage("Please enter clinic id.", required),
                    minLength: helpers.withMessage("Please enter clinic id must be at least 4 char.", minLength(4)),
					maxLength: helpers.withMessage("Please enter clinic id must be max. 20 char.", maxLength(20)),
					pattern: helpers.withMessage(
						"clinic id must only contain letters and hyphens(-) (no spaces).",
						value => /^[A-Za-z-]+$/.test(value)
					),
				},
                location_name:{
                    required: helpers.withMessage("Please enter location name.", required),
					minLength: helpers.withMessage("Please enter Clinic Name must be at least 4 char.", minLength(4)),
					maxLength: helpers.withMessage("Please enter Clinic Name must be max. 50 char.", maxLength(50)),
					pattern: helpers.withMessage(
						"Clinic Name must only contain letters, space and hyphens(-). First and last character cannot be a space.",
						value => /^[A-Za-z-]+( [A-Za-z-]+)*$/.test(value)
					),

                },
                address:{
                    required: helpers.withMessage("Please enter address.", required),
                },
                city:{
                    required: helpers.withMessage("Please enter city.", required),
                },
                province:{
                    required: helpers.withMessage("Please enter province.", required),
                },
                postal:{
                    required: helpers.withMessage("Please enter postal.", required),
                },
                phone_number:{
                    required: helpers.withMessage("Please enter phone number.", required),
                },
                fax_number:{
                    required: helpers.withMessage("Please enter fax number.", required),
                }
            }
        },
    }
}

const user_stannp = {
    namespaced: true,
    state: {
        defaultFormData: {
            'id':null,
            'clinic_id':null,
            'account_number':null,
            'password':null,
            'apikey':null,
        },
        form:{
            'id':null,
            'clinic_id':null,
            'account_number':null,
            'password':null,
            'apikey':null,
        },
        validationRules: {
			form: {
                account_number:{
                    required: helpers.withMessage("Please enter account number.", required),
                    maxLength:helpers.withMessage("Please enter account number 20 char.", maxLength(20)),
                },
                password:{
                    required: helpers.withMessage("Please enter password.", required),
                    maxLength:helpers.withMessage("Please enter password 20 char.", maxLength(20)),
                },
                apikey:{
                    required: helpers.withMessage("Please enter api key.", required),
                    maxLength:helpers.withMessage("Please enter api key 255 char.", maxLength(255)),
                },
            }
        },
    }
}

export { user_site_details, user_stannp}
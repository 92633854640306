<template>
	<div class="row e_chart">
		<EChartSubHeader :echartElement="echartElement" @buttonClick="toggleElementBox" @clicked="updatedEChartData">
		</EChartSubHeader>
		<Sidebar :eChartNavData="eChartNavData" :echartElement="echartElement" @callParentFunction="handleChildEvent">
		</Sidebar>

		<div class="col-9 e-chart_page">
			<!-- <div class="e-chart_page_slider" v-if="isActiveShowAll">
				<div class="arrow_img" v-if="!showNextButtonForShowAll" @click="toggleIsDisplayForShowAll"><img
						src="/images/down-arrow_l.svg" class="img-fluid"></div>
				<div class="main_cn_box" v-for="(item, index) in filteredBoxData" :key="index">
					<div class="create_new_note" @click="openCreateModel(item.name)"><span>{{ item.name }}</span> <img
							src="/images/plush_icon.svg" class="img-fluid"></div>
					<div class="contain_list" @click="openModel(item.name)">
						<ul v-if="item.data">
							<li v-for="(dataItem, dataIndex) in item.data" :key="dataIndex">
								<slot v-if="dataItem.title">{{ dataItem.title }}</slot>
								<slot v-if="dataItem.desc && dataItem.title"> | </slot>
								<span class="dtl_cn">
									<slot v-if="dataItem.desc">{{ dataItem.desc }}</slot>
								</span>
							</li>
						</ul>
					</div>
				</div>
				<div class="arrow_img" v-if="showNextButtonForShowAll" @click="toggleIsDisplayForShowAll"><img
						src="/images/down-arrow_r_new.svg" class="img-fluid"></div>
			</div> -->
			<div class="e-chart_page_slider" v-if="!isActiveShowAll">
				<div class="arrow_img" v-if="secondPage && !showNextButton" @click="toggleIsDisplay('previous')"><img
						src="/images/down-arrow_l.svg" class=""></div>
				<div v-for="(item, index) in filteredDefaultBoxData" :key="index">
					<div class="main_cn_box" v-if="checkCondition(item.id)">
						<div class="create_new_note" @click="openCreateModel(item.name)"><span>{{ item.name }}</span>
							<img src="/images/plush_icon.svg" class=""></div>
						<div class="contain_list" @click="openModel(item.name)">
							<ul v-if="item.data">
								<li v-for="(dataItem, dataIndex) in item.data" :key="dataIndex">
									<slot v-if="dataItem.title">{{ dataItem.title }}</slot>
									<slot v-if="dataItem.desc && dataItem.title"> | </slot>
									<span class="dtl_cn">
										<slot v-if="dataItem.desc">{{ dataItem.desc }}</slot>
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="arrow_img" v-if="firstPage && showNextButton" @click="toggleIsDisplay('next')"><img
						src="/images/down-arrow_r_new.svg" class="img-fluid"></div>
			</div>
			<div class="e-chart_page_details">
				<div class="create_new_note" @click="showCreateNoteForm" v-if="isShowCreateNoteBtn">
					<span>Create New Note</span> <img src="/images/plush_icon.svg" class="img-fluid">
				</div>
				<div class="start_ai_scribe" v-if="isShowCreateNoteForm">
					<div class="note_box border-0 ps-0">

						<div class="row align-items-center l-h-n"
							v-if="!this.isPatientAppointmentToday && !this.encounterNoteFormProp.noteId">
							<div class="col-md-9 pe-0">
								<label class="no_appointment">No appointment from today, Please add appointment for this
									patient to create encounter note</label>
							</div>
							<div class="col-md-3 text-end">
								<label class="no_appointment">{{ this.currentDateTime }}</label>
							</div>
						</div>

						<div class="row align-items-end" v-if="this.isPatientAppointmentToday">
							<div class="col-md-9 d-flex align-items-center">
								<!-- <input type="text" class="form-control input_hd_bx" @keyup="searchNote" v-model="noteSearchValue" @blur="noteSearch">
                                <div class="search_drop_box" v-if="encounterSearchNoteList.length > 0">
                                    <slot v-for="(item, index) in encounterSearchNoteList" :key="index">
                                        <div class="con_drop_line" >
                                            <div class="row px-2 align-items-center">
                                                <div class="col-md-6 text-end">
                                                    <ol class="">
                                                        <li class="prevent" @click="showNoteOnCreateNote(item.id)">
                                                            <span>{{ truncateText(item.note) + ' - ' + item.creator }}</span>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </slot>
                                    <div class="create_grpc border-top"><router-link target="_blank" to="/patient/create">CREATE DEMOGRAPHIC</router-link></div>
                                </div> -->
								<div class="timer align-items-center justify-content-between" v-if="isShowTimer">
									<div class="circle_ico"></div>
									<div class="time_num">{{ this.formatTimer(this.timer) }}</div>
								</div>
								<button class="comman_btn" v-if="this.isShowStartAI" @click="startAIScribe">Start AI
									Scribe</button>
								<button class="comman_btn" v-if="this.isShowFinishAI" @click="stopAIScribe">Finish
									Recording
									<img src="images/loader.gif" v-if="loaderAiScribe"
										style="width: calc(var(--scale-ratio) * 18px);" /></button>

							</div>
							<div class="col-md-3 text-end">
								<span class="date">{{ this.encounterNoteFormProp.date_time }}</span>
							</div>
						</div>
					</div>
					<textarea class="form-control" :class="{ 'start_ai': this.isShowFinishAI }" rows="10"
						v-model="encounterNoteFormProp.note" @input="onInput"></textarea>
					<ValidationErrorMessageList :errors="v$.encounterNoteFormProp.note.$errors" />
					<div class="text-end submit_p_btn">
						<button class="comman_brdr_btn  cancel-create-note-btn" @click="hideCreateNoteForm"> Cancel
						</button>
						<button class="comman_btn mx30" @click="saveNote(false)"
							v-if="this.isPatientAppointmentToday || this.encounterNoteFormProp.noteId"> {{
							this.createNoteBtnText }}
							<img src="images/loader.gif" v-if="loader"
								style="width: calc(var(--scale-ratio) * 18px);" />
						</button>
						<button class="comman_btn" @click="saveNote(true)"
							v-if="this.isPatientAppointmentToday || this.encounterNoteFormProp.noteId">{{
							this.createNoteBillBtnText }}
							<img src="images/loader.gif" v-if="loaderSaveBill"
								style="width: calc(var(--scale-ratio) * 18px);" />
						</button>
					</div>
				</div>

				<div
					:class="{ 'add_note_box': !this.isShowCreateNoteBtn, 'add_note_box_full': this.isShowCreateNoteBtn }">
					<div v-for="(data, index) in this.combinedList" :key="index">
						<div v-if="data.type">
							<div class="note_box e-chart-elements-row">
								<div class="contain note-text mt-0">
									<div class="d-flex justify-content-between align-items-center">
										<div class="d-flex align-items-center">
											<p class="e-chart-cunslt-label d-inline-block mb-0">{{
												data.type.toUpperCase() }}</p>
											<p class="e-chart-cunslt-work d-inline-block mb-0">
												{{ data.type === 'family-history' ? data.notesData : data.title }}
											</p>
										</div>
										<div class="d-flex align-items-center">
											<span class="date echart-consult-date">{{
												getDateTimeFormatVal(data.created_datetime) }}</span>
											<a v-if="data.urlRouteName"
												style="cursor: pointer; text-decoration: none; color: #0E2641"
												:href="data.urlRouteName" target="_blank">
												<span
													class="text-decoration-underline edit-note-btn l-h-n d-inline-block">Navigate</span>
											</a>
											<span v-else
												class="text-decoration-underline edit-note-btn l-h-n d-inline-block" @click="viewEditModel(data.id, data.modelId)">
												Navigate
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<div class="note_box e-chart-elements-row">	
								<div class="row align-items-center l-h-n">
									<div class="col-md-3">
										<div class="">
											<span class="note">note</span>
											<span class="px-5">|</span>
											<span></span>
										</div>
									</div>
									<div class="col-md-3 text-center">
										<div class="">
											<span class="code">Dx Code</span>
											<!-- <span class="number">799</span> -->
										</div>
									</div>
									<div class="col-md-3">
										<div class="">
											<span class="code">Billing Code</span>
											<!-- <span class="number">A888A</span> -->
										</div>
									</div>
									<div class="col-md-3 text-end">
										<span class="date">{{ getDateTimeFormatVal(data.updated_datetime) }}</span>
									</div>
								</div>
								<div class="contain note-text">
									{{ data.note }}
								</div>
								<div class="d-flex justify-content-between align-items-center">
									<span class="sign_btn">Signed by {{ data.creator }}</span>
									<span class="text-decoration-underline edit-note-btn l-h-n"
										@click="getEncounterNote(data.id)">Edit Note</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

	<div class="modal fade history-list" id="medical-history-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div class="col-10 mx-auto">
							<h2 class="popup_title">Medical History</h2>
							<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th scope="col">START DATE</th>
											<th scope="col">NOTES</th>
											<th scope="col" class="hover_unset"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in this.eChartNavData.medicalList" :key="index">
											<td class="white-space-nowrap">{{ item.date }}</td>
											<td>{{ item.title }}</td>
											<td class="edit_delet_link">
												<span class="popup_link"
													@click="viewEditModel(item.id, 'medical-history-frm-model')">View</span>
												<span class="popup_link"
													@click="deleteRecords('medical-history', item.id)">Delete</span>
											</td>
										</tr>

										<tr>
											<td colspan="3">
												<div class="plus_sign_row" @click="openCreateModel('MEDICAL HISTORY')">+
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="text-center">
							<button class="comman_brdr_btn big_btn" @click="cancelModel"> Cancel </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade history-list" id="social-history-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div class="col-10 mx-auto">
							<h2 class="popup_title">Social History</h2>
							<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th scope="col">START DATE</th>
											<th scope="col">NOTES</th>
											<th scope="col" class="hover_unset"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in this.eChartNavData.socialList" :key="index">
											<td class="white-space-nowrap">{{ item.date }}</td>
											<td>{{ item.title }}</td>
											<td class="edit_delet_link">
												<span class="popup_link"
													@click="viewEditModel(item.id, 'social-history-frm-model')">View</span>
												<span class="popup_link"
													@click="deleteRecords('social-history', item.id)">Delete</span>
											</td>
										</tr>
										<tr>
											<td colspan="3">
												<div class="plus_sign_row" @click="openCreateModel('SOCIAL HISTORY')">+
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="text-center">
							<button class="comman_brdr_btn big_btn" @click="cancelModel"> Cancel </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade history-list" id="family-history-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div class="col-10 mx-auto">
							<h2 class="popup_title">Family History</h2>
							<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th scope="col">RELATIONSHIP</th>
											<th scope="col">NOTES</th>
											<th scope="col" class="hover_unset"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in this.eChartNavData.familyHistoryList" :key="index">
											<td>{{ item.title }}</td>
											<td>{{ item.desc }}</td>
											<td class="edit_delet_link">
												<span class="popup_link"
													@click="viewEditModel(item.id, 'family-history-frm-model')">View</span>
												<span class="popup_link"
													@click="deleteRecords('family-history', item.id)">Delete</span>
											</td>
										</tr>
										<tr>
											<td colspan="3">
												<div class="plus_sign_row" @click="openCreateModel('FAMILY HISTORY')">+
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="text-center">
							<button class="comman_brdr_btn big_btn" @click="cancelModel"> Cancel </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade history-list" id="reminder-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div class="col-10 mx-auto">
							<h2 class="popup_title">Reminders</h2>
							<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th scope="col">START DATE</th>
											<th scope="col">PROBLEM DESCRIPTION</th>
											<th scope="col" class="hover_unset"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in this.eChartNavData.remindersList" :key="index">
											<td class="white-space-nowrap">{{ item.date }}</td>
											<td>{{ item.title }}</td>
											<td class="edit_delet_link">
												<span class="popup_link"
													@click="viewEditModel(item.id, 'reminder-frm-model')">View</span>
												<span class="popup_link"
													@click="deleteRecords('reminders', item.id)">Delete</span>
											</td>
										</tr>
										<tr>
											<td colspan="3">
												<div class="plus_sign_row" @click="openCreateModel('REMINDER')">+</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="text-center">
							<button class="comman_brdr_btn big_btn" @click="cancelModel"> Cancel </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade history-list" id="ongoing-concerns-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div class="col-10 mx-auto">
							<h2 class="popup_title">Ongoing Concerns</h2>
							<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th scope="col">START DATE</th>
											<th scope="col">PROBLEM DESCRIPTION</th>
											<th scope="col" class="hover_unset"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in this.eChartNavData.ongoingConcernsList" :key="index">
											<td class="white-space-nowrap">{{ item.date }}</td>
											<td>{{ item.title }}</td>
											<td class="edit_delet_link">
												<span class="popup_link"
													@click="viewEditModel(item.id, 'ongoing-concerns-frm-model')">View</span>
												<span class="popup_link"
													@click="deleteRecords('ongoing-concerns', item.id)">Delete</span>
											</td>
										</tr>
										<tr>
											<td colspan="3">
												<div class="plus_sign_row" @click="openCreateModel('ONGOING CONCERNS')">+
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="text-center">
							<button class="comman_brdr_btn big_btn" @click="cancelModel"> Cancel </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade history-list" id="disease-reg-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div class="col-10 mx-auto">
							<h2 class="popup_title">Disease Registry</h2>
							<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th scope="col">START DATE</th>
											<th scope="col">DIAGNOSIS</th>
											<th scope="col" class="hover_unset"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in this.eChartNavData.diseaseRegistrysList" :key="index">
											<td class="white-space-nowrap">{{ item.date }}</td>
											<td>{{ item.title }}</td>
											<td class="edit_delet_link">
												<span class="popup_link"
													@click="viewEditModel(item.id, 'disease-reg-frm-model')">View</span>
												<span class="popup_link"
													@click="deleteRecords('disease-registry', item.id)">Delete</span>
											</td>
										</tr>
										<tr>
											<td colspan="3">
												<div class="plus_sign_row" @click="openCreateModel('DISEASE REGISTRY')">+
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="text-center">
							<button class="comman_brdr_btn big_btn" @click="cancelModel"> Cancel </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade history-list" id="risk-factor-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div class="col-10 mx-auto">
							<h2 class="popup_title">Risk Factors</h2>
							<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th scope="col">START DATE</th>
											<th scope="col">NAME</th>
											<th scope="col" class="hover_unset"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in this.eChartNavData.riskFactorsList" :key="index">
											<td class="white-space-nowrap">{{ item.date }}</td>
											<td>{{ item.title }}</td>
											<td class="edit_delet_link">
												<span class="popup_link"
													@click="viewEditModel(item.id, 'risk-factor-frm-model')">View</span>
												<span class="popup_link"
													@click="deleteRecords('risk-factors', item.id)">Delete</span>
											</td>
										</tr>
										<tr>
											<td colspan="3">
												<div class="plus_sign_row" @click="openCreateModel('RISK FACTOR')">+</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="text-center">
							<button class="comman_brdr_btn big_btn" @click="cancelModel"> Cancel </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade history-frm" id="medical-history-frm-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"
						@click="closeTaskModel"></button>
					<div class="box">
						<div class="col-7 mx-auto">
							<h2 class="popup_title">Medical History</h2>
							<div class="custom-form">
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd" 
										@change="onFieldChange('start_date', 'medical_history_form', 'start_date')"
										@blur="onFieldChange('start_date', 'medical_history_form', 'start_date')"
										placeholder="Start Date" v-model="this.medical_history_form.start_date"
										@keyup="this.keyupdate('start_date')" id="start_date" ref="start_date"></ejs-datepicker>
									<ValidationErrorMessageList :errors="v$.medical_history_form.start_date.$errors" />
								</div>
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd" 
										@change="onFieldChange('procedure_date', 'medical_history_form', 'procedure_date')"
										@blur="onFieldChange('procedure_date', 'medical_history_form', 'procedure_date')"
										placeholder="Procedure Date" v-model="this.medical_history_form.procedure_date"
										@keyup="this.keyupdate('procedure_date')" id="procedure_date" ref="procedure_date"></ejs-datepicker>
								</div>
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd"
										@change="onFieldChange('resolution_date', 'medical_history_form', 'resolution_date')"
										@blur="onFieldChange('resolution_date', 'medical_history_form', 'resolution_date')"
										placeholder="Resolution Date" v-model="this.medical_history_form.resolution_date"
										@keyup="this.keyupdate('resolution_date')" id="resolution_date" ref="resolution_date"></ejs-datepicker>
								</div>
								<div class="input_box">
									<input required="" id="procedure_text" class="form-control form-control-lg"
										v-model="this.medical_history_form.procedure_text">
									<label class="form-label" for="procedure_text">Procedure Note</label>
								</div>

							</div>

							<div class="heading_selact_drodwn">
								<CustomDropDown :options="this.life_stage_options"
									v-model="this.medical_history_form.life_stage"
									:initialValue="this.medical_history_form.life_stage_text"
									@item-selected="handleMedicalFrmItemSelected" labelText="Life Stage"
									fieldName="life_stage" divClass="form-label"></CustomDropDown>
							</div>

							<div class="text_area">
								<label class="txt_label">Notes <small class="asterisksign">*</small></label>
								<textarea class="form-control" rows="7" spellcheck="false"
									v-model="this.medical_history_form.note"></textarea>
								<ValidationErrorMessageList :errors="v$.medical_history_form.note.$errors" />
							</div>

						</div>
						<div class="col-7 mx-auto">
							<div class="text-center">
								<button class="comman_brdr_btn big_btn mx36" @click="cancelModelFrm"> Cancel </button>
								<button class="comman_btn big_btn mx36"
									@click="saveEchartEle('medical-history')">{{ this.saveButtonModelTxt }} <img
										src="images/loader.gif" v-if="modelSaveLoader"  style="width: calc(var(--scale-ratio) * 18px);" /></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade history-frm sm-model-e-chrt" id="social-history-frm-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"
						@click="closeTaskModel"></button>
					<div class="box">
						<div class="col-7 mx-auto">
							<h2 class="popup_title">Social History</h2>
							<div class="custom-form">
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd" 
										@change="onFieldChange('start_date', 'social_history_form', 'start_date_shf')"
										@blur="onFieldChange('start_date', 'social_history_form', 'start_date_shf')"
										placeholder="Start Date" v-model="this.social_history_form.start_date"
										@keyup="this.keyupdate('start_date_shf')" id="start_date_shf" ref="start_date_shf"></ejs-datepicker>
									<ValidationErrorMessageList :errors="v$.social_history_form.start_date.$errors" />
								</div>
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd"
										@change="onFieldChange('resolution_date', 'social_history_form', 'resolution_date_shf')"
										@blur="onFieldChange('resolution_date', 'social_history_form', 'resolution_date_shf')"
										placeholder="Resolution Date" v-model="this.social_history_form.resolution_date"
										@keyup="this.keyupdate('resolution_date_shf')" id="resolution_date_shf" ref="resolution_date_shf"></ejs-datepicker>
								</div>
								<div class="text_area">
									<label class="txt_label">Notes <small class="asterisksign">*</small></label>
									<textarea class="form-control" rows="7" spellcheck="false"
										v-model="this.social_history_form.note"></textarea>
									<ValidationErrorMessageList :errors="v$.social_history_form.note.$errors" />
								</div>
							</div>
						</div>
						<div class="col-7 mx-auto">
							<div class="text-center">
								<button class="comman_brdr_btn big_btn mx36" @click="cancelModelFrm"> Cancel </button>
								<button class="comman_btn big_btn mx36"
									@click="saveEchartEle('social-history')">{{ this.saveButtonModelTxt }} <img
										src="images/loader.gif" v-if="modelSaveLoader"  style="width: calc(var(--scale-ratio) * 18px);"/></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade history-frm" id="family-history-frm-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"
						@click="closeTaskModel"></button>
					<div class="box">
						<div class="col-7 mx-auto">
							<h2 class="popup_title">Family History</h2>
							<div class="custom-form">
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd" 
										@change="onFieldChange('start_date', 'family_history_form', 'start_date_fhf')"
										@blur="onFieldChange('start_date', 'family_history_form', 'start_date_fhf')"
										placeholder="Start Date" v-model="this.family_history_form.start_date"
										@keyup="this.keyupdate('start_date_fhf')" id="start_date_fhf" ref="start_date_fhf"></ejs-datepicker>
									<ValidationErrorMessageList :errors="v$.family_history_form.start_date.$errors" />
								</div>
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd" 
										@change="onFieldChange('resolution_date', 'family_history_form', 'resolution_date_fhf')"
										@blur="onFieldChange('resolution_date', 'family_history_form', 'resolution_date_fhf')"
										placeholder="Resolution Date" v-model="this.family_history_form.resolution_date"
										@keyup="this.keyupdate('resolution_date_fhf')" id="resolution_date_fhf" ref="resolution_date_fhf"></ejs-datepicker>
								</div>
							</div>

							<div class="heading_selact_drodwn full_input_drop slct-drp-name">
								<CustomDropDown :options="this.relationshipList"
									v-model="this.family_history_form.relationship"
									:initialValue="this.family_history_form.relationship"
									@item-selected="handleFamilyFrmItemSelected" labelText="Relationship"
									fieldName="relationship" divClass="form-label"></CustomDropDown>
								<ValidationErrorMessageList :errors="v$.family_history_form.relationship.$errors" />
							</div>
							<div class="custom-form">
								<div class="input_box">
									<input required="" type="number" id="age_at_onset" class="form-control form-control-lg"
										v-model="this.family_history_form.age_at_onset">
									<label class="form-label" for="age_at_onset">Age on Onset</label>
								</div>

								<div class="input_box">
									<input required="" type="" id="treatment" class="form-control form-control-lg"
										v-model="this.family_history_form.treatment">
									<label class="form-label" for="treatment">Treatment</label>
								</div>
							</div>
							<div class="heading_selact_drodwn">
								<CustomDropDown :options="this.life_stage_options"
									v-model="this.family_history_form.life_stage"
									:initialValue="this.family_history_form.life_stage_text"
									@item-selected="handleFamilyFrmItemSelected" labelText="Life Stage"
									fieldName="life_stage" divClass="form-label"></CustomDropDown>
							</div>

							<div class="text_area">
								<label class="txt_label">Notes <small class="asterisksign">*</small></label>
								<textarea class="form-control" rows="7" spellcheck="false"
									v-model="this.family_history_form.note"></textarea>
								<ValidationErrorMessageList :errors="v$.family_history_form.note.$errors" />
							</div>
						</div>
						<div class="col-7 mx-auto">
							<div class="text-center">
								<button class="comman_brdr_btn big_btn mx36" @click="cancelModelFrm"> Cancel </button>
								<button class="comman_btn big_btn mx36"
									@click="saveEchartEle('family-history')">{{ this.saveButtonModelTxt }} <img
										src="images/loader.gif" v-if="modelSaveLoader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade history-frm sm-model-e-chrt" id="reminder-frm-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"
						@click="closeTaskModel"></button>
					<div class="box">
						<div class="col-7 mx-auto">
							<h2 class="popup_title">Reminders</h2>
							<div class="custom-form">
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd" 
										@change="onFieldChange('start_date', 'reminder_form', 'start_date_rf')"
										@blur="onFieldChange('start_date', 'reminder_form', 'start_date_rf')"
										placeholder="Start Date" v-model="this.reminder_form.start_date"
										@keyup="this.keyupdate('start_date_rf')" id="start_date_rf" ref="start_date_rf"></ejs-datepicker>
									<ValidationErrorMessageList :errors="v$.reminder_form.start_date.$errors" />
								</div>
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg"
										:showClearButton="false" format="yyyy-MM-dd"
										@change="onFieldChange('resolution_date', 'reminder_form', 'resolution_date_rf')"
										@blur="onFieldChange('resolution_date', 'reminder_form', 'resolution_date_rf')"
										placeholder="Resolution Date" v-model="this.reminder_form.resolution_date"
										@keyup="this.keyupdate('resolution_date_rf')" id="resolution_date_rf" ref="resolution_date_rf"></ejs-datepicker>
								</div>
								<div class="input_box">
									<input required="" id="description" class="form-control form-control-lg"
										v-model="this.reminder_form.description">
									<label class="form-label" for="description">Procedure Description <small
											class="asterisksign">*</small></label>
									<ValidationErrorMessageList :errors="v$.reminder_form.description.$errors" />
								</div>
							</div>
							<div class="text_area">
								<label class="txt_label">Notes</label>
								<textarea class="form-control" rows="7" spellcheck="false"
									v-model="this.reminder_form.note"></textarea>
							</div>
						</div>
						<div class="col-7 mx-auto">
							<div class="text-center">
								<button class="comman_brdr_btn big_btn mx36" @click="cancelModelFrm"> Cancel </button>
								<button class="comman_btn big_btn mx36"
									@click="saveEchartEle('reminders')">{{ this.saveButtonModelTxt }} <img
										src="images/loader.gif"  style="width: calc(var(--scale-ratio) * 18px);" v-if="modelSaveLoader" /></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade history-frm" id="ongoing-concerns-frm-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"
						@click="closeTaskModel"></button>
					<div class="box">
						<div class="col-7 mx-auto">
							<h2 class="popup_title">Ongoing Concerns</h2>
							<div class="custom-form">
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd" 
										@change="onFieldChange('start_date', 'ongoing_concerns_form', 'start_date_ocf')"
										@blur="onFieldChange('start_date', 'ongoing_concerns_form', 'start_date_ocf')"
										placeholder="Start Date" v-model="this.ongoing_concerns_form.start_date"
										@keyup="this.keyupdate('start_date_ocf')" id="start_date_ocf" ref="start_date_ocf"></ejs-datepicker>
									<ValidationErrorMessageList :errors="v$.ongoing_concerns_form.start_date.$errors" />
								</div>
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd"
										@change="onFieldChange('resolution_date', 'ongoing_concerns_form', 'resolution_date_ocf')"
										@blur="onFieldChange('resolution_date', 'ongoing_concerns_form', 'resolution_date_ocf')"
										placeholder="Resolution Date" v-model="this.ongoing_concerns_form.resolution_date"
										@keyup="this.keyupdate('resolution_date_ocf')" id="resolution_date_ocf" ref="resolution_date_ocf"></ejs-datepicker>
								</div>
								<div class="input_box">
									<input required="" id="name" class="form-control form-control-lg"
										v-model="this.ongoing_concerns_form.name">
									<label class="form-label" for="name">Name<small class="asterisksign">*</small></label>
									<ValidationErrorMessageList :errors="v$.ongoing_concerns_form.name.$errors" />
								</div>
								<div class="input_box">
									<input required="" id="problem_description" class="form-control form-control-lg"
										v-model="this.ongoing_concerns_form.problem_description">
									<label class="form-label" for="problem_description">Problem Description<small
											class="asterisksign">*</small></label>
									<ValidationErrorMessageList
										:errors="v$.ongoing_concerns_form.problem_description.$errors" />
								</div>
								<div class="input_box">
									<input required="" id="problem_status" class="form-control form-control-lg"
										v-model="this.ongoing_concerns_form.problem_status">
									<label class="form-label" for="problem_status">Problem Status</label>
								</div>
							</div>
							<div class="heading_selact_drodwn">
								<CustomDropDown :options="this.life_stage_options"
									v-model="this.risk_factors_form.life_stage"
									:initialValue="this.risk_factors_form.life_stage_text"
									@item-selected="handleRiskFactorFrmItemSelected" labelText="Life Stage"
									fieldName="life_stage" divClass="form-label"></CustomDropDown>
							</div>

							<div class="text_area">
								<label class="txt_label">Notes</label>
								<textarea class="form-control" rows="7" spellcheck="false"
									v-model="this.ongoing_concerns_form.note"></textarea>
								<ValidationErrorMessageList :errors="v$.ongoing_concerns_form.note.$errors" />
							</div>

						</div>
						<div class="col-7 mx-auto">
							<div class="text-center">
								<button class="comman_brdr_btn big_btn mx36" @click="cancelModelFrm"> Cancel </button>
								<button class="comman_btn big_btn mx36"
									@click="saveEchartEle('ongoing-concerns')">{{ this.saveButtonModelTxt }} <img
										src="images/loader.gif"  style="width: calc(var(--scale-ratio) * 18px);" v-if="modelSaveLoader" /></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade history-frm" id="disease-reg-frm-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"
						@click="closeTaskModel"></button>
					<div class="box">
						<div class="col-7 mx-auto">
							<h2 class="popup_title">Disease Registry</h2>
							<div class="custom-form">
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd" 
										@change="onFieldChange('start_date', 'disease_reg_form', 'start_date_drf')"
										@blur="onFieldChange('start_date', 'disease_reg_form', 'start_date_drf')"
										placeholder="Start Date" v-model="this.disease_reg_form.start_date"
										@keyup="this.keyupdate('start_date_drf')" id="start_date_drf" ref="start_date_drf"></ejs-datepicker>
									<ValidationErrorMessageList :errors="v$.disease_reg_form.start_date.$errors" />
								</div>
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd" 
										@change="onFieldChange('procedure_date', 'disease_reg_form', 'procedure_date_drf')"
										@blur="onFieldChange('procedure_date', 'disease_reg_form', 'procedure_date_drf')"
										placeholder="Procedure Date" v-model="this.disease_reg_form.procedure_date"
										@keyup="this.keyupdate('procedure_date_drf')" id="procedure_date_drf" ref="procedure_date_drf"></ejs-datepicker>
								</div>
								<div class="input_box input_date_picker _datepic">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd" 
										@change="onFieldChange('resolved_date', 'disease_reg_form', 'resolved_date_drf')"
										@blur="onFieldChange('resolved_date', 'disease_reg_form', 'resolved_date_drf')"
										placeholder="Resolution Date" v-model="this.disease_reg_form.resolved_date"
										@keyup="this.keyupdate('resolved_date_drf')" id="resolved_date_drf" ref="resolved_date_drf"></ejs-datepicker>
								</div>
								<div class="input_box">
									<input required="" id="diagnosis" class="form-control form-control-lg"
										v-model="this.disease_reg_form.diagnosis">
									<label class="form-label" for="diagnosis">Diagnosis<small
											class="asterisksign">*</small></label>
									<ValidationErrorMessageList :errors="v$.disease_reg_form.diagnosis.$errors" />
								</div>

								<div class="input_box">
									<input required="" id="status" class="form-control form-control-lg"
										v-model="this.disease_reg_form.status">
									<label class="form-label" for="status">Problem Status</label>
								</div>

							</div>

							<div class="heading_selact_drodwn">
								<CustomDropDown :options="this.life_stage_options"
									v-model="this.disease_reg_form.life_stage"
									:initialValue="this.disease_reg_form.life_stage_text"
									@item-selected="handleDiseaseFrmItemSelected" labelText="Life Stage"
									fieldName="life_stage" divClass="form-label"></CustomDropDown>
							</div>

							<div class="text_area">
								<label class="txt_label">Notes <small class="asterisksign">*</small></label>
								<textarea class="form-control" rows="7" spellcheck="false"
									v-model="this.disease_reg_form.notes"></textarea>
								<ValidationErrorMessageList :errors="v$.disease_reg_form.notes.$errors" />

							</div>

						</div>
						<div class="col-7 mx-auto">
							<div class="text-center">
								<button class="comman_brdr_btn big_btn mx36" @click="cancelModelFrm"> Cancel </button>
								<button class="comman_btn big_btn mx36"
									@click="saveEchartEle('disease-registry')">{{ this.saveButtonModelTxt }} <img
										src="images/loader.gif" v-if="modelSaveLoader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade history-frm" id="risk-factor-frm-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"
						@click="closeTaskModel"></button>
					<div class="box">
						<div class="col-7 mx-auto">
							<h2 class="popup_title">Risk Factors</h2>
							<div class="custom-form">
								<div class="input_box input_date_picker _datepic risk_factors_date">
									<ejs-datepicker class="e-field form-control form-control-lg"
										:showClearButton="false" format="yyyy-MM-dd"
										@change="onFieldChange('start_date', 'risk_factors_form', 'start_date_rff')"
										@blur="onFieldChange('start_date', 'risk_factors_form', 'start_date_rff')"
										placeholder="Start Date" v-model="this.risk_factors_form.start_date"
										@keyup="this.keyupdate('start_date_rff')" id="start_date_rff" ref="start_date_rff"></ejs-datepicker>
									<ValidationErrorMessageList :errors="v$.risk_factors_form.start_date.$errors" />
								</div>
								<div class="input_box input_date_picker _datepic risk_factors_date">
									<ejs-datepicker class="e-field form-control form-control-lg" :showClearButton="false"
										format="yyyy-MM-dd" 
										@change="onFieldChange('resolution_date', 'risk_factors_form', 'resolution_date_rff')"
										@blur="onFieldChange('resolution_date', 'risk_factors_form', 'resolution_date_rff')"
										placeholder="Resolution Date" v-model="this.risk_factors_form.resolution_date"
										@keyup="this.keyupdate('resolution_date_rff')" id="resolution_date_rff" ref="resolution_date_rff"></ejs-datepicker>
								</div>
								<div class="input_box">
									<input required="" id="risk_factor_name" class="form-control form-control-lg"
										v-model="this.risk_factors_form.risk_factor_name">
									<label class="form-label" for="risk_factor_name">Name<small
											class="asterisksign">*</small></label>
									<ValidationErrorMessageList :errors="v$.risk_factors_form.risk_factor_name.$errors" />
								</div>

								<div class="input_box">
									<input required="" type="number" id="age_at_onset" class="form-control form-control-lg"
										v-model="this.risk_factors_form.age_at_onset">
									<label class="form-label" for="age_at_onset">Age on Onset</label>
								</div>

								<div class="input_box">
									<input required="" id="exposure_details" class="form-control form-control-lg"
										v-model="this.risk_factors_form.exposure_details">
									<label class="form-label" for="exposure_details">Exposure Details</label>
								</div>

							</div>

							<div class="heading_selact_drodwn">
								<CustomDropDown :options="this.life_stage_options"
									v-model="this.risk_factors_form.life_stage"
									:initialValue="this.risk_factors_form.life_stage_text"
									@item-selected="handleRiskFactorFrmItemSelected" labelText="Life Stage"
									fieldName="life_stage" divClass="form-label"></CustomDropDown>
							</div>

							<div class="text_area">
								<label class="txt_label">Notes</label>
								<textarea class="form-control" rows="7" spellcheck="false"
									v-model="this.risk_factors_form.note"></textarea>
								<ValidationErrorMessageList :errors="v$.risk_factors_form.note.$errors" />
							</div>

						</div>
						<div class="col-7 mx-auto">
							<div class="text-center">
								<button class="comman_brdr_btn  big_btn mx36" @click="cancelModelFrm"> Cancel </button>
								<button class="comman_btn big_btn mx36"
									@click="saveEchartEle('risk-factors')">{{ this.saveButtonModelTxt }} <img
										src="images/loader.gif" v-if="modelSaveLoader"  style="width: calc(var(--scale-ratio) * 18px);" /></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import EChartSubHeader from './EChartSubHeader.vue';
import Sidebar from './EChartSidebar.vue';
import axios from "@/scripts/axios.js";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { ref } from 'vue';
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import ValidationErrorMessageList from '../base/ValidationErrorMessageList.vue';
// import { debounce } from 'lodash';
import $ from "jquery";

export default {
	setup: () => {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			action: 'Add',
			/* Search note property */
			// encounterSearchNoteList: [],
			// noteSearchValue: '',

			timer: ref(0),
			intervalId: null,
			loader: false,
			loaderSaveBill: false,
			today: moment(new Date()).format('YYYY-MM-DD'),
			currentDateTime: this.$filters.getCurrentDateTime(),
			dateTimeFormat: "yyyy-dd-MM hh:mm a",
			echartElement: [
				{ id: "allergies", name: "Allergies", navVariable: "allergiesList", checked: true, urlRouteName: "PatientEChartAllergies" },
				{ id: "consultations", name: "Consultations", navVariable: "consultationsList", checked: true, urlRouteName: "PatientEChartConsultList" },
				{ id: "documents", name: "Files", navVariable: "documentsList", checked: true, urlRouteName: "PatientEChartFilesOverview" },
				{ id: "e-forms", name: "Imaging", navVariable: "imagingList", checked: true, urlRouteName: "patientImagingList" },
				{ id: "lab", name: "Labs", navVariable: "labsList", checked: true, urlRouteName: "patientLabsList" },
				{ id: "measurements", name: "Measurements", navVariable: "vitalList", checked: true, urlRouteName: "patientMeasurementList" },
				{ id: "medications", name: "Medications/Rx", navVariable: "prescriptionList", checked: true, urlRouteName: "PatientEChartPrescriptionList" },
				{ id: "preventions", name: "Preventions", navVariable: "preventionsList", checked: true, urlRouteName: "PatientEChartImmunizationList" },
				{ id: "task", name: "Tasks", navVariable: "taskList", checked: true, urlRouteName: "PatientEChartActiveTaskList" },

				{ id: "medical-history", name: "Medical History", navVariable: "medicalList", checked: false },
				{ id: "social-history", name: "Social History", navVariable: "socialList", checked: false },
				{ id: "family-history", name: "Family History", navVariable: "familyHistoryList", checked: false },
				{ id: "reminder", name: "Reminder", navVariable: "remindersList", checked: false },
				{ id: "ongoing-concerns", name: "Ongoing Concerns", navVariable: "ongoingConcernsList", checked: false },
				{ id: "disease-registry", name: "Disease Registry", navVariable: "diseaseRegistrysList", checked: false},
				{ id: "risk-factor", name: "Risk Factor", navVariable: "riskFactorsList", checked: false },
			],
			life_stage_options: [
				{ value: "", title: "Not Set" },
				{ value: "N", title: "Newborn: Birth to 28 days" },
				{ value: "I", title: "Infant: 29 days to 2 years" },
				{ value: "C", title: "Child: 2 years to 15 years" },
				{ value: "T", title: "Adolescent: 16 to 17 years" },
				{ value: "A", title: "Adult: 18 years or more" },
			],

			/* Sidebar elements array */
			eChartNavData: {
				allergiesList: [],
				consultationsList: [],
				documentsList: [],
				imagingList: [],
				labsList: [],
				measurementsList: [],
				preventionsList: [],
				taskList: [],
				diseaseRegistrysList: [],
				familyHistoryList: [],
				medicalList: [],
				ongoingConcernsList: [],
				remindersList: [],
				riskFactorsList: [],
				socialList: [],
				prescriptionsList: [],
				// vitalList: []
				encounterNotes: []
			},

			/* Elements boxes object */
			isActiveShowAll: false,
			showNextButton: false,
			showNextButtonForShowAll: true,
			eChartViewData: {
				medicalList: { id: "medical-history", name: "MEDICAL HISTORY", isDisplay: true },
				socialList: { id: "social-history", name: "SOCIAL HISTORY", isDisplay: true },
				familyHistoryList: { id: "family-history", name: "FAMILY HISTORY", isDisplay: true },
				remindersList: { id: "reminder", name: "REMINDER", isDisplay: true },
				ongoingConcernsList: { id: "ongoing-concerns", name: "ONGOING CONCERNS", isDisplay: true },
				diseaseRegistrysList: { id: "disease-registry", name: "DISEASE REGISTRY", isDisplay: false },
				riskFactorsList: { id: "risk-factor", name: "RISK FACTOR", isDisplay: false },
			},

			/* Encounter-Notes list array */
			patientEncNoteHistory: [],

			/* Create-Note object */
			isShowCreateNoteBtn: true,
			createNoteBtnText: 'Save',
			errors: null,
			isShowCreateNoteForm: false,
			typingTimeout: null,
			lastTypingTime: null,
			isActionInProcessing: false,

			enc_type_options: [
				{ value: 'In person', title: 'In person' },
				{ value: 'virtual', title: 'virtual' }
			],
			isShowTimer: false,
			isShowStartAI: true,
			isShowFinishAI: false,
			recording: false,
			audioChunks: [],
			mediaRecorder: null,
			transcription: '',
			note: null,
			loaderAiScribe: false,
			isPatientAppointmentToday: true,
			saveButtonModelTxt: 'Create',
			relationshipList: [
				{ 'value': 'Father', 'title': 'Father' },
				{ 'value': 'Mother', 'title': 'Mother' },
				{ 'value': 'Parent', 'title': 'Parent' },
				{ 'value': 'Husband', 'title': 'Husband' },
				{ 'value': 'Wife', 'title': 'Wife' },
				{ 'value': 'Partner', 'title': 'Partner' },
				{ 'value': 'Son', 'title': 'Son' },
				{ 'value': 'Daughter', 'title': 'Daughter' },
				{ 'value': 'Brother', 'title': 'Brother' },
				{ 'value': 'Sister', 'title': 'Sister' },
				{ 'value': 'Uncle', 'title': 'Uncle' },
				{ 'value': 'Aunt', 'title': 'Aunt' },
				{ 'value': 'GrandFather', 'title': 'GrandFather' },
				{ 'value': 'GrandMother', 'title': 'GrandMother' },
				{ 'value': 'Guardian', 'title': 'Guardian' },
				{ 'value': 'Foster Parent', 'title': 'Foster Parent' },
				{ 'value': 'Next of Kin', 'title': 'Next of Kin' },
				{ 'value': 'Administrative Staff', 'title': 'Administrative Staff' },
				{ 'value': 'Care Giver ', 'title': 'Care Giver' },
				{ 'value': 'Power Of Attorney ', 'title': 'Power Of Attorney' },
				{ 'value': 'Insurance', 'title': 'Insurance' },
				{ 'value': 'Guarantor', 'title': 'Guarantor' },
				{ 'value': 'Other', 'title': 'Other' },
			],
			modelSaveLoader: false,

			eChartDefaultViewData: {
				medicalList: { id: "medical-history", name: "MEDICAL HISTORY", isDisplay: true, data: [] },
				socialList: { id: "social-history", name: "SOCIAL HISTORY", isDisplay: true, data: [] },
				familyHistoryList: { id: "family-history", name: "FAMILY HISTORY", isDisplay: true, data: [] },
				remindersList: { id: "reminder", name: "REMINDER", isDisplay: true, data: [] },
				ongoingConcernsList: { id: "ongoing-concerns", name: "ONGOING CONCERNS", isDisplay: true, data: [] },
				diseaseRegistrysList: { id: "disease-registry", name: "DISEASE REGISTRY", isDisplay: false, data: [] },
				riskFactorsList: { id: "risk-factor", name: "RISK FACTOR", isDisplay: false, data: [] },
			},
			checkedCount: 0,
			firstPage: true,
			secondPage: false,

			combinedList: [],
			allDataLoaded: false,
			createNoteBillBtnText: "Save & Bill"
		}
	},
	validations() {
		return {
			encounterNoteForm: this.$store.state.encounter_note.validationRules.form,
			medical_history_form: this.$store.state.medical_history.validationRules.form,
			social_history_form: this.$store.state.social_history.validationRules.form,
			family_history_form: this.$store.state.family_history.validationRules.form,
			reminder_form: this.$store.state.reminders.validationRules.form,
			ongoing_concerns_form: this.$store.state.ongoing_concerns.validationRules.form,
			disease_reg_form: this.$store.state.disease_reg.validationRules.form,
			risk_factors_form: this.$store.state.risk_factors.validationRules.form,
			encounterNoteFormProp: this.$store.state.encounter_note.validationRules.form,
		};
	},
	components: {
		Sidebar,
		EChartSubHeader,
		'ejs-datepicker': DatePickerComponent,
		CustomDropDown,
		ValidationErrorMessageList
	},
	mounted() {
		this.getData();
	},
	methods: {
		openDatePicker(refName) {
			var schObj = document.querySelector(`#${refName}`).ej2_instances[0];
			schObj.show();
		},
		keyupdate(obj) {
			const inputValue = $(`#${obj}`).val();
			var sanitizedInput = inputValue.replace(/[^\d]/g, '');
			if (sanitizedInput.length > 8) {
				sanitizedInput = sanitizedInput.substring(0, 8);
			}
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});
			$(`#${obj}`).val(formattedDate);
		},
		async sortDataBasedOnDate() {
			try {
				const appTimeZone = localStorage.getItem('appTimeZone'); // Retrieve AppTimeZone from localStorage

				// Wrap sorting logic in a Promise for async handling
				this.combinedList = await new Promise((resolve) => {
					const sortedList = [...this.combinedList].sort((a, b) => {
						const dateA = this.convertUTCToAppTimeZone(a.created_datetime, appTimeZone);
						const dateB = this.convertUTCToAppTimeZone(b.created_datetime, appTimeZone);

						// Compare dates in the desired format
						return new Date(dateB) - new Date(dateA);
					});
					resolve(sortedList);
				});

				return this.combinedList;
			} catch (error) {
				console.error("Error sorting data:", error);
				throw error;
			}
		},
		convertUTCToAppTimeZone(dateTime, timeZone) {
			if (!dateTime) return null;

			if(dateTime.includes("T")) {
				// Parse UTC date-time into a JavaScript Date object
				const utcDate = new Date(dateTime);
	
				// Calculate UTC offset and target timezone offset
				const utcOffset = utcDate.getTimezoneOffset() * 60 * 1000; // Local offset in milliseconds
				const targetOffset = this.getTimeZoneOffsetInMs(timeZone); // Target offset in milliseconds
	
				// Adjust the time to the target timezone
				const adjustedTime = new Date(utcDate.getTime() + utcOffset + targetOffset);
	
				// Format the adjusted time as "YYYY-MM-DD HH:mm:ss"
				const year = adjustedTime.getFullYear();
				const month = String(adjustedTime.getMonth() + 1).padStart(2, '0');
				const day = String(adjustedTime.getDate()).padStart(2, '0');
				const hours = String(adjustedTime.getHours()).padStart(2, '0');
				const minutes = String(adjustedTime.getMinutes()).padStart(2, '0');
				const seconds = String(adjustedTime.getSeconds()).padStart(2, '0');
	
				return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
			}
			return dateTime;
		},
		closeTaskModel() {
			this.saveButtonModelTxt = 'Create';
		},
		onInput() {
			clearTimeout(this.typingTimeout);
			this.lastTypingTime = Date.now();

			this.typingTimeout = setTimeout(this.checkNoteInput, 30000);
		},
		checkNoteInput() {
			const currentTime = Date.now();
			if ((currentTime - this.lastTypingTime) >= 30000) {
				if (this.encounterNoteFormProp.note && this.encounterNoteFormProp.note.trim() !== '' && !this.isActionInProcessing) {
					this.saveNote(false, true);
				}
			} else {
				console.log('User is still typing, note will not be saved.');
			}
		},
		async getData() {
			try {
				this.combinedList = [];
				this.allDataLoaded = false;
				this.$store.state.loader = true;

				const modules = [
					"allergies", "consultations", "document", "imaging", "lab",
					"immunization", "task", "disease-registry", "family-history",
					"medical-history", "ongoing-concerns", "reminders", "risk-factors",
					"social-history", "prescriptions", "vital", "screening"
				];

				// Load all modules
				await Promise.all(modules.map((module) => this.getList(module)));
				await this.getEncounterNotes();

				// Sort data after fetching
				await this.sortDataBasedOnDate();

				this.$store.state.loader = false;

				// Set up event listeners
				$(document).on("click", '._datepic', (event) => {
					const inputField = $(event.currentTarget).find('input');
					this.openDatePicker(inputField.attr('id'));
				});

				setInterval(this.checkNoteInput, 30000);
			} catch (error) {
				console.error("Error loading data:", error);
				this.$store.state.loader = false;
			}
		},
		/* Event to reset encounter-note search operation */
		// handleClickOutside() {
		//     this.noteSearchValue = '';
		//     this.encounterSearchNoteList = [];
		// },

		/* Show search select note on create-note */
		// showNoteOnCreateNote(targetId) {
		//     console.log('target-id:-', targetId);
		//     const targetObject = this.encounterSearchNoteList.find(note => note.id === targetId);
		//     if(targetObject)
		//     {
		//         console.log('note-id-data:-', targetObject);
		//         this.createNoteBtnText = 'Update';
		//         this.action = 'Update';
		//         this.encounterNoteFormProp.noteId = targetObject.id;
		//         this.encounterNoteFormProp.note = targetObject.note;
		//         this.encounterNoteFormProp.date_time = this.getDateTimeFormatVal(targetObject.encounter_datetime);
		//         this.encounterNoteFormProp.creator = targetObject.creator;

		//     }
		// },

		/* For truncating search note value */
		// truncateText(note) {
		//     const words = note.split(' ');
		//     const truncatedText = words.slice(0, 4).join(' ');
		//     if (words.length > 4) {
		//         return truncatedText + '...';
		//     }
		//     return truncatedText;
		// },

		/* On search encounter note. */
		// searchNote() {
		//     this.encounterSearchNoteList = [];
		//     if(this.noteSearchValue.length > 2)
		//     {
		//         axios.post("patient/encounter-note/search", { patient_id: this.$route.params.patient_id, search_note_text: this.noteSearchValue })
		// 		.then((response) => {
		//             console.log('encounter-note-search-response:- ', response);
		//             if(response.status == 200)
		//             {
		//                 this.encounterSearchNoteList = response.data.data;
		//             }
		//             console.log("encounterSearchNoteList:- ", this.encounterSearchNoteList);
		// 		});
		//     }
		//     if(this.noteSearchValue.length == 0)
		//     {
		//         this.encounterSearchNoteList = [];
		//     }
		// },

		async getList(moduleName) {

			let detail = [];
			let params = { patient_id: this.$route.params.patient_id };
			if (moduleName == 'task') {
				params.from = 'ActiveTaskList'
			} else if (moduleName == 'document') {
				params.status = 'labeled'
			}

			const response = await axios.post("patient/" + moduleName + "/list", params);
			switch (moduleName) {
				case "allergies":
					this.combinedList = this.combinedList.filter(item => item.type !== 'allergies');
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'allergies',
								title: item.description,
								date: item.start_date,
								id: item.id,
								urlRouteName: this.$router.resolve({ name: "PatientEChartAllergies", params: { patient_id: this.$route.params.patient_id }, query: { id: item.id } }).href,
								created_datetime: item.updated_datetime,
							};
						})

						this.eChartNavData.allergiesList = data;
						this.combinedList = [...this.combinedList, ...data];
					}
					break;
				case "consultations":
					this.combinedList = this.combinedList.filter(item => item.type !== 'consultations');
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'consultations',
								title: item.specialist_type + ' | ' + item.specialist_name,
								date: item.referral_date,
								urlRouteName: this.$router.resolve({ name: "PatientEChartConsultList", params: { patient_id: this.$route.params.patient_id }, query: { id: item.id } }).href,
								created_datetime: item.updated_datetime,
							};
						})

						this.eChartNavData.consultationsList = data;
						this.combinedList = [...this.combinedList, ...data];
					}
					break;
				case "document":
					this.combinedList = this.combinedList.filter(item => item.type !== 'files');
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'files',
								title: item.description,
								date: this.$filters.inputDateTime(item.created_datetime, 'YYYY-MM-DD'),
								id: item.id,
								urlRouteName: this.$router.resolve({ name: "PatientEChartFilesEdit", params: { patient_id: this.$route.params.patient_id, id: item.id } }).href,
								created_datetime: item.updated_datetime,
							};
						})

						this.eChartNavData.documentsList = data;
						// this.eChartDefaultViewData.documentsList.data = data;
						this.combinedList = [...this.combinedList, ...data];
					}
					break;
				case "imaging":
					this.combinedList = this.combinedList.filter(item => item.type !== 'imaging');
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'imaging',
								title: item.service,
								date: item.referral_date,
								urlRouteName: this.$router.resolve({ name: "patientImagingList", params: { patient_id: this.$route.params.patient_id }, query: { id: item.id } }).href,
								created_datetime: item.updated_datetime,
							};
						})

						this.eChartNavData.imagingList = data;
						// this.eChartDefaultViewData.imagingList.data = data;
						this.combinedList = [...this.combinedList, ...data];
					}
					break;
				case "lab":
					this.combinedList = this.combinedList.filter(item => item.type !== 'lab');
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'lab',
								title: item.lab_name,
								desc: item.test_code,
								date: item.ordered_datetime,
								urlRouteName: this.$router.resolve({ name: "patientLabsList", params: { patient_id: this.$route.params.patient_id }, query: { id: item.id } }).href,
								created_datetime: item.updated_datetime,
							};
						})

						this.eChartNavData.labsList = data;
						// this.eChartDefaultViewData.labsList.data = data;
						this.combinedList = [...this.combinedList, ...data];
					}
					break;
				case "vital":
					this.combinedList = this.combinedList.filter(item => item.type !== 'measurements');
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'measurements',
								title: item.vital_name,
								// desc: item.value,
								date: item.measure_date,
								urlRouteName: this.$router.resolve({ name: "patientMeasurementList", params: { patient_id: this.$route.params.patient_id }, query: { id: item.vital_id } }).href,
								created_datetime: item.updated_datetime,
							};
						})

						this.eChartNavData.vitalList = data;
						// this.eChartDefaultViewData.vitalList.data = data;
						this.combinedList = [...this.combinedList, ...data];
					}
					break;
				case "immunization":
					this.combinedList = this.combinedList.filter(item => item.type !== 'immunization');
					detail = response.data.data;
					if (detail.length > 0) {
						const mappedData = detail.map((item) => {
							return {
								type: 'immunization',
								title: item.type,
								date: item.date,
								urlRouteName: this.$router.resolve({ name: "PatientEChartImmunizationList", params: { patient_id: this.$route.params.patient_id }, query: { id: item.id } }).href,
								created_datetime: item.updated_datetime,
							};
						})

						if (this.eChartNavData.preventionsList.length > 0) {
							this.eChartNavData.preventionsList.push(...mappedData); // Spread operator to push multiple items
						} else {
							this.eChartNavData.preventionsList = mappedData
						}

						// this.eChartDefaultViewData.preventionsList.data = this.eChartNavData.preventionsList
						this.combinedList = [...this.combinedList, ...mappedData];
					}
					break;
				case "screening":
					this.combinedList = this.combinedList.filter(item => item.type !== 'screening');
					detail = response.data.data;
					if (detail.length > 0) {
						const mappedData = detail.map((item) => {
							return {
								type: 'screening',
								title: item.screening_type,
								date: this.$filters.inputDateTime(item.created_datetime, 'YYYY-MM-DD'),
								urlRouteName: this.$router.resolve({ name: "PatientEChartScreeningList", params: { patient_id: this.$route.params.patient_id }, query: { id: item.id } }).href,
								created_datetime: item.updated_datetime,
							};
						})

					if (this.eChartDefaultViewData.preventionsList) {
						this.eChartDefaultViewData.preventionsList.data = this.eChartNavData.preventionsList
					}
					this.combinedList = [...this.combinedList, ...mappedData];
				}
				break;
				case "task":
				this.combinedList = this.combinedList.filter(item => item.type !== 'task');
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'task',
								title: item.reminder_message,
								date: item.service_date,
								id: item.id,
								urlRouteName:this.$router.resolve({ name: "PatientEChartActiveTaskList",params: { patient_id: this.$route.params.patient_id, id: item.id }, query: { id: item.id } }).href,
								created_datetime: item.updated_datetime,
							};
						})

						// this.eChartDefaultViewData.taskList.data = data
						this.eChartNavData.taskList = data;
						this.combinedList = [...this.combinedList, ...data];
					}
				break;
				case "disease-registry":
					this.combinedList = this.combinedList.filter(item => item.type !== 'disease-registry');
					this.eChartNavData.diseaseRegistrysList = []
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'disease-registry',
								title: item.diagnosis,
								date: item.start_date,
								id: item.id,
								created_datetime: item.updated_datetime,
								modelId: 'disease-reg-frm-model',
							};
						})

						this.eChartNavData.diseaseRegistrysList = data
						this.combinedList = [...this.combinedList, ...data];
					}
					this.eChartDefaultViewData.diseaseRegistrysList.data = this.eChartNavData.diseaseRegistrysList
					this.eChartViewData.diseaseRegistrysList.data = this.eChartNavData.diseaseRegistrysList;
					break;
				case "family-history":
					this.combinedList = this.combinedList.filter(item => item.type !== 'family-history');
					this.eChartNavData.familyHistoryList = []
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'family-history',
								title: item.relationship,
								desc: item.note,
								date: item.start_date,
								id: item.id,
								created_datetime: item.encounter_date,
								modelId: 'family-history-frm-model',
								notesData: item.relationship + ' | ' + item.note,
							};
						})

						this.eChartNavData.familyHistoryList = data
						this.combinedList = [...this.combinedList, ...data];
					}
					this.eChartDefaultViewData.familyHistoryList.data = this.eChartNavData.familyHistoryList
					this.eChartViewData.familyHistoryList.data = this.eChartNavData.familyHistoryList;
					break;
				case "medical-history":
					this.combinedList = this.combinedList.filter(item => item.type !== 'medical-history');
					detail = response.data.data;
					this.eChartNavData.medicalList = []
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'medical-history',
								title: item.note,
								date: item.start_date,
								id: item.id,
								created_datetime: item.encounter_date,
								modelId: 'medical-history-frm-model',
							};
						})

						this.eChartNavData.medicalList = data
						this.combinedList = [...this.combinedList, ...data];
					}
					this.eChartDefaultViewData.medicalList.data = this.eChartNavData.medicalList
					this.eChartViewData.medicalList.data = this.eChartNavData.medicalList;
					break;
				case "ongoing-concerns":
					this.combinedList = this.combinedList.filter(item => item.type !== 'ongoing-concerns');
					this.eChartNavData.ongoingConcernsList = []
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'ongoing-concerns',
								title: item.note,
								desc: item.problem_description,
								date: item.start_date,
								id: item.id,
								created_datetime: item.encounter_date,
								modelId: 'ongoing-concerns-frm-model',
							};
						})

						this.eChartNavData.ongoingConcernsList = data;
						this.eChartDefaultViewData.ongoingConcernsList.data = this.eChartNavData.ongoingConcernsList
						this.combinedList = [...this.combinedList, ...data];
					}
					this.eChartViewData.ongoingConcernsList.data = this.eChartNavData.ongoingConcernsList;
					break;
				case "reminders":
					this.eChartNavData.remindersList = []
					this.combinedList = this.combinedList.filter(item => item.type !== 'reminders');
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'reminders',
								title: item.note,
								date: item.start_date,
								id: item.id,
								created_datetime: item.encounter_date,
								modelId: 'reminder-frm-model',
							};
						})

						this.eChartNavData.remindersList = data
						this.eChartDefaultViewData.remindersList.data = data;

						this.combinedList = [...this.combinedList, ...data];
					}
					this.eChartViewData.remindersList.data = this.eChartNavData.remindersList;
					break;
				case "risk-factors":
					this.combinedList = this.combinedList.filter(item => item.type !== 'risk-factors');
					this.eChartNavData.riskFactorsList = []
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'risk-factors',
								title: item.risk_factor_name,
								date: item.start_date,
								id: item.id,
								created_datetime: item.encounter_date,
								modelId: 'risk-factor-frm-model',
							};
						})

						this.eChartNavData.riskFactorsList = data
						this.eChartDefaultViewData.riskFactorsList.data = data
						this.combinedList = [...this.combinedList, ...data];
					}
					this.eChartViewData.riskFactorsList.data = this.eChartNavData.riskFactorsList;
					break;
				case "social-history":
					this.combinedList = this.combinedList.filter(item => item.type !== 'social-history');
					this.eChartNavData.socialList = []
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'social-history',
								title: item.note,
								date: item.start_date,
								id: item.id,
								created_datetime: item.encounter_date,
								modelId: 'social-history-frm-model',
							};
						})

						this.eChartNavData.socialList = data
						this.eChartDefaultViewData.socialList.data = data
						this.combinedList = [...this.combinedList, ...data];
					}
					this.eChartViewData.socialList.data = this.eChartNavData.socialList;
					break;
				case "prescriptions":
					this.combinedList = this.combinedList.filter(item => item.type !== 'medications');
					detail = response.data.data;
					if (detail.length > 0) {
						let data
						data = detail.map((item) => {
							return {
								type: 'medications',
								title: item.drug_name,
								date: item.written_date,
								urlRouteName: this.$router.resolve({ name: "PatientEChartPrescriptionView", params: { patient_id: this.$route.params.patient_id, id: item.id } }).href,
								id: item.id,
								created_datetime: item.updated_datetime,
							};
						})

						this.eChartNavData.prescriptionList = data
						// this.eChartDefaultViewData.prescriptionList.data = data
						this.combinedList = [...this.combinedList, ...data];
					}
					break;
			}
			await this.sortDataBasedOnDate();
		},
		toggleElementBox() {
			this.showNextButton = true;
			if(!this.isActiveShowAll) {
				Object.values(this.eChartDefaultViewData).forEach((viewData) => {
					if (viewData.id === 'disease-registry' || viewData.id === 'risk-factor') {
						viewData.isDisplay = false;
					} else {
						viewData.isDisplay = true;
					}
				});
			}
		},
		toggleIsDisplayForShowAll() {
			for (let key in this.eChartViewData) {
				this.eChartViewData[key].isDisplay = !this.eChartViewData[key].isDisplay;
			}
			this.showNextButtonForShowAll = !this.showNextButtonForShowAll;
		},
		updatedEChartData(resultData) {
			this.echartElement = this.echartElement = Array.isArray(resultData) ? resultData : [];
			this.checkedCount = 0;

			// Update checkedCount and set isDisplay for elements
			if(this.echartElement.length > 0) {
				this.echartElement.forEach(element => {
					Object.values(this.eChartDefaultViewData).forEach(data => {
						if (element.id === data.id && element.checked) {
							this.checkedCount++;
							data.isDisplay = this.checkedCount <= 5; // Display only up to 5 elements on the first page
						}
					});
				});
	
				// Show the 'next' button only if more than 5 elements are checked
				this.showNextButton = this.checkedCount > 5;
	
				// Handle second page logic when on the second page
				if (this.secondPage) {
					// Check if any relevant elements are checked directly
					if (this.checkedCount > 5) {
						const relevantChecked = this.echartElement.some(
							element =>
								element.checked &&
								(element.id === 'disease-registry' || element.id === 'risk-factor')
						);
	
						// If relevant elements are checked, display them on the second page
						if (relevantChecked) {
							this.echartElement.forEach(element => {
								Object.values(this.eChartDefaultViewData).forEach(data => {
									if (element.id === data.id) {
										// Display only if checked and relevant
										data.isDisplay =
											element.checked &&
											(data.id === 'disease-registry' || data.id === 'risk-factor');
									}
								});
							});
	
							const isCheckedRiskFactor = this.echartElement.some(
								element => element.checked && element.id === 'risk-factor'
							);
	
							const isCheckedDiseaseRegistry = this.echartElement.some(
								element => element.checked && element.id === 'disease-registry'
							);
	
							if (this.checkedCount === 6) {
								this.echartElement.forEach(element => {
									Object.values(this.eChartDefaultViewData).forEach(data => {
										if(isCheckedDiseaseRegistry && isCheckedRiskFactor) {
											if (element.id === data.id && data.id === 'disease-registry') {
												data.isDisplay = false;
											}
										}
									});
								});
								this.echartElement.forEach(element => {
									Object.values(this.eChartDefaultViewData).forEach(data => {
										if(isCheckedDiseaseRegistry && !isCheckedRiskFactor) {
											if (element.id === data.id && data.id === 'disease-registry') {
												data.isDisplay = true;
											}
										}
									});
								});
							}
						} else {
							// If relevant elements are not checked, switch to the first page
							this.firstPage = true;
							this.secondPage = false;
							this.showNextButton = this.checkedCount > 5;
	
							// Set isDisplay to false for 'disease-registry' and 'risk-factor'
							this.echartElement.forEach(element => {
								Object.values(this.eChartDefaultViewData).forEach(data => {
									if (element.id === data.id) {
										if (data.id === 'disease-registry' || data.id === 'risk-factor') {
											data.isDisplay = false; // Ensure they are hidden
										}
									}
								});
							});
						}
					} else {
						// If checked count is 5 or fewer, switch to the first page
						this.firstPage = true;
						this.secondPage = false;
						this.showNextButton = false;
					}
				}
	
				// Ensure correct button logic: show previous button only if on the second page
				if (this.secondPage) {
					this.showNextButton = false;
				}
			}
		},
		toggleIsDisplay(from = '') {
			if(from === 'next') {
				for (let key in this.eChartDefaultViewData) {
					this.eChartDefaultViewData[key].isDisplay = !this.eChartDefaultViewData[key].isDisplay;
				}
				this.echartElement.forEach(element => {
					Object.values(this.eChartDefaultViewData).forEach((data) => {
						if (element.id === data.id && element.checked) {
							this.checkedCount--;
						}
					});
				});
				this.firstPage = false;
				this.secondPage = true;
			}
			if(from === 'previous') {
				for (let key in this.eChartDefaultViewData) {
					this.eChartDefaultViewData[key].isDisplay = !this.eChartDefaultViewData[key].isDisplay;
				}
				this.echartElement.forEach(element => {
					Object.values(this.eChartDefaultViewData).forEach((data) => {
						if (element.id === data.id && element.checked) {
							this.checkedCount++;
						}
					});
				});
				this.secondPage = false;
				this.firstPage = true;
			}
			this.showNextButton = !this.showNextButton;
		},
		// getEncounterNotes() {
		// 	axios.post('patient/encounter-note/list', { patient_id: this.$route.params.patient_id })
		// 		.then((response) => {
		// 			if (response.status == 200) {
		// 				this.patientEncNoteHistory = response.data.data;
		// 				this.combinedList = [...this.combinedList, ...this.patientEncNoteHistory];
		// 				this.$store.state.loader = false;
		// 			} else {
		// 				this.$filters.moshaToast(response.data.message, "error");
		// 				this.$store.state.loader = false;
		// 			}
		// 		}).catch(error => {
		// 			this.$store.state.loader = false;
		// 			if (error.response.status === 422) {
		// 				this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
		// 			} else {
		// 				this.$filters.moshaToast(error.response.data.message, "error");
		// 			}
		// 		});
		// },
		async getEncounterNotes() {
			try {
				const response = await axios.post('patient/encounter-note/list', {
					patient_id: this.$route.params.patient_id,
				});

				if (response.status === 200) {
					this.patientEncNoteHistory = response.data.data;
					this.combinedList = [...this.combinedList, ...this.patientEncNoteHistory];
				} else {
					this.$filters.moshaToast(response.data.message, "error");
				}
			} catch (error) {
				if (error.response?.status === 422) {
					const errorMessage = Object.values(error.response.data.data).join(', ');
					this.$filters.moshaToast(errorMessage, "error");
				} else {
					this.$filters.moshaToast(
						error.response?.data?.message || "An error occurred",
						"error"
					);
				}
			} finally {
				this.$store.state.loader = false;
			}
		},
		getDateTimeFormatWithoutTimeZoneVal(noteDateTime) {
			const now = new Date(noteDateTime);
			const formattedDateTime = `${this.getMonthAbbreviation(now.getMonth())} ${now.getDate()}, ${now.getFullYear()} ${this.formatAMPM(now)}`;
			return formattedDateTime;
		},
		getDateTimeFormatVal(noteDateTime) {
			if (!noteDateTime) {
				console.warn("noteDateTime is null or undefined.");
				return ""; // Return an empty string or a default value
			}

			if (noteDateTime.includes("T")) {

				const timeZone = localStorage.getItem('appTimeZone');
				const now = new Date(noteDateTime);

				// Convert the UTC time to the specified timezone
				const utcOffset = now.getTimezoneOffset() * 60 * 1000;
				const targetOffset = this.getTimeZoneOffsetInMs(timeZone);
				const adjustedTime = new Date(now.getTime() + utcOffset + targetOffset);

				const formattedDateTime = `${this.getMonthAbbreviation(adjustedTime.getMonth())} ${adjustedTime.getDate()}, ${adjustedTime.getFullYear()} ${this.formatAMPM(adjustedTime)}`;
				return formattedDateTime;
			} else {
				return this.getDateTimeFormatWithoutTimeZoneVal(noteDateTime);
			}
		},
		getTimeZoneOffsetInMs(timeZone) {
			// Create a date object in the target timezone
			const date = new Date();
			const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
			const targetDate = new Date(date.toLocaleString("en-US", { timeZone }));

			// Return the difference in milliseconds
			return targetDate - utcDate;
		},
		getMonthAbbreviation(monthIndex) {
			const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
				"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
			return monthNames[monthIndex];
		},
		formatAMPM(date) {
			let hours = date.getHours();
			let minutes = date.getMinutes();
			const ampm = hours >= 12 ? 'PM' : 'AM';
			hours = hours % 12;
			hours = hours ? hours : 12; // Handle midnight (12:00)
			minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero if needed
			return hours + ':' + minutes + ' ' + ampm;
		},
		showCreateNoteForm() {
			this.resetEncounterNoteForm()
			this.isShowCreateNoteForm = true;
			this.isShowCreateNoteBtn = !this.isShowCreateNoteBtn;

			axios.post('appointment/check-today-appointment', { patient_id: this.$route.params.patient_id })
				.then((response) => {
					if (response.status == 200) {
						this.isPatientAppointmentToday = response.data.data.is_today_appointment_exist
						this.isPatientAppointmentToday = true
					}
				})
		},
		resetEncounterNoteForm(){
			Object.assign(
				this.$store.state.encounter_note.form,
				this.$store.state.encounter_note.defaultFormData
			);
			this.v$['encounterNoteFormProp'].$reset();
			this.isShowCreateNoteForm = false;
			this.isShowCreateNoteBtn = true;
			this.encounterNoteFormProp.note = '';
			this.encounterNoteFormProp.date_time = this.$filters.getCurrentDateTime();
			this.encounterNoteFormProp.noteId = null;
			this.createNoteBtnText = 'Save';
			this.action = 'Add';
			this.createNoteBillBtnText = 'Save & Bill'
			this.loader = false;
			this.loaderSaveBill = false;
		},
		hideCreateNoteForm() {
			this.resetEncounterNoteForm()
		},
		navigateToRoute(routeName) {
			this.$router.push(routeName)
		},
		saveNote(is_generate_bill_post_save = false, is_auto_save=false) {
			this.isActionInProcessing = true;
			const now = new Date();
			const appTimeZone = localStorage.getItem('appTimeZone') || 'UTC';

			const formatter = new Intl.DateTimeFormat('en-US', {
				timeZone: appTimeZone,
				dateStyle: 'short',
				timeStyle: 'short',
			});

			const noteSubmitDateTime = formatter.format(now);

			const formName = 'encounterNoteFormProp';
			this.v$[formName].$validate();
			if (!this.v$[formName].$error) {
				this.encounterNoteFormProp.patient_id = this.$route.params.patient_id

				if (is_generate_bill_post_save){
					this.loaderSaveBill = true;
				} else {
					this.loader = true;
				}
	
				this.encounterNoteFormProp.encounter_datetime = noteSubmitDateTime

				let url = 'patient/encounter-note/store'
				if (this.action !== 'Add'){
					url = 'patient/encounter-note/update'
					this.encounterNoteFormProp.id = this.encounterNoteFormProp.noteId
				}

				axios.post(url, this.encounterNoteFormProp)
					.then((response) => {
						if (response.status == 200) {
							let detail = response.data.data
							this.loader = false;
							this.loaderSaveBill = false;

							if (!is_auto_save) {
								// this.$filters.moshaToast(response.data.message, "success");
								this.getData();
								this.resetEncounterNoteForm()
								if (is_generate_bill_post_save == 1) {
									this.openBill(this.$route.params.patient_id,'', '', '', 0)
								}
							} else {
								let noteId = null
								if (this.action !== 'Add'){
									noteId = this.encounterNoteFormProp.noteId
								} else {
									noteId = detail.id
								}
								this.getEncounterNote(noteId)
							}
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
					}).catch(error => {
						this.loader = false;
						if (error.response.status === 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
							console.log(Object.values(error.response.data.data).join(', '))
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					});

			} else {
				for (const [key] of Object.entries(this.v$[formName])) {
					if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}
			this.isActionInProcessing = false;
		},
		getEncounterNote(patientNoteId) {
			axios.post('patient/encounter-note/retrieve', { patient_id: this.$route.params.patient_id, encounter_note_id: patientNoteId })
				.then((response) => {
					if (response.status == 200) {
						this.createNoteBtnText = 'Update';
						this.createNoteBillBtnText = 'Update & Bill'
						this.action = 'Update';
						this.isShowCreateNoteForm = true;

						// const dateTime = response.data.data.encounter_datetime;
						// const [datePart, timePart] = dateTime.split(' ');
						// this.encounterNoteFormProp.date_time = this.$filters.inputDateTime(datePart + " " + timePart, 'MMM. D, YYYY, hh:mm a');
						// this.encounterNoteFormProp.date_time = this.getDateTimeFormatVal(dateTime);
						this.encounterNoteFormProp.date_time = this.$filters.getCurrentDateTime();
						this.encounterNoteFormProp.noteId = response.data.data.id;
						this.encounterNoteFormProp.note = response.data.data.note;
						this.encounterNoteFormProp.creator = response.data.data.creator;
						this.isShowCreateNoteBtn = true
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		async startAIScribe() {
			this.isShowTimer = !this.isShowTimer
			this.isShowStartAI = !this.isShowStartAI
			this.isShowFinishAI = !this.isShowFinishAI
			this.timer = 0
			this.intervalId = setInterval(() => {
				this.timer += 0.01; // Increment timer by 0.01 seconds
			}, 10); // Update every 10 milliseconds (0.01 seconds)

			try {
				const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
				this.mediaRecorder = new MediaRecorder(stream);
				this.mediaRecorder.start();
				this.mediaRecorder.addEventListener('dataavailable', event => {
					console.log('Data available:', event.data.size);
					console.log('Data:', event.data);
					this.audioChunks.push(event.data);
				});

				// Event listener for when recording stops
				this.mediaRecorder.addEventListener('stop', () => {
					const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
					console.log('Blob size:', audioBlob.size);

					if (audioBlob.size > 0) {
						this.loaderAiScribe = true;
						const formData = new FormData();
						formData.append('audio', audioBlob);
						console.log('Audio Blob:', audioBlob);
						try {
							const config = {
								headers: {
									'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
									'Content-Type': 'multipart/form-data'
								},
							};

							axios.post('patient/encounter-note/generate-from-voice', formData, config).then((response) => {
								if (response.status == 200) {
									let detail = response.data
									this.encounterNoteFormProp.recording_file_path = detail.data.recording_file_path
									this.encounterNoteFormProp.encounter_transcript = detail.data.encounter_transcript
									this.encounterNoteFormProp.note = detail.data.note
									this.audioChunks = [];

									this.loaderAiScribe = false;

									this.isShowStartAI = !this.isShowStartAI
									this.isShowFinishAI = !this.isShowFinishAI

									this.$filters.moshaToast("AI Scribe content generate successfully", "success");
								}
							}).catch(error => {
								this.loaderAiScribe = false;
								this.$filters.moshaToast(error.response.data.message, "error");
							});
						} catch (error) {
							console.error('Error sending audio to server:', error);
						}
					} else {
						this.$filters.moshaToast("Please do recording again", "error");
						this.isShowStartAI = !this.isShowStartAI
						this.isShowFinishAI = !this.isShowFinishAI

					}



				});

				// this.mediaRecorder.addEventListener('stop', this.stopRecording);
				this.recording = true;
			}
			catch (error) {
				console.error('Error starting recording:', error);
				this.$filters.moshaToast('Error starting recording:' + error, "error");
				this.stopAIScribe();
			}
		},
		async stopAIScribe() {
			if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
				this.mediaRecorder.stop();
			}
			this.isShowTimer = !this.isShowTimer
			this.recording = false;
			clearInterval(this.intervalId); // Stop the timer

		},
		formatTimer(value) {
			const minutes = Math.floor(value / 60);
			const seconds = Math.floor(value % 60);
			return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`; // Format as mm:ss
		},
		openModel(name) {
			let modelId = this.getModelName(name)
			window.$(".history-list").modal("hide")
			window.$("#" + modelId).modal("show")
		},
		openCreateModel(name) {
			this.resetAllModels()
			this.resetModelFrom('medical-history')
			// window.$(".history-frm").modal("hide")
			// window.$(".history-list").modal("hide")
			let modelId = this.getModelFrmName(name)
			window.$("#" + modelId).modal("show")
			// this.saveButtonModelTxt = 'Save';
		},
		checkCondition(id) {
			return this.echartElement.some(element => element.id === id && element.checked);
		},
		getModelName(name) {
			let modelId = '';
			if (name == 'MEDICAL HISTORY') {
				modelId = "medical-history-model"
				this.getList('medical-history');
			} else if (name == 'SOCIAL HISTORY') {
				modelId = "social-history-model"
				this.getList('social-history');
			} else if (name == 'FAMILY HISTORY') {
				modelId = 'family-history-model'
				this.getList('family-history');
			} else if (name == 'REMINDER') {
				modelId = 'reminder-model'
				this.getList('reminders');
			} else if (name == 'ONGOING CONCERNS') {
				modelId = 'ongoing-concerns-model'
				this.getList('ongoing-concerns');
			} else if (name == 'DISEASE REGISTRY') {
				modelId = 'disease-reg-model'
				this.getList('disease-registry');
			} else if (name == 'RISK FACTOR') {
				modelId = 'risk-factor-model'
				this.getList('risk-factors');
			}

			return modelId
		},
		getModelFrmName(name) {
			let modelId = '';
			if (name == 'MEDICAL HISTORY') {
				modelId = "medical-history-frm-model"
				// this.getList('medical-history');
			} else if (name == 'SOCIAL HISTORY') {
				modelId = "social-history-frm-model"
				// this.getList('medical-history');
			} else if (name == 'FAMILY HISTORY') {
				modelId = 'family-history-frm-model'
				// this.getList('family-history');
			} else if (name == 'REMINDER') {
				modelId = 'reminder-frm-model'
				// this.getList('reminders');
			} else if (name == 'ONGOING CONCERNS') {
				modelId = 'ongoing-concerns-frm-model'
				// this.getList('ongoing-concerns');
			} else if (name == 'DISEASE REGISTRY') {
				modelId = 'disease-reg-frm-model'
				// this.getList('disease-registry');
			} else if (name == 'RISK FACTOR') {
				modelId = 'risk-factor-frm-model'
				// this.getList('risk-factors');
			}

			return modelId
		},
		changeNameAsPerModal(name) {
			if(name == 'consultationsList'){
				return 'CONSULTATIONS'
			} else if(name == 'documentsList'){
				return 'DOCUMENT'
			} else if (name == 'prescriptionList') {
				return 'PRESCRIPTION'
			} else if (name == 'immunizationList') {
				return 'IMMUNIZATION'
			} else if (name == 'imagingList') {
				return 'IMAGING'
			} else if(name == 'labsList'){
				return 'LAB'
			} else if(name == 'radiologyList'){	
				return 'RADIOLOGY'
			} else if(name == 'procedureList'){
				return 'PROCEDURE'
			} else if(name == 'vitalList'){
				return 'VITAL'
			} else if(name == 'allergiesList'){
				return 'ALLERGY'
			} else if(name == 'preventionsListDT') {
				return 'PREVENTION'
			} else if(name == 'taskList') {
				return 'TASK'
			} else if(name == 'diseaseRegistrysList') {
				return 'DISEASE REGISTRY'
			} else if(name == 'familyHistoryList') {
				return 'FAMILY HISTORY'
			} else if(name == 'medicalList') {
				return 'MEDICAL HISTORY'
			} else if(name == 'ongoingConcernsList') {
				return 'ONGOING CONCERNS'
			} else if(name == 'remindersList') {
				return 'REMINDER'
			} else if(name == 'socialList') {
				return 'SOCIAL HISTORY'
			} else if(name == 'riskFactorsList') {
				return 'RISK FACTOR'
			}
		},
		cancelModel() {
			window.$(".history-list").modal("hide")
		},
		deleteRecords(moduleName, id) {
			this.$swal({
				title: 'Are you sure to DELETE this record?',
				text: '',
				icon: '',
				showCancelButton: true,
				confirmButtonColor: '#DD6B55',
				confirmButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				closeOnConfirm: false,
				closeOnCancel: false,
				reverseButtons: true, // This will swap the Confirm and Cancel buttons
				customClass: {
					container: 'my-swal-container',
					popup: 'my-swal-popup delete-popup',
					header: 'my-swal-header',
					title: 'my-swal-title',
					closeButton: 'my-swal-close-button',
					icon: 'my-swal-icon',
					image: 'my-swal-image',
					content: 'my-swal-content',
					input: 'my-swal-input',
					actions: 'my-swal-actions',
					confirmButton: 'my-swal-confirm-button',
					cancelButton: 'my-swal-cancel-button',
					footer: 'my-swal-footer'
				}
			}).then((result) => {
				if (result.isConfirmed) {
					axios.post("patient/" + moduleName + "/delete", { id: id, patient_id: this.$route.params.patient_id })
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
								this.$filters.moshaToast(response.data.message, "success");
								this.getList(moduleName);
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						})
					// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
				} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
				}
			});
		},
		handleMedicalFrmItemSelected({ fieldName, item }) {
			this.medical_history_form[fieldName] = item.value;
			if (fieldName == 'life_stage') {
				this.medical_history_form['life_stage_text'] = item.title.split(':')[0].trim();
			}
		},
		handleFamilyFrmItemSelected({ fieldName, item }) {
			this.family_history_form[fieldName] = item.value;
			if (fieldName == 'relationship') {
				this.family_history_form['relationship_text'] = item.title
			}
			if (fieldName == 'life_stage') {
				this.family_history_form['life_stage_text'] = item.title.split(':')[0].trim();
			}
		},
		handleDiseaseFrmItemSelected({ fieldName, item }) {
			this.disease_reg_form[fieldName] = item.value;
			if (fieldName == 'life_stage') {
				this.disease_reg_form['life_stage_text'] = item.title.split(':')[0].trim();
			}
		},
		handleRiskFactorFrmItemSelected({ fieldName, item }) {
			this.risk_factors_form[fieldName] = item.value;
			if (fieldName == 'life_stage') {
				this.risk_factors_form['life_stage_text'] = item.title.split(':')[0].trim();
			}
		},
		saveEchartEle(moduleName) {
			let url = ''
			let form = {}
			let $this = this
			let formName = ''

			if (moduleName == 'medical-history') {
				if (this.medical_history_form.id) {
					url = "patient/medical-history/store";
				} else {
					url = "patient/medical-history/store";
				}

				form = this.medical_history_form
				formName = 'medical_history_form';

			} else if (moduleName == 'social-history') {
				if (this.medical_history_form.id) {
					url = "patient/social-history/store";
				} else {
					url = "patient/social-history/store";
				}

				form = this.social_history_form
				formName = 'social_history_form';
			} else if (moduleName == 'family-history') {
				if (this.medical_history_form.id) {
					url = "patient/family-history/store";
				} else {
					url = "patient/family-history/store";
				}

				form = this.family_history_form
				formName = 'family_history_form';
			} else if (moduleName == 'reminders') {
				if (this.reminder_form.id) {
					url = "patient/reminders/store";
				} else {
					url = "patient/reminders/store";
				}

				form = this.reminder_form
				formName = 'reminder_form';
			} else if (moduleName == 'ongoing-concerns') {
				if (this.ongoing_concerns_form.id) {
					url = "patient/ongoing-concerns/store";
				} else {
					url = "patient/ongoing-concerns/store";
				}

				form = this.ongoing_concerns_form
				formName = 'ongoing_concerns_form';
			} else if (moduleName == 'disease-registry') {
				if (this.disease_reg_form.id) {
					url = "patient/disease-registry/update";
				} else {
					url = "patient/disease-registry/store";
				}

				form = this.disease_reg_form
				formName = 'disease_reg_form';
			} else if (moduleName == 'risk-factors') {
				if (this.risk_factors_form.id) {
					url = "patient/risk-factors/store";
				} else {
					url = "patient/risk-factors/store";
				}

				form = this.risk_factors_form
				formName = 'risk_factors_form';
			}

			this.v$[formName].$validate();

			if (this.v$[formName].$error) {
				for (const [key] of Object.entries(this.v$[formName])) {
					if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}

				return false
			}

			$this.modelSaveLoader = true
			form['patient_id'] = this.$route.params.patient_id;

			axios.post(url, form)
				.then((response) => {
					$this.modelSaveLoader = false
					if (response.status == 200) {
						setTimeout(function () {
							$this.$filters.moshaToast(response.data.message, "success")
							window.$(".history-frm").modal("hide")
							$this.getList(moduleName)
							$this.resetModelFrom(moduleName)
						}, 400);
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				}).catch(error => {
					$this.modelSaveLoader = false
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						console.log(Object.values(error.response.data.data).join(', '))
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});

		},
		isValidDate(dateString) {
			const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
			const date = new Date(year, month - 1, day);
			return date.getFullYear() === year && (month === 0 || date.getMonth() + 1 === month) && (day === 0 || date.getDate() === day);
		},
		onFieldChange(fieldName, formName, obj=null) {
			if(obj){
				const sanitizedInput = $(`#${obj}`).val();
				var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
					let result = y;
					if (m) result += '-' + m;
					if (d) result += '-' + d;
					return result;
				});
	
				// Get or create the error message element
				let errorElement = $(`#${obj}-error`);
				if (errorElement.length === 0) {
					errorElement = $(`<div id="${obj}-error" style="color: red;margin-top: 5px;"></div>`);
					$(`#${obj}`).parent().after(errorElement);
				}
				const isValidDate = (formattedDate!='') ? this.isValidDate(formattedDate) : false;
				console.log(isValidDate);
				if (isValidDate) {
					errorElement.text(''); // Clear error message if the date is valid
					this[formName][fieldName] = moment(sanitizedInput).format('YYYY-MM-DD');
				} else {
					if(formattedDate!=''){
						errorElement.text('Invalid Date');
					}
					$(`#${obj}`).val("");
				}
			}else{
				this[formName][fieldName] = (this[formName][fieldName]) ? moment(this[formName][fieldName]).format('YYYY-MM-DD') : '';
			}
		},
		resetModelFrom(moduleName) {
			if (moduleName == 'medical-history') {
				Object.assign(
					this.$store.state.medical_history.form,
					this.$store.state.medical_history.defaultFormData
				);
				this.v$['medical_history_form'].$reset();

				this.$store.state.medical_history.form['start_date'] = null
				this.$store.state.medical_history.form['resolution_date'] = null
				this.$store.state.medical_history.form['procedure_date'] = null
				this.$store.state.medical_history.form['life_stage'] = ''
				this.$store.state.medical_history.form['life_stage_text'] = 'Not Set'
				this.onFieldChange('start_date', 'medical_history_form')
				this.onFieldChange('procedure_date', 'medical_history_form')
				this.onFieldChange('resolution_date', 'medical_history_form')

			} else if (moduleName == 'social-history') {
				Object.assign(
					this.$store.state.social_history.form,
					this.$store.state.social_history.defaultFormData
				);
				this.v$['social_history_form'].$reset();

				this.$store.state.social_history.form['start_date'] = null
				this.$store.state.social_history.form['resolution_date'] = null
				this.$store.state.social_history.form['procedure_date'] = null
				this.$store.state.social_history.form['life_stage'] = ''
				this.$store.state.social_history.form['life_stage_text'] = 'Not Set'

				this.onFieldChange('start_date', 'social_history_form')
				this.onFieldChange('procedure_date', 'social_history_form')
				this.onFieldChange('resolution_date', 'social_history_form')
			} else if (moduleName == 'family-history') {
				Object.assign(
					this.$store.state.family_history.form,
					this.$store.state.family_history.defaultFormData
				);
				this.v$['family_history_form'].$reset();

				this.$store.state.family_history.form['start_date'] = null
				this.$store.state.family_history.form['resolution_date'] = null
				this.$store.state.family_history.form['procedure_date'] = null
				this.$store.state.family_history.form['life_stage'] = ''
				this.$store.state.family_history.form['life_stage_text'] = 'Not Set'

				this.onFieldChange('start_date', 'family_history_form')
				this.onFieldChange('procedure_date', 'family_history_form')
				this.onFieldChange('resolution_date', 'family_history_form')
			} else if (moduleName == 'reminders') {
				Object.assign(
					this.$store.state.reminders.form,
					this.$store.state.reminders.defaultFormData
				);
				this.v$['reminder_form'].$reset();

				this.$store.state.reminders.form['start_date'] = null
				this.$store.state.reminders.form['resolution_date'] = null
				this.$store.state.reminders.form['procedure_date'] = null
				this.$store.state.reminders.form['life_stage'] = ''
				this.$store.state.reminders.form['life_stage_text'] = 'Not Set'

				this.onFieldChange('start_date', 'reminder_form')
				this.onFieldChange('procedure_date', 'reminder_form')
				this.onFieldChange('resolution_date', 'reminder_form')
			} else if (moduleName == 'ongoing-concerns') {
				Object.assign(
					this.$store.state.ongoing_concerns.form,
					this.$store.state.ongoing_concerns.defaultFormData
				);
				this.v$['ongoing_concerns_form'].$reset();

				this.$store.state.ongoing_concerns.form['start_date'] = null
				this.$store.state.ongoing_concerns.form['resolution_date'] = null
				this.$store.state.ongoing_concerns.form['procedure_date'] = null
				this.$store.state.ongoing_concerns.form['life_stage'] = ''
				this.$store.state.ongoing_concerns.form['life_stage_text'] = 'Not Set'

				this.onFieldChange('start_date', 'ongoing_concerns_form')
				this.onFieldChange('procedure_date', 'ongoing_concerns_form')
				this.onFieldChange('resolution_date', 'ongoing_concerns_form')
			} else if (moduleName == 'disease-registry') {
				Object.assign(
					this.$store.state.disease_reg.form,
					this.$store.state.disease_reg.defaultFormData
				);
				this.v$['disease_reg_form'].$reset();

				this.$store.state.disease_reg.form['start_date'] = null
				this.$store.state.disease_reg.form['resolution_date'] = null
				this.$store.state.disease_reg.form['procedure_date'] = null
				this.$store.state.disease_reg.form['life_stage'] = ''
				this.$store.state.disease_reg.form['life_stage_text'] = 'Not Set'

				this.onFieldChange('start_date', 'disease_reg_form')
				this.onFieldChange('procedure_date', 'disease_reg_form')
				this.onFieldChange('resolved_date', 'disease_reg_form')
			} else if (moduleName == 'risk-factors') {
				Object.assign(
					this.$store.state.risk_factors.form,
					this.$store.state.risk_factors.defaultFormData
				);
				this.v$['risk_factors_form'].$reset();

				this.$store.state.risk_factors.form['start_date'] = null
				this.$store.state.risk_factors.form['resolution_date'] = null
				this.$store.state.risk_factors.form['procedure_date'] = null
				this.$store.state.risk_factors.form['life_stage'] = ''
				this.$store.state.risk_factors.form['life_stage_text'] = 'Not Set'

				this.onFieldChange('start_date', 'risk_factors_form')
				this.onFieldChange('procedure_date', 'risk_factors_form')
				this.onFieldChange('resolution_date', 'risk_factors_form')
			}
		},
		cancelModelFrm() {
			this.resetModelFrom('medical-history')
			window.$(".history-frm").modal("hide")
			this.saveButtonModelTxt = 'Create';
		},
		viewEditModel(id, modelId) {
			this.resetModelFrom('medical-history')
			window.$(".history-frm").modal("hide")
			this.edit(id, modelId)
			window.$("#" + modelId).modal("show")
			this.saveButtonModelTxt = 'Save';
		},
		edit(id, modelId) {
			let url = ''
			if (modelId == 'medical-history-frm-model') {
				url = "patient/medical-history/retrieve";
			} else if (modelId == 'social-history-frm-model') {
				url = "patient/social-history/retrieve";
			} else if (modelId == 'family-history-frm-model') {
				url = "patient/family-history/retrieve";
			} else if (modelId == 'reminder-frm-model') {
				url = "patient/reminders/retrieve";
			} else if (modelId == 'ongoing-concerns-frm-model') {
				url = "patient/ongoing-concerns/retrieve";
			} else if (modelId == 'risk-factor-frm-model') {
				url = "patient/risk-factors/retrieve";
			} else if (modelId == 'disease-reg-frm-model') {
				url = "patient/disease-registry/retrieve";
			}

			axios.post(url, { 'patient_id': this.$route.params.patient_id, id: id })
				.then((response) => {
					if (response.data.status === 200) {
						if (modelId == 'medical-history-frm-model') {
							this.$store.state.medical_history.form = response.data.data
						} else if (modelId == 'social-history-frm-model') {
							this.$store.state.social_history.form = response.data.data
						} else if (modelId == 'family-history-frm-model') {
							this.$store.state.family_history.form = response.data.data
						} else if (modelId == 'reminder-frm-model') {
							this.$store.state.reminders.form = response.data.data
						} else if (modelId == 'ongoing-concerns-frm-model') {
							this.$store.state.ongoing_concerns.form = response.data.data
						} else if (modelId == 'risk-factor-frm-model') {
							this.$store.state.risk_factors.form = response.data.data
						} else if (modelId == 'disease-reg-frm-model') {
							this.$store.state.disease_reg.form = response.data.data
						}
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$filters.moshaToast(error.message, "error");
				});
		},
		resetAllModels() {
			this.$store.state.medical_history.form = {}
			this.$store.state.social_history.form = {}
			this.$store.state.family_history.form = {}
			this.$store.state.risk_factors.form = {}
			this.$store.state.ongoing_concerns.form = {}
			this.$store.state.disease_reg.form = {}
			this.$store.state.reminders.form = {}
			this.resetModelFrom('medical-history')
			this.resetModelFrom('social-history')
			this.resetModelFrom('family-history')
			this.resetModelFrom('reminders')
			this.resetModelFrom('ongoing-concerns')
			this.resetModelFrom('disease-registry')
			this.resetModelFrom('risk-factors')
		},
		handleChildEvent(data) {
			if (data.action == 'Listing') {
				this.openModel(data.name)
			} else if (data.action == 'Detail') {
				let modelId = this.getModelFrmName(data.name)
				this.viewEditModel(data.id, modelId)
			}
		},
		openBill(patient_id,patient_name, provider_id, provider_name, is_private){
			const url = this.$router.resolve({
				name: 'InvoiceMasterCreate',
				query: { patient_id, patient_name, provider_id, provider_name, is_private }
			}).href;
			window.open(url, '_blank');
		}
	},
	computed: {
		filteredBoxData() {
			return Object.values(this.eChartViewData).filter(item => item.isDisplay);
		},
		filteredDefaultBoxData() {
			return Object.values(this.eChartDefaultViewData).filter(item => item.isDisplay);
		},
		medical_history_form() {
			return this.$store.state.medical_history.form;
		},
		social_history_form() {
			return this.$store.state.social_history.form;
		},
		family_history_form() {
			return this.$store.state.family_history.form;
		},
		risk_factors_form() {
			return this.$store.state.risk_factors.form;
		},
		ongoing_concerns_form() {
			return this.$store.state.ongoing_concerns.form;
		},
		disease_reg_form() {
			return this.$store.state.disease_reg.form;
		},
		reminder_form() {
			return this.$store.state.reminders.form;
		},
		encounterNoteFormProp(){
			return this.$store.state.encounter_note.form;
		}
	}
}
</script>

<style scoped>
	.e-chart_page_slider>img {width: calc(var(--scale-ratio) * 25px);height: calc(var(--scale-ratio) * 25px);}
	img {vertical-align: middle;}
</style>

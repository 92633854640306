import {
	helpers,
	required,
	// email,
} from "@vuelidate/validators";

const allergies = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			description: null,
			reaction: null,
			start_date: new Date().toISOString().slice(0, 10),
			age_of_onset: null,
			life_stage: null,
			severity_of_reaction: null,
			allergy_type:null,
			note:null,
			property:null,
			drug_code:null,
			class:null,
			pepid_code:null,
			severity_of_reaction_text:'Not Set',
			life_stage_text:'Not Set'
		},
		form: {
			id: null,
			description: null,
			reaction: null,
			start_date: new Date().toISOString().slice(0, 10),
			age_of_onset: null,
			life_stage: null,
			severity_of_reaction: null,
			allergy_type:null,
			note:null,
			property:null,
			drug_code:null,
			class:null,
			pepid_code:null,
			severity_of_reaction_text:'Not Set',
			life_stage_text:'Not Set'
		},
		validationRules: {
			form: {
				description: {
					required: helpers.withMessage("Please Enter Description.", required),
				},
			},
		},
		vuelidateExternalResults: {
			form: {
				
			}
		},
		list: [],
	},

	getters: {},
	mutations: {},
	actions: {},
};


export default allergies;

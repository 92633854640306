
<template>
	<!-- row colom Start -->
	<div class="consult_add_page">
		<div class="row justify-content-center align-items-center">
			<div class="col-lg-5">
				<div class="d-flex align-items-center mb-10">
					<p class="attachments-btn cursor-pointer mb-0" :class="{'attachments-active': this.transfer_form.e_chart_transfer.is_attach}" @click="openAttachment">ATTACHMENTS</p>
					<span class="attachment-clear-icon cursor-pointer" v-if="this.transfer_form.e_chart_transfer.is_attach" @click="removeAttach"><img src="/images/close-icon.svg"></span>
				</div>
			</div>
			<div class="col-lg-5" >
				<div class="text-end" v-if="form.status">
					<div class="status-btn"><span>Status</span>
						<slot>{{ form.status ? form.status : '' }}</slot>
					</div>
				</div>
			</div>
		</div>
		<div class="row patient_chart justify-content-center edit_record_page">
			<!-- col-lg-7 colom start -->
			<div class="col-lg-6 ">
				<h4 class="box-title mt-0">Referral Basic Information</h4>
				<div class="box-body containt_detail">
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Referring Practitioner</label>
						<div class="col-sm-8">
							<CustomDropDown :options="mrpProviderDataSource" :initialValue="this.physician_text"
								v-model="form.referring_practitioner_id" @item-selected="handleItemSelected"
								fieldName="referring_practitioner_id" id="referring_practitioner_id">
							</CustomDropDown>
						</div>
					</div>
					<ValidationErrorMessageList :errors="v$.form.referring_practitioner_id.$errors" />

					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Referral Date </label>
						<div class="col-sm-8">
							<span class="two_inputs">
								<div class="calendar _datepic demog-datepick-icon-remove">
									<ejs-datepicker class="e-field form-control" @change="handleDate('referral_date')"
										@blur="handleDate('referral_date')" :maxDate="currentDate" v-model="form.referral_date"
										:showClearButton="false" format="yyyy-MM-dd"
										@keyup="this.keyupdate('referral_date')" id="referral_date"
										ref="referral_date"></ejs-datepicker>
								</div>
								<aside class="mute_text ms-3 fw-normal">(YYYY-MM-DD)</aside>
							</span>
						</div>
					</div>
					<ValidationErrorMessageList :errors="v$.form.referral_date.$errors" />

					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Service</label>
						<div class="col-sm-8">
							<CustomDropDown :options="serviceList" :initialValue="this.service_text" @item-selected="handleItemSelected" fieldName="service_id" id="service_id"></CustomDropDown>
						</div>
					</div>
					<ValidationErrorMessageList :errors="v$.form.service_id.$errors" />

					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Specialist</label>
						<div class="col-sm-8">
							<CustomDropDown :options="specialistsList" :initialValue="this.specialist_text"
								@item-selected="handleItemSelected" fieldName="specialist_id" id="specialist_id"></CustomDropDown>
						</div>
					</div>
					<ValidationErrorMessageList :errors="v$.form.specialist_id.$errors" />

					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Urgency</label>
						<div class="col-sm-8">
							<CustomDropDown :options="urgencyList" :initialValue="this.form.urgency"
								@item-selected="handleItemSelected" fieldName="urgency"></CustomDropDown>
						</div>
					</div>
					<ValidationErrorMessageList :errors="v$.form.urgency.$errors" />

					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Phone</label>
						<div class="col-sm-8"><input type="number" class="form-control" v-model="form.phone"></div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Fax</label>
						<div class="col-sm-8"><input type="text" class="form-control" v-model="form.fax"></div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Address</label>
						<div class="col-sm-8"><input type="text" class="form-control" v-model="form.address"></div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">City</label>
						<div class="col-sm-8"><input type="text" class="form-control" v-model="form.city"></div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Province</label>
						<div class="col-sm-8"><input type="text" class="form-control" v-model="form.province"></div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Postal Code</label>
						<div class="col-sm-8"><input type="text" class="form-control" v-model="form.zip"></div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Appointment Instructions</label>
						<div class="col-sm-8"><input type="text" class="form-control" v-model="form.referrer_instructions" @input="checkCount('appointment-instructions')">
						</div>
					</div>
					<div class="mb-0 row pd-bottom ">
						<label for="text" class="col-sm-4 col-form-label right-bor">Appointment Date</label>
						<div class="col-sm-8">
							<span class="two_inputs">
								<div class="calendar _datepic demog-datepick-icon-remove">
									<ejs-datepicker class="e-field form-control" @change="handleDate('appointment_date')"
										@blur="handleDate('appointment_date')" :maxDate="currentDate" v-model="form.appointment_date"
										:showClearButton="false" format="yyyy-MM-dd"
										@keyup="this.keyupdate('appointment_date')" id="appointment_date"
										ref="appointment_date"></ejs-datepicker>
								</div>
								<aside class="mute_text ms-3 fw-normal">(YYYY-MM-DD)</aside>
							</span>
						</div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Appointment Time</label>
						<div class="col-sm-8">
							<span class="two_inputs">
								<div class="calendar referral-appointment-time">
									<CustomTimePicker v-model="form.appointment_time" @change="changeAppointmentTime"></CustomTimePicker>
								</div>
							</span>
						</div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Reason for Consultation</label>
						<div class="col-sm-8">
							<textarea class="form-control" id="exampleFormControlTextarea1" rows="12"
								v-model="form.reason_for_consultation" @input="checkCount('reason-for-consultation')"></textarea>
						</div>
					</div>
				</div>
			</div>
			<!-- col-lg-7 colom end -->

			<!-- col-lg-5 colom start -->
			<div class="col-lg-4">
				<!-- Patient Information Start -->
				<h4 class="box-title mt-0">Patient Information</h4>
				<div class="box-body containt_detail">
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-6 col-form-label right-bor">Patient Name</label>
						<div class="col-sm-6">
							<div class="pi-info">{{ patient_detail.last_name }} {{ patient_detail.first_name }}</div>
						</div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-6 col-form-label right-bor">Gender</label>
						<div class="col-sm-6">
							<div class="pi-info">Male</div>
						</div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-6 col-form-label right-bor">Date Of Birth</label>
						<div class="col-sm-6">
							<div class="pi-info">{{ patient_detail.dob }}</div>
						</div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-6 col-form-label right-bor">Address</label>
						<div class="col-sm-6">
							<div class="pi-info" v-html="patient_detail.full_address"></div>
						</div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-6 col-form-label right-bor">Cell Phone</label>
						<div class="col-sm-6">
							<div class="pi-info">{{ patient_detail.cell_phone }}</div>
						</div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-6 col-form-label right-bor">Home Phone</label>
						<div class="col-sm-6">
							<div class="pi-info">{{ patient_detail.home_phone }}</div>
						</div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-6 col-form-label right-bor">Work Phone</label>
						<div class="col-sm-6">
							<div class="pi-info">{{ patient_detail.work_phone }}</div>
						</div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-6 col-form-label right-bor">Email</label>
						<div class="col-sm-6">
							<div class="pi-info">{{ patient_detail.email }}</div>
						</div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-6 col-form-label right-bor">Health Card Info</label>
						<div class="col-sm-6">
							<div class="pi-info">{{ patient_detail.health_insurance_no }} {{ patient_detail.health_card_ver
							}} {{ patient_detail.health_insurance_type ? `(${patient_detail.health_insurance_type})` : '' }}</div>
						</div>
					</div>
				</div>
				<!-- Patient Information End -->
				<!-- Letterhead Start -->
				<h4 class="box-title">Letterhead</h4>
				<div class="box-body containt_detail">
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-6 col-form-label right-bor">Letterhead Name</label>
						<div class="col-sm-6">
							<div class="pi-info">{{this.clinicDetail.organization_name}}</div>
						</div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-6 col-form-label right-bor">Letterhead Address</label>
						<div class="col-sm-6">
							<div class="pi-info">{{this.clinicDetail.address}} <br>{{this.clinicDetail.city}}, {{this.clinicDetail.state}}<br>{{this.clinicDetail.zipcode}}</div>
						</div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-6 col-form-label right-bor">Letterhead Phone</label>
						<div class="col-sm-6">
							<div class="pi-info">{{this.clinicDetail.phone_number}}</div>
						</div>
					</div>
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-6 col-form-label right-bor">Letterhead Fax</label>
						<div class="col-sm-6">
							<div class="pi-info">{{this.clinicDetail.fax}}</div>
						</div>
					</div>
				</div>
				<!-- Letterhead end -->


				<!-- Signature Start -->
				<h4 class="box-title">Signature</h4>
				<img :src="this.sigImgBase64" alt="Base64 Image" class="e-control e-signature e-lib" height="60" width="429"
					v-if="this.sigImgBase64">
				<!-- <canvas id="signature" class="e-control e-signature e-lib" role="img" aria-label="signature" tabindex="-1"
					height="60" width="429" v-else></canvas> -->

			</div>
			<!-- col-lg-5 colom end -->
		</div>
		<div class="row patient_chart justify-content-center edit_record_page align-items-end">
			<div class="col-lg-1"></div>
			<div class="col-lg-6">
				<h4 class="box-title">Clinical Information</h4>
				<div class="box-body containt_detail">
					<div class="mb-0 row pd-bottom">
						<label for="text" class="col-sm-4 col-form-label right-bor">Clinical Information</label>
						<div class="col-sm-8">
							<textarea class="form-control h-232" id="exampleFormControlTextarea1" rows="11"
								v-model="form.other_information"></textarea>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-5">
				<div class="text-center">
					<button class="comman_brdr_btn big_btn" @click="cancel"> Cancel </button>

					<button class="comman_btn big_btn mx42" :class="{ 'add-prescription-disabled': !isUserDocORNurse() }" @click="save('save')" > Save <img src="images/loader.gif"
							v-if="savingloader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
					<button class="comman_btn big_btn" :class="{ 'add-prescription-disabled': !isUserDocORNurse() }" @click="save('save_print')" > Save & Print / Fax <img
							src="images/loader.gif" v-if="savingPrintloader"  style="width: calc(var(--scale-ratio) * 18px);"/> </button>
				</div>
			</div>
		</div>
	</div>

	<!-- row colom end -->

	<div class="modal fade" id="prescription-fax-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl fax-from-modal">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box d-flex flex-direction-column justify-content-between h-100">
							<div class="row patient_chart justify-content-center edit_record_page">
								<div class="col-lg-5">
									<h4 class="box-title mt-0">From</h4>
									<div class="box-body containt_detail mb-50">
										<div class="mb-0 row pd-bottom">
											<label for="text" class="col-sm-6 col-form-label right-bor">Clinic Name</label>
											<div class="col-sm-6">
												<div class="pi-info">{{this.clinicDetail.organization_name}}</div>
											</div>
										</div>
										<div class="mb-0 row pd-bottom align-items-start">
											<label for="text" class="col-sm-6 col-form-label right-bor">Address</label>
											<div class="col-sm-6">
												<div class="pi-info">{{this.clinicDetail.address}} <br>{{this.clinicDetail.city}}, {{this.clinicDetail.state}}<br>{{this.clinicDetail.zipcode}}</div>
											</div>
										</div>
										<!-- <div class="mb-0 row pd-bottom">
											<label for="text" class="col-sm-6 col-form-label right-bor">Postal Code</label>
											<div class="col-sm-6">
												<div class="pi-info">{{this.pharmacy_detail.zip_code}}</div>
											</div>
										</div> -->
										<div class="mb-0 row pd-bottom">
											<label for="text" class="col-sm-6 col-form-label right-bor">Phone Number</label>
											<div class="col-sm-6">
												<div class="pi-info">{{this.clinicDetail.phone_number}}</div>
											</div>
										</div>
										<div class="mb-0 row pd-bottom">
											<label for="text" class="col-sm-6 col-form-label right-bor">Fax Number</label>
											<div class="col-sm-6">
												<div class="pi-info">{{this.clinicDetail.fax}}</div>
											</div>
										</div>
									</div>
									<h4 class="box-title">Signature</h4>
									<div class="box-body containt_detail mb-3 mt-15">
										<div id="signature-control">
											<!-- <div class="e-sign-heading"><span id="signdescription"></span></div> -->
											<div class="col-10 mx-auto" style="height: 60px;">
												<!-- <div class="signature-ImgBase h-100 d-flex align-items-end">
													<img :src="this.sigImgBase64" v-if="this.sigImgBase64" alt="Base64 Image" class="e-control e-signature e-lib" height="60" style="height: 100%; width: 100%;" width="429">
													<span class="signature-clear cursor-pointer">clear</span>
												</div> -->
												<div class="signature-ImgBase h-100 d-flex align-items-end">
													<!-- <canvas id="signature" class="e-control e-signature e-lib" role="img"
													aria-label="signature" tabindex="-1" height="60"
													style="height: 100%; width: 100%;" width="429"></canvas> -->
													<img :src="this.sigImgBase64" v-if="this.sigImgBase64 && !this.isSignatureClear" class="e-control e-signature e-lib" height="60px" width="429px">
													<ejs-signature v-if="!this.sigImgBase64 || this.isSignatureClear" id="signature" ref="signatureObj" :height="'60px'" :width="'429px'" @change="onDraw"></ejs-signature>
													<span class="signature-clear cursor-pointer" @click="clearSignature">clear</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-7">
									<div class="fax-setting-preview position-relative">
										<div class="left-side-arrow">
											<img src="images/down-arrow_l.svg" class="cursor-pointer" v-if="currentPage > 1" @click="goToPreviousPage">
										</div>
										<div class="text-center">
											<canvas id="pdfCanvas" ref="pdfCanvas" class="w-100" style="height:calc(var(--scale-ratio) * 690px);"></canvas>
											<!-- <img src="/images/prescription-rx.png"> -->
										</div>
										<div class="right-side-arrow">
											<img src="images/down-arrow_r.svg" class="cursor-pointer" v-if="currentPage < totalPages" @click="goToNextPage">
										</div>
									</div>
								</div>
							</div>
							<div class="text-center">
								<div class="btn_part d-flex justify-content-center pt-0">
									<button class="comman_brdr_btn" @click="cancelPrint">Cancel</button>
									<button class="comman_brdr_btn mx40" @click="viewPdfInNewTab">Print</button>
									<button class="comman_btn" @click="sendFaxModel">Fax / Email</button>
								</div>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade fax-exportconsultation-modal" id="add-exportconsultation-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
						<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<ul class="export_tabs">
							<li :class="{ active: isFaxFormVisible }" @click="changeExportOpt('fax')">Fax</li>
							<li :class="{ active: isEmailFormVisible }" @click="changeExportOpt('email')">Email</li>
							<li :class="{ active: isMailFormVisible }" @click="changeExportOpt('mail')">Mail</li>
						</ul>
						<section class="con_detl_sec">
							<div class="fax_menu" v-if="isFaxFormVisible">
								<div class="row align-items-center">
									<label for="text" class="col-sm-4 col-form-label right-bor py-0 fs-18">Specialist(s)</label>
									<div class="col-sm-8 position-relative selact_dropdown cunlt_specialist_option">
										<selectCheckBox :dataSource="specialist_option" :labelName="tagSpecialistLabel" @clicked="selectedSpecialist" :displayDiv="false" :isUnselectAll="true" unselectLabel="None" :isShowDoctorIcon="false"></selectCheckBox>
									</div>
								</div>

								<div class="or_line"><span>OR</span></div>

								<div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="faxForm.fax_number" @input="formatFaxNumber" @keydown="handleKeyDown" @paste="handlePaste">
                                        <span class="multi-rc-fax-nmber white-space-nowrap d-inline-block me-2 mt-2" v-for="(phone, index) in this.faxForm.phoneNumbers" :key="index" style="display: flex; align-items: center;">
                                            {{ phone }}
                                            <img src="/images/close-icon.svg" @click="removeSelectedFax(phone)" class="cursor-pointer ml-10" style="margin-left: calc(var(--scale-ratio) * 10px); height: calc(var(--scale-ratio) * 10px);">
                                        </span>
                                        <label class="form-label" for="typeunique_idX-2">Recipient Fax Number</label>
                                        <ValidationErrorMessageList :errors="v$.faxForm.phoneNumbers.$errors" />
                                    </div>
                                    <!-- <p class="separate-multiple-fax text-center mb-0">(use comma to separate multiple fax recipients. ex: 647777777, 647123123)</p> -->
                                </div>
							</div>
							<div class="email_menu" v-if="isEmailFormVisible">
								<div class="custom-form">
									<div class="input_box">
										<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_id">
										<label class="form-label" for="typeunique_idX-2">Recipient Email</label>
										<ValidationErrorMessageList :errors="v$.emailForm.email_id.$errors" />
									</div>
									<div class="input_box">
										<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_header">
										<label class="form-label" for="typeunique_idX-2">Email Header</label>
										<ValidationErrorMessageList :errors="v$.emailForm.email_header.$errors" />
									</div>
								</div>

							</div>
							<div class="mail_menu" v-if="isMailFormVisible">
								<div class="custom-form">
									<div class="input_box">
										<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.name">
										<label class="form-label" for="typeunique_idX-2">Recipient Name</label>
										<ValidationErrorMessageList :errors="v$.mailForm.name.$errors" />
									</div>
									<div class="input_box">
										<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.address">
										<label class="form-label" for="typeunique_idX-2">Recipient Address</label>
										<ValidationErrorMessageList :errors="v$.mailForm.address.$errors" />
									</div>
									<div class="input_box">
										<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.city">
										<label class="form-label" for="typeunique_idX-2">Recipient City</label>
										<ValidationErrorMessageList :errors="v$.mailForm.city.$errors" />
									</div>
									<div class="input_box">
										<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.state">
										<label class="form-label" for="typeunique_idX-2">Recipient Province</label>
										<ValidationErrorMessageList :errors="v$.mailForm.state.$errors" />
									</div>
									<div class="input_box">
										<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.zip">
										<label class="form-label" for="typeunique_idX-2">Recipient Postal Code</label>
										<ValidationErrorMessageList :errors="v$.mailForm.zip.$errors" />
									</div>
								</div>
							</div>
						</section>
						<div class="btn_part text-center">
							<button class="comman_brdr_btn big_btn mx36" @click="closeExportModel"> Cancel </button>
							<button class="comman_btn big_btn mx36" @click="exportSubmit">Confirm
								<img src="images/loader.gif" v-if="faxLoader"  style="width: calc(var(--scale-ratio) * 18px);" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="attachments-echart-modal" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl e-chart-transfer-modal">
			<div class="modal-content">
				<div class="modal-body">					
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeEChartTransferPop"></button>
                    <div class="box">
						<h2 class="popup_title">E-CHART TRANSFER</h2>
						<div class="hdng_con">
							<div class="heading">Date Range</div>
							<span class="con_tx">Please select the date range you would like the E-Chart report to cover.</span>
						</div>
						<div class="row align-items-center justify-content-center time_set_part">
							<div class="col-md-6 text-center">
								<div class="contain_bx">
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.transfer_form.e_chart_transfer.is_all_time_range"></span>
										<label>All Time Range</label>
									</div>
								</div>
							</div>
							<div class="col-md-6 text-center left_con_box">
								<div class="position-relative mx-4" :class="{'time-range-disabled-form': this.transfer_form.e_chart_transfer.is_all_time_range}">
									<div class="contain_bx" >
										<label for="text"><span class="cursor-text">From</span></label>
										<div class="calendar e-chart-clnder" @click="openDateRangePicker('start')">
											<ejs-datepicker class="text-center e-field form-control" ref="startDatePicker"
												:format="{ skeleton: 'full' }" :showClearButton="false"
												v-model="this.selectedStartDate" @change="updateStartDate($event.value)"
												:max="eChartTransferMaxDate"
												:value="selectedStartDate" 
												@keyup="this.keyupdate('selectedStartDate')" :strictMode="true" id="selectedStartDate"/>
										</div>
									</div>
									<div class="contain_bx">
										<label for="text"><span class="cursor-text">To</span></label>
										<div class="calendar e-chart-clnder" @click="openDateRangePicker('end')">
											<ejs-datepicker class="text-center e-field form-control" ref="endDatePicker"
												:format="{ skeleton: 'full' }" :showClearButton="false"
												v-model="this.selectedREndDate" @change="updateEndDate($event.value)" 
												:max="eChartTransferMaxDate"
												:value="selectedEndDate" 
												@keyup="this.keyupdate('selectedREndDate')" :strictMode="true" id="selectedREndDate"/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="hdng_con">
							<div class="heading">E-Chart Report Content</div>
							<span class="con_tx">Please select the content you would like the E-chart report to include.</span>
						</div>
						<div class="chart-report-check">
							<div class="d-flex justify-content-center selact_box_part">
								<div class="select_report_chart_first">
									<div class="address_line">
											<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.transfer_form.e_chart_transfer.is_demographic" checked></span>
											<label>Demographic</label>
										</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.transfer_form.e_chart_transfer.is_encounter_notes" checked></span>
										<label>Encounter Notes</label>
									</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.transfer_form.e_chart_transfer.is_files" checked></span>
										<label>Files</label>
									</div>
								</div>
								<div class="select_report_chart_second">
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.transfer_form.e_chart_transfer.is_measurements" checked></span>
										<label>Measurements</label>
									</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.transfer_form.e_chart_transfer.is_medication" checked></span>
										<label>Medications</label>
									</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.transfer_form.e_chart_transfer.is_other_infos" checked></span>
										<label>Other Infos</label>
									</div>
								</div>
								<div class="select_report_chart_thrd">
										<div class="address_line">
											<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.transfer_form.e_chart_transfer.is_preventions" checked></span>
											<label>Preventions</label>
										</div>
										<div class="address_line">
											<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.transfer_form.e_chart_transfer.is_screening" checked></span>
											<label>Screenings</label>
										</div>
									</div>
							</div>
						</div>
                        <div class="btn_part text-center">
                            <button class="comman_btn mx36" @click="printEchart">Attach</button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import axios from "@/scripts/axios.js";
import CustomDropDown from '../../../base/formFields/CustomDropDown.vue';
import $ from "jquery";
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../../../base/ValidationErrorMessageList.vue';
import CustomTimePicker from "../../../base/formFields/CustomTimePicker.vue";
import moment from "moment";
import { PDFDocument } from 'pdf-lib';
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import { SignatureComponent } from "@syncfusion/ej2-vue-inputs";

export default {
	data() {
		return {
			serviceList: [],
			specialistsList: [],
			patient_detail: [],
			urgencyList: [
				{ value: "Urgent", title: "Urgent" },
				{ value: "Not Urgent", title: "Not Urgent" },
				{ value: "Follow Up", title: "Follow Up" },
			],
			mrpProviderDataSource: [],
			physician_text: 'Select Primary Physician',
			savingloader: false,
			savingPrintloader: false,
			savingFaxloader: false,
			allCustomSpecialist: [],
			allergiesList: [],
			prescriptionList: [],
			familyHistoryList: [],
			medicalHistoryList: [],
			socialHistoryList: null,
			otherInformation: '',
			clinicDetail:[],
			specialist_text:'Select',
			service_text:'Select',
			pharmacy_detail:[],
			sigImgBase64:null,
			currentPage:1,
			documentPath:'',
			pdfDoc:'',
			existingPdfBytes:null,
			totalPages:0,
			isFaxFormVisible:true,
			isEmailFormVisible:false,
			isMailFormVisible:false,
			selectedItems:[],
			selectedStartDate: new Date(),
			selectedEndDate: new Date(),
			eChartTransferMaxDate: new Date(),
			transfer_form: {
				e_chart_transfer : {
					is_attach: false,
					is_all_time_range: true,
					from_date: null,
					to_date: null,
					is_demographic: true,
					is_measurements: true,
					is_screening: true,
					is_encounter_notes: true,
					is_files: true,
					is_labs: true,
					is_preventions: true,
					is_other_infos: true,
					is_medication: true
				},
			},
			specialist_option: [],
			tagSpecialistLabel: "",
			consultataionID: 0,
			updateDateSignature: null,
			previousFileName: null,
			faxLoader:false,
			isSignatureClear: false
		}
	},
	setup: () => {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			form: this.$store.state.consultations.validationRules.form,
			faxForm: this.$store.state.consultations.validationRules.faxForm,
            emailForm: this.$store.state.consultations.validationRules.emailForm,
            mailForm: this.$store.state.consultations.validationRules.mailForm,
		}
	},
	components: {
		'ejs-datepicker': DatePickerComponent,
		CustomDropDown,
		//   "ejs-button": ButtonComponent,
		ValidationErrorMessageList,
		CustomTimePicker,
		"ejs-signature": SignatureComponent,
		selectCheckBox,
	},
	methods: {
		keyupdate(obj) {
			const inputValue = $(`#${obj}`).val();
			var sanitizedInput = inputValue.replace(/[^\d]/g, '');
			if (sanitizedInput.length > 8) {
				sanitizedInput = sanitizedInput.substring(0, 8);
			}
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});
			$(`#${obj}`).val(formattedDate);
		},
		getConsultationsServices() {
			axios.post("custom-specialists/services-list")
				.then((response) => {
					this.serviceList = response.data.data.map((item) => {
						return { value: item.id, title: item.name };
					});
				});
		},
		fetchPatientDetail() {
			axios.post("patient/detail", { 'id': this.$route.params.patient_id })
				.then((response) => {
					if (response.data.status === 200) {
						var detail = response.data.data
						let fullAddArr = []
						if (detail.address) { fullAddArr.push(detail.address) }
						if (detail.city) { fullAddArr.push(detail.city) }
						if (detail.state) { fullAddArr.push(detail.state) }
						if (detail.zip) { fullAddArr.push(detail.zip) }
						detail.full_address = fullAddArr.join('<br/> ')
						this.patient_detail = detail
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
		async fetchProviderData() {
			var $this = this
			axios.post("json_list/provider", { roleId: [2, 3] })
				.then((response) => {
					const providerTempData = [];
					const details = response.data.data;
					$.each(details, function (key, detail) {
						if (!($this.$route.name == "PatientEChartConsultAdd" && detail.deleted_datetime)) {
							providerTempData.push({
								value: detail.user_id,
								title: detail.full_name,
							})
						}
					})
					this.mrpProviderDataSource = providerTempData

				})
		},
		handleItemSelected({ fieldName, item }) {
			this.form[fieldName] = item.value;
			if (fieldName == 'referring_practitioner_id') {
				this.physician_text = item.title
				this.fetchUserDetail()
			}

			if (fieldName == 'service_id') {
				this.service_text = item.title
				this.specialist_text = 'Select'
				this.form.specialist_id = null
				this.getCustomSpecialist()
			}
			if (fieldName == 'specialist_id') {
				this.specialist_text = item.title
				this.form.phone = (item.phone_ext || '') + (item.phone || '')
				this.form.fax = item.fax
				this.form.address = item.address
				this.form.city = item.city
				this.form.province = item.province
				this.form.zip = item.zip
			}
		},
		cancel() {
			window.$("#prescription-fax-model").modal("hide");
			window.scrollTo(0, 0);
			this.$router.push({
				name: "PatientEChartConsultList",
			});
		},
		save(action = 'save') {
			let formName = 'form'
			this.v$[formName].$validate();
			if (!this.v$[formName].$error) {
				this.$store.state.loader = true;
				this.changeLoaderStatus(action, true)
				this.form.patient_id = this.$route.params.patient_id;
				let url
				if (this.$route.name == 'PatientEChartConsultEdit') {
					url = "patient/consultations/update";
					this.form.id = this.$route.params.id;
				} else {
					url = "patient/consultations/store";
					this.form.status = 'Created'
				}

				this.form.include_fax_cover_page = 0
				let $this = this

				axios.post(url, this.form)
					.then((response) => {
						let detail = response.data.data
						this.$store.state.loader = false;
						$this.changeLoaderStatus(action, false)
						if (action == 'save_print') {
							// this.documentPath = response.data.data.patient_consult_file;
							this.currentPage = 1
							$this.viewPDF(detail.id)
							this.consultataionID = detail.id;
							this.selectedItems = [detail.id];
							console.log("this.selectedItems");
							console.log(this.selectedItems);
						} else {
							setTimeout(function () {
								$this.$filters.moshaToast(response.data.message, "success")
								$this.v$.$reset();
								$this.$router.push(
									`/patient/e-chart/${$this.$route.params.patient_id}/consultation/consult-list`
								);
							}, 400);
						}
					});

			} else {
				console.log(Object.entries(this.v$[formName]))
				for (const [key] of Object.entries(this.v$[formName])) {
					if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}
		},
		isValidDate(dateString) {
			const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
			const date = new Date(year, month - 1, day);
			return date.getFullYear() === year && (month === 0 || date.getMonth() + 1 === month) && (day === 0 || date.getDate() === day);
		},
		handleDate(obj) {
			const sanitizedInput = $(`#${obj}`).val();
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});

			// Get or create the error message element
			let errorElement = $(`#${obj}-error`);
			if (errorElement.length === 0) {
				errorElement = $(`<div id="${obj}-error" style="color: red;margin-top: 5px;"></div>`);
				$(`#${obj}`).parent().after(errorElement);
			}
			const isValidDate = (formattedDate!='') ? this.isValidDate(formattedDate) : false;
			if (isValidDate) {
				errorElement.text(''); // Clear error message if the date is valid
				if (this.form[obj]) {
					this.form[obj] = moment(sanitizedInput).format('YYYY-MM-DD');
				}
			} else {
				if(formattedDate!=''){
					errorElement.text('Invalid Date');
				}
				$(`#${obj}`).val("");
			}
		},
		resetforms() {
			Object.assign(
				this.$store.state.consultations.form,
				this.$store.state.consultations.defaultFormData
			);
		},
		changeLoaderStatus(action, status) {
			if (action == 'save') {
				this.savingloader = status
			} else if (action == 'save_print') {
				this.savingPrintloader = status
			} else if (action == 'save_fax') {
				this.savingFaxloader = status
			}
		},
		async viewPDF(id) {
			this.$store.state.loader = true;
			console.log(id);
			const response = await axios.post("patient/consultations/viewPDF", { id: id, patient_id:this.$route.params.patient_id, data: this.transfer_form.e_chart_transfer });
			this.documentPath = response.data.data.patient_consult_file;
			// const responseFile = await axios.post("/get-asset-link", { filename: response.data.data });
			// this.documentPath = responseFile.data;

			// // Fetch the PDF file
			// const responseFileObj = await fetch(this.documentPath);
			// if (!responseFileObj.ok) {
			// throw new Error(`HTTP error! Status: ${responseFileObj.status}`);
			// }

			// this.existingPdfBytes = await responseFileObj.arrayBuffer();
			// this.pdfDoc = await PDFDocument.load(this.existingPdfBytes, { ignoreEncryption: true });

			// this.totalPages = this.pdfDoc.getPageCount();
			// this.renderPage(this.currentPage);

			if (!this.documentPath) {
				throw new Error('Document path is not defined.');
			}

			// Assuming `this.documentPath` is the base64 string without data URI prefix
			const blob = this.base64ToBlob(this.documentPath); // Convert base64 to Blob
			const url = URL.createObjectURL(blob); // Create URL for the Blob

			this.form.annotatedPdfBytes = await fetch(url).then(res => res.arrayBuffer());
			this.existingPdfBytes = this.form.annotatedPdfBytes;
			this.pdfDoc = await PDFDocument.load(this.existingPdfBytes);
			await this.pdfDoc.save();

			this.totalPages = this.pdfDoc.getPageCount();
			if(this.totalPages > 0)
			{
				this.isShowPagination = true;
			}
			this.renderPage(this.currentPage);

			this.$store.state.loader = false;
			this.openSignature()
			window.$("#prescription-fax-model").modal("show");
		},
		base64ToBlob(base64, type = 'application/pdf') {
            const binaryString = atob(base64); // Decode the base64 string
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return new Blob([bytes], { type });
        },
		async renderPage(pageNumber) {
			try {
				const loadingTask = window.pdfjsLib.getDocument({ data: this.existingPdfBytes });
				const pdf = await loadingTask.promise;
				const page = await pdf.getPage(pageNumber);

				const canvas = this.$refs.pdfCanvas;
				const context = canvas.getContext('2d');

				// Set canvas dimensions to match the page at 100% scale
				const viewport = page.getViewport({ scale: 1 });
				canvas.width = viewport.width;
				canvas.height = viewport.height;

				// Render the page on the canvas
				const renderContext = {
					canvasContext: context,
					viewport: viewport,
				};
				await page.render(renderContext).promise;
			} catch (error) {
				console.error('Error rendering page:', error);
			}
		},
		goToNextPage() {
			if (this.currentPage < this.totalPages) {
				this.currentPage++;
				this.renderPage(this.currentPage);
			}
		},
		goToPreviousPage() {
			if (this.currentPage > 1) {
				this.currentPage--;
				this.renderPage(this.currentPage);
			}
		},
		async getList() {
			try {
				const [allergiesResponse, socialHistoryResponse, medicalHistoryResponse, familyHistoryResponse, prescriptionsResponse] = await Promise.all([
					axios.post("patient/allergies/list", { patient_id: this.$route.params.patient_id }),
					axios.post("patient/social-history/list", { patient_id: this.$route.params.patient_id }),
					axios.post("patient/medical-history/list", { patient_id: this.$route.params.patient_id }),
					axios.post("patient/family-history/list", { patient_id: this.$route.params.patient_id }),
					axios.post("patient/prescriptions/list", { patient_id: this.$route.params.patient_id }),
				]);

				this.otherInformation = ''

				// Process allergies response
				const allergiesList = allergiesResponse.data.data
					.map(item => item.description)
					.filter(Boolean)
					.join(', ');
				this.allergiesList = allergiesList;
				if (allergiesList) {
					this.otherInformation += "Allergies: " + allergiesList;
				}
				
				// Process allergies response
				const prescriptionList = prescriptionsResponse.data.data
					.map(item => "- "+item.start_date+", "+item.generic_name+", "+item.drug_name)
					.filter(Boolean)
					.join('\n');
				this.prescriptionList = prescriptionList;
				if (prescriptionList) {
					this.otherInformation += "\n\nMedications:\n" + prescriptionList;
				}

				// Process medical history response
				const medicalHistoryList = medicalHistoryResponse.data.data
					.map(item => item.note)
					.filter(Boolean)
					.join(', ');
				this.medicalHistoryList = medicalHistoryList;
				if (medicalHistoryList) {
					this.otherInformation += "\n\nMedical History: " + medicalHistoryList;
				}

				// Process social history response
				const socialHistoryList = socialHistoryResponse.data.data
					.map(item => item.note)
					.filter(Boolean)
					.join(', ');
				this.socialHistoryList = socialHistoryList;
				if (socialHistoryList) {
					this.otherInformation += "\n\nSocial History: " + socialHistoryList;
				}

				// Process family history response
				const familyHistoryList = familyHistoryResponse.data.data
					.map(item => item.note)
					.filter(Boolean)
					.join(', ');
				this.familyHistoryList = familyHistoryList;
				if (familyHistoryList) {
					this.otherInformation += "\n\nFamily History: " + familyHistoryList;
				}

				this.form.other_information = this.otherInformation;
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		},
		async fetchData() {
			await this.getList();
			await this.getCustomSpecialist();
			await this.getSpecialistList()
		},
		fetchUserDetail() {
			this.$store.state.loader = true;
			let url = "user/retrieve";
			axios.post(url, { id: this.form.referring_practitioner_id })
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						this.sigImgBase64 = response.data.data.base64_sign
					}

				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						console.log(Object.values(error.response.data.data).join(', '))
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		openDatePicker(refName) {
			var schObj = document.querySelector(`#${refName}`).ej2_instances[0];
			schObj.show();
		},
		getClinicDetail(){
			axios.post("admin/clinic/retrieve")
				.then((response) => {
					if (response.status == 200) {
						this.clinicDetail = response.data.data
					}else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		getSpecialistList(){
			console.log("at here to fecth datya");
			axios.post("custom-specialists/list", {specialist_type:this.form.service_id})
				.then((response) => {
					if (response.status == 200) {
						const details = response.data.data;
						details.sort((a, b) => {
							return (b.is_preferred_specialist == true) - (a.is_preferred_specialist == true);
						});

						const specialistTempData = details.map((item) => {
							let name = item.name;
							if (item.is_preferred_specialist) {
								name += "<p class='preferred-label float-end mb-0 mt-10'>(Preferred)</p>"
							}
							if (item.address){
								name += "<p class='address-line mb-0'>"+item.address+"</p>"
							}
							return { id: item.id, name: item.name, checked: false, displayLabel: name };
						});
						this.specialist_option = specialistTempData;
						console.log(this.specialist_option);
						console.log(this.specialist_option);
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.loader = false;
					console.log(error)
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		edit(){
			this.getCustomSpecialist()
			this.$store.state.loader = true;
			axios.post("patient/consultations/retrieve", { 'id': this.$route.params.id })
			.then((response) => {
				if (response.data.status === 200) {
					let detail = response.data.data
					this.physician_text = (this.mrpProviderDataSource.find(item => item.value == detail.referring_practitioner_id) || {}).title || "Select Primary Physician";

					let specialistItem = (this.specialistsList.find(item => item.id == detail.specialist_id) || {}) || {};
					this.service_text =(this.specialistsList.find(item => item.id == detail.specialist_id) || {}).specialist_type || "Select";
					this.$store.state.consultations.form = detail;
					this.form.service_id = (this.serviceList.find(service => service.title == this.service_text) || {}).value || null;
					this.handleItemSelected({fieldName: 'service_id', item:{value:this.form.service_id, title:this.service_text}});

					this.specialist_text = (this.specialistsList.find(item => item.id == detail.specialist_id) || {}).title || "Select";
					this.handleItemSelected({fieldName: 'specialist_id', item:specialistItem});

					this.fetchUserDetail()

					this.$store.state.loader = false;
				} else {
					this.$filters.moshaToast(response.data.message, "error")
					this.$store.state.loader = false;
				}
			}).catch(error => {
				this.$filters.moshaToast(error.message, "error")
				this.$store.state.loader = false;
			});
		},
		getCustomSpecialist(){
			const selectedService = this.serviceList.find(service => service.value == this.form.service_id);
			const serviceName = selectedService ? selectedService.title : null;
			const advanceFilter = {};
			advanceFilter.specialist_type = serviceName;
			axios.post("custom-specialists/list", { advanceFilter })
				.then((response) => {
					if (response.status == 200) {
						const details = response.data.data;
						const specialistTempData = [];
						$.each(details, function (key, detail) {
							detail.value = detail.id
							detail.title = detail.name
							detail.isFrom = 'consultation'
							specialistTempData.push(detail)
						})
						this.specialistsList = specialistTempData.sort((a, b) => {
							return b.is_preferred_specialist - a.is_preferred_specialist;
						});
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.loader = false;
					console.log(error)
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		changeAppointmentTime(){
			this.form.appointment_time = this.form.appointment_time+":00";
		},
		getPreferredPharamcy(){
			axios.post("patient/get-preferred-pharamcy", { patient_id: this.$route.params.patient_id })
				.then((response) => {
				this.pharmacy_detail = response.data.data
			})
		},
		async viewPdfInNewTab() {
			// Convert the PDF bytes to a Blob
			const blob = new Blob([this.existingPdfBytes], { type: 'application/pdf' });

			// Create a URL for the Blob
			const url = URL.createObjectURL(blob);

			// Open the Blob URL in a new tab
			window.open(url, '_blank');
			// this.cancelPrint()
		},
		cancelPrint(){
			this.v$.$reset();
			window.$("#prescription-fax-model").modal("hide");
			window.scrollTo(0, 0);
			// this.$router.push(
			// 	`/patient/e-chart/${this.$route.params.patient_id}/consultation/consult-list`
			// );

		},
		sendFaxModel() {
			this.v$['faxForm'].$reset();
			this.v$['emailForm'].$reset();
			this.v$['mailForm'].$reset();
			Object.assign(
				this.$store.state.consultations.faxForm,
				this.$store.state.consultations.defaultexportfrm
			);
			Object.assign(
				this.$store.state.consultations.emailForm,
				this.$store.state.consultations.defaultexportfrm
			);
			Object.assign(
				this.$store.state.consultations.mailForm,
				this.$store.state.consultations.defaultexportfrm
			);
			this.tagSpecialistLabel = ''
			this.specialist_option.map((item) => {
				item.checked = false;
			});
			this.isFaxFormVisible=true
			this.isEmailFormVisible=false
			this.isMailFormVisible=false

			window.$('#add-exportconsultation-model').modal('show');
		},
		changeExportOpt(type){
			this.isFaxFormVisible=false
			this.isEmailFormVisible=false
			this.isMailFormVisible=false

			if (type == 'fax') {
				this.isFaxFormVisible=true
			} else if (type == 'email') {
				this.isEmailFormVisible=true
			} else if (type == 'mail') {
				this.isMailFormVisible=true
			}
		},
		selectedSpecialist(value){
			this.specialist_option = value;
			const specialist_list = this.specialist_option.map((item) => {
				return (item.checked == true) ? item.id : null;
			});

			this.faxForm.specialist_id = specialist_list.filter(n => n);
			if (this.faxForm.specialist_id.length > 0) {
				if (this.faxForm.specialist_id.length == 1) {
					this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist Selected`;
				} else {
					this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist(s) Selected`;
				}
			} else {
				this.tagSpecialistLabel = "";
			}
		},
		closeExportModel(){
			window.$("#add-exportconsultation-model").modal("hide");
		},
		exportSubmit(){
			let form = null
			let formName = 'faxForm'
			if (this.isFaxFormVisible) {
				form = { ...this.faxForm };
				form.type = "fax"
				formName = 'faxForm';
				form.fax_number = this.faxForm['phoneNumbers'].join(', ');
			} else if(this.isEmailFormVisible){
				form = this.emailForm
				form.type = "email"
				formName = 'emailForm';
			} else if (this.isMailFormVisible){
				form = this.mailForm
				form.type = "mail"
				formName = 'mailForm';
			}
			form.ids = this.selectedItems
			form.patient_id = (this.$route.params.patient_id) ? this.$route.params.patient_id : null;
			// form.user_signature = this.$refs.signatureObj;
			form.user_signature = this.updateDateSignature
			form.combinedPDF = this.documentPath
			this.v$[formName].$validate();

			if (!this.v$[formName].$error) {

				this.faxLoader = true

				let url = "patient/consultations/export"
					axios.post(url, form)
						.then((response) => {
                            this.faxLoader = false
                            if (response.status == 200) {
								if (formName == 'faxForm') {
									let allSuccess = false
									for (const detail of response.data.data) {
										if (detail.status == 'success'){
											allSuccess = true
											this.$filters.moshaToast(detail.msg, "success");
										} else {
											this.$filters.moshaToast(detail.msg, "error");
										}
									}
									if (allSuccess) {
										this.closeExportModel()
									}
								} else {
									this.$filters.moshaToast(response.data.message, "success");
									this.closeExportModel()
								}
                            } else {
                                this.$filters.moshaToast(response.data.message, "error");
                            }
						}).catch(error => {
							this.faxLoader = false
							this.$filters.moshaToast(error.response.data.message, "error");
						});
			} else {
				console.log(Object.entries(this.v$[formName]))
				this.faxLoader = false
				for (const [key] of Object.entries(this.v$[formName])) {
					if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
			}
		},
        formatFaxNumber(){
            const { fax_number, phoneNumbers } = this.faxForm;

            // Check the length of phoneNumbers after formatting
            if (this.faxForm.phoneNumbers.length >= 5 && this.faxForm.fax_number) {
                this.faxForm.fax_number = ''; // Clear the fax_number
                this.$filters.moshaToast("You cannot add more than five recipient fax numbers for sending.", "error");
                return false; // Prevent further processing
            }

			// Call the globally defined formatFaxNumber
			const result = this.$filters.formatFaxNumber(fax_number, phoneNumbers);

            // Update the model based on the result
            this.faxForm.fax_number = result.faxNumber;
            this.faxForm.phoneNumbers = result.phoneNumbers;

        },
        removeSelectedFax(numberToRemove){
            const updatedPhoneNumbers = this.faxForm.phoneNumbers.filter(phone => phone !== numberToRemove);
            this.faxForm.phoneNumbers = updatedPhoneNumbers
        },
        handlePaste(event) {
            // Get the pasted content
            const pasteData = (event.clipboardData || window.clipboardData).getData("text");
            // Allow only digits and "-"
            let sanitizedData = pasteData.replace(/[^0-9-]/g, "");

            // If the sanitized data is empty, reject the paste
            if (!sanitizedData) {
                console.log("Invalid input: Only numbers and '-' are allowed.");
                event.preventDefault();
                return;
            }

            // Remove "-" from the sanitized data
            sanitizedData = sanitizedData.replace(/-/g, "");

            // Determine max length based on presence of "-"
            const currentLength = this.faxForm.fax_number ? this.faxForm.fax_number.length : 0;
            const allowedLength = this.maxLength - currentLength;
            // Trim pasted content to fit the remaining allowed length
            const trimmedData = sanitizedData.slice(0, allowedLength);

            // Update the input value
            this.faxForm.fax_number = (this.faxForm.fax_number || "") + trimmedData;
        },
		handleKeyDown(event) {
            if (event.key == 'Backspace' && this.faxForm.fax_number) {
                this.faxForm.fax_number = this.faxForm.fax_number.slice(0, -1);
                event.preventDefault();
            }

			const allowedKeys = [
				"Backspace",
				"ArrowLeft",
				"ArrowRight",
				"Tab",
				"-",
			];
			const isAllowedKey = allowedKeys.includes(event.key);
			const isDigit = event.key >= "0" && event.key <= "9";
			const isPasteShortcut = (event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "v";
			if (!isDigit && !isAllowedKey && !isPasteShortcut) {
				event.preventDefault();
			}

        },
		checkCount(field_name) {
			if(field_name == 'reason-for-consultation') {
				console.log("this.form.reason_for_consultation");
				console.log(this.form.reason_for_consultation.trim().length)
				if(this.form.reason_for_consultation.trim().length > 1000) {
					this.form.reason_for_consultation = this.form.reason_for_consultation.slice(0, 1000);
					this.$filters.moshaToast("You have exceeded the 1000-character limit for Reason for Consultation", "error");
				}	
			}
			if(field_name == 'appointment-instructions') {
				if(this.form.referrer_instructions.trim().length > 80) {
					this.form.referrer_instructions = this.form.referrer_instructions.slice(0, 80);
					this.$filters.moshaToast("You have exceeded the 80-character limit for Appointment Instructions", "error");
				}	
			}
		},
		openAttachment(){
			window.$('#attachments-echart-modal').modal('show');

		},
		closeEChartTransferPop() {
			window.$("#attachments-echart-modal").modal("hide");
		},
		updateStartDate(value) {
			this.selectedStartDate = this.formatDatePickerDate(value);
			this.transfer_form.e_chart_transfer.from_date = moment(value).format('YYYY-MM-DD');
		},
		updateEndDate(value) {
			this.selectedEndDate = this.formatDatePickerDate(value);
			this.transfer_form.e_chart_transfer.to_date = moment(value).format('YYYY-MM-DD');
		},
		openDateRangePicker(type) {
			if (type === 'start') {
				this.$refs.startDatePicker.show();
			} else if (type === 'end') {
				this.$refs.endDatePicker.show();
			}
		},
		printEchart(){
			if(this.transfer_form.e_chart_transfer.is_all_time_range == false)
			{
				if(this.transfer_form.e_chart_transfer.from_date == null)
				{
					this.$filters.moshaToast('Required to select from date', "error");
					return false;
				}
				if(this.transfer_form.e_chart_transfer.to_date === null)
				{
					this.$filters.moshaToast('Required to select to date', "error");
					return false;
				}
			}
			else
			{
				this.transfer_form.e_chart_transfer.from_date = null;
				this.transfer_form.e_chart_transfer.to_date = null;
			}
			if(
				this.transfer_form.e_chart_transfer.is_encounter_notes == false && 
				this.transfer_form.e_chart_transfer.is_files == false &&
				this.transfer_form.e_chart_transfer.is_labs == false &&
				this.transfer_form.e_chart_transfer.is_preventions == false &&
				this.transfer_form.e_chart_transfer.is_other_infos ==  false &&
				this.transfer_form.e_chart_transfer.is_medication == false
			)
			{
				this.$filters.moshaToast('Required to select any one content', "error");
				return false;
			}

			this.transfer_form.e_chart_transfer.is_attach = true;

			window.$('#attachments-echart-modal').modal('hide');
		},
		clearSignature(){
			this.isSignatureClear = true;
			this.$refs.signatureObj.clear();
			this.onDraw()
		},
		async updatePDF(){
			const response = await axios.post("patient/consultations/viewPDF", { id: this.consultataionID, transfer_form: this.transfer_form.e_chart_transfer, signature: this.updateDateSignature, previous_file: this.previousFileName });
			const responseFile = await axios.post("/get-asset-link", { filename: response.data.data });
			this.previousFileName = response.data.data;
			this.documentPath = responseFile.data;

			// Fetch the PDF file
			const responseFileObj = await fetch(this.documentPath);
			if (!responseFileObj.ok) {
			throw new Error(`HTTP error! Status: ${responseFileObj.status}`);
			}

			this.existingPdfBytes = await responseFileObj.arrayBuffer();
			this.pdfDoc = await PDFDocument.load(this.existingPdfBytes, { ignoreEncryption: true });

			this.totalPages = this.pdfDoc.getPageCount();
			this.renderPage(this.currentPage);

			// this.$store.state.loader = false;
			// this.openSignature()
		},
		async onDraw(){
			// Get a reference to the ejs-signature component
			const signatureComponent = this.$refs.signatureObj;

			// Check if the ref is defined
			if (signatureComponent) {
				// Save the signature data as a Blob
				const signatureData = signatureComponent.saveAsBlob();
				console.log(signatureData);

				this.signatureValidationMsg = null
				if (signatureData.size <= 1224) {
					this.isSignatureValidate = false
				} else {
					this.isSignatureValidate = true
				}

				const signatureBase64 = await this.blobToBase64(signatureData);
				this.signatureImage = signatureBase64;
				console.log(signatureBase64);

				this.$refs.signatureObj = signatureData;
				this.updateDateSignature = signatureBase64;

				setTimeout(() => {
					this.updatePDF();
				}, 2000);

			} else {
				console.error("Signature component is not mounted or ref is not set");
			}
		},
		blobToBase64(blob) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					resolve(reader.result);
				};
				reader.onerror = reject;
				reader.readAsDataURL(blob);
			});
		},
		openSignature() {
			try {
				const signatureComponent = this.$refs.signatureObj;
				if (signatureComponent) {
				const ej2Instance = signatureComponent.ej2Instances;
				if (ej2Instance && this.sigImgBase64) {
					// Load the base64 image into the Signature component
					ej2Instance.load(this.sigImgBase64);

					// We can't directly manipulate canvas, so we need to extract the canvas
					const canvas = ej2Instance.canvas;
					if (canvas) {
					// Adjust canvas size (height = 60, width = 429)
					canvas.height = 60;
					canvas.width = 429;

					const context = canvas.getContext('2d');
					if (context) {
						const img = new Image();
						img.src = this.sigImgBase64;

						img.onload = () => {
						// Clear the canvas and draw the image resized
						context.clearRect(0, 0, canvas.width, canvas.height);
						context.drawImage(img, 0, 0, canvas.width, canvas.height); // Draw the resized image

						// Optional: Convert resized image back to base64 if needed
						const resizedImageBase64 = canvas.toDataURL('image/jpeg', 0.8);
						console.log("Resized Image Base64:", resizedImageBase64);
						};
					} else {
						console.error("Failed to get 2D context from canvas.");
					}
					} else {
					console.error("Canvas not found in ej2Instance.");
					}
				} else {
					console.error("No signature to load or ej2Instance not found.");
				}
				} else {
				console.error("Signature component reference not found.");
				}
			} catch (error) {
				console.error("Failed to load signature.", error);
				this.errorMessage = "Failed to load signature.";
			}
		},
		removeAttach(){
			this.transfer_form.e_chart_transfer.is_attach = false
		},
		isUserDocORNurse() {
			let userRole = localStorage.getItem('authUserRole');
			if(userRole == 'Doctor' || userRole == 'Nurse') {
				return true;
			}
			return false;
		},
	},
	created() {
		this.resetforms()
		this.fetchProviderData()
		this.getConsultationsServices()
		this.fetchPatientDetail()
		this.getClinicDetail()
		this.getPreferredPharamcy()
		if (this.$route.name == "PatientEChartConsultEdit") {
			this.edit()
		}
	},
	computed: {
		form() {
			return this.$store.state.consultations.form;
		},
		faxForm(){
			return this.$store.state.consultations.faxForm;
		},
		emailForm(){
			return this.$store.state.consultations.emailForm;
		},
		mailForm(){
			return this.$store.state.consultations.mailForm;
		},
	},
	mounted() {
		if (this.$route.name == "PatientEChartConsultAdd") {
			this.fetchData();
			const authUserRole = localStorage.getItem('authUserRole');
			if (authUserRole === 'Doctor' || authUserRole === 'Nurse') {
				const userId = localStorage.getItem('userId');
				const authUserName = localStorage.getItem('authUserName');
	
				this.form.referring_practitioner_id = userId
				this.physician_text = authUserName;
				this.fetchUserDetail()
			}
		}
		var $this = this;
		$(document).on("click", '._datepic', function () {
			const inputField = $(this).find('input');
			const inputId = inputField.attr('id');
			$this.openDatePicker(inputId);
		});

		const pdfScript = document.createElement('script');
		pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.min.js';
		// Letest
		// pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.min.js';
		// client
		// pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.min.js';
		pdfScript.onload = () => {
			const workerScript = document.createElement('script');
			workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';
			// Letest
			// workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.worker.min.js';
			// client
			// workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf-lib/1.17.1/pdf-lib.min.js';
			workerScript.onload = () => {
				// this.initializePdf();
			};
			document.head.appendChild(workerScript);
		}
		document.head.appendChild(pdfScript);

	}
}
</script>

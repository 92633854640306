<template>
	<div ref="dropdown" class="Selact_drodwn" :class="{ 'Selact_drodwn_upside': isOpenUpSide }">
		<div class="dropdown-div" :class="{ 'disabled': isDisabled }" @click="toggleList($event)">
			<div :class="['form-control caption select-list-label', divClass, { 'cur_po_arrow': isFromViewInvoice }]">
				<label v-if="labelText" v-html="labelText"></label>
				<span v-html="selectedValue" :class="[{ 'active': isSelectSeverityOfReaction || isSelectLifeStage || isSelectQTOperator || isSelectQTParameter || isSelectQTModule || isSelectInvoiceType || isSelectInvoiceStatus || isSelectDayOfWeek || isSelectWeekInterval || isSelectProvider || getDefaultClass || (isFrom === 'update') || invoiceListCookieStorageStatus || invoiceListCookieStorageType }, defaultSelectedClass]"></span>
				<img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': showList }">
			</div>
			<div class="list select-list-box" :class="{ 'd-none': !showList }">
				<slot v-for="(item, index) in options" :key="index">
					<slot v-if="item.isFrom === 'consultation' || item.isFrom === 'templateEdit'">
						<div class="item specialist-preferred-li d-flex justify-content-between" @click="selectItem(item)">
							<div class="specialist-name-adrs">
								<p class="specialist_Name">{{ item.name }}</p>
								<p class="specialist_location">{{ item.address }}</p>
							</div>
							<p class="Preferred-label" v-if="item.is_preferred_specialist">(Preferred)</p>
						</div>
					</slot>
					<slot v-else-if="item.displayLabel">
						<div class="item" @click="selectItem(item)" :class="{ active: this.selectedValue == item.title }" v-html="item.displayLabel">

						</div>	
					</slot>
					<slot v-else>
						<div class="item" @click="selectItem(item)" :class="{ active: this.selectedValue == item.title }" v-html="item.title">
						</div>
					</slot>
				</slot>
			</div>
		</div>
	</div>
</template>
  
<script>
import $ from "jquery";
export default {
	props: {
		options: {
			type: Array,
			required: true,
		},
		initialValue: {
			type: String,
			default: 'Select',
		},
		fieldName: {
			type: String,
			required: true,
		},
		divClass: {
			type: String,
			default: "",
		},
		labelText: {
			type: String,
			default: "",
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		isFromViewInvoice: {
			type: Boolean,
			default: false,
		},
		isFrom: {
			type: String,
			default: "create",
		},
		defaultSelectedClass: {
			type: String,
			default: "",
		},
		dropdownDefaultSelectedClass: {
			type: Array,
			default: () => [],
		},
		invoiceListCookieStorageStatus: {
			type: Boolean,
			default: false,
		},
		invoiceListCookieStorageType: {
			type: Boolean,
			default: false,
		},
		isCreateInvoice: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectedValue: this.initialValue,
			showList: false,
			isOpenUpSide: false,
			isSelectProvider: false,
			isSelectWeekInterval: false,
			isSelectInvoiceStatus: false,
			isSelectQTParameter: false,
			isSelectSeverityOfReaction: false,
			isSelectDayOfWeek: false,
			isSelectInvoiceType: false,
			isSelectQTModule: false,
			isSelectQTOperator: false,
			isSelectLifeStage: false,

		};
	},
	methods: {
		toggleList($event) {
			$event.stopPropagation();
			if (this.isCreateInvoice) {
				this.$filters.moshaToast('Invoice Type cannot be changed on existing invoice', "error");
				return false
			}
			if (this.isDisabled) {
				return true
			}
			
			const selectListBoxes = document.querySelectorAll('.select-list-box');
			const rotateElements = document.querySelectorAll('.rotate180');
			if(rotateElements){
				rotateElements.forEach(function(rotateElement) {
					rotateElement.classList.remove('rotate180');
				});
			}
			selectListBoxes.forEach(function(selectListBox) {
				const nextSelectListBox = $($event.target).parent().next('.select-list-box')[0];
				const findSelectListBox = $($event.target).parent().find('.select-list-box')[0];
				if (selectListBox === nextSelectListBox || selectListBox === findSelectListBox) {
					if (selectListBox === findSelectListBox) {
						if($($event.target).parent().find('.select-list-box').hasClass('d-none')){
							$($event.target).parent().find('.select-list-box').removeClass("d-none")
							$($event.target).parent().find(".img-fluid").addClass("rotate180");
						}else{
							$($event.target).parent().find('.select-list-box').addClass("d-none")
							$($event.target).parent().find(".img-fluid").removeClass("rotate180");
						}
					}else{
						if($($event.target).parent().next('.select-list-box').hasClass('d-none')){
							$($event.target).parent().next('.select-list-box').removeClass("d-none")
							$($event.target).parent().find(".img-fluid").addClass("rotate180");
						}else{
							$($event.target).parent().next('.select-list-box').addClass("d-none")
							$($event.target).parent().find(".img-fluid").removeClass("rotate180");
						}
					}
				}else{
					$(selectListBox).addClass('d-none');
				}
			});
			
			if (!this.isDisabled) {
				if (this.isCreateInvoice) {
					this.$filters.moshaToast('Invoice Type cannot be changed on existing invoice', "error");
					return false;
				}
				this.checkDropdownPosition();
			}
		},
		checkDropdownPosition() {
			this.$nextTick(() => {
				const dropdown = this.$refs.dropdown;
				const dropdownRect = dropdown.getBoundingClientRect();
				const listHeight = dropdown.querySelector('.select-list-box').offsetHeight;
				let viewportHeight = window.innerHeight;
				let totalDropdownHeight = dropdownRect.bottom + listHeight
				console.log("viewportHeight Old: "+viewportHeight)
				if (this.fieldName && typeof this.fieldName === 'string') {
					const regex = /^patient_vital_form.vital\.(\d+)$/;
					const match = this.fieldName.match(regex);
					if (match) {
						// let scaleRatio = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--scale-ratio'));
						if (viewportHeight > 1100){
							totalDropdownHeight += 470
						} else if (viewportHeight <= 1100 && viewportHeight >= 1000){
							totalDropdownHeight += 200
						} else if (viewportHeight < 1000 && viewportHeight >= 900){
							totalDropdownHeight += 158
						} else if (viewportHeight < 900 && viewportHeight >= 800){
							totalDropdownHeight += 120
						} else if (viewportHeight < 800 && viewportHeight >= 700){
							totalDropdownHeight += 160
						} else {
							totalDropdownHeight += 70
						}
					}

					console.log("dropdownRect.bottom "+dropdownRect.bottom)
					console.log("listHeight "+listHeight)
					console.log("dropdownRect.bottom + listHeight "+totalDropdownHeight)
					console.log("viewportHeight "+viewportHeight)
					if (totalDropdownHeight >= viewportHeight) {
						this.isOpenUpSide = true;
					} else {
						this.isOpenUpSide = false;
					}
					this.$emit('dropdown-clicked');
				}
			});
		},
		selectItem(item) {
			if (this.fieldName === 'claim_type') {
				this.selectedValue = item.title.split('|')[0].trim();
			} else if (this.fieldName === 'type') {
				this.isSelectInvoiceType = true;
				if (!this.isCreateInvoice) {
					this.selectedValue = item.title;
				}
			} else if (this.fieldName === 'status') {
				this.isSelectInvoiceStatus = true;
				this.selectedValue = item.title;
			} else if (this.fieldName === 'roleId') {
				this.isSelectInvoiceStatus = true;
				this.selectedValue = item.title;
			} else if ((typeof this.fieldName === 'string' || Array.isArray(this.fieldName)) && this.fieldName.includes('week_day_')) {
				this.isSelectDayOfWeek = true;
				this.selectedValue = item.value;
			} else if ((typeof this.fieldName === 'string' || Array.isArray(this.fieldName)) && this.fieldName.includes('recurrence_week_interval')) {
				this.isSelectWeekInterval = true;
				this.selectedValue = item.value;
			} else if (this.fieldName === 'provider_list') {
				this.isSelectProvider = true;
				this.selectedValue = item.title;
			} else if (this.fieldName === 'module_name') {
				this.isSelectQTModule = true;
				this.selectedValue = item.title;
			} else if (this.fieldName === 'parameter') {
				this.isSelectQTParameter = true;
				this.selectedValue = item.title;
			} else if (this.fieldName === 'operator') {
				this.isSelectQTOperator = true;
				this.selectedValue = item.title;
			}  else if (this.fieldName === 'life_stage') {
				this.isSelectLifeStage = true;
				this.selectedValue = item.title;
			} else if (this.fieldName === 'severity_of_reaction') {
				this.isSelectSeverityOfReaction = true;
				this.selectedValue = item.title;
			} else {
				this.selectedValue = item.title;
			}

			this.$emit('item-selected', { fieldName: this.fieldName, item });
			this.$emit('item-change', this.selectedValue);
			this.$emit('dropdown-clicked');
			setTimeout(() => {
				this.hideAll();
			}, 100);
		},
		handleClickOutside(event) {
			const dropdownEle = this.$refs.dropdown;
			if (dropdownEle && !dropdownEle.contains(event.target)) {
				this.hideAll();
			}
		},
		hideAll(){
			const selectListBoxes = document.querySelectorAll('.select-list-box');
			const rotateElements = document.querySelectorAll('.rotate180');
			selectListBoxes.forEach(function(selectListBox) {
				$(selectListBox).addClass('d-none');
			});
			if(rotateElements){
				rotateElements.forEach(function(rotateElement) {
					rotateElement.classList.remove('rotate180');
				});
			}
		},
	},
	computed: {
		getDefaultClass() {
			return this.dropdownDefaultSelectedClass.includes(this.fieldName) ? 'active' : '';
		},
	},
	watch: {
		initialValue(newValue) {
			if (newValue) {
				this.selectedValue = newValue;
				if (this.fieldName === 'roleId') {
					this.isSelectInvoiceStatus = true;
				}
				if (this.fieldName === 'provider_list') {
					this.isSelectProvider = true;
				}
			}
		},
	},
	mounted() {
		window.addEventListener('click', this.handleClickOutside);
	}
};
</script>

import {
	helpers,
	required,
	email,
	maxLength,
	minLength
} from "@vuelidate/validators";
import moment from "moment";

const alpha = helpers.withMessage(
    'Please enter only alphabets.',
    value => /^[A-Za-z]*$/.test(value)
);

const patient = {
	namespaced: true,

	state: {
		listUrl: "/patient/list",
		header: [
			{ "patient_id": "Demographic No." },
			{ "": "Module" },
			{ "first_name": "Name" },
			{ "chart_id": "Chart No." },
			{ "gender": "Sex" },
			{ "dob": "DOB" },
			{ "health_insurance_no": "HIN" },
			{ "provider_name": "Doctor" },
			// { "roster_status": "Roster Status" },
			{ "status": "Patient Status" },
			{ "cell_phone": "Phone" },
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		form: {
			first_name: null,
			last_name: null,
			middle_name: null,
			title: null,
			suffix: null,
			gender: null,
			spoken_language: null,
			dob: null,
			country: null,
			aboriginal:null,
			provider_id:null,
			email: null,

			// endroll_to_physician: null,
			// endroll_to_physician_ohip: null,
			// endroll_to_physician_cpso: null,
			// roster_status: null,
			// roster_date: null,
			// termination_date: null,
			// termination_reason: null,

			// patient_label: null,
			status: 'AC',
			patient_status_text: 'Active',
			patient_status_date:new Date(),
			// patient_status_start_date: null,
			// patient_status_end_date: null,
			chart_id: null,
			// joined_date: new Date(),
			// end_date: null,

			// mrp_provider: null,
			// nurse_provider: null,
			// midwife_provider: null,
			// resident_provider: null,
			// referral_provider_name: null,
			// referral_provider_ohip: null,
			// family_provider_name: null,
			// family_provider_ohip: null,

			// Patient Contact
			home_phone: null,
			work_phone: null,
			work_phone_ext:null,
			cell_phone: null,
			phone_comment:null,
			residential_address: null,
			residential_city: null,
			residential_state: null,
			residential_country:null,
			residential_zip: null,
			is_same_residential_mailing: true,
			mailing_address: null,
			mailing_city: null,
			mailing_state: null,
			mailing_zip: null,
			mailing_country:null,

			// newsletter: null,
			// notify_opt_out: null,
			// notify_un_subscribed: null,

			health_insurance_no: null,
			health_card_ver:null,
			health_card_province_id: null,
			// eff_date: null,
			renew_date: null,
			// expiry_date:null,
			// sin: null,
			alert: null,
			notes: null,
			// is_inside_use: false,
			// waiting_title: null,
			// waiting_list_note: null,
			// request_date: '',
			// is_deny_online_booking: false,
			// is_prescribe_opt: false,
			heath_card_province_text: '',
			title_text:"Select Title",
			gender_text:"Select Gender",
			spoken_language_text:"Select Spoken Language"

		},
		defaultFormData: {
			first_name: null,
			last_name: null,
			middle_name: null,
			title: null,
			suffix: '',
			gender: null,
			spoken_language: null,
			dob: null,
			country: null,
			aboriginal:null,
			provider_id:null,
			email: null,
			status: 'AC',
			chart_id: null,
			home_phone: null,
			work_phone: null,
			work_phone_ext:null,
			cell_phone: null,
			phone_comment:null,
			residential_address: null,
			residential_city: null,
			residential_state: null,
			residential_country:null,
			residential_zip: null,
			is_same_residential_mailing: true,
			mailing_address: null,
			mailing_city: null,
			mailing_state: null,
			mailing_zip: null,
			mailing_country:null,
			health_insurance_no: null,
			health_card_ver:null,
			health_card_province_id: null,
			renew_date: null,
			alert: null,
			notes: null,
			patient_status_text: 'Active',
			patient_status_date:moment(new Date()).format('YYYY-MM-DD'),
			heath_card_province_text: 'Select Health Card Province',
			title_text:"Select Title",
			gender_text:"Select Gender",
			spoken_language_text:"Select Spoken Language",

			alternative_contact_detail:[
				{
					"first_name":"",
					"relationship":"",
					"contact_purpose":"",
					"contact_purpose_text":"Select Purpose",
					"relationship_text":"Select Relationship",
				},
				{
					"first_name":"",
					"relationship":"",
					"contact_purpose":"",
					"contact_purpose_text":"Select Purpose",
					"relationship_text":"Select Relationship",
				},
			],
		},
		validationRules: {
			form: {
				first_name: {
					required: helpers.withMessage("Please enter patient's first name.", required),
				},
				last_name: {
					required: helpers.withMessage("Please enter patient's last name.", required),
				},
				email: {
					email: helpers.withMessage("Email address is not valid", email),
				},
				gender: {
					required: helpers.withMessage("Please select patient's gender.", required)
				},
				status: {
					required: helpers.withMessage("Please select patient's status.", required)
				},
				work_phone_ext:{
					max: maxLength(10),
				},
				residential_zip:{
					max: maxLength(10),
				},
				mailing_zip:{
					max: maxLength(10),
				},
				dob:{
					required: helpers.withMessage("Please select date of birth.", required)
				},
				patient_status_date:{
					required: helpers.withMessage("Please select patient status date.", required)
				},
				health_card_ver:{
					maxLength:helpers.withMessage("Please enter Health card ver. max 2 char.", maxLength(2)),
					minLength:helpers.withMessage("Please enter Health card ver. atleast 2 char.", minLength(2)),
					alpha
				},
				cell_phone:{
					required: helpers.withMessage("Please entrr cell phone.", required),
					max: maxLength(20),
				}
			},
		},
		vuelidateExternalResults: {
			form: {
				first_name: '',
				last_name: '',
				gender: '',
				status: ''
			}
		},
		list: [],
	},

	getters: {},
	mutations: {},
	actions: {},
};


export default patient;
